// import { apiSlice } from '../api/apiSlice'
// import 

import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const STOCK_BASE = "/stocks";
const buildUrl = (endpoint) => STOCK_BASE + endpoint;

const stockApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStock: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Stock' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getStock', 'Stock')
        }),
        getStockForProduct: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Stock' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getStockForProduct', 'StockForProduct')
        }),
        
        getStockById: builder.query({
            query: stockId => buildUrl(`/${stockId}`),
            providesTags: (result, error, arg) => [{ type: 'Stock', id: arg }]
        }),
        getStockMutations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/mutations?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'StockMutations' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getStockMutations', 'StockMutations')
        }),
    })
});

export const { useGetStockQuery,useGetStockForProductQuery, useGetStockByIdQuery,useGetStockMutationsQuery } = stockApiSlice;
