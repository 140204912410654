import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import SHModal from "../theme/Modal/SHModal";
import { tokens } from "../../theme";
import ReturnPortalViewPort from "./ViewPort";
// import { required } from '../../utils/validation';



const MPortalPreview = (props) => {
    
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

  

    return(
        
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box
                sx={{
                    maxHeight:"85vh",
                    overflow:"auto"
                }}
            >
                <ReturnPortalViewPort
                    addModal={props.addModal} 
                    setAddModalOpen={props.setAddModalOpen}
                    open={true}
                >
                    {props.children}
                </ReturnPortalViewPort>
            </Box>
        </SHModal>
    )
}

export default MPortalPreview;

