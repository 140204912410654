import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import Pickup from "./Views/Pickup";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";
import Authorisation from "./Views/Authorisation";
import { ReactComponent as DhlLogo } from '../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../../../styles/svg/bol.svg';
import { spShipperDetailsConfig } from "./spShipperDetailsConfig";

const SidePanelActions = (props) => {    
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpShipperDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props

    
    

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: "green",
            content: <LocalShippingOutlinedIcon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended}/>   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && data !== null && typeof data.id !== "undefined") {
            
        let shipperLogo;
    switch (data?.type) {
        case 'dhl':
            shipperLogo = <DhlLogo />;
            break;
        case 'postnl':
            shipperLogo = <PostNLLogo />;
            break;
        case 'dpd':
            shipperLogo = <DpdLogo/>;
            break;
        case 'bol':
            shipperLogo = <BolLogo/>;
            break;
        default:
            shipperLogo = <LocalShippingOutlinedIcon/>
            break;
    }
      
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data.name,
            subTitle: data.reference ?? "",
            icon :{
                theme: "green",
                content: shipperLogo
            },
            options: [
                // {
                //     heading: t('zone'),
                //     content: data.warehouseZone?.description
                // },
                // {
                //     component: <MultiProductBox 
                //     multiproduct={data.multiproduct} 
                //     multiproductzone={data.warehouseZone.multiproduct} 
                //     multibatchzone={data.warehouseZone.multibatch}/>
                // },
                // {
                //     heading: t('location_type'),
                //     content: data.warehouseLocationType?.description
                // },
                // {
                //     heading: t('reach_type'),
                //     content: data.reachType?.description
                // }
            ],
          }));
        }
      }, [data]);

    return (
        <Sidepanel2
            tabConfig={spShipperDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spShipperDetailsConfig.details} data={props.data}/>
                    <Pickup config={spShipperDetailsConfig.pickup} data={props.data}/>
                    <Authorisation config={spShipperDetailsConfig.authorisation} data={props.data} />
                    {/* { <Barcodes id={props.data ? props.data.id : -1} apiRef={barcodesApiRef}/> } */}
                    {/* { <Channels id={props.data ? props.data.id : -1} apiRef={channelsApiRef}/> } */}
                    {/* { <Logs id={props.data ? props.data.id : -1} apiRef={logsApiRef}/> } */}
                </>
        </Sidepanel2>
    );
};

export default SpShipperDetails;