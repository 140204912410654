import { Box, ButtonBase, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import Subheading from "../../../../../components/theme/text/Subheading";
import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { useGetWebshopCredentialsSingleQuery, useGetWebshopCredentialsQuery } from "../../../../../newapi/webshop/webshopSlice";
import MpAddShipper from "../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";
import SpWebshopCredentialsDetails from "../../../../../components/global/Sidepanels/SpWebshopCredentialsDetails/SpWebshopCredentialsDetails";
import MpAddWebshop from "../../../../../components/global/ModalsProgression/MpAddWebshop/MpAddWebshop";
import { RelationWebshopIntegrationCard } from "../../../../../components/theme/cards/other/WebshopIntegrationCard";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import ComputerIcon from '@mui/icons-material/Computer';
import ViewPortController from "../../../../../components/portalbuilder/ViewPortController";

import cx from 'classnames';
import ReturnPortalViewPort from "../../../../../components/portalbuilder/ViewPort";
import MPortalPreview from "../../../../../components/portalbuilder/MPortalPreview";
import EditRegisterReturn from "../../../../../components/portalbuilder/views/EditRegisterReturn";


const All = (props) => {
    const { t } = useTranslation();
    const apiRef = props.apiRef;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    
    // useEffect(() => {
    //     props.replaceView({
    //         ...view,
    //         apiRef: props.apiRef
    //     },false);

    //     return () => {
    //         // console.log("unmounting warehouse");
    //     }
    // },[]);

    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }
    const handleClose = () => {
        setAddModalOpen(false);
    };

    return (
        <Box
            sx={{
                height: 'calc(100vh - 112px)', // Ensure the parent has a fixed height
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                },
            }}
        >
            <Box
                sx={{
                    height: '100%', // Ensure the parent has a fixed height
                    overflow: "hidden", // Prevent the parent from scrolling
                }}
            >
                <Box
                    sx={{
                        height: '100%', // Ensure the parent has a fixed height
                        overflow: "hidden", // Enable scrolling on the inner container
                        mt: 4,
                        mx: 4,
                        background: "blue",
                        height: '100%', // Ensure the inner container takes full height
                        display:"flex",
                        background:theme.palette.mode === "dark" ? colors.grey[300]: colors.bg['tertiary'],
                        height:1,
                        borderTopLeftRadius:24,
                        borderTopRightRadius:24,
                        boxShadow:"0 0 19px rgba(49,65,95,.25)",
                        border: `1px solid ${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]}`,
                        borderBottom:"none",
                    }}
                >
                    {/* Canvas */}
                    <ReturnPortalViewPort
                        addModal={addModal}
                        setAddModalOpen={setAddModalOpen}
                        open={false}
                    >
                        <EditRegisterReturn/>
                    </ReturnPortalViewPort>
                  
                    {/* Editting stuff */}
                    <Box
                        sx={{
                            overflow:"hidden",
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                            minWidth:300,
                            display:"flex",
                            flexDirection:"column",
                            boxShadow: "0 0 19px rgba(49,65,95,.25)",
                            pt:3,
                        }}
                    >
                        <Box
                            sx={{
                                pl:3,
                            }}
                        >
                            <Subheading>
                                Aanpassen
                            </Subheading>
                        </Box>
                        <Box
                            sx={{
                                height:"calc(100% - 116px)",
                                overflow:"auto",
                            }}
                        >
                            <Box sx={{ height: 2000 }}>
                                {/* Editting content */}


                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height:64,
                                px:3,
                            }}
                        >
                            {/* <ButtonGroup */}
                            <Shbutton className={"group-btn"} color={"green"}
                                variant="contained" 
                                block
                            // onClick={() => saveChildProducts(props)}
                            >
                                {t("save")}
                            </Shbutton>
                        </Box>
                    </Box>
                </Box>
            </Box>
                
            <MPortalPreview addModal={addModal} setAddModalOpen={setAddModalOpen}  onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}>
                <EditRegisterReturn/>
            </MPortalPreview>
        </Box>
    )
};

export default All;