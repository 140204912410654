import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Shbutton from "../../buttons/Shbutton";
import FilterRule from "./FilterRule";

import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useFilterbuilder } from "../../../../contexts/FbState";
import { getConditionExtensionTypes } from "./Settings";
import { useFilter } from "../../../../contexts/FilterState";
import { useDispatch } from "react-redux";
import { setFilterBuilderSettings } from "../../../../newapi/global/filter/builder/gridFilterBuilderSettingsSlice";
import { removeFilterGroup } from "../../../../newapi/global/filter/gridFilterSlice";
import { useGridFilter } from "../../../../contexts/GridFilterContext";

const FilterGroup = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const filterBuilder = useFilterbuilder();
    const { index,id } = useGridFilter(); // Get the current instance ID from context
    
    const handleFbOpen = () => {
        let settings = filterBuilder.settings;
        settings.groupToAddTo = props.index;
        filterBuilder.updateSettings(settings);
        // dispatch(setFilterBuilderSettings(settings));

        props.handleFbOpen();
    }

    const handleRemove = () => {
        dispatch(removeFilterGroup({
            index,
            id,
            groupIndex: props.index
        }));
        props.filterRef.current.applyFilterAfterRemoval();
    }

    return (
        <Box
            sx={{
                display:"flex",
                position:"relative",
                flexDirection:"column",
                background:`${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[50]}`,
                padding:1.5,
                borderRadius:4,
                "@media screen and (max-width: 47.9375em)" : {
                    background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50]}`,

                }
            }}
        >
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center"
                }}
            >
                {/* <Shbutton variant="primaryGrey">Where</Shbutton> */}
                <Typography
                    component={"span"}
                    sx={{
                        color:colors.txt["primary"],
                        fontSize:14,
                        fontWeight:700,
                        display:"inline-block",
                        "@media screen and (max-width:48em)" : {
                            fontSize:17
                        }

                    }}
                >
                    {getConditionExtensionTypes()[props.fgOptions.conditionExtensionType]}
                </Typography>
                {props.index > 0 ?
                    <Box sx={{
                        marginLeft:1,
                        width:"24px",
                        height:24,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        borderRadius:5,
                        color: colors.txt["secondary"],
                        transform:"rotate(90deg)",
                    }}>
                        <ArrowForwardIosOutlinedIcon fontSize="18px"/>
                    </Box>
                : null }

            </Box>
            <Box>
                {/* Filter rule container */}
                <Box sx={{
                    display:"flex",
                    flexWrap:"wrap",
                    gap:"8px 16px",
                    // "@media screen and (max-width:47.9375em)" : {
                        padding:0,
                        marginTop:2
                    // }
                }}>
                    {/* Rule */}
                    {typeof props.fgOptions.rules !== "undefined" ?
                    <>
                        {props.fgOptions.rules.map((condition, index) => (
                            <FilterRule {...props} index={index} groupindex={props.index} key={index} condition={condition} />
                        ))}
                    </>
                    :null}

                <Box
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"center",
                        background:`${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[50]}`,
                        // paddingY:4,
                        lineHeight:"32px",
                        borderRadius:5,
                        color:colors.txt["primary"],
                        fontSize:14,
                        fontWeight:500,
                        userSelect:"none",
                        cursor:"pointer",
                        transition:"250ms",
                        "&:hover" : {
                            background:`${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100]}`,
                            transition:"250ms",
                        },
                        "@media screen and (max-width: 47.9375em)" : {
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50]}`,
                            "&:hover" : {
                                background:`${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100]}`,
                            },
                        }
                    }}
                    onClick={handleFbOpen}
                    // onClick={handleOpen}
                >
                    Add filter
                </Box>

                </Box>
                {/* X marks the spot */}
                {props.index > 0 ?
                    <Box
                        sx={{
                            // "@media screen and (max-width:47.9375em)" : {
                                position:"absolute",
                                right:8,
                                top:8

                            // }
                            
                        }}
                    >
                        <Shbutton onClick={handleRemove} icon color={theme.palette.mode === "dark" ? "primaryGreyOpposite" : "primaryGrey"} size="32">
                            <CloseIcon fontSize="small"/>
                        </Shbutton>
                    </Box>
                :null}
            </Box>
        </Box>
    )
}
export default FilterGroup;