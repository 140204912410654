// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputBase, InputLabel, Select } from '@mui/material';
import { tokens } from '../../../../../theme';
import { t } from 'i18next';
import { useGetReachTypesSelectQuery } from '../../../../../newapi/warehouse/reachTypeSlice';
import { MenuItem } from 'react-pro-sidebar';
import { InputDropdown } from '../../../../theme/dropdowns/InputDropdown';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPickingprocesstypesQuery } from '../../../../../newapi/global/stream/streamSlice';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);
    const { data: reachTypes, isLoading } = useGetReachTypesSelectQuery();
    const { data: pickingprocesstypes } = useGetPickingprocesstypesQuery();

    const data = {...props.data};
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (<>
    <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                paddingTop:3,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('reachtype')}
                    </Text>
                    
                </Box>
        <InputDropdown
        variant="darker"
        displayName={"description"} 
        onChange={(selected) => handleChange("reachTypeId", reachTypes.data[selected].id ?? -1)}
        noSelection={t("choose_reachtype")} //Custom text when nothing is selected
        options={reachTypes?.data ? reachTypes.data : []}
        selected={reachTypes?.data.findIndex(reachType => reachType.id === data.reachTypeId) ?? -1}
        closeOnSelection
    />
    </Box>
    <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                paddingTop:3,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('pickingprocesstype')}
                    </Text>
                    
                </Box>
    <InputDropdown
    variant="darker"
    displayName={"description"} 
    onChange={(selected) => handleChange("pickingprocesstype", pickingprocesstypes[selected] ?? -1)}
    noSelection={t("choose_picking_process_type")} //Custom text when nothing is selected
    options={pickingprocesstypes}
    selected={pickingprocesstypes?.findIndex(pickingprocesstype => pickingprocesstype === data.pickingprocesstype) ?? -1}
    closeOnSelection
/>
</Box>
</>
    );
};

const StreamReachType = (props) => {
    return (
        <ProgressionTab
            tabId="tabReachType"
            subtitle={t("choose_picking_settings")}
            step={4}
            maxSteps={4}
        >
            <Content />
        </ProgressionTab>
    )
}

export default StreamReachType;

