export const reduxMigrations = {
    // The key is the version number for the migration
    // This should be an integer greater than the previous versions
    2: (state) => {
      if (!state.settings || !state.settings.workSpace) {
        const workSpaceInitialState = {
          id: -1,
          name: "workspace_not_set"
        };
  
        return {
            ...state,
            settings: {
                ...state.settings,
                workSpace: workSpaceInitialState
            }
        };
      }
  
      return state;
    },
    3: (state) => {
        if(!state.settings || !state.settings.themeMode) {
            const themeMode = "light";

            return {
                ...state,
                settings: {
                    ...state.settings,
                    themeMode:themeMode
                }
            }
        }
    },
    4: (state) => {
      // Check if the language setting exists and if it's set to 'en'
      if (!state.settings || state.settings.language === 'en') {
          return {
              ...state,
              settings: {
                  ...state.settings,
                  language: 'nl', // Update language from 'en' to 'nl'
              },
          };
      }
  
      return state; // Return state unchanged if no migration is needed
    }
};