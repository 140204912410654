import { Box, ButtonBase, Typography, useTheme } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { tokens } from "../../theme";

import cx from "classnames";
import * as yup from "yup";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as StockVisual } from '../../styles/svg/rmaportal/stockvisual.svg';
import { useGetFinalizeQuery } from "../../newapi/return/returnsSlice";
import { useEffect, useState } from "react";


const Finished = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const {relation} = useParams();
    const navigate = useNavigate();

    const {data: finalizeData,  isError} = useGetFinalizeQuery({relation: relation}, {
        skip: relation === undefined
    });

    useEffect(() => {
        if(finalizeData && !finalizeData?.found ){
            navigate(`/notfound`);
        } else if(isError) {
            navigate(`/notfound`);
        } else {

            props.setRelation(finalizeData?.return.relation.name);
            // props.setRelation(relationData?.dataValues.name);
        }
    }, [finalizeData, isError])

    const handleFormSubmit = async (values) => {
        if(finalizeData?.labelContent){
            const decodedData = atob(finalizeData?.labelContent);
            const arrayBuffer = new ArrayBuffer(decodedData.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < decodedData.length; i++) {
                uint8Array[i] = decodedData.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);
            window.open(pdfUrl, '_blank');
        }
    }

    return (
        <Box
            component={"main"}
            sx={{
                // minHeight:"calc(100%)"
                zIndex:1,
            }}
        >
            <Box
                component={"section"}
                sx={{
                    // background:"rgba(65,128,246,.10)",
                    background: "rgba(65,128,246,.10)",
                    background: "radial-gradient(circle, rgba(65,128,246,0.25) 0%, rgba(65,128,246,0.10) 100%)",
                    width:1,
                    minHeight:"100vh",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"flex-start",
                    paddingTop:"200px",
                    paddingBottom:"200px",
                    // height:"100vh",
                    position:'relative',
                    overflow:"hidden",
                    "@media screen and (max-width: 1280px)" : {
                        py:"120px",
                    },

                    ".stock-visual" : {
                        position:'absolute',
                        bottom:"0",
                        width:1,
                        zIndex:0,
                    }
                }}
            >

                <Box
                    sx={{
                        width:768,
                        zIndex:2,
                        "@media screen and (max-width: 920px)" : {
                            px:4,
                        },
                        "@media screen and (max-width: 768px)" : {
                            px:3,
                        }
                    }}
                >
                    <Box>
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize:"2.75em",
                                fontWeight:"100",
                                color:"#31415f",
                            }}
                        >
                            {t("order_of")} <b>{finalizeData?.return.name}</b> {t("has_been_registered")}
                        </Typography>
                        {/* <Typography 
                            variant="p"
                            sx={{
                                color:"rgba(49,65,95,1)",
                                fontSize:17,
                            }}
                        >
                            Selecteer de te retourneren producten, het aantal en de retourreden.
                        </Typography> */}
                    </Box>
                    <Box
                        className={"order-container"}
                        sx={{
                            alignItems:"stretch",
                            gap:3,
                            mt:3,
                        }}
                    >
                        <Box
                            className={"order-wrapper"}
                            sx={{
                                width:"66%",
                                background:"rgba(255,255,255,.35)",
                                borderRadius:6,
                                backdropFilter:"blur(4px)",
                                width:1
                            }}
                        >
                            <Box className={"order-content"}
                                sx={{
                                    padding:3,
                                    // pb:0,
                                }}
                            >

                                    <Typography
                                        variant="p"
                                        sx={{
                                            fontSize:16,
                                            color:"#31415f",
                                            
                                        }}
                                    >
                                        {t("return_finished_1")} PostNL {t("return_finished_2")} <b>({t("email")}: {finalizeData?.return.email})</b>. {t("return_finished_3")}
                                        <br/><br/>
                                        {t("return_finished_4")} PostNL {t("return_finished_5")}
                                        <br/><br/>
                                        {t("return_finished_6")}
                                    </Typography>


                            </Box>
                        </Box>

                    </Box>
                    <Box
                        className={"button-wrapper-container"}
                        sx={{
                            display:"flex",
                            gap:3,
                            mt:3,
                        }}
                    >
                       
                        <Box
                            className={"button-wrapper"}
                            sx={{
                                borderRadius:6,
                                display:"flex",
                                flexDirection:"column",
                                backdropFilter:"blur(4px)",
                                width:1,
                            }}
                        >
                            <ButtonBase
                                type="submit"
                                onClick={handleFormSubmit}
                                sx={{
                                    color:"white",
                                    fontWeight:"700",
                                    fontSize:16,
                                    // justifyContent:"flex-start",
                                    height:"48px",
                                    width:"48px",
                                    alignItems:"center",
                                    // pl:2,
                                    // background:"rgba(255,255,255,.15)",
                                    background:"rgba(65,128,246,1)",
                                    borderRadius:6,
                                    flexShrink:0,
                                    width:1,
                                    transition:"250ms",
                                    "&:hover" : {
                                        background:"#0057FA",
                                        transition:"250ms"
                                    },                                     
                                }}
                            >
                                {t("download_return_label")}
                                    {/* <ArrowBackIcon fontSize="small"/> */}
                            </ButtonBase>
                        </Box>
                    </Box>

                </Box>

                <StockVisual className="stock-visual"/>
            </Box>
        </Box>
    )
}

export default Finished