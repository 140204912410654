import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeMode, selectThemeMode, getTheme } from './newapi/global/settingsSlice';
import { ThemeProvider } from '@mui/material';

const ThemeManager = ({ children }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(selectThemeMode);
  const theme = getTheme(themeMode);
  

  useEffect(() => {
    const listenForThemeChange = (e) => {
      const colorScheme = e.matches ? 'dark' : 'light';
      dispatch(setThemeMode(colorScheme));
    };

    const query = window.matchMedia('(prefers-color-scheme: dark)');
    query.addEventListener ? query.addEventListener('change', listenForThemeChange) : query.addListener(listenForThemeChange);

    return () => {
      query.removeEventListener ? query.removeEventListener('change', listenForThemeChange) : query.removeListener(listenForThemeChange);
    };
  }, [dispatch]);

  // const listenForThemeChange = (e) => {
  //   const colorScheme = e.matches ? "dark" : "light";
  //   // setColorMode.setColorMode(colorScheme);
    
  //   dispatch(setThemeMode(colorScheme));
  //   if(collapsed && window.innerWidth <=1366){
  //       document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
  //       document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
  //   } else {
  //       document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? colors.bg["secondary"]: colors.bg["primary"]}`);
  //       document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? colors.bg["secondary"]: colors.bg["primary"]}`);
  //   }
  // }
  
  // useEffect(() => {
  //   let query = window.matchMedia("(prefers-color-scheme: dark)");
  //   if (query.addEventListener) {
  //     query.addEventListener('change', listenForThemeChange);
  //   } else {
  //     query.addListener(listenForThemeChange);
  //   }

  //   return () => {
  //     if (query.removeEventListener) {
  //       query.removeEventListener('change', listenForThemeChange);
  //     } else {
  //       query.removeListener(listenForThemeChange);
  //     }
  //   }
  // }, []);

  // ... other theme-related logic

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeManager;