import { useState, useContext, useEffect, useRef } from "react";
import {Routes, Route, useLocation } from "react-router-dom";
// import Topbar from "./components/theme/global/Topbar";

import Sidebar from './components/global/Sidebar';
import Dashboard from "./environments/warehouse/dashboard";
import Outbound from "./environments/warehouse/outbound/dashboard";

import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./components/global/Sidepanels/SpStockDetails/Views/Warehouse";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import Login from "./scenes/login";
import Calendar from "./scenes/calendar";
// import { RequireAuth } from "react-auth-kit";
import { MenuStateContext } from "./contexts/MenuState";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import { tokens } from "./theme";

// import cx from classNames
import cx from "classnames";
import SHTransition from "./components/theme/SHTransition";
import StockManagement from "./environments/warehouse/stock/stock/Index";
import StockProduct from "./environments/warehouse/stock/products/Index";
import StockLocation from "./environments/warehouse/stock/locations/Index";

//Outbound
import OutboundDashboard from "./environments/warehouse/outbound/dashboard";
import OutboundOrders from "./environments/warehouse/outbound/orders/index";
import OutboundBatches from "./environments/warehouse/outbound/batches/index";

// Outbound setting
import OutBoundSettingsPicking from "./environments/settings/warehouse/outbound/picking/Picking";
import OutBoundSettingsCarriers from "./environments/settings/warehouse/outbound/carriers/Carriers";
import OutBoundSettingsStreams from "./environments/settings/warehouse/picking/streams/Streams";
import OutboundSettingsStock from "./environments/settings/warehouse/outbound/stock/Stock";

//Settings
import Shippers from "./environments/settings/warehouse/shippers/Shippers";
import Relations from "./environments/settings/relation/Index";
import SettingsGeneral from "./environments/settings/warehouse/general/General";
import SettingsWebshop from "./environments/settings/integrations/webshop/Index";

//Inbound
import InboundDashboard from "./environments/warehouse/inbound/dashboard/index";
import InboundInbounds from "./environments/warehouse/inbound/inbounds/index";

// Relation
import RelationDashboard from "./environments/relation/dashboard";
import RelationSettingsWebshop from "./environments/relation/settings/webshop/Index";

import Carriers from "./environments/settings/warehouse/outbound/carriers/Carriers";
import Echeck from "./environments/warehouse/echeck/index";

import Topbar from "./components/global/Topbar";
import { RequireAuth } from "./authentication/RequireAuth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { AxiosBackendWrapper, AxiosWrapper } from "./api/Settings";
import Logout from "./environments/global/Logout";
import { useDispatch, useSelector } from "react-redux";
import { removeViewInstance, resetFilter } from "./newapi/global/filter/gridFilterSlice";
import { UserState, selectAuthStatus } from "./newapi/user/authentication/authSlice";
import AccountGeneral from "./environments/user/account/General";
import { UserPrivileges } from './utils/permissions';
import MigrationImport from "./environments/settings/warehouse/import/MigrationImport";
import Users from "./environments/settings/access/users/Users";
import Usergroups from "./environments/settings/access/usergroups/Usergroups";
import RelationOutboundOrders from "./environments/relation/orders";
import RelationOutbound from "./environments/relation/orders";
import RelationInbound from "./environments/relation/inbounds";
import RelationProducts from "./environments/relation/products/Index";
import SettingsSupplier from "./environments/relation/settings/supplier/Index";
import Warehouses from "./environments/settings/warehouse/warehouses/Warehouses";
import Workspaces from "./environments/settings/warehouse/office/workspaces/Workspaces";
import Printers from "./environments/settings/warehouse/office/printers/Printers";
import SettingsReturnPortal from "./environments/relation/settings/returnportal/Index";
import Resources from "./environments/settings/warehouse/picking/resources/Resources";

// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import { TouchBackend } from 'react-dnd-touch-backend';
// import  {MultiBackend, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
// import { Preview } from 'react-dnd-multi-backend';

// // Define your backends
// const HTML5toTouch = {
//   backends: [
//     {
//       backend: HTML5Backend,
//       transition: MouseTransition,
//     },
//     {
//       backend: TouchBackend, // Note that you can pass options to the TouchBackend here
//       options: { enableMouseEvents: true },
//       preview: true,
//       transition: TouchTransition,
//     },
//   ],
// };


function App() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoginPage = location.pathname === "/login";

  const menuContext = useContext(MenuStateContext);
  const collapsed = menuContext.collapsed;

  const backdropRef = useRef(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // selectAuthStatus
  const authStatus = useSelector(selectAuthStatus);
  const handleResize = () => {
    setWindowWidth(window.innerWidth); 
  }
   
  useEffect(() => {
    dispatch(resetFilter());
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  },[])

  useEffect(() => {
      if(collapsed && windowWidth <= 1366) {
        backdropRef.current.classList.add("menu-backdrop-enter-done");
      }
  },[theme.palette.mode])

  // useEffect(() => {
  //   if(authStatus === UserState.LOGGED_OUT || authStatus === UserState.UNSET) {
  //     backdropRef.current.classList.remove("menu-backdrop-enter-done");
  //   } else {
  //     if(collapsed && windowWidth <= 1366) {
  //       backdropRef.current.classList.add("menu-backdrop-enter-done");
  //     }

  //   }
  // },[authStatus,theme.palette.mode])

  const prevPathnameRef = useRef(location.pathname);
  useEffect(() => {
      prevPathnameRef.current = location.pathname;
  }, [dispatch, location.pathname]);


  return (
    // <DndProvider backend={MultiBackend} options={HTML5toTouch}>
     <div className="app">
        {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
        <Box 
          component={"main"} 
          className={cx(`content`, {
            'hide-side' : isLoginPage
          })}
          sx={{
            background:theme.palette.mode === "dark" ? colors.grey["200"] : colors.grey["0"]
          }}
        >
        {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
          {/* <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/form" element={<Form />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/pie" element={<Pie />} />
            <Route path="/line" element={<Line />} />
            <Route path="/calendar" element={<Calendar />} />
          </Routes> */}
          <Routes>
                <Route
                  path="/"
                  // element= {
                  //   <Dashboard/>
                  // }
                  element={
                    <RequireAuth path="/login">
                      <Dashboard />
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/warehouse"
                  // element= {
                  //   <Dashboard/>
                  // }
                  element={
                    <RequireAuth path="/login">
                      <Dashboard />
                    </RequireAuth>
                  }
                ></Route>

              <Route
                path="/warehouse/outbound/dashboard"
                // element= {
                //   <Outbound/>
                // }
                element={
                  <RequireAuth 
                  permissions={[UserPrivileges.OUTBOUND_MANAGE]} 
                  path="/login">
                    <OutboundDashboard />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/warehouse/outbound/orders"
                // element= {
                //   <Outbound/>
                // }
                element={
                  <RequireAuth 
                  permissions={[UserPrivileges.OUTBOUND_READ]} 
                  path="/login">
                    <OutboundOrders />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/warehouse/outbound/batches"
                // element= {
                //   <Outbound/>
                // }
                element={
                  <RequireAuth 
                  permissions={[UserPrivileges.OUTBOUND_READ]} 
                  path="/login">
                    <OutboundBatches />
                  </RequireAuth>
                }
              ></Route>

              <Route
                path="/warehouse/inbound/dashboard"
                element={
                  <RequireAuth
                  permissions={[UserPrivileges.INBOUND_READ]} 
                  path="/login">
                    <InboundDashboard />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/warehouse/inbound/inbounds"

                element={
                  <RequireAuth
                  permissions={[UserPrivileges.INBOUND_READ]} 
                  path="/login">
                    <InboundInbounds />
                  </RequireAuth>
                }
              ></Route>

              <Route
                path="/warehouse/stock/stock"
                // permissions={[UserPrivileges.OUTBOUND_MANAGE]}
                element= {
                  <RequireAuth
                  permissions={[UserPrivileges.STOCK_READ]} 
                  path="/login">
                    <StockManagement/>
                  </RequireAuth>
                }
              ></Route>

              <Route
                path="/warehouse/stock/locations"
                // permissions={[UserPrivileges.OUTBOUND_MANAGE]}
                element= {
                  <RequireAuth
                  permissions={[UserPrivileges.STOCK_READ]} 
                  path="/login">
                    <StockLocation/>
                  </RequireAuth>
                }
              ></Route>

              <Route
                path="/warehouse/stock/products"
                // permissions={[UserPrivileges.OUTBOUND_MANAGE]}
                element= {
                  <RequireAuth
                  permissions={[UserPrivileges.STOCK_READ]} 
                  path="/login">
                    <StockProduct/>
                  </RequireAuth>
                }
              ></Route>

                <Route
                  path="/warehouse/echeck"
                  element= {
                    <RequireAuth
                    permissions={[UserPrivileges.ECHECK]} 
                    path="/login">
                      <Echeck/>
                    </RequireAuth>
                  }
                ></Route>

                <Route
                  path="/relation"
                  element= {
                    <RequireAuth
                      path="/login"
                    >
                      <RelationDashboard/>
                    </RequireAuth>
                  }
                >
                </Route>
                <Route
                  path="/relation/outbound"
                  element= {
                    <RequireAuth
                      path="/login"
                    >
                      <RelationOutbound/>
                    </RequireAuth>
                  }
                >
                </Route>
                <Route
                  path="/relation/products"
                  element= {
                    <RequireAuth
                      path="/login"
                    >
                      <RelationProducts/>
                    </RequireAuth>
                  }
                >
                </Route>
                <Route
                  path="/relation/inbound"
                  element= {
                    <RequireAuth
                      path="/login"
                    >
                      <RelationInbound/>
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/relation/settings/suppliers"
                  element= {
                    <RequireAuth
                      path="/login"
                    >
                      <SettingsSupplier/>
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/relation/settings/webshop"
                  element= {
                    <RequireAuth
                      path="/login"
                    >
                      <RelationSettingsWebshop/>
                    </RequireAuth>
                  }
                >
                </Route>

                <Route
                  path="/relation/settings/returnportal"
                  element= {
                    <RequireAuth
                      path="/login"
                    >
                      <SettingsReturnPortal/>
                    </RequireAuth>
                  }
                >
                </Route>

                <Route
                  path="/team"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Team />
                    // </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/contacts"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Contacts />
                    // </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/invoices"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Invoices />
                    // </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/form"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Form />
                    // </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/bar"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Bar />
                    // </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/pie"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Pie />
                    // </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/line"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Line />
                    // </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/calendar"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Calendar />
                    // </RequireAuth>
                  }
                ></Route>

                <Route
                  path="/settings"
                >
                  <Route path="outbound/carriers" element={<OutBoundSettingsCarriers/>}/>
                  <Route path="outbound/picking" element={<OutBoundSettingsPicking/>}/>
                  <Route path="outbound/stock" element={<OutboundSettingsStock/>}/>
                  <Route path="general" element={<SettingsGeneral/>}/>
                  <Route path="shippers" element={<Shippers/>}/>
                  <Route path="warehouses" element={<Warehouses/>}/>
                  <Route path="relations" element={<Relations/>}/>
                  <Route path="picking/streams" element={<OutBoundSettingsStreams/>}/>
                  <Route path="picking/resources" element={<Resources/>}/>
                  <Route path="migratie" element={<MigrationImport/>}/>
                  <Route path="integrations/webshop" element={<SettingsWebshop/>}/>
                  <Route path="integrations/pim" element={<SettingsWebshop/>}/>
                  <Route path="office/workspaces" element={<Workspaces/>} />
                  <Route path="office/printers" element={<Printers/>}/>
                  <Route path="access/users" element={<Users/>}/>
                  <Route path="access/usergroups" element={<Usergroups/>}/>
                </Route>
                <Route
                  path="/user"
                >
                  <Route path="account/general" element={<AccountGeneral/>}/>
                  {/* <Route path="account/security" element={<AccountSecurity/>}/>
                  <Route path="account/archive" element={<AccountArchive/>}/>
                  <Route path="accessibility" element={<Accessibility/>}/>
                  <Route path="notifications" element={<Notifications/>}/> */}
                </Route>

                <Route
                  path="/*"
                  element={
                    // <RequireAuth loginPath="/login">
                      <Dashboard />
                    // </RequireAuth>
                  }
                ></Route>
                <Route path="/logout" element={<Logout />}></Route>
                <Route path="/login" element={<Login />}></Route>
        </Routes>

        <SHTransition
        nodeRef={backdropRef}
          in={collapsed && windowWidth <= 1366}
          classNames="menu-backdrop"
          appear
          
          timeout={500}
        >
          <Box className={`${theme.palette.mode}`} onClick={() => menuContext.setCollapsed.toggleMenuState()} ref={backdropRef} sx={{position:"fixed", left:0,top:0,width:1,height:1,zIndex:"-1"}}></Box> 
        </SHTransition>
        
        <ToastContainer autoClose={1200} />
        </Box>
      </div>
    // </DndProvider>
  );
}

export default App;
