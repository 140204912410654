import { apiSlice } from "../createApiSlice";

const API_BASE = "/general";
const buildUrl = (endpoint) => API_BASE + endpoint;

export const generalApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEnums: builder.query({
            query: () => ({
                url: buildUrl(`/enums`),
                method: 'GET',
            }),
        }),
        getSettings: builder.query({
            query: (key) => ({
                url: buildUrl(`/settings/${key}`),
                method: 'GET',
            }),
            providesTags: (result, error, key) => [{ type: 'Settings', key }],
        }),
        updateSetting: builder.mutation({
            query: (setting) => ({
                url: buildUrl(`/setting`),
                method: 'PUT',
                body: setting,
            }),
            invalidatesTags: (result, error, setting) => [{ type: 'Settings', key: setting.key.split('_')[0] }],
        }),
    })
});

export const { 
    useGetEnumsQuery,
    useGetSettingsQuery,
    useUpdateSettingMutation
} = generalApiSlice;
