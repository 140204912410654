import { Switch, useTheme, useThemeProps } from '@mui/material';
import { tokens } from "../../../theme";

const ShSwitch = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return (
        <Switch color={props.color} 
        checked={props.checked}
        disabled={props.disabled ? true : undefined}
        onChange={props.onChange}
        sx={{
            marginLeft:"-12px",
            "@media screen and (max-width: 47.9375em)" : {
                marginLeft:0,
                marginRight:"-12px"
            },

            "& .MuiSwitch-track" : {
                background: `${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]}`,
                opacity:1
            }
        }}/>
    )
}

export default ShSwitch;