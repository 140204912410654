import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { PURGE, REHYDRATE } from 'redux-persist';

const initialState = { 
  activeId: -1,
  activeName: "all_warehouses"
};

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState: initialState,
  reducers: {
    updateActive: (state, action) => {
        state.activeId = action.payload.id;
        state.activeName = action.payload.name;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(PURGE, () => initialState)
      // ... your other matchers
      .addCase(REHYDRATE, (state, action) => {
        // Or if you want to update the state with the rehydrated state:
        if (action.payload) {
          return { ...state, ...action.payload.warehouse};
        }
        return state;
      })
  }
});

export const { updateActive } = warehouseSlice.actions;
export default warehouseSlice;
