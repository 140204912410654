import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LogCard from "../../../../theme/cards/other/LogCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../../utils";
import { useGetLogsQuery } from "../../../../../newapi/global/logsSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Logs = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();

    
    const [config, setConfig] = useState({
        "type": "Carrier",
        "id": props.data
    });
    
  //  console.log("hello");
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    
    // useEffect(() => {
    //     if(!dataHasBeenSet) {
    //         getLogs({type: "Carrier", externalId: props.data}, (result,data) => {
    //             if(result){
    //                 setData(data.data);
    //                 setDataHasBeenSet(true);
    //             }
    //         })
    //     }
    // },[props.isActive])

    const columns = [
        {
            field: "createdAt", 
            headerName: t('created_on'),
            flex:1,
            renderCell : (cellValues) => {
                const {date, time} = getTranslatedDateAndTime(cellValues.row.createdAt, i18n);
                return `${date} ${time}`;
            }
        },
        {field: "description", headerName: t('description'),flex:1},
        {field: "userName", headerName: t('by'),flex:1}
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .description-cell" : {
                    // background:"orange !important",
                    // overflow:"hidden",
                    paddingLeft:0,
                    marginRight:2,
                },
                "& [aria-label='Created on']" : {
                    // background:"purple",
                    paddingLeft:3,
                },
                "& [aria-label='Description']" : {
                    // background:"purple",
                    paddingLeft:0,
                },
                "& [aria-label='By']" : {
                    // background:"purple",
                    paddingLeft:3.125,
                }
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2
                content={useGetLogsQuery}
                config={config}
                title={t("logs")}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
        </Box>
    );
}

export default Logs;