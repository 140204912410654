import React, { createContext, useContext, useState } from "react";

export const useFilterbuilder = () => useContext(FbContext);


const defaultValue = {
    settings : {
        addToFilterGroup: false,
        groupToAddTo:-1,
        newGroup:{
            conditionExtensionType:0,
            rules:[]
        }
    },


    column: -1,
    conditionExtensionType: 1,
    type: {
        name : "",
        value: -1
    },
    value: null,
    finishedBuilding: false,

    updateSettings: () => {},
    updateColumn : () => {},
    updateConditionExtensionType : () => {},
    updateType : () => {},
    updateValue : () => {},
    updateFinishedBuilding : () => {}
};


export const FbContext = createContext(defaultValue);


const FbContextProvider = (props) => {
    const [settings, setSettings] = useState({addToFilterGroup:false, groupToAddTo:-1,newGroup:{conditionExtensionType:"and",rules:[]}});
    const [column, setColumn] = useState(-1);
    const [conditionExtensionType,setConditionExtensionType] = useState("where");
    const [type, setType] = useState({name: "", value: -1});
    const [value, setValue] = useState(null);

    const [finishedBuilding, setFinishedBuilding] = useState(false);

    return (
        <FbContext.Provider value={{
            settings,
            column,
            conditionExtensionType,
            type,
            value,
            finishedBuilding,
            updateSettings: (val) => {
                setSettings(val);
            },
            updateColumn : (val) => {
                setColumn(val);
            },
            updateConditionExtensionType : (val) => {
                console.log(val);
                setConditionExtensionType(val);
            },
            updateType : (val) => {
                setType(val);
            },
            updateValue : (val) => {
                setValue(val);
            },
            updateFinishedBuilding: (val) => {
                console.log("finished");
                setFinishedBuilding(val);
            }
        }}>
            {props.children}
        </FbContext.Provider>
    )
}
export default FbContextProvider;