import React, { useRef, useState,createRef, forwardRef, useEffect, useContext } from "react";
import { Box, IconButton, Typegraphy, Typography, useTheme, Button, Icon } from '@mui/material';
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
// import { createRef } from "react";
import cx from 'classnames';
import { MenuStateContext } from "../../../contexts/MenuState";

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import CloseOverlayBtn from "../buttons/CloseOverlayBtn";


const Shbutton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const classes = props.className ?? "";
    return (
        // <ColorButton colors={colors}>{props.children}</ColorButton>
        <Button 
          tabIndex={props.tabindex ?? null}
          onClick={props.onClick}
          // onClick={() => props.handleClick}
          className={cx(classes,{
              'sidepanel-fix-bottom': props.isSidepanel,
              'cancel-btn': props.isCancel,
              'tab-btn': props.isTab,
              'active': props.active,
              's32' : props.size === "32",
              "filter-modal-btn": props.isFilterModalBtn,
              "icon" : props.icon,
              "mobile-footer-btn" : props.modalFooterBtn,
              "flipped": props.flipped,
              "block" : props.block,
          })}
          component="button"
          startIcon={props.startIcon}
          endIcon={props.endIcon}
          color={props.color}
          variant={props.variant}
          
          type={props.type ?? "button"}
          sx={{
            boxShadow:"none",
            textTransform:"none",
            paddingX:3,
            paddingY:0,
            height:"40px",
            lineHeight:"40px",
            borderRadius:"20px",
            fontSize:"0.875em",
            // Content within
            ".MuiButton-endIcon" : {
              marginLeft:2
            },
            //Sizes
           
            "&.s32" : {
              paddingX:2.5,
              height:"32px",
              lineHeight:"32px"
            },
            "&.s48" : {
              height:"48px",
              lineHeight:"48px"
            },
            "&.s56" : {
              height:"56px",
              lineHeight:"56px"
            },
            // Types
            "&.block" : {
              width:1
            },
            "&.flipped" : {
              transform:"rotate(180deg) scaleX(-1)"
            },
            "&.icon" : {
              padding:0,
              width:"40px",
              minWidth:"40px",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              "&.s24" :{
                height:"24px",
                lineHeight:"24px",
                width:"24px",
                minWidth:"24px"
              },

              "&.s32" :{
                height:"32px",
                lineHeight:"32px",
                width:"32px",
                minWidth:"32px"
              },
              "& .shbtn-content" : {
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
              }
            },
            "&.filter" : {
              border: theme.palette.mode === "light" ? `1px solid ${colors.grey[200]}` : null,
              background: theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
              paddingRight:0.5,
              paddingLeft:2.5,
              color:colors.txt["primary"],
              "&.small" : {
                background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                padding:0,
                minWidth:0,
                border:0
              },
              "& .filter-icon path" : {
                stroke: colors.txt["primary"]
              },
              "& .shbtn-content" :{
                display:"flex",
                alignItems:"center",
                gap:0.5
              }
            },
            "&.cancel-btn" : {
              display:"none",
              height:"48px",
              lineHeight:"48px",
              borderRadius:"24px",
              // paddingX:0,
              fontSize:"1em",
              justifyContent:"space-between",
              textAlign:"center",
              color:`${colors.txt["primary"]}`,
              fontWeight:"500 !important",
              flexShrink:0,
              ".MuiButton-startIcon" : {
                // marginLeft:"auto"
                fontSize:"20px",
                marginRight:2,
                float:"right",
                justifySelf:"flex-end",
                transform:"rotate(180deg)",
                color: `${colors.txt["secondary"]}`,
                // alignSelf:"flex-end"
              },

              "&.always-visible" : {
                display:"flex"
              }
            },
            "&.tab-btn" : {
              borderRadius:0,
              color: `${colors.txt["secondary"]}`,
              position:"relative",
              paddingX:0,
              minWidth:"unset",
              //States
              "&:after" : {
                content: '""',
                position:"absolute",
                width:1,
                bottom:0,
                borderRadius:1,
                height:"2px",
                // background: `${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`,
                // background:"green"
              },
              "&.active" : {
                color: `${colors.txt["primary"]}`,
                "&:after" :{
                  background: `${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`,
                }
              },
              "&:hover" : {
                color: `${colors.txt["primary"]}`,
                backgroundColor: "transparent",
              },

              //Changes
              "&.btn-body-tab" : {
                paddingX:2,
                borderRadius:3,
                fontWeight:400,
                color: colors.txt["primary"],
                justifyContent:"flex-start",
                minWidth:200,
                "&:after" : {
                  display:"none"
                },
                // background: theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[400],
                "&.active" : {
                  fontWeight:500,
                  background: theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                  color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                  "& .MuiButton-startIcon": {
                    color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                  },
                },

                "& .MuiButton-startIcon": {
                  color: colors.txt["secondary"],
                  marginRight:2
                },
              },
              "&.box-tab" : {
                pb:2,
                flexWrap:"nowrap",
                "& .shbtn-content" : {
                  whiteSpace:"nowrap"
                }
              }
            },
            "&.space-end-icon" : {
              
              ".shbtn-content, .MuiButton-endIcon" : {
                marginLeft:"auto",
              }
            },
            "&.filter-modal-btn" : { 
              paddingRight:0.5,
              paddingLeft:2,
              marginLeft:"auto",
              "& .shbtn-content" : {
                display:"inline-flex",
                alignItems:"center"
              },
            },
            "&.group-btn" : {
              flex:1
            },
            // States
            "&:hover": {
              boxShadow:"none",

            },

            // Screen sizes, 1023px
            "@media screen and (max-width: 63.9375em)":{
              "&.sidepanel-fix-bottom":{
                height:"40px",
                lineHeight:"40px",
                borderRadius:"24px",
                flex:1,
                display:"flex",
                justifyContent:"space-between",
                textAlign:"center",
                fontSize: "1em",
                ".force-right": {
                  width:20,
                  height:1
                },
                ".MuiButton-endIcon" : {
                  justifySelf:"flex-end",
                }
                // justifyContent:""
              },
              "&.cancel-btn" : {
                display:"flex",
              }
            },
            //767px
            "@media screen and (max-width: 47.9375em)" : {
              "&.mobile-footer-btn" : {
                height:"40px",
                lineHeight:"40px",
                borderRadius:"20px",
                display:"flex",
                justifyContent:"space-between",
                textAlign:"center",
                fontSize: "1em",
                flex:1,
                ".force-right": {
                  width:20,
                  height:1
                },
                ".MuiButton-endIcon" : {
                  justifySelf:"flex-end",
                }
              },
              "&.tab-btn:not(.as-tab)" : {
                flex:1,
                height:80,
                paddingX:"16px",
                paddingTop:"4px",
                flexShrink:0,
                background: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"]}`,
                borderRadius:2,
                flexBasis:"calc(50% - 24px)",
                textAlign:"left",
                whiteSpace:"nowrap",
                overflow:"hidden",
                textOverflow:"ellipsis",
                color: `${colors.txt["primary"]}`,
                flexWrap:"wrap",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"space-between",
                lineHeight:"16px",
                ".MuiButton-startIcon" : {
                  width:"100%",
                  flexBasis:"100%",
                  flexShrink:"0",
                  marginX:0,
                },
                ".MuiButton-endIcon" : {
                  marginX:0,
                },
                ".shbtn-content" : {
                  display:"inline-block",
                  flex: "0",
                  width: "calc(100%)",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexBasis: "calc(100% - 20px)",
                },
                "&:after" : {
                  display:"none"
                },
                "&:hover" : {
                  background: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"]}`,
                }
              },
              "&.filter" : {
                background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                padding:0,
                minWidth:0,
                border:0
              },
              "&.group-btn" : {
                paddingX:2,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                position:"relative",
                "&:after" : {
                    content:"''",
                    display:"block",
                    height:"inherit",
                    top:0,
                    left:"100%",
                    width:16,
                    background:"inherit",
                    position:"absolute",
                },
                "& .MuiButton-endIcon": {
                  display:"none"
                }
              }

            }
          }}
          >
            { props.isSidepanel || props.modalFooterBtn ? <Box className="force-right"></Box> : null}
              <Box className="shbtn-content">{props.children}</Box>
          </Button>
    );
};

export default Shbutton;