// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useDispatch } from 'react-redux';


/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    
    const data = {...props.data};
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("max_weight")}</Text>&nbsp;
                    <Text italic light>(kg)</Text>
                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <NumberInput btnSize={"24"}
                    value={data.weight ?? ""}
                    change={(e) => handleChange('weight', e)}/>
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("max_length")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <NumberInput btnSize={"24"}
                    value={data.length ?? ""}
                    change={(e) => handleChange('length', e)}/>
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("max_width")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <NumberInput btnSize={"24"}
                    value={data.width ?? ""}
                    change={(e) => handleChange('width', e)}/>
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("max_height")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <NumberInput btnSize={"24"}
                    value={data.height ?? ""}
                    change={(e) => handleChange('height', e)}/>
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("max_volume")}</Text>&nbsp;
                    <Text italic light>(cm3)</Text>
                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <NumberInput btnSize={"24"}
                    value={data.volume ?? ""}
                    change={(e) => handleChange('volume', e)}/>
                </Box>
            </Box>
        </>
    );
};

const ShipperMeasurements = (props) => {
    const { t } = useTranslation();
    const schema = yup.object({

    });

    return (
        <ProgressionTab 
            tabId="tabMeasurements" 
            subtitle={t("measurements")} 
            step={3}
            nextTab={"tabShipperSender"}
            maxSteps={6}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ShipperMeasurements;

