import { Box, IconButton, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../../../theme";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
//import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import ShDataGrid from "../../../../components/theme/datagrid/ShDataGrid";
import Subheading from "../../../../components/theme/text/Subheading";
import Text from "../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { useGetRelationQuery, useGetRelationsQuery  } from "../../../../newapi/global/relation/relationSlice";
import SpRelationDetails from "../../../../components/global/Sidepanels/SpRelationDetails/SpRelationDetails";
import MpAddRelation from "../../../../components/global/ModalsProgression/MpAddRelation/MpAddRelation";
//import MpAddShipper from "../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";


const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_relation')}
            </Shbutton>
        </ButtonGroup>
    );
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const apiRef = props.apiRef;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    const { data: relationData, error, isLoading } = useGetRelationQuery(
       selectedRowId,
        {
            skip: !rowSelected
        }
    );
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [view, setView] = useState({
        title: 'relations',
        subtitle: 'all_relations',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
        apiRef: apiRef,
        mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>,
        gridOptions:gridOptions,
    })

    const getRelationDetails = (row) => {  
        if (!row) return;
        setSelectedRowId(row.id);
        setRowSelected(true);
    };

    useEffect(() => {
        if (!isLoading && relationData && rowSelected) {
            setIsExtended(true);
        }
    }, [relationData, isLoading, rowSelected]);

    useEffect(() => {
        if(!isExtended) {
            setRowSelected(false);
        }
    },[isExtended])
    
    useEffect(() => {
        props.addView({
            ...view,
            apiRef: props.apiRef
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    useEffect(() => {
        if (props.apiRef.current) {
            props.addView({
                ...view,
                apiRef: props.apiRef
            },true);
        } 
    }, [props.apiRef.current]);
    
    
    const columns = [
        {
            field: "name", 
            headerName: t("relation"), 
            flex:2, 
            cellClassName: "relation", 
            renderCell : (cellValues) => {
            return (
                <Text semibold>{cellValues.row.name}</Text>
            )
        }},
        {
            field: "phone",
            headerName: t("phone"),
            flex:1,
            renderCell : (cellValues) => {
                return cellValues.row.phone
            },
        },
        {
            field: "email",
            headerName: t("email"),
            flex:1,
            renderCell : (cellValues) => {
                return cellValues.row.email
            },
        },
        {
            field: "website",
            headerName: t("website"),
            flex:1,
            renderCell : (cellValues) => {
                return cellValues.row.website
            },
        },
        {
            field: "webshopcount",
            headerName: t("websites_linked"),
            flex:1,
            renderCell : (cellValues) => {
                return (cellValues.row.webshopCredentials ? cellValues.row.webshopCredentials.length : 0)
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    return (
        props.isActive ?
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                
                        <ShDataGrid  
                            content={useGetRelationsQuery}
                            gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                            title={t("overview")}
                            gridOptions={gridOptions} 
                            apiRef={apiRef} 
                            columns={columns} 
                            isSidepanelGrid
                            onRowClick={getRelationDetails}
                            rows={data}>
                        </ShDataGrid>

                    <SpRelationDetails
                        isExtended={isExtended}
                        setIsExtended={setIsExtended}
                        data={relationData}                
                    ></SpRelationDetails>
                    <MpAddRelation onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
                </Box>
        : null
    )
};

export default All;