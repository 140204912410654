import React from "react";
import { TopbarActionButton, TopbarActionIcon } from "../../global/Modals/MTopbarAction";
import Text from "../text/Text";

const DropdownButton = (props) => {
    return (
        <TopbarActionButton onClick={props.onClick}>
            <TopbarActionIcon>
                {React.cloneElement(props.icon, {fontSize:"small"})}
                {/* <SettingsOutlinedIcon fontSize="small" /> */}
            </TopbarActionIcon>
            <Text medium>
                {props.text}
            </Text>
        </TopbarActionButton>
    )
}

export default DropdownButton;