import { useTheme } from "@mui/material"
import { tokens } from "../../../../../../theme";
import SelectGroup from "../../../../Select/SelectGroup";
import { useContext, useEffect, useState } from "react";
import { useFilterbuilder } from "../../../../../../contexts/FbState";
import { getBuilderConditionExtensionTypes, getConditionExtensionTypes } from "../../Settings";
import { useDispatch } from "react-redux";
import { updateConditionExtensionType } from "../../../../../../newapi/global/filter/builder/gridFilterBuilderRuleSlice";

const SelectFilterRuleExtensionTypeViewBody = (props) => {
    const [selected, setSelected] = useState(-1);
    const [columns, setColumns] = useState(getBuilderConditionExtensionTypes());

    const filterBuilder = useFilterbuilder();

    const dispatch = useDispatch();

    //Select element and go to next screen
    const select = (selected) => {
        setSelected(selected);

        //update rule
        dispatch(updateConditionExtensionType(selected+1));
        //Update context
        // filterBuilder.updateConditionExtensionType(selected+1);

        if(typeof props.builderRef !== "undefined") {
            props.builderRef.current.nextView();
        }
    }
    
    return (
        <SelectGroup selected={selected} setSelected={select} options={columns} />
    )
}

const SelectFilterRuleExtensionTypeView = (props, builderRef,skipStep) => {
    return {
        skipStep: skipStep,
        header : {
			title: "Pick an extension type",
			// preview: <FiltergroupPreview/>
        },
        body : {
            component: <SelectFilterRuleExtensionTypeViewBody {...props} builderRef={builderRef}/>
        }
    }
}

export default SelectFilterRuleExtensionTypeView;