import { Box, ButtonBase, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import OverflowRow from "../../../components/theme/OverflowRow";
import { useTranslation } from "react-i18next";

import DonutChart from "../../../components/theme/charts/DonutChart";

import {HorizontalBarChart,BarChart,StackedBarChart,StackedHorizontalBarChart} from "../../../components/theme/charts/HorizontalBarChart";
import AreaChart from "../../../components/theme/charts/AreaChart";
import { InputDropdown } from "../../../components/theme/dropdowns/InputDropdown";
import Subheading from "../../../components/theme/text/Subheading";
import Text from "../../../components/theme/text/Text";
import Heading from "../../../components/theme/text/Heading";
import ImportComponent from "../../../components/theme/import/ImportPreprocessor";
import { useGetDashboardOrdersQuery } from "../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { OrderHistoryChart } from "../../../components/charts/dashboard/fulfillment/OrderHistoryChart";
import { InboundChart } from "../../../components/charts/inbound/fulfillment/InboundChart";
import { ReturnChart } from "../../../components/charts/dashboard/fulfillment/ReturnChart";
import { OrderlinesChart } from "../../../components/charts/dashboard/fulfillment/OrderlinesChart";
import { OrdersChart } from "../../../components/charts/dashboard/fulfillment/OrdersChart";
import { ReturnHistoryChart } from "../../../components/charts/dashboard/fulfillment/ReturnHistoryChart";


const RelationDashboard = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleChangeTwo = (selected) => {

    }



    return (
        <Box sx={{background:colors.bg['tertiary'], width:1,paddingTop:9,paddingX:4,}}>
            {ready ?
                <Box>
                    <Box
                        sx={{
                            display:'grid',
                            gridTemplateColumns: '2fr 4fr',
                            gap:3,
                            "@media screen and (max-width: 1024px)" : {
                                gridTemplateColumns: "2fr 2fr",
                            },
                            "@media screen and (max-width: 768px)" : {
                                gridTemplateColumns: "repeat(2, 1fr)"
                            }
                        }}
                    >
                        <OrdersChart/>
                        <OrderHistoryChart/>
                    </Box>
                    <Box
                        sx={{
                            mt:3,
                            display:'grid',
                            gridTemplateColumns: '2fr 4fr',
                            gap:3,
                            "@media screen and (max-width: 1024px)" : {
                                gridTemplateColumns: "repeat(2, 1fr)"
                            },
                            "@media screen and (max-width: 768px)" : {
                                gridTemplateColumns: "repeat(2, 1fr)"
                            }
                        }}
                    >
                        <OrderlinesChart/>
                        
                            {/* <InboundChart/>*/}
                        <ReturnHistoryChart/>


                    </Box>
                </Box>
            :null}
        </Box>
    );
};

export default RelationDashboard;

