// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useDispatch } from 'react-redux';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    
    const data = {...props.data};
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
    
    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: dayjs(val).format("HH:mm") });
        // dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("mon_fri")}</Text>&nbsp;
                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    
                    {/* <TimePicker */}
                    {/* <TimePicker 
                        btnSize={"24"} 
                        showTimeSelect 
                        showTimeSelectOnly
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        format="HH:mm"
                        value={data.mon_fri}
                    onChange={(e) => handleChange('mon_fri', e)}/> */}
                    
                    <TimePicker btnSize={"24"} 
                    showTimeSelect showTimeSelectOnly
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    format="HH:mm"
                    value={dayjs(data.mon_fri, "HH:mm")}
                    onChange={(e) => handleChange('mon_fri', e)}/>
                
                </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("saturday")}</Text>&nbsp;
                    </Box>
                    
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <TimePicker btnSize={"24"} 
                        showTimeSelect showTimeSelectOnly
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        format="HH:mm"
                        // value={data.saturday}
                        value={dayjs(data.saturday, "HH:mm")}
                        onChange={(e) => handleChange('saturday', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("sunday")}</Text>&nbsp;
                    </Box>
                    
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        
                        <TimePicker 
                            btnSize={"24"} 
                        showTimeSelect showTimeSelectOnly
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        format="HH:mm"
                        // value={data.sunday}
                        value={dayjs(data.sunday, "HH:mm")}
                        onChange={(e) => handleChange('sunday', e)}/>
                    </Box>
                </Box>
            </LocalizationProvider>
        );
    };

const ShipperPickupTimes = (props) => {
    const { t } = useTranslation();
    const schema = yup.object({

    });

    return (
        <ProgressionTab 
            tabId="tabPickupTimes" 
            subtitle={t("pickuptimes")} 
            step={5}
            nextTab={"tabAuthorisation"}
            maxSteps={6}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ShipperPickupTimes;

