import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { formatDate } from '@fullcalendar/core'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import daygrid from "@fullcalendar/daygrid";

const Calendar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentEvents, setCurrentEvents] = useState([]);

    const handleDateClick = (selected) => {
        const title = prompt("Vul een nieuwe titel in");
        const calendarApi = selected.view.calendar;
        calendarApi.unselect();

        if (title) {
            calendarApi.addEvent({
                id: `${selected.dateStr}-${title}`,
                title: title,
                start: selected.startStr,
                end: selected.endStr,
                allDay: selected.allDay
            });
        }
    };

    const handleEventClick = (selected) => {
        if (
            window.confirm(`Are you sure you want to delete the event '${selected.event.title}'`)
            ) {
                selected.event.remove();
            }
    };

    return (
        <Box m="20px">
            <Header title="AGENDA" subtitle="Fullcalendar testpagina" />
            <Box display="flex" justifyContent="space-between">
                {/* Calendar sidebar */}
                <Box flex="1 1 20%" backgroundColor={colors.primary[400]} p="15px" borderRadius="4px">
                    <Typography variant="h5">Events</Typography>
                    <List>
                        {currentEvents.map((event) => (
                            <ListItem
                            key={event.id}
                            sx={{backgroundColor: colors.greenAccent[500], margin: "10px 0", borderRadius: "2px"}}
                            >
                                <ListItemText
                                primary={event.title}
                                secondary={
                                <Typography>{formatDate(event.start, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric"
                                })}
                                </Typography>
                                } />
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Calendar */}
                <Box flex="1 1 100%" ml="15px">
                    <FullCalendar
                    height="75vh"
                    plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        listPlugin
                    ]}
                    headerToolbar={{
                        left: "prev,next,today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth"
                    }}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    select={handleDateClick}
                    eventClick={handleEventClick}
                    eventsSet={(events) => setCurrentEvents(events)}
                    initialEvents={[
                        { id: "1234", title: "Machineren", date: "2023-02-13"},
                        { id: "4567", title: "DonaldSenpai event", date: "2023-02-14"},
                        { id: "652", title: "Mooileem event", date: "2023-02-15"},
                    ]}
                    ></FullCalendar>
                </Box>
            </Box>
        </Box>
    )
}

export default Calendar;
