import React, { useEffect } from 'react';
import { InputBase, Box, Typography, useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import cx from 'classnames';
import Text from '../text/Text';

const ShValidatedInput = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box 
            sx={{width:1}}
        >
            <Box
                className={cx(props.className, {
                    'error': typeof props.error !== "undefined" && props.error.length > 0,
                })}
                sx={{
                    flex:1,
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:4,
                    display:"flex",
                    height:32,
                    "&.error" : {
                        background: colors.red[100],
                        border: `1px solid ${colors.red[400]}`
                    }
                }}
            >
                <InputBase 
                type={props.type ?? "text"}
                    sx={{
                        px:2,
                        flex:1, 
                        color:colors.txt["primary"],
                        lineHeight:"32px",
                    }}
                    value={props.value}
                    onChange={(e) => props.onChange(props.name, e.target.value)}
                    error={Boolean(props.error)}
                />
            </Box>
            {typeof props.error !== "undefined" && props.error.length > 0 &&
                <Text bold variant="red">{props.error}</Text>    
            }
        </Box>
    );
};

export default ShValidatedInput;