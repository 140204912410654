import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useGetDashboardOrderHistoryQuery, useGetDashboardReturnHistoryQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { useTranslation } from "react-i18next";
import AreaChart from "../../../theme/charts/AreaChart";


export const ReturnHistoryChart = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, isLoading, isFetching, isError,refetch} = useGetDashboardReturnHistoryQuery();
    
    // Function to handle retry
    const handleRetry = () => {
        refetch();
    };
    return (
        <Box
            sx={props.sx ?? {
                overflow:"hidden",
                "@media screen and (max-width: 768px)" : {
                    gridArea: "2 / 1 / 3 / 3",
                }
            }}
        >
            <AreaChart
                height={"100%"}
                hideFilter
                optionsEnabled={false}
                title={t("returns_through_time")}
                type="area"
                staticColors={[
                    colors.green[400],
                    colors.green[200],
                ]}
                colors={['green']}
                isError={isError}
                isLoading={isLoading || isFetching}
                categories={data?.categories}
                values={data?.values}
                color={'default'}
                onRetry={handleRetry}
                tickAmount={30}
            />

        </Box>
    );
}