import { Button, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import cx from 'classnames';
import React, { useState } from "react";

const ContextButton = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [hasActive, setHasActive] = useState(typeof props.hasActive !== "undefined" && props.hasActive);
    const [active, setActive] = useState(props.active ?? false);

    const handleClick = () => {
        if(typeof props.onClick !== "undefined"){
            props.onClick({hasActive:hasActive, active:!active});
        }
        if(hasActive) {
            setActive((active) => !active);
        }
    }

    const childrenWithProps = React.Children.map(props.children, child => React.cloneElement(child, {active}));

    return (
        
        <Button
            className={cx({
                'active': active
            })}
            onClick={handleClick}
            TouchRippleProps={{ 
                style: { 
                    color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                } 
            }} // Directly styling the ripple here
            variant='contained'
            sx={{
                background:theme.palette.mode === "dark" ? colors.grey[300]  : "#fff",
                width:1,
                height:40,
                borderRadius:"8px",
                padding:0,
                minWidth:0,
                paddingX:1.5,
                display: "flex",
                alignItems:"center",
                justifyContent:"flex-start",
                textTransform:"none",
                minWidth:250,
                boxShadow: "none",
                opacity:1,
                "&:hover" : {
                    transition:"250ms",
                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                    boxShadow:"none"
                },
                "&.active" : {
                    background: theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                    color:`${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]} !important`
                }
            }}
        >
            {childrenWithProps}

        </Button>
    );

}
export default ContextButton;