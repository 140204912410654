import { Box, ButtonBase, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import cx from "classnames";


import { localeSettings } from "../../utils/locales";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { ReactComponent as NavClosed } from "../../styles/svg/nav-closed.svg";
import { ReactComponent as NavOpen } from "../../styles/svg/nav-open.svg";
import MPOChooseLanguage from "../../components/global/ModalsPopover/MPOChooseLanguage";



export const RmaNav = (props) => {

    
    const language = useSelector((state) => state.settings.language);
    const [navActive, setNavActive] = useState(false);
    const toggleNavActive = () => {
      setNavActive((active) => !active);
      // setMode((prev) => (prev === "light" ? "dark" : "light")
    }

    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
        // setExportAnchorEl(null);
    };

    return (
        <>
            <Box
                className={cx("", {
                'active': navActive,
                })}
                component={"nav"}
                sx={{
                height:64,
                width:1,
                left:0,
                top:0,
                position:"fixed",
                // pl:40,
                // pr:40,
                px:3,
                zIndex:4,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",

                // background:"blue",
                backdropFilter:"blur(8px)",
                border:"2px solid rgba(65,128,246,.15)",

                "& .nav-link" : {
                    display:"inline-flex",
                    alignItems:"center",
                    flexShrink:0,
                    height:64,
                    px:2,
                    borderRadius:5,
                    textDecoration:"none",
                    transition:"250ms",
                    fontFamily:"Roboto, sans-serif",
                    position:"relative",


                    "& p" : {
                    transition:"250ms",
                    color:"#31415f",
                    },


                    "&:after" : {
                    content:"''",
                    display:"block",
                    position:"absolute",
                    bottom:0,
                    left:0,
                    height:2,
                    width:1,
                    transition:"250ms",
                    background:"transparent",
                    },

                    "& .icon" : {
                    transition:"250ms",
                    color:"#8E97A7",
                    },

                    "&:hover" : {
                    transition:"250ms",

                    "& p" : {
                        transition:"250ms",
                        // fontWeight:500,
                        color:"#31415f",
                        textShadow:"1px 0 0 currentColor"

                    },

                    "&:after" : {
                        transition:"250ms",
                        background:"rgba(65,128,246,1)",
                    },


                    "& .icon" : {
                        transition:"250ms",
                        color:"#31415f",
                    },
                    },

                },
                "@media screen and (max-width:950px)" : {
                    ".nav-link" : {
                    display:"none"
                    }
                },
                "@media screen and (max-width:575px)" : {
                    pl:1
                }
                
                }}
            >

                <Box
                    sx={{
                        width:1024,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",

                        "@media screen and (max-width:1120px)" : {
                        width:850
                        },
                        "@media screen and (max-width:950px)" : {
                        width:1,
                        }
                    }}
                >
                    <Box

                        sx={{
                        display:"flex",
                        alignItems:"center",
                        }}
                    >
                        <Box 

                        className={cx("", {
                            'active': navActive,
                        })}
                        onClick={() => toggleNavActive()}
                        sx={{
                            width:40,
                            height:40,
                            // backgroundColor:"#0C9",
                            color:"#FFF",
                            borderRadius:"50%",
                            textAlign:"center",
                            cursor: 'pointer',
                            position:"relative",
                            display:"none",
                            alignItems:'center',
                            justifyContent:"center",
                            "& .nav-open, .nav-closed" : {
                            transition: "all .25s ease-in-out"
                            },
                            "&.active .nav-open" : {
                            transform: "rotate(90deg) scale(0)",
                            opacity: 0,
                            },
                            "&:not(.active) .nav-closed" : {
                            transform: "rotate(-90deg) scale(0)",
                            opacity: 0,
                            },

                            "@media screen and (max-width:950px)" : {
                            display:"flex",
                            mr:2,
                            },
                            "@media screen and (max-width:575px)" : {
                            mr:1,
                            }
                        }}
                        >
                        {/* Nav closed | transforms from */}
                        <Box className={"nav-open"}>
                            <NavClosed/>
                        </Box>
                        {/* Nav open | transforms to */}
                        <Box className={"nav-closed"}
                            sx={{
                            position: "absolute", 
                            display:"flex",
                            alignItems:'center'
                            // top: "20px", 
                            // left:"20px",
                            }}
                        >
                            <NavOpen/>
                            
                        </Box>
                        </Box>

                        <Typography
                        sx={{
                            fontWeight:700,
                            fontFamily:"Roboto",
                            fontSize:20,
                        }}
                        >
                            {props.relation}
                        </Typography>
                        <Box
                        sx={{
                            ml:2,
                        }}
                        >
                        <Link className="nav-link">
                            <Typography
                            sx={{
                                fontWeight:400,
                                fontFamily:"Roboto",
                                fontSize:16,
                            }}
                            >
                            Retour aanmelden
                            </Typography>
                        </Link>

                        <Link className="nav-link">
                            <Typography
                            sx={{
                                fontWeight:400,
                                fontFamily:"Roboto",
                                fontSize:16,
                            }}
                            >
                            FAQ
                            </Typography>
                        </Link>
                        </Box>
                        
                    </Box>

                    <Box
                        sx={{
                        display:"flex",
                        alignItems:"center",
                        }}
                    >
                        <Link className="nav-link to-webshop">
                            <Typography
                                sx={{
                                fontWeight:400,
                                fontFamily:"Roboto",
                                fontSize:16,
                                display:"flex",
                                alignItems:"center",
                                gap:2
                                }}
                            >
                                Naar webshop
                                <OpenInNewOutlinedIcon className="icon"/>
                            </Typography>
                        </Link>
                        <ButtonBase
                        type="submit"
                        // onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                        sx={{
                            ml:1,
                            color:"white",
                            fontWeight:"500",
                            fontFamily:"Roboto",
                            fontSize:16,
                            // justifyContent:"flex-start",
                            height:"40px",
                            alignItems:"center",
                            // pl:2,
                            // background:"rgba(255,255,255,.15)",
                            background:"rgba(65,128,246,1)",
                            borderRadius:6,
                            flexShrink:0,
                            px:3,
                            transition:"250ms",
                            "&:hover" : {
                                background:"#0057FA",
                                transition:"250ms"
                            },
                            "@media screen and (max-width: 575px)" : {
                                display:"none"
                            }
                            
                        }}
                        >
                            Neem contact op
                            {/* <ArrowBackIcon fontSize="small"/> */}
                        </ButtonBase>
                        <Box
                            sx={{
                                display:"flex",
                                alignItems:'center',
                                ml:3,
                            }}
                            onClick={handleFilterPopoverClick}
                        >
                            {localeSettings[language].flag}
                        </Box>
                        <MPOChooseLanguage
                        triangle={{
                            position:"center"
                        }}
                            id={filterPopoverId}
                            open={filterPopoverOpen}
                            anchorEl={filterAnchorEl}
                            onClose={handleFilterPopoverClose}
                        />
                    </Box>
                </Box>
            
            </Box>
            <Box
                className={cx("mobile-nav", {
                'active': navActive,
                })}
                // className={"mobile-nav"}
                sx={{
                position:"absolute",
                display:"flex",
                flexDirection:"column",
                left:0,
                width:1,
                height:1,
                px:3,
                top:0,
                pt:8,
                zIndex:3,
                background:'#D1DCF3',
                transition:"250ms",
                transform:"scale(0)",
                transformOrigin:"left 64px",
                

                "& .nav-link" : {
                    display:"flex",
                    alignItems:"center",
                    py:2,


                    flexShrink:0,
                    px:2,
                    borderRadius:5,
                    textDecoration:"none",
                    transition:"250ms",
                    fontFamily:"Roboto, sans-serif",
                    position:"relative",


                    "& p" : {
                    transition:"250ms",
                    color:"#31415f",
                    },
                    "& .icon" : {
                    transition:"250ms",
                    color:"#8E97A7",
                    },

                    "&:hover" : {
                    transition:"250ms",

                    "& p" : {
                        transition:"250ms",
                        // fontWeight:500,
                        color:"#31415f",
                        textShadow:"1px 0 0 currentColor"

                    },


                    "& .icon" : {
                        transition:"250ms",
                        color:"#31415f",
                    },
                    },

                },
                
                "@media screen and (max-width:950px)" : {
                    "&.active" : {
                    // left:0,
                    // top:0,
                    transition:"250ms",
                    transform:"scale(1)",
                    // display:"flex",
                    // flexDirection:"column",
                    },
                },
                "@media screen and (max-width:575px)" : {
                    pl:0
                }

                }}
            >
                <Box
                    sx={{
                        pt:2,
                        width:850,
                        "@media screen and (max-width:950px)" : {
                        width:1,
                        }
                    }}
                    >
                    <Link className="nav-link">
                        <Box sx={{width:40}}></Box>
                        <Typography
                        sx={{
                            fontWeight:400,
                            fontFamily:"Roboto",
                            fontSize:17,
                        }}
                        >
                        Retour aanmelden
                        </Typography>
                    </Link>

                    <Link className="nav-link">
                        <Box sx={{width:40}}></Box>
                        <Typography
                        sx={{
                            fontWeight:400,
                            fontFamily:"Roboto",
                            fontSize:17,
                        }}
                        >
                        FAQ
                        </Typography>
                    </Link>

                    <Link className="nav-link to-webshop">
                        <Box sx={{width:40}}></Box>
                        <Typography
                        sx={{
                            fontWeight:400,
                            fontFamily:"Roboto",
                            fontSize:16,
                            display:"flex",
                            alignItems:"center",
                            gap:2
                        }}
                        >
                        Naar webshop
                        <OpenInNewOutlinedIcon className="icon"/>
                        </Typography>
                    </Link>
                </Box>

                <Box
                    sx={{
                    position:"absolute",
                    bottom:24,
                    left:24,
                    right:24,
                    }}
                >
                    <ButtonBase
                        type="submit"
                        // onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                        sx={{
                            color:"white",
                            fontWeight:"500",
                            fontFamily:"Roboto",
                            fontSize:16,
                            // justifyContent:"flex-start",
                            height:"40px",
                            alignItems:"center",
                            // pl:2,
                            // background:"rgba(255,255,255,.15)",
                            background:"rgba(65,128,246,1)",
                            borderRadius:6,
                            flexShrink:0,
                            width:1,
                            transition:"250ms",
                            "&:hover" : {
                                background:"#0057FA",
                                transition:"250ms"
                            },
                            "@media screen and (min-width: 575px)" : {
                            display:"none"
                            }
                            
                        }}
                    >
                        Neem contact op
                        {/* <ArrowBackIcon fontSize="small"/> */}
                    </ButtonBase>
                </Box>

            </Box>
        </>
    )
}