import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import BottomRight from "../../positionals/BottomRight";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";

const LogCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        {props.skeleton ? 
                            <Box width="50%">
                                <ShTextSkeleton/>
                            </Box>    
                            :
                            <Text bold>{props.row.user}</Text>
                        }

                        <Box display={"flex"} gap={0.5} width={props.skeleton ? "88" : null}>
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/>
                                :
                                <>
                                    <Text noBreak>{props.row.creationDate}</Text>
                                    <Text light>{props.row.creationTime}</Text>
                                </>
                            }
                        </Box>
                    </Box>

                    {/* Holds tht date and batch */}
                    <Box marginTop={2}
                        sx={{
                            whiteSpace:"normal"
                        }}
                    >
                        {props.skeleton ? 
                            <ShTextSkeleton/>
                            :
                            <Text>{props.row.description}</Text>
                        }
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default LogCard;