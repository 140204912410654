import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { tokens } from "../../../../../../theme";
import ShDataGrid from "../../../../../../components/theme/datagrid/ShDataGrid";
import Text from "../../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";

import { useTranslation } from "react-i18next";
import MAddPrinter from "../../../../../../components/global/Modals/MAddPrinter";
import { useFindPrinterByIdQuery, useFindAllPrintersQuery} from "../../../../../../newapi/warehouse/printerSlice"
import SpPrinterDetails from "../../../../../../components/global/Sidepanels/SpPrinterDetails/SpPrinterDetails";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_printer")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "printerFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [selectedPrinterId, setSelectedPrinterId] = useState(null);
    const [printerSelected, setPrinterSelected] = useState(false);
    const { data: printerData, error, isLoading } = useFindPrinterByIdQuery(
       selectedPrinterId,
        {
            skip: !printerSelected
        }
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const getPrinterDetails = (row) => {  
        if (!row) return;
        setSelectedPrinterId(row.id);
        setPrinterSelected(true);
    };

    useEffect(() => {
        if (!isLoading && printerData && printerSelected) {
            setIsExtended(true);
        }
    }, [printerData, isLoading, printerSelected]);

    useEffect(() => {
        if(!isExtended) {
            setPrinterSelected(false);
        }
    },[isExtended])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        { field: "description", headerName: t("description"), flex: 2, cellClassName: "printer-description" },
        { field: "internal_name", headerName: t("internal_name"), flex: 2, cellClassName: "printer-internal-name" },
        //{ field: "printnode_id", headerName: "PrintNode ID", flex: 1, cellClassName: "printer-printnode-id" },
        { field: "zpl_support", headerName: t("zpl_support"), flex: 1, cellClassName: "printer-zpl-support", renderCell: (cellValues) => cellValues.value ? "Yes" : "No" },
        { field: "format", headerName: t("format"), flex: 1, cellClassName: "printer-format" },
        { field: "custom_width", headerName: t("custom_width"), flex: 1, cellClassName: "printer-custom-width" },
        { field: "custom_height", headerName: t("custom_height"), flex: 1, cellClassName: "printer-custom-height" },
        { field: "warehouseId", headerName: t("warehouse"), flex: 1, cellClassName: "printer-warehouse-id",
            renderCell: (cellValues) => {
                return(<Text>{cellValues.row.warehouse?.name}</Text>)
            }
        },
            {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // Custom cell rendering for phone view
            }
        }
    ];

    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                <Box
                    sx={{
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        flex:1,
                        borderTopLeftRadius:16,
                        borderTopRightRadius:16,
                        "@media screen and (max-width: 48em)" : {
                            background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        }
                    }}
                >
                    <ShDataGrid2
                        content={useFindAllPrintersQuery}
                        gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                        title={t("overview")}
                        gridOptions={gridOptions} 
                        columns={columns}
                        isSidepanelGrid
                        onRowClick={getPrinterDetails}
                        rows={data}>
                    </ShDataGrid2>
                </Box>

                <MAddPrinter onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                { <SpPrinterDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    data={printerData}                
                ></SpPrinterDetails> }
            </Box>
    )
};

export default All;
