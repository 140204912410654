import { Box, ButtonBase, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useLayoutEffect, useState } from "react";
import Text from "../text/Text";
import ChartText from "../text/ChartText";
// import Dropdown from "../Dropdown";
import { DropdownBase } from "./Dropdown";

export const FilterDropdownBase = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const colorType = theme.palette.mode === "dark" ? "light" : "st";
    const mode = props.mode ?? "text";
    
    const [color,setColor] = useState(props.color ?? "default");
    useLayoutEffect(() => {
        if(props.mode === "filled" && typeof props.color !== "undefined") {
            setColor(props.color);
        }
    },[props.color,props.mode]);

    const getTextForMultiple = () => {
        if(props.selected.length === 0) return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        if(props.selected.length === 1) return props.options[props.selected][props.displayName];
        
        return props.selected.length
    }


    const getSingleValue = () => {
        if(props.selected === -1 || !props.options) {
            return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        }
        if(typeof props.options[props.selected] === "object") {
            return props.options[props.selected][props.displayName]
        }
        return props.options[props.selected]
    }


    return (
        <ButtonBase
            aria-describedby={props.describedBy} 
            onClick={props.onClick}

            TouchRippleProps={{ 
                style: { 
                    color: mode === "text" ? colors.txt["secondary"] :  colors.primary[200]
                } 
            }} 
            sx={{
                display:"flex",
                alignItems:"center",
                gap:mode == "text" ? 0 : 1.5,
                background: mode == "text" ? null : chartTokens[props.color][props.color === "default" ? colorType : 'st'][100],
                height:40,
                paddingRight: 0.5,
                paddingLeft: 1.5,
                borderRadius:5,
            }}
        >
            {props.multiple ?
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1
                    }}
                >
                    <ChartText mode={mode} color={color} bold>{getTextForMultiple()}</ChartText>
                    {props.selected.length > 1 ?
                        <ChartText mode={mode} color={color} light={props.color === "default"}>geselecteerd</ChartText>
                    :null}
                    {/* <ChartText mode={mode} color={color} bold>{selected !== -1 ? props.options[selected][props.displayName] : "Alle"}</ChartText> */}
                </Box> 
            :
                <ChartText 
                    mode={mode} 
                    color={color} 
                    bold
                >
                    {getSingleValue()}
                </ChartText>
            }
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    transform:"rotate(90deg)",
                    width: 32,
                    height:32,
                    flexShrink:0,
                    borderRadius:4,
                    background: mode == "text" ? null : chartTokens[color][colorType][1000],
                    color: mode == "text" ? colors.txt["secondary"] : theme.palette.mode === "dark" ? colors.txt["primary"] : colors.grey[0],

                }}
            >
                <ChevronRightIcon/>
            </Box>
        </ButtonBase>
    )
}

export const FilterDropdown = (props) => {
    return (
        <DropdownBase {...props}>
            <FilterDropdownBase/>
        </DropdownBase>
    )
}