import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Channels from "./Views/Channels";
import Logs from "./Views/Logs";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import Details from ".//Views/Details";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spCarrierDetailsConfig } from "./spCarrierDetailsConfig";
const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpCarrierDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props

    
    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: theme.palette.mode === "dark" ? "blue" : "primary",
            content: <LocalShippingIcon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && data !== null && typeof data.id !== "undefined") {
        //   let locations = data.stocks.map((stock) => stock.warehouseLocation.location);
        //   let combinedLocations = locations.join(", ");
      
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data.name,
            subTitle: `${data.length} x ${data.width} x ${data.height}`,
            icon :{
                theme: theme.palette.mode === "dark" ? "blue" : "primary",
                content: <LocalShippingIcon/>
            },
            options: [
                // {
                //     heading: t('zone'),
                //     content: data.warehouseZone?.description
                // },
                // {
                //     component: <MultiProductBox 
                //     multiproduct={data.multiproduct} 
                //     multiproductzone={data.warehouseZone.multiproduct} 
                //     multibatchzone={data.warehouseZone.multibatch}/>
                // },
                // {
                //     heading: t('location_type'),
                //     content: data.warehouseLocationType.description
                // },
                // {
                //     heading: t('reach_type'),
                //     content: data.reachType.description
                // }
            ],
          }));
        }
      }, [data,theme.palette.mode]);

    return (
        <Sidepanel2
            tabConfig={spCarrierDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spCarrierDetailsConfig.details} data={props.data}/>
                    {/* { <Barcodes id={props.data ? props.data.id : -1} apiRef={barcodesApiRef}/> } */}
                    { <Channels config={spCarrierDetailsConfig.channels} id={props.data ? props.data.id : -1} /> }
                    { <Logs config={spCarrierDetailsConfig.logs} id={props.data ? props.data.id : -1}/> }
                </>
        </Sidepanel2>
    );
};

export default SpCarrierDetails;