import { Box, InputBase, useTheme } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useAddWarehouseMutation } from "../../../newapi/warehouse/warehouseApiSlice";
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import { yupRequired } from "../../../utils/validation";
import { countryCodeNL } from '../../../utils/constants';
import { countriesFromLocale } from '../../../utils/countries';
import { useSelector } from 'react-redux';


const MAddWarehouse = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [addWarehouse,{isLoading}] = useAddWarehouseMutation();

    const [warehouseInfo, setWarehouseInfo] = useState({
        name: "",
        dockings: 0,
        street: "",
        house_nr: "",
        house_nr_extra: "",
        postal: "",
        city: "",
        country: ""
    });

    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        street: yupRequired('street', t),
        house_nr: yupRequired('house_nr', t),
        postal: yupRequired('postal', t),
        city: yupRequired('city', t),
        country: yupRequired('country', t)
    };
    
    const schema = yup.object().shape(rules);

   
    const SaveWarehouseInfo = async (warehouseInfo, props) => {
        try {
            setFieldErrors({});
            await schema.validate(warehouseInfo, { abortEarly: false });
            const data = await addWarehouse(warehouseInfo).unwrap()
            showToastMessagePostRequest(t, data);

            // reset object
            setWarehouseInfo({
                name: "",
                dockings: 0,
                street: "",
                house_nr: "",
                house_nr_extra: "",
                postal: "",
                city: "",
                country: ""
            });

            props.handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setWarehouseInfo({
          ...warehouseInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };
      
    // Set default country
    if (warehouseInfo.country === "") {
        handleChange('country', countryCodeNL);
    }

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_warehouse")}</Subheading>
            </Box>

            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("name")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                                name="name"
                                value={warehouseInfo.name}
                                onChange={handleChange}
                                error={fieldErrors.name}
                            />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("street")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                                name="street"
                                value={warehouseInfo.street}
                                onChange={handleChange}
                                error={fieldErrors.street}
                            />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("house_nr")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                                name="house_nr"
                                value={warehouseInfo.house_nr}
                                onChange={handleChange}
                                error={fieldErrors.house_nr}
                            />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("house_nr_extra")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <InputBase 
                        sx={{
                            px:2,
                            flex:1, 
                            color:colors.txt["primary"],
                            lineHeight:"32px",
                        }}
                        value={warehouseInfo.house_nr_extra}
                        onChange={(e) => handleChange(props.house_nr_extra, e.target.value)}
                    />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("postal")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                                name="postal"
                                value={warehouseInfo.postal}
                                onChange={handleChange}
                                error={fieldErrors.postal}
                            />
                        
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("city")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                                name="city"
                                value={warehouseInfo.city}
                                onChange={handleChange}
                                error={fieldErrors.city}
                            />
                        
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("country")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                            <InputDropdown
                                // variant="darker"
                                displayName={"name"} 
                                onChange={(selected) => handleChange('country', countries[selected].alpha2)}
                                noSelection={t("select_country")} //Custom text when nothing is selected
                                options={countries ?? null}
                                selected={countries?.findIndex(country => (warehouseInfo.country && warehouseInfo.country === country.alpha2) || (country.alpha2 === countryCodeNL)) ?? -1}
                                closeOnSelection
                            />
                    </Box>
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveWarehouseInfo(warehouseInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddWarehouse;
