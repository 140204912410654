
import { Box } from '@mui/material';
import Heading from '../../text/Heading';
import Text from '../../text/Text';
import { ReactComponent as TableEmpty } from '../../../../styles/svg/tableempty.svg'
import { useTranslation } from 'react-i18next';

const ShGridEmpty = () => {
    const {t} = useTranslation();
    
    return (
        <Box 
            sx={{
                display:"flex",
                justifyContent:"center",
            }}
        >
            <Box
                sx={{
                    maxWidth:300,
                    display:"flex",
                    justifyContent:"center",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >
                <TableEmpty/>
                <Box
                    sx={{
                        mt:3,
                        display:"flex",
                        flexDirection:"column",
                        gap:0.5
                    }}
                >
                    <Heading align="center">{t('grid_empty_title')}</Heading>
                    <Text align="center" light>{t('grid_empty_description')}</Text>
                </Box>
            </Box>
        </Box>
    );
}

export default ShGridEmpty;