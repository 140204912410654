// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    
        const columns = [
            {title: "PostNL", logo: <PostNLLogo width={60} height={60}/>, key: "postnl"},
            {title: "DHL", logo: <DhlLogo width={60} height={60}/>, key: "dhl"},
            {title: "Dpd", logo: <DpdLogo width={60} height={60}/>, key: "dpd"},
            {title: "Bol.", logo: <BolLogo width={60} height={60}/>, key: "bol"},
            {title: "Anders", logo: <AltRouteOutlinedIcon sx={{fontSize:"60px"}} />, key: "other"}
        ]
        // New prop is called "Logo"
        // {title: 'normal', logo: <CheckCircleOutlineIcon />, key: "normal"},
        //     {title: 'runner', logo: <DirectionsRunIcon />, key: "runner"},
        //     {title: 'highcare', logo: <FavoriteOutlinedIcon />, key: "highcare"},
        //     {title: 'special', logo: <AutoAwesomeOutlinedIcon />, key: "special"}

        const handleOptionSelect = (option) => {
            props.handleDataUpdate({ selectedOption: columns[option].key });
            props.handleNext("tabBasicInfo");
        };
    
        return (
                <SelectGroup selected={-1} setSelected={handleOptionSelect} options={columns} image={true} />
        );
    };

const ChooseShipperType = (props) => {
    return (
        <ProgressionTab tabId="tabChooseType" step={1} maxSteps={6}>
            <Content/>
        </ProgressionTab>
    )
}

export default ChooseShipperType;

