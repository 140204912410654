import { Box, InputBase, } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';

import Text from "../../../../theme/text/Text";

import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";

import NumberInput from "../../../../theme/inputs/NumberInput";

import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditShipperMutation } from "../../../../../newapi/shipper/shipperSlice";


import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { JsonRequestError } from "@fullcalendar/core";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [shipperInfo, setShipperInfo] = useState({
        state: "active",
        id: 2,
        name: "DHL",
        reference: "",
        type: null,
        standard_time: "12:00",
        standard_time_saturday: "14:00",
        standard_time_sunday: "14:30",
        maxWeight: "120000",
        maxLength: 2000,
        maxWidth: 2000,
        maxHeight: 2500,
        maxVolume: "10000000000",
        alternativeAddress: null,
        street: null,
        house_nr: null,
        house_nr_extra: null,
        postal: null,
        city: null,
        country: null,
        company: null,
        contactperson: null,
        public_key: null,
        private_key: null
    });
    useEffect(() => {
        setShipperInfo(props.data);
    },[]);
    

    const handleChange = (key, value) => {
        setShipperInfo({
          ...shipperInfo,
          [key]: value
        });
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={shipperInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [EditShipper,{isLoading}] = useEditShipperMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const data = await EditShipper({
                    ...shipperInfo,
                    reference: shipperInfo.reference === "" ? null : shipperInfo.reference,
                  }).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };
    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("name")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={shipperInfo.name} onChange={(e) => handleChange('name', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>

                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    > 
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("reference")}</Text>
                            </Box>
                            
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                            // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={shipperInfo.reference} onChange={(e) => handleChange('reference', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
            </Dropdown>
            <Dropdown title={t("measurements")} subTitle={t("shipper_max_dimensions_and_weight_description")} open={window.innerWidth > 767} isLast sx={{paddingBottom:"0px"}}>
            {/* <Divider></Divider> */}
                <Box sx={{
                    display:"flex",
                    flexDirection:"column"
                }}>
                    {/* Two columns */}
                    <Box
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        {/* <Box display={"flex"} flexDirection={"column"} gap={2}> */}
                            <Box lineHeight={"38px"}>
                                <Text>{t("max_weight")} (gram)</Text>
                                <NumberInput value={shipperInfo.maxWeight} onChange={(e) => handleChange('maxWeight', e.target.value)}></NumberInput>
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("max_length")} (mm)</Text>
                                <NumberInput value={shipperInfo.maxLength} onChange={(e) => handleChange('maxLength', e.target.value)}></NumberInput>
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("max_width")} (mm)</Text>
                                <NumberInput value={shipperInfo.maxWidth} onChange={(e) => handleChange('maxWidth', e.target.value)}></NumberInput>
                            </Box>
                            
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                    <Box lineHeight={"40px"}>
                                <Text>{t("max_height")} (mm)</Text>
                                <NumberInput value={shipperInfo.maxHeight} onChange={(e) => handleChange('maxHeight', e.target.value)}></NumberInput>
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t("max_volume")} (mm)</Text>
                                <NumberInput value={shipperInfo.maxVolume} onChange={(e) => handleChange('maxVolume', e.target.value)}></NumberInput>
                            </Box>
                            </Box>
                    <Box>
                    </Box>
                    </Box>
            </Dropdown>

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
        <GridActions
            id={props.data.id}
            action={handleSaveClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;