
import CropFreeIcon from '@mui/icons-material/CropFree';
const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
}

export const spShipperDetailsConfig = {
    authorisation:  {
        title: 'authorisation',
        icon: <CropFreeIcon/>,
        //mobileactions added later
    },
    details: {
        title: 'details',
        icon: <CropFreeIcon/>,
        //mobileactions added later
    },
    pickup: {
        title: 'sending',
        icon: <CropFreeIcon/>,
        options : [],
    }
};
