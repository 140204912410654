import { useSortable } from "@dnd-kit/sortable";
import { FC, useEffect } from "react";
import { CSS } from "@dnd-kit/utilities";
import { Box, useTheme } from "@mui/material";
import Text from "../theme/text/Text";
import { DropdownBase } from "../theme/dropdowns/Dropdown";
import { tokens } from "../../theme";
import NumberInput from "../theme/inputs/NumberInput";
import TextInput from "../theme/inputs/TextInput";

const SplitOrderLine = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const selectOption = (index) => {

  }

  const onChange =(e) => {
    const value = e.target.value;
    if (value && !isNaN(value)) {
      props.setProducts(props.index,value);
      // setProducts() // change here
      console.log(`The value is a number: ${value}`);
    } else {
      props.setProducts(props.index,0);
    }
  }

  console.log(props.isError);
  
  return (
    <Box
      sx={{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        background:"white",
        border:"1px solid #ecf0f5",
        boxShadow:"0px 1px 2px rgba(49,65,95,.55)",
        height:40,
        pl:2,
        pr:0.5,
        borderRadius:5,
        width:450,
        
      }}
    >
      <Box 
        sx={{
          flex:1,
          minWidth:0,
          wordBreak:"keep-all",
          whiteSpace:"nowrap",
          overflow:"hidden",
          textOverflow:"ellipsis",
          color: colors.txt["primary"],
          fontSize:14,
          // fontWeight:"bold"
        }}
      >
        {props.product.title}
      </Box>

      <Box 
        sx={{
          ml:"auto",
          mr:1,
          px:2,
          lineHeight:"32px",
          // background:"#ecf0f5",
          borderRadius:4,
        }}
      >
        <Text >{props.product.currAmount}</Text>
        <Text light>&nbsp;x</Text>
      </Box>
      
      <TextInput error={props.isError} type="number" onChange={onChange} width={64}/>
        
        
      {/* {props.product?.amount} */}
    </Box>
  );
};

export default SplitOrderLine;