
import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import { useTranslation } from "react-i18next";
import Icon from "../../icons/Icon";

import StayPrimaryPortraitIcon from '@mui/icons-material/StayPrimaryPortrait';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
export const SupplierRelationCard = (props) => {
    const theme = useTheme();
    const { t,i18n } = useTranslation();

    
    // let date = new Date(props.createdAt);

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text bold>Jumbo Distributie</Text>
                                <Text light>Zeggeven 2, Enschede, Overijssel, Netherlands</Text>
                            </>
                        }
                    </Box>
                    <Box 
                        sx={{
                            mt:4,
                        }}
                    >
                        <Box>
                            <Icon size="s24"><StayPrimaryPortraitIcon fontSize="small"/></Icon>
                        </Box>
                        <Box
                            sx={{
                                mt:1,
                            }}
                        >
                            <Icon size="s24"><MailOutlineIcon fontSize="small"/></Icon>
                        </Box>
                        {props.skeleton ?
                            <ShTextSkeleton/> 
                            :
                            <>
                                <Text>
                                    {/* {date.toLocaleDateString(i18n.language)} */}
                                </Text>&nbsp;&nbsp;
                                <Text light>
                                    {/* {date.toLocaleTimeString(i18n.language,{timeStyle: 'short'})} */}
                                </Text>
                            </>
                        }
                    </Box>

                    <TopRight>
                        <CardLabel 
                            variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                             
                            colorflip={theme.palette.mode !== "dark"}
                            position="child-tr"
                        >
                            {props.skeleton ? "" : "2"}
                        </CardLabel>
                    </TopRight>
                </Box>
            </Box>
        </BaseCard>
    )
}
