import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseCard from "../BaseCard";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import { NoTextLabel } from "../../label/NoTextLabel";

export const PickListCard = (props) => {
    const theme = useTheme();
    
    const { t,i18n } = useTranslation();

    let status = props.state;
    let labelcolor = "grey";
    let labeltext = t('unknown');
    switch(status){
        case "notreceived":
            labelcolor = "grey"
            labeltext = t('not_received');
            break;
        case "partlyreceived":
            labelcolor = "blue"
            labeltext = t('partly_received');
            break;
        case "fullyreceived":
            labelcolor = "green"
            labeltext = t('fully_received');
            break;
        default:
            labelcolor = "red"
            labeltext = t('unknown');
            break;
    }
    let typeLabelColor = props.totalOrderLines && props.totalOrderLines === 1 ? "orange" : "purple";

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                        }}
                    >
                        <Box>
                            {props.skeleton ?
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{props.id}</Text><br/>
                                    <Text>{props.relation.name}</Text>
                                </>
                            }
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                gap:1,
                            }}
                        >
                            <NoTextLabel palette="normal" variant={typeLabelColor}/>
                            <NoTextLabel palette="normal" variant={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            mt:3,
                            display:"flex",
                            gap:4,
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            {props.skeleton ?
                                <ShTextSkeleton/> 
                                :
                                <>
                                    <Text light>{t("products")}</Text>
                                    <Text>{props.totalOrderLines}</Text>
                                </>
                            }
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            {props.skeleton ?
                                <ShTextSkeleton/> 
                                :
                                <>
                                    <Text light>{t("stream")}</Text>
                                    <Text>{props.stream?.shortName}</Text>
                                </>
                            }
                        </Box>
                    </Box>

                </Box>
            </Box>
        </BaseCard>
    )
}
