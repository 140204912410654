// TabContent1.js
import React, { useEffect, useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import SelectGroupMultiple from '../../../../theme/Select/SelectGroupMultiple';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const {t} = useTranslation();
        const columns = [
            {title: 'normal', logo: <CheckCircleOutlineIcon sx={{fontSize:"60px"}}  />, key: "normal"},
            {title: 'runner', logo: <DirectionsRunIcon sx={{fontSize:"60px"}}  />, key: "runner"},
            {title: 'highcare', logo: <FavoriteOutlinedIcon sx={{fontSize:"60px"}}  />, key: "highcare"},
            {title: 'special', logo: <AutoAwesomeOutlinedIcon sx={{fontSize:"60px"}}  />, key: "special"}
        ]

        const [selectedOptions, setSelectedOptions] = useState([]);
        const handleOptionSelect = (optionIndices) => {
            const options = optionIndices.map(index => columns[index].key);
            setSelectedOptions(options);
        };

        useEffect(() => {
            props.handleDataUpdate({ priorities: selectedOptions });
        }, [selectedOptions]);

    
        return (
            <div>
                <SelectGroupMultiple selected={selectedOptions} setSelected={handleOptionSelect} options={columns} image={true} multiple={true} />
            </div>
        );
    };

const StreamPriorities = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
            tabId="tabPriorities" 
            step={3} 
            maxSteps={5}
            subtitle={t("priorities")} 
            nextTab={"tabShipper"}>
            <Content/>
        </ProgressionTab>
    )
}

export default StreamPriorities;

