import { Box, InputBase, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';

import TvDropOut from "../../../theme/dropout/TvDropOut";


import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import CallReceivedOutlinedIcon from '@mui/icons-material/CallReceivedOutlined';

import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import ShLabel from "../../../theme/label/ShLabel";
import SettingGroup from "../../../theme/settings/SettingGroup";
import { useGetSettingsQuery } from "../../../../newapi/global/generalApiSlice";
import BoolSetting from "../../Settings/BoolSetting";
import NumberSetting from "../../Settings/NumberSetting";
import BoolAuthSetting from "../../Settings/BoolAuthSetting";
import { UserPrivileges } from "../../../../utils/permissions";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import SaveIcon from '@mui/icons-material/Save';
import { useEditUsergroupMutation } from "../../../../newapi/user/usergroupSlice";
import { toast } from "react-toastify";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../utils/toasts";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <Shbutton
        onClick={props.action}
        color={theme.palette.mode === "dark" ? "blue" : "primary"}
        variant="contained"
        block
        endIcon={<SaveIcon />}
      >
          {t('save')}
      </Shbutton>
    );
};

const TopRight = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (    
        
        <ShLabel
            palette={"normal"}
            isHeader
            fitted
            variant={theme.palette.mode === "dark" ? "blue" : "primary"}
            size={32}
        >
            {props.title}
        </ShLabel>
    )
}

const SpUserPermissionDetails = (props) => {
    const { t,ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [EditUsergroup,{isLoading}] = useEditUsergroupMutation();
    const handleSaveClick = async () => {
        const canSave = true;
        if(canSave){    
            try {
                const data = await EditUsergroup(usergroupInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
              } catch (err) {
                  showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
              }
          } else {
              if(!isLoading) {
                  showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
            }
        }        
    };


    const [panel, setPanel] = useState({
        title: props.data?.name,
        subTitle:t('assign_permissions'),
        icon :{
            theme: theme.palette.mode === "dark" ? "blue" : "primary",
            content: <AdminPanelSettingsOutlinedIcon/>
        },
        options : [],
        // actions: <GridActions
        //           id={props.data?.id}
        //           action={handleSaveClick}/>
    });

    const [usergroupInfo, setUsergroupInfo] = useState({
        "id": 0,
        "name": "",
        "userPrivileges": [],
        "admin": false,
        "customerId": null,
        "relationId": null
    });

    

    useEffect(() => {
      if(props.data) {
        setUsergroupInfo(props.data);
        setUsergroupInfo(() => {
                setPanel(prevPanel => ({
                    ...prevPanel,
                    title: props.data.name,
                    icon :{
                        theme: theme.palette.mode === "dark" ? "blue" : "primary",
                        content: <AdminPanelSettingsOutlinedIcon/>
                    },
                    // actions: <GridActions
                    //           id={props.data?.id}
                    //           action={handleSaveClick}/>
                }));
            return props.data;
        });
      }
    },[props.data,theme.palette.mode]);

    

    const handleChange = (key, value) => {
        setUsergroupInfo(prevUsergroupInfo => {
            const updatedUsergroupInfo = { ...prevUsergroupInfo, [key]: value };
            if(key === "name"){
                setPanel(prevPanel => ({
                    ...prevPanel,
                    title: updatedUsergroupInfo.name   
                }));
            }
            return updatedUsergroupInfo;
        });
    };

    const handlePrivChange = (key, value) => {
        if(!usergroupInfo || usergroupInfo.admin) return;
        let changed = false;
        let updatedUsergroupInfo = JSON.parse(JSON.stringify(usergroupInfo));
        if(value){
            if(!updatedUsergroupInfo.userPrivileges) updatedUsergroupInfo.userPrivileges = [];
            if(!updatedUsergroupInfo.userPrivileges.includes(key)){
                updatedUsergroupInfo.userPrivileges.push(key);
                changed = true;
            }
        } else {
            const index = updatedUsergroupInfo.userPrivileges.indexOf(key);
            if(index !== -1){
                updatedUsergroupInfo.userPrivileges.splice(index, 1);
                changed = true;
            }
        }
        if(changed){
            setUsergroupInfo(updatedUsergroupInfo);
        }
    };

    return (
      <Sidepanel
        content={panel}
        isExtended={props.isExtended}
        setIsExtended={props.setIsExtended}
        asTab //new, shows singular view if need be
        hideFooter //new hides the footer
      >
        <>
          <Box
            height={1}
            display={"flex"}
            flexDirection={"column"}
            paddingX={3}
            sx={{
              px: 3,
              pb: 11,
              // marginBottom:10
            }}
          >
            <TvDropOut
              icon={<CallMadeOutlinedIcon fontSize="small" />}
              title={t("general")}
              subTitle={t("assign_general_permissions_description")}
              defaultOpen={true}
              topRight={<TopRight title={panel.title} />}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "24px 80px",
                  flexWrap: "wrap",
                  "@media screen and (max-width: 63.9375em)": {
                    py: 3,
                    flexDirection: "column",
                    gap: 4,
                    marginBottom: "80px",
                  },
                }}
              >

              <Box
                sx={{
                  flex:1,
                  maxWidth:250,
                  "@media screen and (max-width: 1023px)" : {
                    maxWidth:"unset"
                  }
                }}
              >
                  <SettingGroup title={t("name")} >
                    <Box>
                      <Box
                        display="flex"
                        height="32px"
                        // maxWidth="400px"
                        borderRadius="20px"
                        sx={{
                          background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                          marginTop: 0.5,
                          marginBottom: 0.5,
                          justifyContent: "space-between",
                          "@media screen and (max-width: 47.9375em)": {
                            flexDirection: "column",
                          },
                        }}
                      >
                        <InputBase
                          sx={{ ml: 2, flex: 1 }}
                          placeholder=""
                          value={usergroupInfo.name}
                          onChange={(e) => handleChange("name", e.target.value)}
                        />
                      </Box>
                    </Box>
                  </SettingGroup>
                </Box>


                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("admin")} >
                    <BoolAuthSetting
                      title={t("is_admin")}
                      val={usergroupInfo.admin}
                      onChange={(e) => handleChange("admin", e)}
                    />
                  </SettingGroup>
                </Box>
              </Box>
            </TvDropOut>
            <TvDropOut
              icon={<CallMadeOutlinedIcon fontSize="small" />}
              title={t("warehouse")}
              subTitle={t("assign_warehouse_permissions_description")}
              defaultOpen={false}
              topRight={<TopRight title={panel.title} />}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "24px 80px",
                  flexWrap: "wrap",
                  "@media screen and (max-width: 63.9375em)": {
                    py: 3,
                    flexDirection: "column",
                    gap: 4,
                    marginBottom: "80px",
                  },
                }}
              >

                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup
                    title={t("warehouse")}
                    
                  >
                    <BoolAuthSetting
                      title={UserPrivileges.WAREHOUSE_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.WAREHOUSE_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.WAREHOUSE_READ, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.WAREHOUSE_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.WAREHOUSE_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.WAREHOUSE_MANAGE, e)
                      }
                    />
                  </SettingGroup>
                </Box>
                

                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("warehouse_location")}>
                    <BoolAuthSetting
                      title={UserPrivileges.WAREHOUSELOCATION_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.WAREHOUSELOCATION_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.WAREHOUSELOCATION_READ, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.WAREHOUSELOCATION_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.WAREHOUSELOCATION_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(
                          UserPrivileges.WAREHOUSELOCATION_MANAGE,
                          e
                        )
                      }
                    />
                  </SettingGroup>
                </Box>


                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("pickingjob")}>
                    <BoolAuthSetting
                      title={UserPrivileges.PICKINGJOB_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.PICKINGJOB_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.PICKINGJOB_MANAGE, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.PICKINGJOB_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.PICKINGJOB_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.PICKINGJOB_READ, e)
                      }
                    />
                  </SettingGroup>
                </Box>
                

                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("stock")}>
                    <BoolAuthSetting
                      title={UserPrivileges.STOCK_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.STOCK_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.STOCK_READ, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.STOCK_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.STOCK_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.STOCK_MANAGE, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.STOCK_TRANSFER}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.STOCK_TRANSFER
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.STOCK_TRANSFER, e)
                      }
                    />
                  </SettingGroup>
                </Box>
                

                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("product")}>
                    <BoolAuthSetting
                      title={UserPrivileges.PRODUCT_IMPORT}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.PRODUCT_IMPORT
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.PRODUCT_IMPORT, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.PRODUCT_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.PRODUCT_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.PRODUCT_MANAGE, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.PRODUCT_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.PRODUCT_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.PRODUCT_READ, e)
                      }
                    />
                  </SettingGroup>
                </Box>
                

                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("inbound")}>
                    <BoolAuthSetting
                      title={UserPrivileges.INBOUND_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.INBOUND_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.INBOUND_READ, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.INBOUND_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.INBOUND_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.PRODUCT_MANAGE, e)
                      }
                    />
                  </SettingGroup>
                </Box>
                


                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("outbound")}>
                    <BoolAuthSetting
                      title={UserPrivileges.OUTBOUND_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.OUTBOUND_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.OUTBOUND_READ, e)
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.OUTBOUND_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.OUTBOUND_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.OUTBOUND_MANAGE, e)
                      }
                    />
                  </SettingGroup>
                </Box>
                
              </Box>
            </TvDropOut>
            <TvDropOut
              icon={<CallReceivedOutlinedIcon fontSize="small" />}
              title={t("settings")}
              subTitle={t("assign_settings_permissions_description")}
              topRight={<TopRight title={panel.title} />}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "24px 80px",
                  flexWrap: "wrap",
                  "@media screen and (max-width: 63.9375em)": {
                    py: 3,
                    flexDirection: "column",
                    gap: 4,
                    marginBottom: "80px",
                  },
                }}
              >

                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                <SettingGroup title={t("general")}>
                  <BoolAuthSetting
                    title={UserPrivileges.SETTINGS_ALL}
                    disabled={usergroupInfo.admin}
                    val={
                      usergroupInfo?.userPrivileges
                        ? usergroupInfo.userPrivileges.includes(
                            UserPrivileges.SETTINGS_ALL
                          )
                        : false
                    }
                    onChange={(e) =>
                      handlePrivChange(UserPrivileges.SETTINGS_ALL, e)
                    }
                  />
                  <BoolAuthSetting
                    title={UserPrivileges.SETTINGS_GENERAL}
                    disabled={usergroupInfo.admin}
                    val={
                      usergroupInfo?.userPrivileges
                        ? usergroupInfo.userPrivileges.includes(
                            UserPrivileges.SETTINGS_GENERAL
                          )
                        : false
                    }
                    onChange={(e) =>
                      handlePrivChange(UserPrivileges.SETTINGS_GENERAL, e)
                    }
                  />
                  <BoolAuthSetting
                    title={UserPrivileges.SETTINGS_USERPRIVILEGES}
                    disabled={usergroupInfo.admin}
                    val={
                      usergroupInfo?.userPrivileges
                        ? usergroupInfo.userPrivileges.includes(
                            UserPrivileges.SETTINGS_USERPRIVILEGES
                          )
                        : false
                    }
                    onChange={(e) =>
                      handlePrivChange(
                        UserPrivileges.SETTINGS_USERPRIVILEGES,
                        e
                      )
                    }
                  />
                </SettingGroup>
                </Box>
                
                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("webshopcredentials")}>
                    <BoolAuthSetting
                      title={UserPrivileges.WEBSHOPCREDENTIALS_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.WEBSHOPCREDENTIALS_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(
                          UserPrivileges.WEBSHOPCREDENTIALS_MANAGE,
                          e
                        )
                      }
                    />
                  </SettingGroup>
                </Box>
                
                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("relation")}>
                    <BoolAuthSetting
                      title={UserPrivileges.RELATION_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.WEBSHOPCREDENTIALS_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(
                          UserPrivileges.WEBSHOPCREDENTIALS_MANAGE,
                          e
                        )
                      }
                    />
                    <BoolAuthSetting
                      title={UserPrivileges.RELATION_READ}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.RELATION_READ
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.RELATION_READ, e)
                      }
                    />
                  </SettingGroup>
                </Box>
                
                <Box
                  sx={{
                    flex:1,
                    maxWidth:250,
                    "@media screen and (max-width: 1023px)" : {
                      maxWidth:"unset"
                    }
                  }}
                >
                  <SettingGroup title={t("carriers")}>
                    <BoolAuthSetting
                      title={UserPrivileges.CARRIER_MANAGE}
                      disabled={usergroupInfo.admin}
                      val={
                        usergroupInfo?.userPrivileges
                          ? usergroupInfo.userPrivileges.includes(
                              UserPrivileges.CARRIER_MANAGE
                            )
                          : false
                      }
                      onChange={(e) =>
                        handlePrivChange(UserPrivileges.CARRIER_MANAGE, e)
                      }
                    />
                  </SettingGroup>
                </Box>
              </Box>
            </TvDropOut>
            <TvDropOut
              icon={<WarehouseOutlinedIcon fontSize="small" />}
              title={"misc"}
              subTitle={t("assign_misc_permissions_description")}
              topRight={<TopRight title={panel.title} />}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "24px 80px",
                  flexWrap: "wrap",
                  maxWidth:250,
                  "@media screen and (max-width: 63.9375em)": {
                    py: 3,
                    flexDirection: "column",
                    gap: 4,
                    marginBottom: "80px",
                  },
                  "@media screen and (max-width: 1023px)" : {
                    maxWidth:"unset"
                  }
                }}
              >
                <SettingGroup title={t("functions")}>
                  <BoolAuthSetting
                    title={UserPrivileges.BACKOFFICE_GENERAL}
                    disabled={usergroupInfo.admin}
                    val={
                      usergroupInfo?.userPrivileges
                        ? usergroupInfo.userPrivileges.includes(
                            UserPrivileges.BACKOFFICE_GENERAL
                          )
                        : false
                    }
                    onChange={(e) =>
                      handlePrivChange(UserPrivileges.BACKOFFICE_GENERAL, e)
                    }
                  />
                  <BoolAuthSetting
                    title={UserPrivileges.ECHECK}
                    disabled={usergroupInfo.admin}
                    val={
                      usergroupInfo?.userPrivileges
                        ? usergroupInfo.userPrivileges.includes(
                            UserPrivileges.ECHECK
                          )
                        : false
                    }
                    onChange={(e) => handlePrivChange(UserPrivileges.ECHECK, e)}
                  />
                </SettingGroup>
              
              </Box>
            </TvDropOut>
       
       
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    maxWidth:"min-content",
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                        maxWidth:"unset",
                    },
                }}
            >
             <GridActions
              id={props.data?.id}
              action={handleSaveClick}/>
            </Box>
          </Box>
          
        </>
        
      </Sidepanel>
    );
};

export default SpUserPermissionDetails;