import { Box, Button, Popper, useTheme } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { tokens } from "../../../theme";
import PopoverModal from "../Modal/PopoverModal";
import { useTranslation } from "react-i18next";
import TextInput from "../inputs/TextInput";

import { ReactComponent as TableEmpty } from '../../../styles/svg/tableempty.svg'
import Text from "../text/Text";
import Subheading from "../text/Subheading";

export const DropdownBase = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const [selected, setSelected] = useState(props.multiple ? [] : props.selected ?? -1); //inner for displayname


    useEffect(() => {
        // Call the toggleDragAndDrop function prop with the popoverOpen state
        if (props.toggleDragAndDrop) {
            props.toggleDragAndDrop(!popoverOpen);
        }
    }, [popoverOpen, props.toggleDragAndDrop]);
    
    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (selected) => {
        setSelected(selected);
        props.onChange(selected);
        if(props.closeOnSelection) {
            setAnchorEl(null);
        }
        if(props.resetOnSelection){
            setSelected(-1);
        }
    }

    useEffect(() => {
        if(typeof props.selected !== "undefined"){
            setSelected(props.selected)
        }
    },[props.selected])


    // Define the props you want to exclude
    const excludedProps = ['displayName','noSelection','closeOnSelection','toggleDragAndDrop','searchOptions','valueKey','disallowDeselection','useTranslation', 'describedBy'];

    // Programmatically filter out the props
    const filteredProps = useMemo(() => Object.keys(props).reduce((acc, key) => {
        if (!excludedProps.includes(key)) {
            // console.log("key",key)
            acc[key] = props[key];
        }
        return acc;
    }, {}),[props]);


    return (
        <>
            {React.cloneElement(props.children, 
                {
                    ...filteredProps,
                    options: props.options, 
                    selected: selected, 
                    onClick:handlePopoverClick
                }
            )}

            {/* <ShDropdown */}
            <ShDropdown
                anchorOrigin={props.anchorOrigin}
                transformOrigin={props.transformOrigin}
                id={popoverId}
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                onChange={handleChange}
                useTranslation={props.useTranslation}
                displayName={props.displayName ?? "name"}
                multiple={props.multiple ?? false}
                options={props.options}
                selected={selected}
                maxHeight={props.maxHeight}
                valueKey={props.valueKey ?? null}
                searchOptions={props.searchOptions}
                styles={props.styles}
                disallowDeselection={props.disallowDeselection ?? null}
                // enableSearch={props.enableSearch}
            />
        </>
    );
}

const ShDropdown = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const multiple = props.multiple ?? false;
    const [searchValue, setSearchValue] = useState('');


    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const getOptionValue = (option, index) => {
        // If valueKey is provided and the option is an object, use it to get the value.
        // Otherwise, default to using the index as the value.
        return props.valueKey && typeof option === "object" ? option[props.valueKey] : index;
    };

    const switchSelected = (index) => {
        
        const selectedValue = getOptionValue(filteredOptions[index], index);
        const isValueSelected = props.valueKey ? selectedValue === props.selected : index === props.selected;


        if(props.searchOptions?.enabled) {
            setSearchValue("");
        }

        if(!multiple) {
            if(isValueSelected && !props.disallowDeselection) {
                // props.onChange(-1);
                props.onChange(props.valueKey ? null : -1);
           
            } else {
                props.onChange(selectedValue);
            }
        } else {
            props.onChange(prevSelected =>
                prevSelected.includes(selectedValue)
                    ? prevSelected.filter(val => getOptionValue(val) !== selectedValue)
                    : [...prevSelected, props.options[index]]
            );
        }
    };

    const isSelected = (index) => {
        const selectedValue = getOptionValue(filteredOptions[index], index);
        const currentValue = typeof props.selected === 'number' ? props.selected : getOptionValue(props.selected);

        if(!multiple) {
            return selectedValue === currentValue;
        }
        return props.selected.includes(selectedValue);
    };

    const getBackground = (index) => {
        if(isSelected(index)) {
            return theme.palette.mode === "dark" ?
                colors.blue[100] : colors.primary[100];
        }
        return theme.palette.mode === "dark" ? colors.grey[300] : "#fff";
    }
    const getColor = (index) => {
        if(isSelected(index)) {
            return theme.palette.mode === "dark" ?
                colors.blue[400] : colors.primary[400];
        }
        return colors.txt.primary;
    }


    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const filteredOptions = props.options?.filter((option) => {
        const optionValue = typeof option === "object" ? option[props.displayName] : option;
        return optionValue.toString().toLowerCase().includes(searchValue.toLowerCase());
    });

    // Define the props you want to exclude
    const excludedProps = ['displayName','noSelection','closeOnSelection','toggleDragAndDrop','searchOptions','valueKey','disallowDeselection','useTranslation', 'describedBy'];


    // Programmatically filter out the props
    const filteredProps = useMemo(() => Object.keys(props).reduce((acc, key) => {
        if (!excludedProps.includes(key)) {
            // console.log("key",key)
            acc[key] = props[key];
        }
        return acc;
    }, {}),[props]);


    return (
        
        <PopoverModal
            {...filteredProps}
            triangle={{position:"center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
        >
            <Box 
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[300] : "#fff",
                    padding:1.5,
                    paddingBottom: isStandAlone ? 4 : 1.5,
                    borderRadius:"16px",
                    borderBottomLeftRadius:0,
                    borderBottomRightRadius:0,
                    "@media screen and (max-width: 48em)" : {
                        borderRadius:"32px",
                        borderTopLeftRadius:"12px",
                        borderTopRightRadius:"12px",
                        borderBottomLeftRadius:0,
                        borderBottomRightRadius:0,

                    }
                }}
            >
                { props.searchOptions?.enabled &&
                    <Box
                        sx={{
                            mt:1.5,
                            mb:3,
                        }}
                    >
                        <TextInput
                            background={theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100]}
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={props.searchOptions.placeholder}
                        />
                    </Box>
                }
                <Box
                    sx={{
                        minWidth: props.styles?.minWidth ?? null,
                        maxHeight:props.maxHeight ?? 300,
                        display:"flex",
                        gap:1,
                        flexDirection:"column",
                        overflowY:"auto",
                        alignItems:'center',
                    }}
                >
                    {filteredOptions?.map((option, index) => (
                        <Button
                            key={"dd"+index}
                            onClick={() => switchSelected(index)}
                            TouchRippleProps={{ 
                                style: { 
                                    color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                                } 
                            }} // Directly styling the ripple here
                            variant='contained'
                            sx={{
                                background:getBackground(index),
                                color: getColor(index),
                                width:1,
                                height:32,
                                flexShrink:0,
                                borderRadius:"8px",
                                padding:0,
                                minWidth:0,
                                paddingX:1.5,
                                display: "flex",
                                alignItems:"center",
                                justifyContent:"flex-start",
                                textTransform:"none",
                                minWidth:150,
                                boxShadow: "none",
                                opacity:1,
                                "&:hover" : {
                                    transition:"250ms",
                                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                                    boxShadow:"none"
                                },
                            }}
                        >
                            {typeof option === "object" ? option[props.displayName] : props.useTranslation ? t(option) : option}

                        </Button>
                    ))}
                    {filteredOptions?.length === 0 &&
                        <Box
                            sx={{
                                maxWidth:300,
                                display:"flex",
                                justifyContent:"center",
                                flexDirection:"column",
                                alignItems:"center",
                                mb:3,
                            }}
                        >
                            <TableEmpty/>
                            <Box
                                sx={{
                                    mt:3,
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    textAlign:"center",
                                }}
                            >
                                <Subheading align="center" bold>{t('search_no_result_title')}</Subheading>
                                <Text align="center" light>{t('search_no_result_description')}</Text>
                            </Box>
                        </Box>
                    }
                </Box>

                
            </Box>

        </PopoverModal>
    );
}

export default ShDropdown;