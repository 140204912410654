import { Box, List, useTheme } from "@mui/material"
import SHModal from "../../theme/Modal/SHModal"
import Shbutton from "../../theme/buttons/Shbutton"
import { tokens } from "../../../theme";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import SelectGroup from "../../theme/Select/SelectGroup";
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../theme/buttons/ButtonGroup";

const MLogout = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);

    const select = (selected) => {
        setSelected(selected);
    }

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const columns = [
        "Enschede euregioweg",
        "Enschede peepoweg",
        "Enschede",
        "Hengelo geerdinksweg 2",
        "Zwolle",
        "Amsterdamn"
    ]

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>Uitloggen</Subheading>
                <Text light>Weet je zeker dat je wilt uitloggen?</Text>
            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>Nee</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"red"} onClick={props.handleLogout} variant="contained">Log uit</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MLogout;