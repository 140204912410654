import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import BottomRight from "../../positionals/BottomRight";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";

const WriteOffsCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        {props.skeleton ? 
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.row.sku}</Text>
                                <Text light>{props.row.description}</Text>
                            </>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel variant="grey" colorflip position="child-tr">
                            {props.skeleton ? null : props.row.quantity}
                        </CardLabel>
                    </TopRight>


                    {/* Holds tht date and batch */}
                    <Box display={"flex"} gap={4} marginTop={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text>Location</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{props.row.location}</Text>
                            }
                        </Box>

                        <Box display={"flex"} flexDirection={"column"}>
                            <Text>Barcode</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text light>{props.row.barcode}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default WriteOffsCard;