import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import cx from "classnames"
import {checkAllPropertiesTruthy } from "../../../utils"
import { useAddNewSupplierMutation } from "../../../newapi/global/supplier/supplierSlice";
import { showToastMessage, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../utils/validation";
import { useCreateWorkspaceMutation } from "../../../newapi/warehouse/workspaceSlice";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import { useGetWarehousesSelectQuery } from "../../../newapi/warehouse/warehouseApiSlice";
import { useFindPrintersSelectQuery } from "../../../newapi/warehouse/printerSlice";



const MAddWorkspace = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addWorkspace,{isLoading}] = useCreateWorkspaceMutation();
    const { data: warehouses } = useGetWarehousesSelectQuery();
    const { data: printers } = useFindPrintersSelectQuery();
    const select = (selected) => {
        setSelected(selected);
    }

    const handleWarehouseChange = (e) => {
        handleChange("warehouseId", warehouses.data[e].id);
    }

    const handlePrinterChange = (key,e) => {
        handleChange(key, printers.data[e].id);
    }

    const [workspaceInfo, setWorkspaceInfo] = useState({
        name: "",
        printer_packinglist: null,
        printer_label: null,
        printer_documents: null,
        autoPrint: false,
        warehouseId: null
    });

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        printer_packinglist: yup.number().nullable().required(t('required')),
        printer_label: yup.number().nullable().required(t('required')),
        printer_documents: yup.number().nullable().required(t('required')),
        warehouseId: yup.number().nullable().required(t('required')),
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveWorkspace = async () => {
        try {
          // Reset field errors
          setFieldErrors({});
      
          await schema.validate(workspaceInfo, { abortEarly: false });
      
          // If validation is successful, proceed with the addSupplier call
          const data = await addWorkspace(workspaceInfo).unwrap();
          showToastMessagePostRequest(t, data);
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
          }
        }
    };




    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const handleChange = (key, value) => {
        setWorkspaceInfo({
          ...workspaceInfo,
          [key]: value
        });
        // Optionally clear the error for that field
        setFieldErrors({
          ...fieldErrors,
          [key]: ''
        });
      };

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_workspace")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("name")}</Text>
                    </Box>
                    <ShValidatedInput
                        name="name"
                        value={workspaceInfo.name}
                        onChange={handleChange}
                        error={fieldErrors.name}
                    />
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("printer_packinglist")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <InputDropdown
                displayName={"description"} 
                onChange={(selected) => handlePrinterChange("printer_packinglist",selected)}
                noSelection={t("choose_printer")} //Custom text when nothing is selected
                options={printers?.data}
                closeOnSelection
            />
                    </Box>
                    </Box>
                    <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("printer_label")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <InputDropdown
                displayName={"description"} 
                onChange={(selected) => handlePrinterChange("printer_label",selected)}
                noSelection={t("choose_printer")} //Custom text when nothing is selected
                options={printers?.data}
                closeOnSelection
            />
                    </Box>
                    </Box>
                    <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("printer_documents")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <InputDropdown
                displayName={"description"} 
                onChange={(selected) => handlePrinterChange("printer_documents",selected)}
                noSelection={t("choose_printer")} //Custom text when nothing is selected
                options={printers?.data}
                closeOnSelection
            />
                    </Box>
                    </Box>
                    <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("autoprint")}</Text>
                    </Box>
                    <ToggleButtonGroup
                            color={workspaceInfo.autoPrint === "true" ? "green" : "red"}
                            value={workspaceInfo.autoPrint === "true" ? "true" : "false"}
                            exclusive
                            onChange={(e) => handleChange('autoPrint', e.target.value)}
                            aria-label="Active"
                            className={cx(`${workspaceInfo.autoPrint}`, {
                                'active': workspaceInfo.autoPrint === "true"
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('no')}
                            </ToggleButton>
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('yes')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            height:32,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text light>{t("warehouse")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <InputDropdown
                displayName={"name"} 
                onChange={(selected) => handleWarehouseChange(selected)}
                noSelection={t("choose_warehouse")} //Custom text when nothing is selected
                options={warehouses?.data}
                
                closeOnSelection
            />
                    </Box>
                </Box>
              </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => SaveWorkspace()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddWorkspace;
