import { Box, Fade, IconButton, Popper, Typography, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import React, { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Details from "./Views/Details";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import { useSyncWebshopProductsMutation } from "../../../../newapi/webshop/webshopSlice";
import { showToastMessageDeleteRequest, showToastMessagePostRequest, showToastMessageUpdateRequest } from "../../../../utils/toasts";
import SyncIcon from '@mui/icons-material/Sync';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';



import { Copy } from "../../../theme/Copy";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spWebshopCredentialsDetailsConfig } from "./spWebshopCredentialsDetailsConfig";

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.syncAction}
                color={"primaryGrey"}
                variant="contained"
                endIcon={<SyncIcon />}
            >
                {t('sync')}
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}


const CopyHeaderElement = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        
        <Copy onClick={() => navigator.clipboard.writeText(props.value)} message={props.value}>
            <Box
                sx={{
                    
                    display:"flex",
                    alignItems:"flex-end",
                }}
            >
                <Box
                    sx={{
                        maxWidth:props.small ? 80 : 282,
                        display:"flex",
                        flexDirection:"column"
                    }}
                >
                    <Text light>{props.title}</Text>
                    <Text bold noBreak>{props.value ? props.value : "N/A"}</Text>
                </Box>

                    <IconButton
                        sx={{ color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary["400"] }}
                    >
                        <ContentCopyOutlinedIcon fontSize="small" />
                    </IconButton>
            </Box>
        </Copy>
    );
}

const SpWebshopCredentialsDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const [SyncWebshopProducts,{isLoading: syncIsLoading}] = useSyncWebshopProductsMutation();
    
    const handleSyncClick = async () => {
        try {
            const response = await SyncWebshopProducts({id: props.data.id}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: theme.palette.mode === "dark" ? "blue" : "primary",
            content: <StorefrontOutlinedIcon/>
        },
        //Heading row for default shit
        // options : [],
        options:[],
       
        actions:<SidePanelActions setIsExtended={props.setIsExtended} syncAction={handleSyncClick} />   
    });

    const panelSettings = { 
        row: {
            paddingTop:4,
            display:"flex",
            gap:"24px 40px",
            flexWrap:"wrap",
            "@media screen and (max-width: 63.9375em)":{
                overflowX:"auto",
                flexWrap:"nowrap",
                gap: "16px",
                paddingTop:3,
                "&.sub-row": {
                    paddingTop:2,
                    // marginRight:3
                }
            }
        },
    };

    useEffect(() => {
        if (typeof data !== "undefined" && typeof data.id !== "undefined") {
        //   let locations = data.stocks.map((stock) => stock.warehouseLocation.location);
        //   let combinedLocations = locations.join(", ");
      
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data.description,
            subTitle: [data.webshop_type],
            icon :{
                theme: theme.palette.mode === "dark" ? "blue" : "primary",
                content: <StorefrontOutlinedIcon/>
            },
            options: [
                {
                    component: <CopyHeaderElement title={t("token")} small value={data.token}/>
                },
                {
                    component: <CopyHeaderElement title={t("order_webhook")} value={data.webhooks.order}/>
                },
                {
                    component: <CopyHeaderElement title={t("product_webhook")} value={data.webhooks.product}/>
                },
            ],
          }));
        }
      }, [data,theme.palette.mode]);
    

    return (
        <Sidepanel2
            tabConfig={spWebshopCredentialsDetailsConfig}
            content={panel} 
            settings={panelSettings}
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spWebshopCredentialsDetailsConfig.details} data={props.data} />
                </>
        </Sidepanel2>
    );
};

export default SpWebshopCredentialsDetails;