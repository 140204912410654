
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import WarehouseIcon from '@mui/icons-material/Warehouse';

export const spOrderDetailsConfig = {
    attachments:  {
        title: "attachments",
        icon: <WidgetsIcon/>,
    },
    jokers: {
        title: "jokers",
        icon: <WidgetsIcon/>,
    },
    logs: {
        title: 'logs',
        icon: <FormatListBulletedIcon/>,
        isSingleDataGrid: true,
    },
    orderLines: {
        title: 'orderlines',
        icon: <CropFreeIcon/>,
    },
    // parcelInvestigation : {
    //     //to be added
    // },
    pickingLines: {
        title: 'pickinglines',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    replenish: {
        title: 'replenish',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    shipOrder: {
        title: 'ship_order',
        icon: <CropFreeIcon/>,
        options : [],
        status:{
            value: "Shipped",
            variant:"green",
            palette:"normal"
        },
    },
    splitOrder: {
        title: "split_order",
        icon: <WidgetsIcon/>,
    },
    writeOffs: {
        title: 'write_offs',
        icon: <CropFreeIcon/>,
    },
    status: {
        title: 'status',
        icon: <WarehouseIcon/>,
    }
};
