import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Text from "../../../../../components/theme/text/Text";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme";
import Subheading from "../../../../../components/theme/text/Subheading";
import Heading from "../../../../../components/theme/text/Heading";
import Inventory2Icon from '@mui/icons-material/Inventory2';


// import ContactBody from "../../../../components/global/Sidepanels/SpStockDetails/Views/Warehouse";
import ContactBody from "../../../../../components/global/Sidepanels/SpStockDetails/Views/Warehouse";
import Echeck  from "../../../../warehouse/dashboard/views/Echeck";
// import Echeck from "./views/Echeck";
import { useGridApiRef } from "@mui/x-data-grid";
import Standard from "./views/Standard";
import TabView from "../../../../../components/theme/TabViews/TabView";

const OutboundStock = forwardRef((props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const contactApiRef = useGridApiRef();

    const panel = {
        // title: "Jollein - Silliconen Billendoekjes Box - Caramel",
        // subTitle: "711-001-00092",
        icon :{
            theme: "green",
            content: <Inventory2Icon/>
        },
        //Heading row for default shit
        options : [
            {
                heading: 'Location',
                content:'711-001-00092'
            },
            {
                heading: 'Best before',
                content:'8 may 2023'
            },
            {
                heading: 'Batch',
                content:'121232'
            },
            {
                heading: 'Picked last 10 days',
                content:'9'
            }
        ],
        //Tabs
        views : [
            // {...Standard()},
            // {...Echeck()}
        ]    
    }

    useImperativeHandle(ref, () => ({
    }));
    return (
        <Box 
            sx={{
                background:"", 
                width:1,
                marginTop:9,
                height: "calc(100% - 72px)",
                position:"relative",
                paddingX:4,
                paddingTop:4,
                overflow:"hidden",
                display:"flex",
                flexDirection:"column",
                "&:before" : {
                    content: "''",
                    display:"block",
                    width:"32px",
                    height:"32px",
                    position:"absolute",
                    left:0,
                    top:0,
                    zIndex:1,
                    backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    height:"48px",
                    width:"24px",
                    borderTopLeftRadius:"24px",
                    boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                    // transition:"250ms"
                },

                "&:after" : {
                    content: "''",
                    display:"block",
                    width:"32px",
                    height:"32px",
                    position:"absolute",
                    top:0,
                    zIndex:1,
                    backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    right:0,
                    height:"48px",
                    width:"24px",
                    borderTopLeftRadius:"24px",
                    boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                    transform:"scaleX(-1)",
                    // transition:"250ms"
                },

                "@media screen and (max-width: 47.9375em)" : {
                    paddingX:0,
                    paddingTop:3,
                    background:colors.bg["tertiary"],
                    "&:before,&:after":{
                        display:"none"
                    }
                }
            }}
        >

            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    width:1,
                    marginBottom:4,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingX:3,
                        marginBottom:6.5,
                    }
                }}
            >
                <Box
                
                    sx={{
                        display: "flex",
                        alignItems:"flex-start",
                        flexDirection:"column",
                        gap:0.5,
                    }}
                >
                    <Heading>Outbound stock settings</Heading>
                    <Text light>All settings in this category pertain to outbound stock</Text>
                </Box>

                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        gap:2,
                        height:40,
                        background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                        borderRadius:5,
                        paddingX:2,
                        color:"#fff",
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    <Box>
                        <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
                    </Box>
                    <KeyboardArrowDownIcon color="white"/>
                </Box>
            </Box>

                    
            <TabView
                viewType="bodytabs"
                sx={{
                    position:"relative",
                    zIndex:2,
                    display: "flex",
                    flex:1,
                    overflow:"auto",
                    flexDirection:"column",
                    "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            // transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            // transition:"250ms"
                        },
                    }
                  
                }}
                title={panel.title}
                views={panel.views}
                tabClasses={"btn-body-tab"}
            >
                <>
                    <Standard/>
                </>
            </TabView>


        </Box>
    )
});

export default OutboundStock;