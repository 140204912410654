import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useGetDashboardOrderLinesQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { useTranslation } from "react-i18next";
import DonutChart from "../../../theme/charts/DonutChart";

export const OrderlinesChart = (props) => {
    
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, isLoading, isFetching, isError,refetch} = useGetDashboardOrderLinesQuery();
    
    // Function to handle retry
    const handleRetry = () => {
        refetch();
    };
    return (
        <Box
            sx={{
                "@media screen and (max-width: 1024px)" : {
                    gridArea: "1 / 1 / 2 / 3",
                }
            }}
        >
            <DonutChart
                hideFilter
                hideEffect
                title={t("order_lines")}
                subtitle={t("today")}
                type="donut"
                colors={['purple','primary']}
                isError={isError}
                isLoading={isLoading || isFetching}
                categories={data?.categories}
                values={data?.values}
                color={'default'}
                onRetry={handleRetry}
            ></DonutChart>
        </Box>


    );
}