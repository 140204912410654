import SelectGroup from "../../../../Select/SelectGroup";
import { useEffect, useState } from "react";
import { conditionsFromType, getConditions } from "../../Settings";
import { useFilterbuilder } from "../../../../../../contexts/FbState";
import { useDispatch, useSelector } from "react-redux";
import { updateType } from "../../../../../../newapi/global/filter/builder/gridFilterBuilderRuleSlice";
import { setFilterBuilderFinishedBuilding } from "../../../../../../newapi/global/filter/builder/gridFilterBuilderSettingsSlice";

const SelectFilterConditionViewBody = (props) => {
    const [selected, setSelected] = useState(-1);
    const [options, setOptions] = useState([]);
    const filterBuilder = useFilterbuilder();
    const dispatch = useDispatch();
    const builderRule = useSelector(state => state.gridFilterBuilderRule);

    useEffect(() => {
        if(typeof props.apiRef !== "undefined" && Object.keys(props.apiRef).length > 0 && props.apiRef.current !== null) {
            let currColumns = props.apiRef.current.getAllColumns();
            if(typeof builderRule.column !== "undefined" && typeof currColumns[builderRule.column].shFilter !== "undefined") {
                setOptions(conditionsFromType(currColumns[builderRule.column].shFilter.type));
            } else {
                setOptions(conditionsFromType(currColumns[builderRule.column].type));
            }
        }
    },[props.apiRef, builderRule]);

    const select = (selected) => {
        setSelected(selected);

        dispatch(updateType({value: selected}));
        // filterBuilder.updateType({
        //     name : filterBuilder.type.name,
        //     value: selected
        // });
        
        if(builderRule.type.name !== "boolean"){
            if(typeof props.builderRef !== "undefined") {
                props.builderRef.current.nextView();
            }
        } else {
            dispatch(setFilterBuilderFinishedBuilding(true));
            // dispatch(setfilderupda())
            // filterBuilder.updateFinishedBuilding(true);
        }
    }
    
    return (
        <SelectGroup selected={selected} setSelected={select} options={options} />
    )
}

const SelectFilterConditionView = (props, builderRef) => {
    return {
        header : {
			title: "Pick a condition",
			// preview: <FiltergroupPreview/>
        },
        body : {
            component: <SelectFilterConditionViewBody {...props} builderRef={builderRef} />
        }
    }
}

export default SelectFilterConditionView;