import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Heading from "./theme/text/Heading";
import Text from "./theme/text/Text";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Heading>
        {title}
      </Heading>
      <Text light>{subtitle}</Text>
      {/* <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.grey[400]}>
        {subtitle}
      </Typography> */}
    </Box>
  );
};

export default Header;