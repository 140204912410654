import { Box, InputBase } from '@mui/material';
import { useTheme } from '@mui/system';
import { tokens } from '../../../theme';
import cx from 'classnames'
const TextInput = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        width:props.width ?? null,
        "@media screen and (max-width: 47.9375em)": {
          flex: 1,
        },
      }}
    >
      <Box
        className={cx(props.className, {
          'error': typeof props.error !== "undefined" && props.error,
        })}
        display="flex"
        borderRadius="20px"
        sx={{
          background: typeof props.background === 'undefined' ?
            theme.palette.mode === "dark"
              ? colors.grey[300]
              : colors.grey[100] : props.background,
          "@media screen and (max-width: 47.9375em)": {
            background: typeof props.background === 'undefined' ?
              theme.palette.mode === "dark"
                ? colors.grey[300]
                : colors.grey[100] : props.background,
          },    
          "&.error" : {
              background: colors.red[100],
              border: `1px solid ${colors.red[400]}`
          }
        }}
      >
        <InputBase
          className='input'
          type={props.type ?? "text"}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder ?? null}
          sx={{
            color: colors.txt["primary"],
            px: 2,
            flex: 1,
            height: 32,
            boxSizing: "border-box",
            "@media screen and (max-width: 47.9375em)": {
              flex: 1,
              width: "unset",
            },
            ...(props.type === 'number' && {
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
                '&::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
              },
            }),
          }}
        />
      </Box>
    </Box>
  );
};

export default TextInput;