import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import DetailsPanel from "./Views/Details";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spReachTypeDetailsConfig } from "./spReachTypeDetailsConfig";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpReachTypeDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: theme.palette.mode === "dark" ? "blue" : "primary",
            content: <WarehouseIcon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && typeof data.id !== "undefined") {
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data.description,
            subTitle: [""],
            options: [
            ],
          }));
        }
      }, [data]);

    return (
        <Sidepanel2
            tabConfig={spReachTypeDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <DetailsPanel config={spReachTypeDetailsConfig.details} data={props.data}/>
                </>
        </Sidepanel2>
    );
};

export default SpReachTypeDetails;