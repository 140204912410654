import { Box, Modal, Slide, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import CloseOverlayBtn from "../buttons/CloseOverlayBtn";


// const contentTheme = document.querySelector('meta[name="theme-color"]').getAttribute("content");

const SHModal = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [contentTheme, setContentTheme] = useState(document.querySelector('meta[name="theme-color"]').getAttribute("content"));
    const showClose = props.showClose ?? true;
    // const co

    useEffect(() => {

      setContentTheme(document.querySelector('meta[name="theme-color"]').getAttribute("content"));
      return () => {
        // alert("hi");
        // console.log("called");
      }
    },[])
    //Executed when value of one of the array vars change
    useEffect(() => {
      // console.log(contentTheme);
          // alert("yooyoo");
      if(props.open) {
        // alert("hi");
        document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#101013" : "#606D85"}`);
        document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#101013" : "#606D85"}`);
        // alert("yooyoo");
      } else {
      //   // alert(contentTheme);
        if (contentTheme === "#181820" || contentTheme === "#ecf0f5") {
          document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
          document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
        } else {
          document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#0F0F12": "#606D85"}`);
          document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#0F0F12": "#606D85"}`);
        }
      }
      // if(subpanelActive && window.innerWidth <=1366){
      //     document.querySelector('meta[name="theme-color"]').setAttribute("content", `${colors.bg["tertiary"]}`);
      //     document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${colors.bg["tertiary"]}`);
      // } else {
      //     if(props.isExtended){
      //         document.querySelector("body").style.overflow = "hidden";
      //         document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#0F0F12": "#606D85"}`);
      //         document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#0F0F12": "#606D85"}`);
      //     } else {
      //         document.querySelector("body").style.overflow = "auto";
      //         document.querySelector('meta[name="theme-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
      //         document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", `${theme.palette.mode === "dark" ? "#181820": "#ecf0f5"}`);
      //     }
      // }

      return () => {
        // alert("hi");
        // console.log("called");
      }
  },[theme.palette.mode,props.open])

    return (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={props.onClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 300,
            },
          }}
          sx={{
            transition:"300ms",
            "@media screen and (min-width: 47.9375em)" :{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            },
            "& .MuiBackdrop-root" : {
                backdropFilter:"blur(4px)",
                background:`${theme.palette.mode === "dark" ? "rgba(14,14,17,.85)" : "rgba(46,65,95,.75)"} !important`,
            }
          }}
        >
           <Slide direction="up" in={props.open}>

               <Box sx={{
                  position:"relative",
                  outlineColor: "transparent !important",
                  // outlineColoor:"transparent !important",
                  background:`${colors.bg["tertiary"]}`,
                  borderRadius:6,
                  minWidth:500,
                  // overflow:"auto",
                  // overflowY:"hidden",
                  "@media screen and (max-width: 47.9375em)" : {
                     position: 'absolute',
                     bottom: '0%',
                     left: '0',
                     minWidth:"unset",
                   //   transform: 'translateX(-50%)',
                     width:1,
                     borderTopLeftRadius:24,
                     borderTopRightRadius:24,
                  }
                   //   width: 400,
               }}>

                {props.open && showClose ?
                  <Box onClick={props.onClose}
                    sx={{
                      "@media screen and (max-width: 47.9375em)" : {
                        background:"blue"
                      }

                    }}
                  > 
                    <CloseOverlayBtn theme="close-overlay"></CloseOverlayBtn> 
                  </Box>: 
                null}
                                
                {props.children}
               </Box>
           </Slide>
       </Modal>
    )
}

export default SHModal;