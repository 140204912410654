import React, { useState, useContext } from "react";
import { Box, Typegraphy, Typography, useTheme } from '@mui/material';
import { chartTokens, tokens } from "../../../theme";
import cx from 'classnames';

import 'react-pro-sidebar/dist/css/styles.css';


const ChartText = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const getColor = () => {
        if(props.mode === "text" && props.color !== "default") return chartTokens.text.primary;
        if(props.color === "default") return colors.txt.primary;
        return chartTokens.text.primary;
    }

    return (
        <Typography
            className={cx(`${props.size}`, {
                'light': props.light,
                'bold' : props.bold,
                'semibold' : props.semibold,
                'italic' : props.italic,
                'no-break': props.noBreak,
                'clickable' : props.clickable,
            })} 
            variant="span" 
            fontWeight={props.fontWeight} 
            fontFamily="Roboto, sans-serif"
            sx={ props.sx ?? {
                color:getColor(),
                fontSize:14,
                display:"inline-block",
                transition:"250ms",
                "&.light" : {
                    color:`${chartTokens.text["secondary"]}`,
                },
                "&.fs13" : {
                    fontSize:13
                },
                "&.semibold" : {
                    fontWeight:500
                },
                "&.bold" : {
                    fontWeight:"700"
                },
                "&.italic" : {
                    fontStyle:"italic"
                },
                "&.no-break" : {
                    whiteSpace:"nowrap",
                    wordBreak:"keep-all",
                    textOverflow:"ellipsis"
                },
                "&.clickable" : {
                    cursor: "pointer"
                },
        }}>
            {props.children}
        </Typography>
    );
};

export default ChartText;