import { Box } from "@mui/material";
import SHModal from "../Modal/SHModal"
import { useDispatch } from "react-redux";
import { setResetTab } from "../../../newapi/global/progressionTabSlice";
import { useEffect } from "react";


const ProgressionModal = (props) => {
    //console.log(props);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(!props.open) {
            setTimeout(() => {
                dispatch(setResetTab());
            },250);
        }
    }, [props.open]);
     
    return (
        <SHModal
            open={props.open}
            onClose={props.onClose}
        >
            {/* <Box
                sx={{
                    background:"#ecf0ff5"
                }}
            > */}
                {props.children}
            {/* </Box> */}
        </SHModal>
    )
}

export default ProgressionModal;