import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import cx from 'classnames';

const ContextIcon = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            className={cx({
                'active': props.active
            })}
            sx={{
                width:24,
                marginRight:1.5,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                color: colors.txt["secondary"],
                "& path" : {
                    fill: colors.txt["secondary"],
                },
                "&.active path" : {
                    fill:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]
                }
            }}
        >
            {props.children}
        </Box>
    );

}
export default ContextIcon;