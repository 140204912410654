import { useTranslation } from "react-i18next"

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal"
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper"
import { useDispatch } from "react-redux"
import ChooseImportType from "./views/ChooseImportType"
import { useEffect } from "react"
import MatchHeaderToColumn from "./views/MatchHeaderToColumn"

const MpMigrationImport = (props) => {
    const dispatch = useDispatch();

    // Call the setResetTab action to reset the state
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }


    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} isModal title={props.source + " import"}>
                <ChooseImportType source={props.source} combined={props.combined}/>
                <MatchHeaderToColumn/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpMigrationImport;