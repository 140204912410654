import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import Text from '../../theme/text/Text';
import { useTranslation } from "react-i18next";
import { useUpdateSettingMutation } from '../../../newapi/global/generalApiSlice';
import { tokens } from '../../../theme';
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";

// variants
//    1: default has both a title and subtitle
//    2: has no subtitle ans text is light
const DropdownSetting = (props) => {
  const { title = true, subtitle = true, variant = 1} = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [updateSetting] = useUpdateSettingMutation();

  const [setting, setSetting] = useState();

  const handleChange = async (value) => {
    await updateSetting({
      key: setting.key,
      value: value.id,
      warehouseId: setting.warehouseId,
    });
  };

  useEffect(() => {
    if(props.skey && typeof props.settings !== "undefined"){
        let xsetting = props.settings.find((setting) => setting.key === props.skey);
        if (!xsetting) {
            xsetting = { key: props.skey, value: 0, warehouseId: -1 };
        }
        setSetting(xsetting);
    }
  }, [props.settings, props.skey]);

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
        height:40,
      }}
    >
      <Box
        sx={{
          display:"flex",
          flexDirection:"column",
        }}
      >
        {title && <Text size={variant === 2 ?? "fs13"} light={variant===2} semibold={variant === 1}>{t(`${setting?.key}_title`)}</Text>}
        {subtitle && variant !== 2 && <Text light>{t(`${setting?.key}_subtitle`)}</Text>}
      </Box>

      <Box
        sx={{
          flex: 1,
          maxWidth: 150,
          marginLeft: "auto",
          backgroundColor: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
          borderRadius: 4,
          display: "flex",
          height: 32,
        }}
      >
        <InputDropdown
                variant={props.variant ?? "darker"}
                displayName={"name"} 
                onChange={(selected) => handleChange(props.objects[selected])}
                noSelection={t(props.noselection)}
                options={props.objects ?? null}
                selected={props.objects?.findIndex(object => setting?.value === object.id) ?? 0}
                closeOnSelection
            />
      </Box>
    </Box>
  );
};

export default DropdownSetting;