import { createSlice } from '@reduxjs/toolkit';
// import { generalApiSlice } from './generalApiSlice';
import { PURGE, REHYDRATE } from 'redux-persist';
import { apiSlice } from '../createApiSlice';
import { generalApiSlice } from './generalApiSlice';
// generalApiSlice
// apiSlice
// generalA
const initialState = { 
  enums: {}
};
const generalSlice = createSlice({
  name: 'general',
  initialState: initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload.token;
      state.expiresAt = action.payload.expires_at;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      // ... your other matchers
      .addCase(REHYDRATE, (state, action) => {
        // Or if you want to update the state with the rehydrated state:
        if (action.payload) {
          return { ...state, ...action.payload.general };
        }
        return state;
      })
      .addMatcher(generalApiSlice.endpoints.getEnums.matchFulfilled, (state, action) => {
        state.enums = action.payload;
      })
  },
});



export default generalSlice;
