import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useLayoutEffect, useState } from "react";
import Text from "../text/Text";
import ChartText from "../text/ChartText";
// import Dropdown from "../Dropdown";
import { DropdownBase } from "./Dropdown";

export const StyledDropdownContent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const variant = props.variant ?? "default";


    const getTextForMultiple = () => {
        if(props.selected.length === 0) return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        if(props.selected.length === 1) return props.options[props.selected][props.displayName];
        
        return props.selected.length
    }


    const getSingleValue = () => {
        if(props.selected === -1 || !props.options) {
            return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        }
        if(typeof props.options[props.selected] === "object") {
            return props.options[props.selected][props.displayName]
        }
        return props.options[props.selected]
    }


    return (
        <ButtonBase
            aria-describedby={props.describedBy} 
            onClick={props.onClick}

            TouchRippleProps={typeof props.sx.touchProps !== "undefined" ? props.sx.touchProps : null} 

            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                height:40,
                paddingLeft: 2,
                borderRadius:5,
                width:1,

                ...props.sx.base,
                color: typeof props.sx.base.color !== "undefined" ? props.sx.base.color : null,
                
                // paddingRight: getPaddingRight(),
                // background: getBackground(),
            }}
        >
            {props.multiple ?
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1
                    }}
                >

                    <Typography
                        sx={{
                            fontSize:14,
                            fontWeight:500,

                            ...props.sx.text,
                            color: typeof props.sx.text.color !== "undefined" ? props.sx.text.color : null,
                            
                        }}
                    >
                        {getTextForMultiple()}
                    </Typography>
                    {/* <Text bold></Text> */}
                    {/* <ChartText mode={mode} color={color} bold>{getTextForMultiple()}</ChartText> */}
                    {props.selected.length > 1 ?
                        <Typography
                            sx={{
                                fontSize:13,
                                fontWeight:400,

                                ...props.sx.textSecondary,
                                color: typeof props.sx.textSecondary.color !== "undefined" ? props.sx.textSecondary.color : null,
                            }}
                        >
                            geselecteerd
                        </Typography>
                        // <Text light>geselecteerd</Text>
                    :null}
                    {/* <ChartText mode={mode} color={color} bold>{selected !== -1 ? props.options[selected][props.displayName] : "Alle"}</ChartText> */}
                </Box> 
            :
                <Typography
                    sx={{
                        fontSize:14,
                        fontWeight:500,

                        ...props.sx.text,
                        color: typeof props.sx.text.color !== "undefined" ? props.sx.text.color : null,
                    }}
                >

                    {getSingleValue()}
                </Typography>
                // <Text bold>
                //     {props.selected !== -1 ? props.options[props.selected][props.displayName] : typeof props.noSelection !== "undefined" ? props.noSelection : "Kies"}
                // </Text>
            }
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    transform:"rotate(90deg)",
                    width: 32,
                    height:32,
                    flexShrink:0,
                    borderRadius:4,
                    
                    ...props.sx.button,
                }}
            >
                <ChevronRightIcon/>
            </Box>
        </ButtonBase>
    )
}

export const StyledDropdown = (props) => {
    return (
        <DropdownBase {...props}>
            <StyledDropdownContent sx={props.sx} multiple={false} variant={props.variant}/>
        </DropdownBase>
    )
}