import { Box, ButtonBase, useTheme } from "@mui/material"

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import ComputerIcon from '@mui/icons-material/Computer';
import cx from 'classnames';
import { tokens } from "../../theme";
import { viewPorts } from "./ViewPort";
// import { ViewPorts } from "./ViewPort";

const icons = {
    sx: <PhoneIphoneIcon fontSize="small" />,
    sm: <TabletMacIcon fontSize="small" />,
    lg: <ComputerIcon fontSize="small" />
};

const ViewPortController = (props) => {
    if(!props.activeViewPort) throw Error("Viewport not set on the viewportcontroller");

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const changeViewPort = (viewPort) => {

        if(props.setViewPort) {
            props.setViewPort(viewPort);
        }
    }
    
    return (
        
        <Box
            
            sx={{
                zIndex:8,
                height:40,
                p:0.5,
                position:"fixed",
                left:"51%",
                top:192,
                transform:"translateX(-50%)",
                background:"white",
                // width:"fit-content",
                borderRadius:5,
                display:"flex",
                border:"1px solid #ced7e1",
                boxShadow: "0 1px 2px #31415f"

            }}
        >
             {Object.entries(viewPorts).map(([key, value]) => (
                <ButtonBase
                    key={key}
                    className={cx('', {
                        'active': props.activeViewPort === key
                    })}
                    onClick={() => changeViewPort(key)}
                    sx={{
                        width: 32,
                        height: 32,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: 'center',
                        borderRadius: 4,
                        color: theme.palette.mode === "dark" ? colors.txt['secondaryDark'] : colors.txt["secondary"],
                        "&.active": {
                            background: "#ced7e1",
                            color: theme.palette.mode === "dark" ? colors.txt['primaryDark'] : colors.txt["primary"]
                        }
                    }}
                >
                    {icons[key]}
                </ButtonBase>
            ))}

        </Box>
    )
}

export default ViewPortController;