import { Box, InputBase, useTheme } from "@mui/material"
import { tokens } from "../../../../../../theme";
import { useFilterbuilder } from "../../../../../../contexts/FbState";
import SelectGroup from "../../../../Select/SelectGroup";
import { useEffect, useState } from "react";
import ShDateRangePicker from "../../../../ShDateRangePicker";
import { conditionsFromType } from "../../Settings";
import NumberInput from "../../../../inputs/NumberInput";
import { useDispatch, useSelector } from "react-redux";
import { updateValue } from "../../../../../../newapi/global/filter/builder/gridFilterBuilderRuleSlice";

const TextBody = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const builderRule = useSelector(state => state.gridFilterBuilderRule);
    
    const changeValue = (value) => {
        dispatch(updateValue(value));
        // props.filterBuilder.updateValue(value);
    }

    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box
                sx={{
                    height:"40px",
                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                    display:"flex",
                    alignItems:"center",
                    padding:2,
                    borderRadius:5,
                    flex:1
                }}
            >
                <InputBase
                    type={builderRule.type.name}
                    value={builderRule.value}
                    onChange={(event) => changeValue(event.target.value)}
                    sx={{
                        height:"40px",
                        width:1,
                        color:colors.txt["primary"],
                        
                    }}
                />
            </Box>
        </Box>
    )
}


const NumberBody = (props) => {
    const dispatch = useDispatch();
    const builderRule = useSelector(state => state.gridFilterBuilderRule);

    // 'Equal to',
    // 'Bigger than',
    // 'Smaller than',
    // 'Between'
    useEffect(() => {
        if(builderRule.type.value === 3){
            dispatch(updateValue([-1,-1]));
            // props.filterBuilder.updateValue([-1,-1]);
        } else {
            dispatch(updateValue(-1));
            // props.filterBuilder.updateValue(-1);
        }
    },[]);

    const changeFirst = (num) => {
        if(builderRule.type.value === 3) {
            let values = builderRule.value;
            values[0] = num;
            dispatch(updateValue(values));
        } else {
            dispatch(updateValue(num));
        }
    }

    const changeSecond = (num) => {
        let values = builderRule.value;
        values[1] = num;
        dispatch(updateValue(values));
    }

    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box>
                <NumberInput change={changeFirst}></NumberInput>
            </Box>
            {builderRule.type.value === 3 ?
                <Box>
                    <NumberInput change={changeSecond}></NumberInput>
                </Box>
            : null}
        </Box>
    )
}

const DateBody = (props) => {
    const dispatch = useDispatch();
    const builderRule = useSelector(state => state.gridFilterBuilderRule);
    
    const select = (dates) => {
        dispatch(updateValue(dates));
        // props.filterBuilder.updateValue(dates);
    }
    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <ShDateRangePicker singleSelect={builderRule.type.value < 2} select={select}></ShDateRangePicker>
        </Box>
    )
}

const OptionsBody = (props) => {
    const dispatch = useDispatch();
    const builderRule = useSelector(state => state.gridFilterBuilderRule);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if(typeof props.apiRef !== "undefined" && Object.keys(props.apiRef).length > 0 && props.apiRef.current !== null) {
            let currColumn = props.apiRef.current.getAllColumns()[builderRule.column];
            if (typeof currColumn.shFilter === "undefined") {
                alert("shFilter cannot be empty on an options body. Please check the body of " + builderRule.column);
                return;
            }
            setOptions(currColumn.shFilter.options);
        }
    },[props.apiRef, props.filter]);

    const select = (index) => {
        dispatch(updateValue(index));
        // props.filterBuilder.updateValue(index);
    }


    return (
        <SelectGroup setSelected={select} options={options} colored/>
    )
}

const SelectFilterValueViewBody = (props) => {
    const [conditons, setConditons] = useState([]);
    const [selectedConditions, setSelectedCondition] = useState(-1); //Need this later for after/before/between
    const filterBuilder = useFilterbuilder();
    const builderRule = useSelector(state => state.gridFilterBuilderRule);

    useEffect(() => {
        if(builderRule.type.value !== -1) {
            setConditons(conditionsFromType(builderRule.type.name));
            setSelectedCondition(builderRule.type.value);
        }
    },[filterBuilder]);

    const componentToRender = () => {
        if(builderRule.type.name === "text" || builderRule.type.name === "string") return <TextBody {...props} conditons={conditons} selectedConditions={selectedConditions}/>
        if(builderRule.type.name === "number") return <NumberBody {...props} conditons={conditons} selectedConditions={selectedConditions}/>
        if(builderRule.type.name === "date") return <DateBody {...props} conditons={conditons} selectedConditions={selectedConditions}/>
        if(builderRule.type.name === "options") return <OptionsBody {...props} conditons={conditons} selectedConditions={selectedConditions}/>

        if(builderRule.type.name !== "boolean"){
            throw new Error("Not implemented for " + builderRule.type.name);
        }

        return <Box></Box>
    }

    return (
        componentToRender()
    )
}

const SelectFilterValueView = (props, builderRef, skipStep) => {
    
    const filter = useFilterbuilder();
    return {
        skipStep: skipStep,
        header : {
			title: "Pick a condition value",
			// preview: <FiltergroupPreview/>
        },
        body : {
            component: <SelectFilterValueViewBody {...props} builderRef={builderRef} />
        }
    }
}

export default SelectFilterValueView;