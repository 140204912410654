import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import { t } from "i18next";

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>Save</Shbutton>
        </Box>
    )
}

const Efullfillment = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions></GridActions>
        });
        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3} overflow={"auto"}>

            <Dropdown isLast title={t('efullfillment')} alwaysOpen>
                <Box
                    sx={{
                        display:"flex",
                        flexWrap:"wrap",
                        gap:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column",
                            gap:0
                        }
                    }}
                >
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('food')}
                        </Text>
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('batch_required')}
                        </Text>
                        
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('dangerous_product')}
                        </Text>
                        
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>
                    

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                "&:first-child" : {
                                    color:"green"
                                }
                            }
                        }}
                        >
                        <Text light>
                            {t('let_shipper_know')}
                        </Text>
                        
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                "&:first-child" : {
                                    color:"green"
                                }
                            }
                        }}
                        >
                        <Text light>
                            {t('high_care')}
                        </Text>
                        
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>
                    
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                "&:first-child" : {
                                    color:"green"
                                }
                            }
                        }}
                        >
                        <Text light>
                            {t('quarantine_at_return')}
                        </Text>
                        
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                "&:first-child" : {
                                    color:"green"
                                }
                            }
                        }}
                        >
                        <Text light>
                            {t('re_enter_size_at_inbound')}
                        </Text>
                        
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                                "&:first-child" : {
                                    color:"green"
                                }
                            }
                        }}
                        >
                        <Text light>
                            {t('size_and_weight_locked_by_warehouse')}
                        </Text>
                        
                        <ShSwitch
                            color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                        ></ShSwitch>
                    </Box>

                </Box>

            </Dropdown>

        </Box>
    );
}

export default Efullfillment;