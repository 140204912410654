import { Box, useTheme,List, ListItem, ListItemButton, ListItemText, } from "@mui/material"
import { tokens } from "../../../../../theme";
import Shbutton from "../../../buttons/Shbutton";
import Subheading from "../../../text/Subheading";

import SortIcon from '@mui/icons-material/Sort';
import CheckIcon from '@mui/icons-material/Check';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useEffect, useState } from "react";

const SortView = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [view, setView] = useState({
        title: "Sorteren",
        icon: <SettingsOutlinedIcon/>,
    })
    

    useEffect(() => {
        props.addView({
            ...view,
            apiRef: props.apiRef
        },false);

        return () => {
            console.log("unmounting warehouse");
        }
    },[]);
    

    return (
        props.isActive ?
        <Box
            sx={{
                padding:1.5,
                width: "520px",
                maxHeight:"400px",
                // maxHeight:"350px",
                display:"flex",
                flexDirection:"column",
                overflow:"auto",
                "@media screen and (max-width: 47.9375em)" : {
                    width:1,
                }
            }}
        >
            <Box 
            sx={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
                position:"relative",
                textAlign:"center",
                paddingTop:1.5,
                paddingX:1.5,
            }}>

                <Subheading>Sorteren</Subheading>

                <Box
                    sx={{
                        // position:"absolute",
                        top:16,
                        left:24,
                        display:"flex",
                        gap:1,
                        alignItems:"center"
                    }}
                >
                    <Shbutton onClick={() => props.changeSortType([{type: "asc", sort: true}, {type:"desc", sort:false}])} size="32" icon color={props.sortTypes[0].sort ? "primary" : "primaryGrey"} variant="contained"><SortIcon fontSize="small" /></Shbutton>
                    <Shbutton onClick={() => props.changeSortType([{type: "asc", sort: false}, {type:"desc", sort:true}])} size="32" icon color={props.sortTypes[1].sort ? "primary" : "primaryGrey"} flipped variant="contained"><SortIcon fontSize="small" /></Shbutton>

                </Box>
                    
            
            </Box>
            <Box
                >
                    <List
                        sx={{
                            padding:0,
                        }}
                    >
                    {props.columns.map((column, index) => (

                        <ListItem key={column.field + "index"} disablePadding onClick={() => props.sort(column)}>
                            <ListItemButton 
                                sx={{
                                    color:`${props.sortedBy !== null && props.sortedBy.field === column.field ? theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] : colors.txt["secondary"]}`,
                                    fontWeight:`${props.sortedBy !== null && props.sortedBy.field === column.field ? 500 : 400}`,
                                    display:"flex",
                                    justifyContent:"space-between",
                                    fontSize:"14px",
                                    paddingX:1.5,
                                    height:40,
                                    borderRadius:5,
                                }}
                            >
                                {column.headerName}

                                {props.sortedBy !== null && props.sortedBy.field === column.field 
                                    ?
                                    <CheckIcon fontSize="small"/>
                                    :
                                    null
                                }
                            </ListItemButton>
                        </ListItem>
                        // <Box>
                        //     {column.headerName}
                        // </Box>
                    ))}
                    </List>
                </Box>
                
        </Box>
        : null
    )
}
export default SortView;