import { authApiSlice } from './authApiSlice';
import { updateToken } from './authSlice';

// const MAX_REFRESH_ATTEMPTS = 3;

// const authRefreshMiddleware = (storeAPI) => (next) => {
//   let retryCounts = new Map();

//   return async (action) => {
//     console.log("kwak",action);
//     if ((action.type && action.type.endsWith('/rejected') && action.payload.status === 401)) {
//       const state = storeAPI.getState();
//       const refreshToken = state.auth.refreshToken;
//       console.log("wejufew",state);
//       console.log("refreshToken",refreshToken);

//       // Generating a unique key for retry count tracking.
//       // You may adjust this based on the uniqueness of your actions.
//       const actionKey = JSON.stringify(action);
//       let retryCount = retryCounts.get(actionKey) || 0;

//       if (retryCount >= MAX_REFRESH_ATTEMPTS) {
//         console.log("bloeb");
//         const currentPath = window.location.pathname;
//         if (currentPath !== '/login') {
//           window.location.href = '/login';
//         }
//         retryCounts.delete(actionKey);
//         return next(action);
//       }

//       try {
//         const resultAction = await storeAPI.dispatch(authApiSlice.endpoints.refreshToken.initiate({"refresh_token":refreshToken}));

//         if (authApiSlice.endpoints.refreshToken.matchFulfilled(resultAction)) {
//           storeAPI.dispatch(updateToken(resultAction.payload));

//           // Reset retry count upon a successful retry.
//           retryCounts.delete(actionKey);

//           // Re-dispatch the original action
//           return storeAPI.dispatch(action);
//         }
//       } catch (error) {
//         console.error("Error during token refresh:", error);
//         window.location.href = "/login";
//         // Handle the error accordingly, possibly redirect to login or show a message to the user.
//       }

//       retryCounts.set(actionKey, retryCount + 1);
//     } else {
//       console.log("poopydough",action);
//     }

//     return next(action);
//   };
// };


// export default authRefreshMiddleware;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

const authRefreshMiddleware = (storeAPI) => (next) => async (action) => {
  if (action.type && action.type.endsWith('/rejected') && action.payload && action.payload.status === 401) {
    
    if (!isRefreshing) {
      isRefreshing = true;
      const state = storeAPI.getState();
      const refreshToken = state.auth.refreshToken;

      try {
        const resultAction = await storeAPI.dispatch(authApiSlice.endpoints.refreshToken.initiate({"refresh_token": refreshToken}));
        // const newToken = resultAction.payload;
        if(!resultAction.error) {
          storeAPI.dispatch(updateToken(resultAction.payload));
          isRefreshing = false;
          processQueue(null);
        } else {
          processQueue("Unauthorized");
          window.location.href = "/login";
        }
      } catch (error) {
        processQueue(error);
        window.location.href = "/login";
      }
    }

    // Return a new promise
    return new Promise((resolve, reject) => {
      failedQueue.push({resolve, reject});
    })
    .then(() => {
      // Retry original action
      return storeAPI.dispatch(action);
    })
    .catch(error => {
      // console.error("Request retry failed:", error);
      return next(action);
    });
  }
  
  return next(action);
};
export default authRefreshMiddleware;