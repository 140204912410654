import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import WidgetsIcon from '@mui/icons-material/Widgets';

import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import { useDeleteWarehouseLocationTypeMutation, useUpdateWarehouseLocationTypeMutation } from "../../../../../newapi/warehouse/warehouseLocationTypeSlice";
import { useGetEnumsQuery } from "../../../../../newapi/global/generalApiSlice";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [view, setView] = useState({
        title: 'details',
        icon: <WidgetsIcon/>,
        apiRef: props.apiRef,
        mobileActions: <GridActions />,
    });

    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery();

    const [warehouseLocationTypeInfo, setWarehouseLocationTypeInfo] = useState({
      id: 0,
      description: "",
      type: "",
    });

    useEffect(() => {
        setWarehouseLocationTypeInfo(props.data);
    },[]);

    const handleChange = (key, value) => {
        setWarehouseLocationTypeInfo({
          ...warehouseLocationTypeInfo,
          [key]: value
        });
      };
    
    const [UpdateWarehouseLocationType,{isLoading}] = useUpdateWarehouseLocationTypeMutation();
    const handleSaveClick = async () => {
        try {
            const response = await UpdateWarehouseLocationType({id: props.data.id, data: warehouseLocationTypeInfo}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };
    
    const [DeleteWarehouseLocationType] = useDeleteWarehouseLocationTypeMutation();
    const handleDeleteClick = async () => {
      try {
          const response = await DeleteWarehouseLocationType({id: props.data.id, warehouseId: props.data.warehouseId}).unwrap();
          showToastMessageDeleteRequest(t, response);
          props.setIsExtended(false);
      } catch (err) {
          showToastMessageDeleteRequest(t, {success: false, error: err.error});
      }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions 
                id={props.warehouseLocationTypeInfo?.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.warehouseLocationTypeInfo,handleSaveClick,handleDeleteClick]);

    return (
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                sx={{
                    "@media screen and (min-width: 48.1em)"  :{
                        marginBottom:11
                    }
                }}
            >
            <Dropdown title={t("general")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap: 4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("description")}</Text>
                            </Box>
                            <Box lineHeight={"40px"} width={"104px"}>
                                <Text>{t("type")}</Text>
                            </Box>
                        </Box>
                        <Box flex={1}>
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={warehouseLocationTypeInfo.description} onChange={(e) => handleChange('description', e.target.value)}/>
                            </Box>
                            <Box display="flex"
                                height="40px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputDropdown
                                    variant="darker"
                                    displayName={"name"}
                                    onChange={(selected) => handleChange('type', globalEnums["warehouselocationtype.type"][selected] ?? '')}
                                    noSelection={t("choose_option")} 
                                    options={globalEnums ? globalEnums["warehouselocationtype.type"].map((item, index) => ({ id: index, name: item })) : []}
                                    selected={globalEnums ? globalEnums["warehouselocationtype.type"].findIndex(item => item === warehouseLocationTypeInfo.type) ?? -1 : -1}
                                    closeOnSelection
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dropdown>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            <GridActions
                id={props.warehouseLocationTypeInfo?.id}
                action={handleSaveClick}
                deleteAction={handleDeleteClick}
            />
                </Box>
            </Box>
    );
}

export default DetailsPanel;