// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { createApi,fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import { config } from '../api/Variables'
import { JWT_TOKEN } from '../storagekeys'
import { cacheKeys } from './cacheKeys';
import { ENV_TYPES } from '../components/global/Sidebar';
import { config } from './apiUrlConfig';

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: config.url.API_BACKEND_URL,
        prepareHeaders: (headers, { getState, endpoint }) => {
            const token = getState().auth.token
            const warehouseId = getState().warehouse.activeId;
            const type = getState().settings.environment.selectedEnvironmentType;
            const id = getState().settings.environment.selectedId;
      
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            if (warehouseId) {
                headers.set('warehouse_id', warehouseId);
            }

            if(type === ENV_TYPES[100]){
                //CUSTOMER
                headers.set("customer_id", id);
            } else if(type === ENV_TYPES[200]) {
                //RELATION
                headers.set("relation_id", id);
            }
            
      
          return headers
        },
    }),
    tagTypes: cacheKeys,
    refetchOnMountOrArgChange: false,
    endpoints: builder => ({
        // getRand: () => builder.query({
        //     query: () => "/",
        //     // providesTags: (result, error, arg) => [{ type: 'Stock', id: arg }]
        // }),
    })
})

// export const {useGetRandQuery} = apiSlice