
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import InputIcon from '@mui/icons-material/Input';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const warehouseOutboundBatchesConfig = {
    todo:  {
        title: 'pickinglists',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    doing:  {
        title: 'doing',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    finished:  {
        title: 'finished',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
};
