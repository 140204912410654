import { useTranslation } from "react-i18next";
import ExplainerModal from "../../../../theme/Modal/ExplainerModal/ExplainerModal";
import Subheading from "../../../../theme/text/Subheading";
import Text from "../../../../theme/text/Text";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import ExplainerBox from "../../../../theme/Modal/ExplainerModal/ExplainerBox";


const EMDefaultShipper = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ExplainerModal>
            
            {/* Content of the PopoverModal */}
            <Box
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:2,
                }}
            >
                <ExplainerBox
                    title={t('mp_add_stream_shipper_rules_title')}
                    text={"This shipper will be used for all orders in this stream when there are no shipment rules matched or if there are no shipment rules configured."}
                />
                <ExplainerBox
                    variant="red"
                    title={t('mp_add_stream_shipper_rules_title')}
                    text={"This shipper will be used for all orders in this stream when there are no shipment rules matched or if there are no shipment rules configured."}
                />
                <ExplainerBox
                    variant={theme.palette.mode === "dark" ? "blue" : "primary"}
                    title={t('mp_add_stream_shipper_rules_title')}
                    text={"This shipper will be used for all orders in this stream when there are no shipment rules matched or if there are no shipment rules configured."}
                />
                
            </Box>
        </ExplainerModal>
    );
}

export default EMDefaultShipper;