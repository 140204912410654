
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spPickingCartDetailsConfig = {
    details:  {
        title: 'details',
        icon: <CropFreeIcon/>,
    },
    locations: {
        title: 'locations',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: false,
    },
};
