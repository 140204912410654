// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import { CenterFocusStrong } from '@mui/icons-material';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    
    const data = {...props.data};
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                        height:"1px",
                    }}
                >

                </Box>
                
                <Box    
                    sx={{
                        flex:1,
                        display:"flex",
                        justifyContent:"space-between",
                        alignItems:"center"
                    }}
                >
                    <Box   
                        sx={{
                            flex:1,
                            display:"flex",
                            justifyContent: "center"
                        }}
                    >
                        <Text bold>{t("min")}</Text>
                    </Box>
                    <Box  
                        sx={{
                            flex:1,
                            display:"flex",
                            justifyContent: "center"
                        }}
                    >
                        <Text bold>{t("max")}</Text>
                    </Box>
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("weight")}</Text>&nbsp;
                    <Text italic light>(kg)</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.minWeight ?? 0}
                        change={(e) => handleChange('minWeight', e)}/>
                    </Box>
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.maxWeight ?? 0}
                        change={(e) => handleChange('maxWeight', e)}/>
                    </Box>
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("length")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.minLength ?? 0}
                        change={(e) => handleChange('minLength', e)}/>
                    </Box>
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.maxLength ?? 0}
                        change={(e) => handleChange('maxLength', e)}/>
                    </Box>
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("width")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.minWidth ?? 0}
                        change={(e) => handleChange('minWidth', e)}/>
                    </Box>
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.maxWidth ?? 0}
                        change={(e) => handleChange('maxWidth', e)}/>
                    </Box>
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("height")}</Text>&nbsp;
                    <Text italic light>(cm)</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.minHeight ?? 0}
                        change={(e) => handleChange('minHeight', e)}/>
                    </Box>
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.maxHeight ?? 0}
                        change={(e) => handleChange('maxHeight', e)}/>
                    </Box>
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("volume")}</Text>&nbsp;
                    <Text italic light>(cm3)</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.minVolume ?? 0}
                        change={(e) => handleChange('minVolume', e)}/>
                    </Box>
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        value={data.maxVolume ?? 0}
                        change={(e) => handleChange('maxVolume', e)}/>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const StreamMeasurements = (props) => {
    return (
        <ProgressionTab 
            tabId="tabMeasurements" 
            subtitle={"measurements"} 
            step={2}
            nextTab={"tabShipper"}
            maxSteps={4}
        >
            <Content />
        </ProgressionTab>
    )
}

export default StreamMeasurements;

