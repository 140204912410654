import { Box, Button } from "@mui/material";
import ContextMenu from "../contextmenu/ContextMenu";
import Text from "../text/Text";

import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import { ReactComponent as PinAlt } from '../../../styles/svg/pin.svg';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import SortIcon from '@mui/icons-material/Sort';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { useEffect } from "react";

//Sort option
const SortColumnOption = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleClick = (data) => {
        props.selectOption(data);
    }

    return (

        <Box
            sx={{
                paddingX:1.5,
                height:40,
                display:"flex",
                alignItems:"center",
                borderRadius:2,
            }}
        >
            {/* Icon */}
            <Box
                sx={{
                    width:24,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    fontWeight:"normal",
                    color:colors.txt["secondary"]
                }}
            >
                <SortByAlphaOutlinedIcon fontSize="small"/>
            </Box>

            <Box
                sx={{
                    paddingLeft:1.5,
                }}
            >
                <Text semibold>Sorteren</Text>
            </Box>

            <Box
                sx={{
                    marginLeft:"auto",
                    display:"flex",
                    gap:1
                    // justifyContent:"flex-end"
                }}
            >
                <Button
                    onClick={() => handleClick({type: "desc", sorted: false})}
                    TouchRippleProps={{ 
                        style: { 
                            color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                        } 
                    }} // Directly styling the ripple here
                    variant='contained'
                    sx={{
                        background:theme.palette.mode === "dark" ? colors.grey[300]  : colors.bg["tertiary"],
                        width:32,
                        height:32,
                        borderRadius:"16px",
                        padding:0,
                        minWidth:0,
                        textTransform:"none",
                        boxShadow: "none",
                        opacity:1,
                        color:colors.txt["primary"],
                        "&:hover" : {
                            transition:"250ms",
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                            boxShadow:"none"
                        },
                    }}
                >
                    <SortIcon fontSize="small" />

                </Button>
                <Button
                    onClick={() => handleClick({type: "asc", sorted: false})}
                    TouchRippleProps={{ 
                        style: { 
                            color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                        } 
                    }} // Directly styling the ripple here
                    variant='contained'
                    sx={{
                        background:theme.palette.mode === "dark" ? colors.grey[300]  : colors.bg["tertiary"],
                        width:32,
                        height:32,
                        borderRadius:"16px",
                        padding:0,
                        minWidth:0,
                        textTransform:"none",
                        boxShadow: "none",
                        opacity:1,
                        color:colors.txt["primary"],
                        transform:"rotate(180deg) scaleX(-1)",
                        "&:hover" : {
                            transition:"250ms",
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                            boxShadow:"none"
                        },
                    }}
                >
                    <SortIcon fontSize="small" />

                </Button>
            </Box>

        </Box>
    
    )
}

// Grid header with context menu
const ShGridHeader = (props) => {

    const togglePin = (active) => {
        if(active) {
            console.log(props.colDef.field);
            props.pinColumn(props.colDef.field);
        } else {
            props.unpinColumn(props.colDef.field);
        }
    }

    const onOptionSelected = (option, data) => {
        switch(option) {
            case 1: 
                console.log(option, data);
                break;
            // Pin/unping 
            case 2:
                togglePin(data.active);
                console.log(option, data.active);
                break;
            case 3:
                console.log(option);
                break;
            case 4:
                console.log(option);
                break;
        }
    }

    //Default grid heading styling
    const defaultHeaderStyle = {
      display: 'block',
      padding: '0 0 0 0px',
      cursor: 'pointer',
      position:"relative",
      width:"100%",
    };
    
    const links = [
        {
            id:1,
            component: <SortColumnOption/>,
            close:false // Custom implementation doesnt require extra setup for this, remove it to see it vanish once click is applied
        },
        {
            id:2,
            title: "Pin column",
            icon: <PinAlt className="pin"/>,
            hasActive:true,
            active:props.isPinned,
            close:false
        },
        {
            id:3,
            title: "Filteren",
            icon:  <FilterAltOutlinedIcon/>,
        },
        {
            id:4,
            title: "Verbergen",
            icon:  <VisibilityOffOutlinedIcon/>
        }
    ]
  
    return (
        <ContextMenu sx={defaultHeaderStyle} options={links} onOptionSelected={onOptionSelected}>
            {props.colDef?.headerName}
        </ContextMenu>
    );
};

export default ShGridHeader;