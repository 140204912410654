
import CropFreeIcon from '@mui/icons-material/CropFree';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const spWarehouseDetailsConfig = {
    details: {
        title: 'details',
        icon: <WidgetsIcon/>,
    },
    zones: {
        title: 'zones',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    levels: {
        title: 'levels',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    
    replenishOrder: {
        title: 'replenish',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    
    
    
};
