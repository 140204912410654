import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { rootSlice } from "../../createRootSlice";

export const authApiSlice =  rootSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({ 
            query: (credentials) => ({ 
                   url: '/users/login', 
                method: 'POST', 
                body: credentials 
            }),
        }),
        logout: builder.mutation({ 
            query: (refreshToken) => ({ 
                url: '/users/logout', 
                method: 'PUT',
                body: {"refreshToken" : refreshToken}
            }) 
        }),
        refreshToken: builder.mutation({ 
            query: (refreshToken) => ({ 
                url: '/users/refresh', 
                method: 'POST', 
                body: refreshToken
            }) 
        }),
    }),
    
});

export const { useLoginMutation, useLogoutMutation, useRefreshTokenMutation } = authApiSlice;
