import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const PICKINGJOB_BASE = "/pickingjob";
const buildUrl = (endpoint) => PICKINGJOB_BASE + endpoint;

const pickingjobApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPickingJobs: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'PickingJobs' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getPickingJobs', 'PickingJobs'),
        }),
        getPickingJob: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'PickingJobsSingle' }],
        }),
        getPickingJobPickingList: builder.query({
            query: id => ({
                url: buildUrl(`/${id}/pickinglines`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'PickingJobPickingLines' }],
        }),
        getPickingJobPickingListPDF: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}/pickinglines?type=pdf`),
                method: 'GET',
                responseHandler: async (response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const blob = new Blob([await response.blob()], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    return {data: url};
                },
            }),
        }),
        getPickingLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/pickinglines/query`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'PickingJobPickingLines' }],
        }),
        addNewPickingJob: builder.mutation({
            query: data => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [`Orders`, 'PickingJobs'],
        }),
        updatePickingJobStepPickingLine: builder.mutation({
            query: pickingLineInfo => ({
                url: buildUrl(`/pickingjobstep/update/${pickingLineInfo.id}`),
                method: 'PUT',
                body: pickingLineInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'PickingJobPickingLines' }],
        }),
    })
});

export const { 
    useGetPickingJobsQuery,
    useGetPickingJobQuery,
    useGetPickingJobPickingListQuery,
    useGetPickingJobPickingListPDFMutation,
    useAddNewPickingJobMutation,
    useGetPickingLinesQuery,
    useUpdatePickingJobStepPickingLineMutation
} = pickingjobApiSlice;
