import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useGetDashboardOrderHistoryQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { useTranslation } from "react-i18next";
import AreaChart from "../../../theme/charts/AreaChart";


export const OutboundChart = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, isLoading, isFetching, isError,refetch} = useGetDashboardOrderHistoryQuery();
    
    // Function to handle retry
    const handleRetry = () => {
        refetch();
    };
    return (
        <Box
            sx={props.sx ?? {
                overflow:"hidden",

                flex:3,
                "@media screen and (max-width: 47.9375em)" : {
                    minWidth: "calc(100% - 48px)"
                }
            }}
        >
            <AreaChart 
                hideEffect
                hideFilter
                title={t("shipper_performace")}
                subtitle={t("per_country")}
                type="bar"
                colors={['primary']}
                values={[{name:"ss",data:[0,0,0]}]}
                // categories={[2017,2018,2019]}
                isLoading={false}
                isError={false}
                color={"default"}
                tickAmount={30}
                onRetry={handleRetry}
            />
        </Box>
    );
}