import { useTranslation } from "react-i18next"

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal"
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper"
import ChooseShipperType from "./views/ChooseShipperType"
import ShipperBasicInfo from "./views/ShipperBasicInfo"
import { useDispatch } from "react-redux"
import { setResetTab } from "../../../../newapi/global/progressionTabSlice"
import ShipperMeasurements from "./views/ShipperMeasurements"
import ShipperSender from "./views/ShipperSender"
import ShipperAuthorisation from "./views/ShipperAuthorisation"
import ShipperPickupTimes from "./views/ShipperPickupTimes"
import { useAddNewShipperMutation } from "../../../../newapi/shipper/shipperSlice"

const MpAddShipper = (props) => {
    // Call the setResetTab action to reset the state
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useAddNewShipperMutation} isModal title={t("add_shipper")}>
                <ChooseShipperType/>
                <ShipperBasicInfo/>
                <ShipperMeasurements/>
                <ShipperSender/>
                <ShipperPickupTimes/>
                <ShipperAuthorisation/>

            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddShipper;