
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import InputIcon from '@mui/icons-material/Input';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

export const settingsWarehouseOfficePrintersConfig = {
    all:  {
        title: "all_printers",
        subTitle: "all_printer_registrations",
        icon: <PrintOutlinedIcon/>,
        isSingleDataGrid: true,
    },
    
};
