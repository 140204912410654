import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import ChildProductOverview from "./Views/ChildProductOverview";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpVirtualProductDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;

    const childProductApiRef = useGridApiRef();

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: theme.palette.mode === "dark" ? "blue" : "primary",
            content: <Inventory2Icon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && typeof data.id !== "undefined") {
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data.description,
            subTitle: [data.sku],
            icon :{
                theme: theme.palette.mode === "dark" ? "blue" : "primary",
                content: <Inventory2Icon/>
            },
            options: [
                // {
                //     heading: t('products'),
                //     content: data.childProducts ? data.childProducts.length : 0
                // },
            ],
          }));
        }
      }, [data,theme.palette.mode]);


    return (
        <Sidepanel 
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <ChildProductOverview actionButtons={props.actionButtons} productId={props.data ? props.data.id : -1} apiRef={childProductApiRef}/>
                </>
        </Sidepanel>
    );
};

export default SpVirtualProductDetails;