import { Box, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import Chart from "react-apexcharts";
import Text from "../text/Text";
import ShChart from "./ShChart";
import ChartCard from "../cards/ChartCard";
import { useEffect, useMemo, useState } from "react";
import { NoChartData } from "./Status/NoChartData";
import { ChartError } from "./Status/ChartError";


const HorizontalBarChartContent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const labels = ['Shipped','Picked','Queued'];

    const colorType = theme.palette.mode === "dark" ? "light" : "st"; 

    const values = props.isLoading ? [{name: "Loading", data: new Array(8).fill(50)}] : 
                props.isError ? [{name: "Error", data: new Array(8).fill(50)}] : props.values;

    const categories = props.isLoading || props.isError ? new Array(values.length).fill('') : props.categories;

    const allValuesAreZero = useMemo(() => {
        return values.every(item => item.data.every(value => value === 0));
    }, [values]);

    const getColor = () => {
        if(typeof props.color === "undefined") return colors.txt["secondary"];
        if(props.color !== "default") return chartTokens.text.primary;
        return colors.txt["secondary"];
    }

    const getChartColors = () => {
        if (props.isLoading || allValuesAreZero) {
            // Use the loading color from DonutChart
            return [theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]];
        }
        if(props.isError) {
            return [colors.red[100]]
        }
        if(props.staticColors) {
            return props.staticColors;
        }
        if(props.stacked) {
            let colors = [];

            var keys = Object.keys(chartTokens[props.colors[0]][colorType]);
            for (var i = keys.length - 1; i >= keys.length - values.length; i--) {
                colors.push(chartTokens[props.colors[0]][colorType][keys[i]]);
            }
            return colors;

        }
        return props.colors.filter(function(item, pos) {
            return props.colors.indexOf(item) == pos;
        }).map((color) => chartTokens[color][colorType][700]);
    }

    return (
        <Box 
            sx={{
                width:1,
                flex:1,
                paddingTop:1.5,
                position:"relative",
                "& .apexcharts-canvas" : {
                    overflow:"hidden",
                    position:"relative",
                    zIndex:3,
                    // position:"unset",
                    "& svg" : {
                        marginLeft:props.horizontal ? "-8px" : "-12px",
                        // marginLeft:props.isModal ? "-16px" : "-37px",
                        marginTop:"-16px",
                        position:"aboslute",
                    },
                    "& .apexcharts-toolbar" : {
                        // right:"50% !important",
                        right:"0px !important",
                        top:"-28px !important",
                        "@media screen and (max-width: 64em)" : {
                            display:"none"
                        }
                        // transform:"translateX(50%)"
                    },
                    "& .apexcharts-tooltip, .apexcharts-xaxistooltip, .apexcharts-yaxistooltip" : {
                        background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                        color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.white[400],
                        border:"none",
                        "& .apexcharts-tooltip-title" : {
                            display:"none"
                        }
                    }
                },
                "@media screen and (max-width: 47.9375em)" : {
                    paddingRight:props.horizontal ? 2 : null
                }
            }}
        >
            <Chart 
                width={"100%"}
                height={6*48}
                labels={labels}
                series={!allValuesAreZero ? values : [{name:"Zero",data:new Array(categories.length).fill(50)}]}
                options={{
                    // colors : ['#b84644', '#4576b5'],
                    colors: getChartColors(),
                    // fill : {
                    //     colors: props.stacked ? [getChartColors()]: null
                    // },
                    // fill: props.stacked ? getChartColors() : null,
                    chart: {
                        stacked:props.stacked ? true : false,
                        animations: {
                            enabled:!props.isModal ??false
                        }
                    },
                    tooltip: {
                        // enabled:false
                    },
                    plotOptions: props.plotOptions,
                    grid:{
                        show:false,
                    },
                    dataLabels: {
                        enabled:false
                    },
                    xaxis: {
                        
                        axisBorder: {
                            show: false,
                            // color: 'orange',
                            // height: 0,
                            // width: '100%',
                        },
                        categories: categories,
                        labels: {
                            show: !props.isLoading,
                            // offsetX: 0,
                            style: {
                                colors:getColor(),
                                // colors: ["blue","orange","grey"],
                                fontSize: '12px',
                                // fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 500,
                                // cssClass: 'apexcharts-xaxis-label',
                            },
                        },
                    },
                    yaxis: {
                        
                        labels: {
                            offsetY:2,
                            offsetX:-4,
                            style: {
                                colors:getColor(),
                                // colors: ["blue","orange","grey"],
                                fontSize: '12px',
                                // fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 500,
                                // cssClass: 'apexcharts-xaxis-label',
                            },
                        },
                    },
                    legend:{
                        show:false,
                    },
                }} 
                type="bar"
            />

            {allValuesAreZero && <NoChartData/>}
            {props.isError && <ChartError onRetry={props.onRetry}/>}
            
        </Box>
    );
}

export const HorizontalBarChart = (props) => {
    var plotOptions = {
        bar: {
            barHeight:"50%",
            // columnHeight:"122px",
            borderRadius: 8,
            horizontal: true,
        }
    }

    return (
        <ShChart color={props.color} type={props.type}>
            <ChartCard 
                {...props}
            >
                <HorizontalBarChartContent {...props} categories={props.categories} values={props.values} horizontal plotOptions={plotOptions}/>
            </ChartCard>
        </ShChart>
    );
}

export const StackedHorizontalBarChart = (props) => {
    var plotOptions = {
        bar: {
            barHeight:"50%",
            // columnHeight:"122px",
            borderRadius: 8,
            horizontal: true,
        }
    }

    return (
        <ShChart>
            <ChartCard 
                {...props}
            >
                <HorizontalBarChartContent {...props} categories={props.categories} values={props.values} stacked horizontal plotOptions={plotOptions} />
            </ChartCard>
        </ShChart>
    );
}

export const BarChart = (props) => {
    var plotOptions = {
        bar: {
            borderRadius: 12,
            distributed:true,
            columnWidth:'24px',
        }
    }
    return (
        <ShChart>
            <ChartCard 
                {...props}
            >
                <HorizontalBarChartContent {...props} categories={props.categories} values={props.values} color={props.color} plotOptions={plotOptions}/>
            </ChartCard>
        </ShChart>
    );
}
export const StackedBarChart = (props) => {
    var plotOptions = {
        bar: {
            borderRadius: 16,
            columnWidth:'32px',
        }
    }
    return (
        <ShChart>
            <ChartCard 
                {...props}
            >
                <HorizontalBarChartContent {...props}  categories={props.categories} values={props.values} stacked color={props.color} plotOptions={plotOptions}/>
            </ChartCard>
        </ShChart>
    );
}