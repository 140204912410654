import { Box, useTheme } from "@mui/material";
import Subheading from "../../../components/theme/text/Subheading";
import Text from "../../../components/theme/text/Text";
import Shbutton from "../../../components/theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../components/theme/sidepanel/SidepanelHeaderActiongroup";
import ContactBody from "../../../components/global/Sidepanels/SpStockDetails/Views/Warehouse";
import { useGridApiRef } from "@mui/x-data-grid";
import OverflowRow from "../../../components/theme/OverflowRow";

import SPStockDetails from "../../../components/global/Sidepanels/SpStockDetails/SPStockDetails";
//import Echeck from "./views/Echeck";
import SpLocationDetails from "../../../components/global/Sidepanels/SpLocationDetails/SpLocationDetails";
import MAddCarrier from "../../../components/global/Modals/carriers/AddCarrier";
import TabView from "../../../components/theme/TabViews/TabView";
import { useTranslation } from "react-i18next";
import AreaChart from "../../../components/theme/charts/AreaChart";
import DonutChart from "../../../components/theme/charts/DonutChart";
import { BarChart } from "../../../components/theme/charts/HorizontalBarChart";
import Heading from "../../../components/theme/text/Heading";
import { useGetDashboardInboundsQuery, useGetDashboardOrderHistoryQuery, useGetDashboardOrderLinesQuery, useGetDashboardOrdersQuery } from "../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import ShTextSkeleton from "../../../components/theme/skeleton/ShTextSkeleton";
import {  InboundChart } from "../../../components/charts/dashboard/fulfillment/InboundChart";
import { OrderlinesChart } from "../../../components/charts/dashboard/fulfillment/OrderlinesChart";
import { OrdersChart } from "../../../components/charts/dashboard/fulfillment/OrdersChart";
import { OrderHistoryChart } from "../../../components/charts/dashboard/fulfillment/OrderHistoryChart";
import { ReturnChart } from "../../../components/charts/dashboard/fulfillment/ReturnChart";

const Dashboard = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {data: orderHistoryData, isLoading: isDashboardOrderHistoryLoading, isFetching: isDashboardOrderHistoryFetching, isError: isDashboardOrderHistoryError, error: dashboardOrderHistoryError} = useGetDashboardOrderHistoryQuery();
    

    return (
        <Box sx={{background:colors.bg['tertiary'], width:1,paddingTop:9,paddingX:4,}}>
            {ready ?
                <Box>
                    <Box
                        sx={{
                            display:'grid',
                            gridTemplateColumns: '2fr 4fr',
                            gap:3,
                            "@media screen and (max-width: 1024px)" : {
                                gridTemplateColumns: "2fr 2fr",
                            },
                            "@media screen and (max-width: 768px)" : {
                                gridTemplateColumns: "repeat(2, 1fr)"
                            }
                        }}
                    >
                        <OrdersChart/>
                        <OrderHistoryChart/>
                    </Box>
                    <Box
                        sx={{
                            mt:3,
                            display:'grid',
                            gridTemplateColumns: '2fr 4fr',
                            gap:3,
                            "@media screen and (max-width: 1024px)" : {
                                gridTemplateColumns: "repeat(2, 1fr)"
                            }
                        }}
                    >
                        <OrderlinesChart/>
                        <Box
                            sx={{
                                display:"grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                // gridTemplateRows:1,
                                gap:3,
                                "@media screen and (max-width: 1024px)" : {
                                    gridArea: "2 / 1 / 3 / 3",
                                },
                                "@media screen and (max-width: 768px)" : {
                                    // flexWrap: "wrap"
                                    gridTemplateColumns:"1fr",
                                    gridTemplateRows:"repeat(2, 1fr)",
                                }
                            }}
                        >
                            <InboundChart/>
                            <ReturnChart/>

                        </Box>

                    </Box>
                </Box>
            :null}
        </Box>
    );
};

export default Dashboard;