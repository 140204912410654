import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import Subheading from "../../../../theme/text/Subheading";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import StockOnlocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import StockMutationCard from "../../../../theme/cards/stock/StockMutationCard";
import DownloadAttachmentCard from "../../../../theme/cards/other/DownloadAttachmentCard";
import JokerCard from "../../../../theme/cards/orders/JokerCard";
import LogCard from "../../../../theme/cards/other/LogCard";
import SurprisePack from "../../../../theme/cards/products/SurprisePack";
import { useTranslation } from "react-i18next";
import { useGetOrderColliQuery } from "../../../../../newapi/order/ordersSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
// import { useGetOrderColliQuery } from "../../../../../newapi/order/ordersSlice";

// import classNames from "classnames";
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>Save</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Card = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const data = props.data;
    return (
        <Box
            sx={{
                // background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]}`,
            }}
        >
            <Text bold>{data.description}</Text>
            <Box
                sx={{
                    marginTop:3,
                    display:"flex",
                    justifyContent:"space-between",
                    gap:5,
                }}
            >
                <Box display={"flex"} flexDirection={"column"}>
                    <Text light>{t("sku")}</Text>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{data.sku}</Text>
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                    <Text light>{t("location")}</Text>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{data.location}</Text>
                </Box>
            </Box>

            <Box
                sx={{
                    position:"absolute",
                    paddingY:0.5,
                    paddingX:2,
                    right:0,
                    top:0,
                    borderRadius: "8px 12px",
                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]
                }}
            >
                {data.quantity}
            </Box>
        </Box>
    )

}

const getFakeData = () => {
    return [
        {
            quantity: "-10",
            package: "JUMBO DC POLO KM",
            weight: "JUMDC-C1100-M",
            dimension: "10-AA-013",
            dimensionByShipper: "10-AA-013",
            trackTrace : "",
            phoneView:{},
        },
        {
            id: randomIntFromInterval(0, 99999999),
            quantity: "-10",
            description: "JUMBO DC POLO KM",
            sku: "JUMDC-C1100-M",
            location: "10-AA-013",
            phoneView:{},
        },
        {
            id: randomIntFromInterval(0, 99999999),
            quantity: "-10",
            description: "JUMBO DC POLO KM",
            sku: "JUMDC-C1100-M",
            location: "10-AA-013",
            phoneView:{},
        },
    ]
}

const buildOptions = (data,cb) => {
    let options = [];
    for(let i = 0; i < data.length; i++) {
        options.push({
            component: <Card data={data[i]}/>
        })
    }
    cb(options);
}

// const getColliData = (xdata) => {
//     getColli(xdata.id,(result, data) => {
//         if(result){
//             return data;
//         }
//     });
// }

const ShipOrder = (props) => {
    const {t} = useTranslation();
    const apiRef = props.apiRef;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions></GridActions>,
            status:{
                value: "Shipped",
                variant:"green",
                palette:"normal"
            },
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[ /* Add dependencies here once we have status data */]);
    
    
    const columns = [
        // {field: "id", hide: true },
        {field: "carrier.name", headerName: t("name"), flex:1, renderCell : (cellValues) => {
            return (
                <Box>
                    <Text semibold>{cellValues.row.carrier?.name}</Text>
                </Box>
            )
        }},
        {field: "carrier.weight", headerName: t("weight"),flex:1,renderCell : (cellValues) => {
            return (
                <Box>
                    <Text semibold>{cellValues.row.carrier?.weight}</Text><Text> KG</Text>
                </Box>
            )
        }},
        
        {field: "carrier.length", headerName: t("dimensions"), flex:1, renderCell : (cellValues) => {
            return (
                <Box>
                    <Text>{cellValues.row.carrier?.length} x {cellValues.row.carrier?.width} x {cellValues.row.carrier?.height}</Text>
                </Box>
            )
        }},

        {field: "dimensionsByShipper", headerName: t("dimensions_by_shipper"), flex:1, renderCell : (cellValues) => {
            return (
                <Box>
                    <Text>{cellValues.value ? cellValues.value : "-"}</Text>
                </Box>
            )
        }},
        {field: "trackTrace", headerName: t("track_trace"), flex:1, renderCell : (cellValues) => {
            return (
                <Box>
                    <Text>{cellValues.value}</Text>
                </Box>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {/* {cellValues.row.id + " " + cellValues.row.name}
                        <br/>
                        {cellValues.row.age}
                        <br/>
                        {cellValues.row.phone} */}
                    </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={1.5} paddingY={3} overflow={"auto"}>
            <Box paddingX={1.5}
                sx={{
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                <Subheading variant={props.data.date_sent? theme.palette.mode === "dark" ? "blue" : "primary" : "red"}>{props.data.date_sent ? t("order_is_shipped") : t("order_is_not_shipped")}</Subheading>
            </Box>
            {/* Details */}
            <Box 
                sx={{
                    display:"flex",
                    borderRadius:3,
                    overflow:"hidden",
                    marginTop:3,
                    marginBottom:1,
                    background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                    "@media screen and (max-width: 47.9375em)" : {
                        marginTop:0,
                        background:"none",
                        flexDirection:"column",
                        overflow:"unset"
                    }
                }}
            >
                {/* Editable content */}
                <Box padding={1.5} paddingRight={4} paddingBottom={3} flex={1}
                    sx={{
                        "@media screen and (max-width: 47.9375em)" : {
                            paddingY:0,
                            paddingRight:1.5,
                        }
                    }}
                >
                    <Box 
                        sx={{
                            display:"flex",
                            // alignItems:"center",
                            flexDirection:"column",
                            "@media screen and (max-width: 47.9375em)" : {
                                gap:0.5,
                                alignItems:"flex-start",
                            }
                        }}
                    >
                        <Subheading>{t("details")}</Subheading>
                        <Text>{props.data.deliveryName}</Text>
                    </Box>

                    <Box
                        sx={{
                            display:"flex",
                            marginTop:3,
                            gap:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column",
                                gap:1,
                                alignItems:"flex-start",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("street")}</Text>
                            </Box>
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text" value={props.data.deliveryStreet || ""} sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}} />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("house_nr")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[200]}
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text" 
                                    value={props.data.deliveryHousenumber || ""}
                                        sx={{
                                            px: 2, 
                                            width:56, 
                                            height:32,
                                            boxSizing:"border-box",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1,
                                                width:"unset"
                                            }
                                        }}/>
                                    <Box 
                                        sx={{
                                            marginY:0.5,
                                            height:24,
                                            width:"1px",
                                            background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                        }}
                                    />
                                    <InputBase readOnly type="text"value={props.data.deliveryHousenumberAddition || ""} sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("postal_code")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text" 
                                    value={props.data.deliveryPostalCode || ""}
                                        sx={{
                                            px: 2, 
                                            width:96, 
                                            height:32,
                                            boxSizing:"border-box",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1,
                                                width:"unset"
                                            }
                                        }}/>
                                    {/* <Box 
                                        sx={{
                                            marginY:0.5,
                                            height:24,
                                            width:"1px",
                                            background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                        }}
                                    />
                                    <InputBase readOnly type="text" sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} /> */}
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("city")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text"
                                    value={props.data.deliveryCity || ""}
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box",
                                            "@media screen and (max-width: 47.9375em)" : {
                                                flex:1,
                                                width:"unset"
                                            }
                                        }}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    <Box
                        sx={{
                            display:"flex",
                            marginTop:3,
                            gap:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                flexDirection:"column",
                                gap:1,
                                alignItems:"flex-start",
                                marginTop:4
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("country")}</Text>
                            </Box>
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text" sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}}
                                    value={props.data.deliveryCountry || ""} />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("delivery_state")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[200]}
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text" 
                                    value={props.data.deliveryState || ""}
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box",
                                        }}/>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1,
                                    marginTop:4,
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("phone")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text" 
                                    value={props.data.deliveryPhone || ""}
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box",
                                        }}/>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:0.5,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"row",
                                    gap:5,
                                    alignItems:"center",
                                    width:1
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:80
                                    }
                                }}
                            >
                                <Text light>{t("email")}</Text>
                            </Box>

                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >

                                <Box display="flex"
                                
                                    borderRadius="20px"
                                    sx={{
                                        marginTop:0.5,
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                        "@media screen and (max-width: 47.9375em)" : {
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                        }
                                    }}
                                >
                                    <InputBase readOnly type="text" 
                                    value={props.data.deliveryEmail || ""}
                                        sx={{
                                            px: 2, 
                                            flex:1, 
                                            height:32,
                                            boxSizing:"border-box",
                                            value: "test"
                                        }}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Maps */}
                {/* <Box 
                    sx={{
                        width:200,
                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]
                    }}
                ></Box> */}
            </Box>

            <Box marginX={-1.5}
                sx={{
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                <ShDataGrid2
                    content={useGetOrderColliQuery}
                    itemId={props.data.id}
                    // gridActions={<GridActions></GridActions>} 
                    title={t("colli")} 
                    gridOptions={gridOptions} 
                    isSidepanelGrid 
                    columns={columns}
                    getRowId={(row, index) => index + 1}
                    rows={data}>
                </ShDataGrid2>
            </Box>
        </Box>
    );
}


export default ShipOrder;