
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
export const settingsWarehouseGeneralConfig = {
    core:  {
        title: 'core',
        subTitle: "subtitle",
        icon: <ChecklistOutlinedIcon/>,
    },
    
};
