// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const [webshopCredentialsInfo, setWebshopCredentialsInfo] = useState(data.credentials);

    const handleCredentialsChange = (key, val) => {
        setWebshopCredentialsInfo({
            ...webshopCredentialsInfo,
            [key]: val
        });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    }

    useEffect(() => {
        props.handleDataUpdate({ credentials: webshopCredentialsInfo });
    }, [webshopCredentialsInfo])

    return (
        <div>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("description")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                            name="description"
                            value={data.description ?? ""}
                            onChange={handleInput}
                            error={props.errors.description}
                        />
                    
                </Box>
            </Box>
            {Object.keys(webshopCredentialsInfo).map((key) => (
                <Box 
                    key={key}
                    sx={{
                        width: 1,
                        paddingTop: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 7
                    }}
                >
                    <Box
                        sx={{
                            width: 120,
                        }}
                    >
                        <Text>{t(key)}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex: 1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            borderRadius: 4,
                            display: "flex",
                            height: 32
                        }}
                    >
                        <ShValidatedInput
                            name={key}
                            value={webshopCredentialsInfo[key] ?? ""}
                            onChange={handleCredentialsChange}
                            error={props.errors[key]}
                        />
                    </Box>
                </Box>
            ))}
    </div>
    );
};

const Credentials = (props) => {
    const data = props.data;
    const { t } = useTranslation();

    // let schema = {
    //     description: yupRequired('description', t),
    // };

    // if (data && data.webshop_type === "woocommerce") {
    //     schema = {
    //         ...schema,
    //         url: yupRequired('url', t),
    //         consumer_key: yupRequired('consumer_key', t),
    //         consumer_secret: yupRequired('consumer_secret', t),
    //     };
    // } else if (data && data.webshop_type === "shopify") {
    //     schema = {
    //         ...schema,
    //         shop: yupRequired('shop', t),
    //         client_key: yupRequired('client_key', t),
    //         access_token: yupRequired('access_token', t),
    //     };
    // }
    return (
        <ProgressionTab 
        tabId="tabCredentials" 
        subtitle={t("webshopcredentials")} 
        step={2}
        nextTab={"tabMeasurementUnits"}
        maxSteps={3}
        // schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Credentials;

