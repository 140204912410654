import { createContext, useState, useMemo } from "react";

// mui theme settings
export const collapsedSettings = (collapsed) => {

    return collapsed;
}

// context for color mode
export const MenuStateContext = createContext( {
    toggleMenuState: () => {},
    updateMenuState: () => {},
});

export const useToggle = () => {
    const [collapsedMode, setCollapsedMode] = useState(false);

    const setIsCollapsed = useMemo(
        () => ({
            toggleMenuState: () => setCollapsedMode((prev) => prev ? false : true),
            updateMenuState: (visibility) => setCollapsedMode(visibility),
        }),
        []
    );

    const collapsed = useMemo(() => collapsedSettings(collapsedMode), [collapsedMode]);

    return [collapsed, setIsCollapsed];
}
