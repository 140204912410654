import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const SettingGroup = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matchesDesktop = useMediaQuery('(min-width:1025px)'); // Adjust the breakpoint as needed

    return (
        <Box
            sx={{
                flex:1,
                "@media screen and (max-width: 1023px)" : {
                    maxWidth:"unset"
                }
            }}
        >
            {/* Title */}
            
            <Typography
                component={"p"}
                sx={{
                    fontSize:14,
                    fontWeight:"700",
                    color: colors.txt["primary"],
                    "@media screen and (max-width: 1023px)" : {
                        px:3,
                        fontWeight:"700",
                        textTransform:"uppercase",
                        fontSize:12
                    }
                }}
            >
                {props.title}
            </Typography>

            {/* List of settings */}
            <Box
                sx={{
                    mt:0.5,
                    "@media screen and (max-width: 1023px)" : {
                        px:1.5,
                        py:0.5,
                        mx:1.5,
                        mt:1,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                        borderRadius:3,
                        display:"flex",
                        flexDirection:"column",
                        
                    }
                }}
            >
                {props.children}
            </Box>

            {/* Footer */}
            {typeof props.note !== "undefined" ?
                <Typography
                    component={"p"}
                    sx={{
                        display:"none",
                        color: colors.txt["primary"],
                        fontSize:13,
                        mt:1,
                        "@media screen and (max-width: 1023px)" : {
                            display:"block",
                            px:3,
                        }
                    }}
                >
                    {props.note}
                </Typography>
            : null }
        </Box>
    );
}

export default SettingGroup;