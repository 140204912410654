import { Box, Divider, IconButton, InputBase, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditCarrierMutation } from "../../../../../newapi/carrier/carrierSlice";
import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import {checkAllPropertiesTruthy } from "../../../../../utils"

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { useGetCarriersTypesQuery } from "../../../../../newapi/carrier/carrierSlice";
import { useUpdatePickupCartMutation } from "../../../../../newapi/warehouse/pickupCartSlice";
import { useGetReachTypesSelectQuery } from "../../../../../newapi/warehouse/reachTypeSlice";
import { useGetWarehouseZonesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data: reachTypes} = useGetReachTypesSelectQuery();
    const {data: warehouseZones} = useGetWarehouseZonesSelectQuery();
    const data = props.data;

    const [pickingCartInfo, setPickingCartInfo] = useState({
        state: "active",
        id: 0,
        capacity: 0,
        barcode: "",
        pickupCartLocations: [],
        warehouseZoneId: 0,
        reachTypeId: 0
    });
    useEffect(() => {
        setPickingCartInfo(props.data);
    },[]);
    

    const handleChange = (key, value) => {
        setPickingCartInfo({
          ...pickingCartInfo,
          [key]: value
        });
      };

      

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={pickingCartInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[pickingCartInfo]);

    const [EditPickingCart,{isLoading}] = useUpdatePickupCartMutation();
    const handleSaveClick = async () => {
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const data = await EditPickingCart(pickingCartInfo).unwrap()
                showToastMessageUpdateRequest(t,data);
        } catch (err) {
            showToastMessageRequestError(t("update_failed"),t,err);
          }
            
        } else {
            if(!isLoading) {
                toast.error(t("not_all_fields_filled"), {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }    
        }        
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    },
                    flexDirection:"column"
                }}>
                    
                    <Box 
        display={"flex"} 
        flexDirection={"column"}
        sx={{
            //maxWidth: 160,
            width:1,
            "@media screen and (max-width:47.9375em)" : {
                width:1,
                flexDirection:"row",
                gap:4,
                alignItems:"center",
                justifyContent:"space-between",
            }
        }}
        >
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    mb:0.5,
                    "@media screen and (max-width:47.9375em)" : {
                        mb:0
                    }
                }}
            >
                <Text semiBold>
                    {t('barcode')}
                </Text>
                
            </Box>
            <ShValidatedInput
                        name="barcode"
                        value={data.barcode ?? ""}
                        onChange={handleChange}
                        // error={props.errors.barcode}
                    />
</Box>
        <Box 
        display={"flex"} 
        flexDirection={"column"}
        sx={{
            //maxWidth: 160,
            width:1,
            paddingTop:3,
            "@media screen and (max-width:47.9375em)" : {
                width:1,
                flexDirection:"row",
                gap:4,
                alignItems:"center",
                justifyContent:"space-between",
            }
        }}
        >
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    mb:0.5,
                    "@media screen and (max-width:47.9375em)" : {
                        mb:0
                    }
                }}
            >
                <Text semiBold>
                    {t('reachtype')}
                </Text>
                
            </Box>
    <InputDropdown
    displayName={"description"} 
    onChange={(selected) => handleChange("reachTypeId", reachTypes?.data[selected].id ?? -1)}
    noSelection={t("choose_reachtype")} //Custom text when nothing is selected
    options={reachTypes?.data}
    selected={reachTypes?.data?.findIndex(reachType => reachType.id === data.reachTypeId) ?? -1}
    closeOnSelection
/>
</Box>
<Box 
        display={"flex"} 
        flexDirection={"column"}
        sx={{
            //maxWidth: 160,
            width:1,
            paddingTop:3,
            "@media screen and (max-width:47.9375em)" : {
                width:1,
                flexDirection:"row",
                gap:4,
                alignItems:"center",
                justifyContent:"space-between",
            }
        }}
        >
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    mb:0.5,
                    "@media screen and (max-width:47.9375em)" : {
                        mb:0
                    }
                }}
            >
                <Text semiBold>
                    {t('warehousezone')}
                </Text>
                
            </Box>
    <InputDropdown
    displayName={"description"} 
    onChange={(selected) => handleChange("warehouseZoneId", warehouseZones?.data[selected].id ?? -1)}
    noSelection={t("choose_warehousezone")} //Custom text when nothing is selected
    options={warehouseZones?.data}
    selected={warehouseZones?.data?.findIndex(warehouseZone => warehouseZone.id === data.warehouseZoneId) ?? -1}
    closeOnSelection
/>
</Box>
</Box>
            </Dropdown>
            

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
        <GridActions
            id={props.data.id}
            action={handleSaveClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;