import { createSlice } from '@reduxjs/toolkit';
import { PURGE, REHYDRATE } from 'redux-persist';

// const initialState = {
//   searchString: '',
//   columns: [
//     {
//       column: "BLAB",
//       visible: true,
//       pinned: true,
//     }
//   ],
//   sortedBy: [],
//   filter: {
//     groups: []
//   },
// };
const filterInitialState = {
  searchString: '',
  columns: [
    {
      column: "BLAB",
      visible: true,
      pinned: true,
    }
  ],
  sortedBy: [],
  filter: {
    groups: []
  },
};

const initialState = {
  // instances: {},
  // instanceOrder: [],
  viewInstances: [],
};


const gridFilterSlice = createSlice({
  name: 'gridFilter',
  initialState: initialState,
  reducers: {
    createViewInstance: (state,action) => {
      // const {id, initialState} = action.payload;
      state.viewInstances.push({});
    },
    removeViewInstance: (state,action) => {
      state.viewInstances.splice(action.payload,1);
    },
    createFilterInstance: (state,action) => {
      const {index,id} = action.payload;
      state.viewInstances[index][id] = filterInitialState;
    },
    removeFilterInstance: (state,action) => {
        const { index, id } = action.payload;
        delete state.viewInstances[index][id];
    },



    // createFilterInstance: (state, action) => {
    //   const { id, initialState } = action.payload;
    //   state.instances[id] = filterInitialState;
    //   state.instanceOrder.push(id); // Assuming instanceOrder is part of your initial state
    // },
    // removeFilterInstance: (state, action) => {
    //   const { id } = action.payload;
    //   delete state.instances[id];
    // },
    // removeLastFilterInstance: (state) => {
    //   if (state.instanceOrder.length > 0) {
    //     const lastId = state.instanceOrder.pop();
    //     delete state.instances[lastId];
    //   }
    // },
    setShGridColumns: (state, action) => {
      const { index,id, columns } = action.payload;
      if (state.viewInstances[index]?.[id]) {
        state.viewInstances[index][id].columns = columns;
      }
    },
    updateShGridColumn: (state, action) => {
      const { index,id, column, visible, pinned } = action.payload;
      const instance = state.viewInstances[index][id];
      if (instance) {
        const index = instance.columns.findIndex(col => col.column === column);
        if (index !== -1) {
          instance.columns[index] = {
            ...instance.columns[index],
            ...(visible !== undefined && { visible }),
            ...(pinned !== undefined && { pinned }),
          };
        }
      }
    },
    setShGridSortedBy: (state, action) => {
      const { index,id, sortedBy } = action.payload;
      if (state.viewInstances[index]?.[id]) {
        state.viewInstances[index][id].sortedBy = sortedBy;
      }
    },
    updateSearch: (state, action) => {
      const { index,id, searchString } = action.payload;
      if (state.viewInstances[index]?.[id]) {
        state.viewInstances[index][id].searchString = searchString;
      }
    },
    // Implementing the rest of the actions similarly...
    removeFilterGroup: (state, action) => {
      const { index,id, groupIndex } = action.payload;
      const instance = state.viewInstances[index][id];
      if (instance && instance.filter.groups[groupIndex]) {
        instance.filter.groups.splice(groupIndex, 1);
      }
    },
    removeFilterRule: (state, action) => {
      const { index,id, groupIndex, ruleIndex } = action.payload;
      const instance = state.viewInstances[index][id];
      if (instance && instance.filter.groups[groupIndex] && instance.filter.groups[groupIndex].rules[ruleIndex]) {
        instance.filter.groups[groupIndex].rules.splice(ruleIndex, 1);
      }
    },
    updateFilterOptions: (state, action) => {
      const { index,id, options } = action.payload;
      if (state.viewInstances[index]?.[id]) {
        state.viewInstances[index][id].filter = { ...state.viewInstances[index][id].filter, ...options };
      }
    },
    resetFilter: (state,action) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(REHYDRATE, (state, action) => {
        // Or if you want to update the state with the rehydrated state:
        if (action.payload) {
          return { ...state, ...action.payload.gridFilter };
        }
        return state;
      })
  }
});

export const {
  createViewInstance,
  removeViewInstance,
  createFilterInstance,
  removeFilterInstance,
  updateSearch,
  resetFilter,
  resetSearchString,
  updateFilter, 
  removeFilterGroup, 
  removeFilterRule,
  options,
  setPreviousRoute,
  setActiveTabChanged,
  setShGridColumns,
  updateShGridColumn,
  setShGridSortedBy,
} = gridFilterSlice.actions;

export default gridFilterSlice;