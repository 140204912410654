import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { rootSlice } from "../createRootSlice";
import { muiDataGridStarted } from "../apiutils";


export const userSlice =  rootSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: ({ args, invalidate }) => ({
                url: `/users?page=${args.page}&size=${args.size}`,
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Users' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getUsers', 'Users')
        }),
        getUser: builder.query({ 
            query: (id) => ({ 
                url: `/users/${id}`, 
                method: 'GET', 
            }),
            providesTags: (id) => [{ type: `SingleUser-${id}`}],
        }),
        getExisting: builder.query({ 
            query: (email) => ({ 
                url: `/users/existing`, 
                method: 'POST', 
                body: {email: email}
            }),
            //providesTags: (result, error, arg) => [{ type: `existing`}],
        }),
        signup: builder.mutation({ 
            query: (user) => ({ 
                url: `/users/signup`, 
                method: 'POST', 
                body: {user}
            }),
            invalidatesTags: () => [{ type: 'Users'}],
        }),
        editUser: builder.mutation({ 
            query: (user) => ({ 
                url: `/users/${user.id}`, 
                method: 'PUT', 
                body: {user}
            }),
            invalidatesTags: (user) => [{type: `SingleUser-${user.id}`}],
        }),     
    }),
    
});

export const { useGetUsersQuery, useGetUserQuery, useGetExistingQuery, useSignupMutation, useEditUserMutation} = rootSlice;
