import { authApiSlice } from "../user/authentication/authApiSlice";
import { apiSlice } from "../createApiSlice";

export const loginMiddleware = (storeAPI) => (next) => (action) => {
    // Proceed to the next middleware/reducer first.
    const result = next(action);  
  
    // Check if this action is the fulfilled action from login.
    if (authApiSlice.endpoints.login.matchFulfilled(action)) {
      // Dispatch getEnums API call if the action is login/fulfilled.
      storeAPI.dispatch(apiSlice.endpoints.getEnums.initiate());
    }
    
    return result;
};