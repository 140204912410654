// import { apiSlice } from '../api/apiSlice'
// import 

import { muiDataGridStarted } from "../../apiutils";
import { apiSlice } from "../../createApiSlice";


const RETURN_BASE = "/reports"; 
const buildUrl = (endpoint) => RETURN_BASE + endpoint;
const reportsFulfillmentSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDashboardOrders: builder.query({ 
            query: () => ({
                url: buildUrl(`/dashboard/orders`),
                method: 'GET',
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getDashboardOrderLines: builder.query({ 
            query: () => ({
                url: buildUrl(`/dashboard/orderlines`),
                method: 'GET',
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getDashboardOrderHistory: builder.query({ 
            query: () => ({
                url: buildUrl(`/dashboard/orderhistory`),
                method: 'GET',
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getDashboardInbounds: builder.query({ 
            query: () => ({
                url: buildUrl(`/dashboard/inbounds`),
                method: 'GET',
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getDashboardReturns: builder.query({ 
            query: () => ({
                url: buildUrl(`/dashboard/returns`),
                method: 'GET',
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getDashboardReturnHistory: builder.query({ 
            query: () => ({
                url: buildUrl(`/dashboard/returnhistory`),
                method: 'GET',
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getInboundStatusCounts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/inbound/counts?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'InboundsCounts' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getInboundsCounts', 'InboundsCounts')
        }),
        getInboundDashboard: builder.query({
            query:() => ({
                url: buildUrl(`/inbound/dashboard`),
            }),
            providesTags: () => [{ type: 'InboundDashboard' }],
        }),
        getOutboundDashboard: builder.query({
            query:() => ({
                url: buildUrl(`/outbound/dashboard`),
            }),
            providesTags: () => [{ type: 'OutboundDashboard' }],
        }),
    })
});

export const {
    useGetDashboardOrdersQuery,
    useGetDashboardOrderLinesQuery,
    useGetDashboardOrderHistoryQuery,
    useGetDashboardReturnsQuery,
    useGetDashboardReturnHistoryQuery,
    useGetDashboardInboundsQuery,
    useGetInboundStatusCountsQuery,
    useGetInboundDashboardQuery,
    useGetOutboundDashboardQuery
  } = reportsFulfillmentSlice;

