import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import PickingLines from "./Views/PickingLines";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spOrderPickingListConfig } from "./spOrderPickingListConfig";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Close
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const HeaderDateBox = (props) => {
    const [planned, setPlanned] = useState({});
    const [shipped, setShipped] =  useState({});
    
    useEffect(() => {
        setPlanned(getTranslatedDateAndTime(props.data.date_planned, props.t));
        setShipped(getTranslatedDateAndTime(props.data.date_shipped, props.t));
    },[props.data])

    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"}>
                <Box width={66} marginRight={1}><Text light>Scheduled</Text></Box>
                <Box marginRight={1}><Text bold>{planned?.date}</Text></Box>
                <Text>{planned?.time}</Text>
            </Box>
            <Box  display={"flex"} alignItems={"center"}>
                <Box width={66}marginRight={1}><Text light>Shipped</Text></Box>
                <Box marginRight={1}><Text bold>{shipped?.date}</Text></Box>
                <Text>{shipped?.time}</Text>
            </Box>
        </>
    )

}

const getOrderStatusColor = (data)  => {
    let labelcolor = "grey";
    let planned = new Date(data?.date_planned);
    let sent = data?.date_sent ? new Date(data?.date_sent) : null;

    if(sent) {
        // when sent is filled and is equal to planned: green
        if(sent.getTime() === planned.getTime()) {
            labelcolor = "green";
        }
        // when sent is filled but later than planned: orange
        else if(sent.getTime() > planned.getTime()) {
            labelcolor = "orange";
        }
    }
    else {
        // When sent is not filled and planned >= now: blue
        if(planned.getTime() >= Date.now()) {
            labelcolor = "blue";
        }
        // When sent is not filled and planned < now: red
        else if(planned.getTime() < Date.now()) {
            labelcolor = "red";
        }
    }

    return labelcolor;
}

const SpOrderPickingList = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data } = props;

    const { t, ready, i18n } = useTranslation();

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: "green",
            content: <Inventory2Icon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && ready) {
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: `Order ${props.data.order.id}`,
            subTitle: `${props.data.order.relation.name} - ${props.data.order.webshopcredentials.description}`,
            icon :{
                theme: getOrderStatusColor(props.data.order),// getOrderStatusColor(props.data), 
                content: <Inventory2Icon/>
            },
            options : [
                {
                    component: <HeaderDateBox data={props.data.order} t={i18n}/>
                },
                {
                    heading: t("webshop_reference"),
                    content: props.data.order.externalId
                },
                {
                    heading: t("products"),
                    content: props.data.order.orderLinesCount
                },
            ],
          }));
        }
      }, [data,ready]);

   
    return (
        <Sidepanel2
            tabConfig={spOrderPickingListConfig}
            // tabConfig={spOrder}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <PickingLines config={spOrderPickingListConfig.pickingLines} data={props.data} /> 
                </>
        </Sidepanel2>
    );
};

export default SpOrderPickingList;