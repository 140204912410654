import { Skeleton, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

const ShTextSkeleton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const type = props.type ?? "light";
    const variant = props.variant ?? "grey";
    const palette = props.palette ?? "normal"

    const getBackground = () => {
        if(palette === "pastel") {
            return colors.pastel[type][variant][400];
        }
        if(variant !== "grey") {
            return colors[variant][200];
        } 
        return theme.palette.mode === "dark" ? colors.grey[300]: colors.grey[100]
    }

    return (
        <Skeleton 
            sx={{
                width:1,
                height:16,
                marginRight:2, 
                bgcolor: getBackground(),
                transform:"none"
            }} 
            animation="wave"
        ></Skeleton>
    )
}

export default ShTextSkeleton;