import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import PickingLines from "./Views/PickingLines";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Close
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpPickingJobPickingList = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const pickingLinesApiRef = useGridApiRef();

    const { data } = props;

    const { t, ready, i18n } = useTranslation();

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: "green",
            content: <Inventory2Icon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && ready) {
          const dateTime = getTranslatedDateAndTime(props.data.pickingJob.createdAt, t);
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: `Batch ${props.data.pickingJob.id}`,
            subTitle: `${props.data.pickingJob.warehouse} - ${props.data.pickingJob.orderCount} orders`,
            icon :{
                theme: "blue",// getOrderStatusColor(props.data), 
                content: <Inventory2Icon/>
            },
            options : [
                {
                    heading: t("created"),
                    content: `${dateTime.date} ${dateTime.time}`
                },
                {
                    heading: t("appointed_to"),
                    content: props.data.pickingJob.userName
                },
                {
                    heading: t("type"),
                    content: props.data.pickingJob.type
                },
            ],
          }));
        }
      }, [data,ready]);

   
    return (
        <Sidepanel
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <PickingLines data={props.data} apiRef={pickingLinesApiRef}/> 
                </>
        </Sidepanel>
    );
};

export default SpPickingJobPickingList;