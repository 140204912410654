import { Box, useTheme,List, ListItem, ListItemButton, ListItemText, } from "@mui/material"
import { tokens } from "../../../../../theme";
import Shbutton from "../../../buttons/Shbutton";
import Subheading from "../../../text/Subheading";

import SortIcon from '@mui/icons-material/Sort';
import CheckIcon from '@mui/icons-material/Check';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShGridSortedBy } from "../../../../../newapi/global/filter/gridFilterSlice";
import { useGridFilter } from "../../../../../contexts/GridFilterContext";

const SortView = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { index,id } = useGridFilter();
    
    const dispatch = useDispatch();
    const filterObject = useSelector((state) => state.gridFilter.viewInstances[index][id]);

    const [view, setView] = useState({
        title: "Sorteren",
        icon: <SettingsOutlinedIcon/>,
    })
    

    useEffect(() => {
        props.addView({
            ...view,
            apiRef: props.apiRef
        },false);

        return () => {
            console.log("unmounting warehouse");
        }
    },[]);
    
    const changeSortType=(sortType) => {
        if(filterObject.sortedBy.length >0) {
            dispatch(setShGridSortedBy({
                index,
                id:id,
                sortedBy: [
                    {
                        column: filterObject.sortedBy[0].column,
                        sort: sortType,
                    }
                ]
            }))
        } 
    }

    const sortBy=(column) => {
        let activeSortType = null;
        if(filterObject.sortedBy.length >0) {
            activeSortType = filterObject.sortedBy[0].sort;
        }
        dispatch(setShGridSortedBy({
            id:id,
            sortedBy: [
                {
                    column: column.column,
                    sort: activeSortType != null ? activeSortType : "asc",
                }
            ]
        }))
    }

    return (
        props.isActive ?
        <Box
            sx={{
                padding:1.5,
                width: "520px",
                maxHeight:"400px",
                // maxHeight:"350px",
                display:"flex",
                flexDirection:"column",
                overflow:"auto",
                "@media screen and (max-width: 47.9375em)" : {
                    width:1,
                }
            }}
        >
            <Box 
            sx={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
                position:"relative",
                textAlign:"center",
                paddingTop:1.5,
                paddingX:1.5,
            }}>

                <Subheading>Sorteren</Subheading>

                <Box
                    sx={{
                        // position:"absolute",
                        top:16,
                        left:24,
                        display:"flex",
                        gap:1,
                        alignItems:"center"
                    }}
                >

{/* // () => props.changeSortType([{type: "asc", sort: true}, {type:"desc", sort:false}]) */}
{/* () => props.changeSortType([{type: "asc", sort: false}, {type:"desc", sort:true}]) */}
{/* props.sortTypes[0].sort ? "primary" : "primaryGrey" */}
                    <Shbutton  size="32" onClick={() => changeSortType("asc")} icon color={filterObject.sortedBy.length > 0 && filterObject.sortedBy[0].sort === "asc" ? "primary" :  "primaryGrey"} variant="contained"><SortIcon fontSize="small" /></Shbutton>
                    <Shbutton  size="32" onClick={() => changeSortType("desc")}  icon color={filterObject.sortedBy.length > 0 && filterObject.sortedBy[0].sort === "desc" ? "primary" :"primaryGrey"} flipped variant="contained"><SortIcon fontSize="small" /></Shbutton>

                </Box>
                    
            
            </Box>
            <Box
                >
                    <List
                        sx={{
                            padding:0,
                        }}
                    >
                    {filterObject.columns.map((column, index) => (

                        <ListItem key={column.column + "index"} disablePadding onClick={() => sortBy(column)}>
                            <ListItemButton 
                                sx={{
                                    color:`${filterObject.sortedBy.length > 0 && filterObject.sortedBy[0].column === column.column ? theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] : colors.txt["secondary"]}`,
                                    fontWeight:`${filterObject.sortedBy.length > 0 && filterObject.sortedBy[0].column === column.column ? 500 : 400}`,
                                    display:"flex",
                                    justifyContent:"space-between",
                                    fontSize:"14px",
                                    paddingX:1.5,
                                    height:40,
                                    borderRadius:5,
                                }}
                            >
                                {column.column}

                                {filterObject.sortedBy.length > 0 && filterObject.sortedBy[0].column === column.column
                                    ?
                                    <CheckIcon fontSize="small"/>
                                    :
                                    null
                                }
                            </ListItemButton>
                        </ListItem>
                        
                    ))}
                    </List>
                </Box>
                
        </Box>
        :null
    )
}
export default SortView;