import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Text from "../../../../components/theme/text/Text";
import { useGetProductsQuery } from "../../../../newapi/warehouse/productSlice";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import RelationProductCard from "../../../../components/theme/cards/products/relation/RelationProductCard";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const ProductOverview = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({isVirtual: false});
    const [isExtended, setIsExtended] = useState(false);



    const columns = [
        {
            field: "sku",
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box>
                        <Text semibold>{params.row.sku}</Text>
                    </Box>
                );
            }
        },
        {
            field: "description",
            headerName: t("description"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.description
            },
        },
        {
            field: "barcode",
            headerName: t("barcode"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.barcode
            },
        },
        {
            field: "secondary_sku",
            headerName: t("secondary_sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.secondary_sku
            },
        },
        {
            field: "stock_quantity",
            headerName: t("stock_quantity"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.stock && params.row.stock.total_quantity ? params.row.stock.total_quantity : 0);
            },
        },
        {
            field: "stock_available",
            headerName: t("stock_available"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.stock && params.row.stock.total_available ? params.row.stock.total_available : 0);
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    
                    <RelationProductCard
                        skeleton={cellValues.row.skeleton}
                        {...cellValues.row}
                    />
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2
                content={useGetProductsQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('overview')} 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}
                rows={data}>
            </ShDataGrid2>
        </Box>
    );
}
export default ProductOverview;