import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import ShSwitch from "../../theme/inputs/ShSwitch";
import Text from "../../theme/text/Text";
import { useTranslation } from "react-i18next";
import { useUpdateSettingMutation } from "../../../newapi/global/generalApiSlice";


// variants
//    1: default has both a title and subtitle
//    2: has no subtitle ans text is light
const BoolAuthSetting = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [val, setVal] = useState(props.val); // Initialize state with props.val
  const [disabled, setDisabled] = useState(props.disabled); // Initialize state with props.disabled

  const handleBoxClick = () => {
    if (!disabled) {
      setVal(!val);
    }
  };

  // Only call props.onChange if the new value is different from the initial prop value
  useEffect(() => {
    if (val !== props.val) {
      props.onChange(val);
    }
  }, [val]);

  // Update local state if the prop changes, but avoid triggering onChange
  useEffect(() => {
    if (val !== props.val) {
      setVal(props.val);
    }
  }, [props.val]);

  useEffect(() => {
    if (disabled !== props.disabled) {
      setDisabled(props.disabled);
    }
  }, [props.disabled]);

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
        height: 40,
      }}
      onClick={handleBoxClick} // Add the click handler to the Box
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          cursor: "pointer",
        }}
      >
        {<Text noBreak light={true} size={"fs13"}>{t(`${props.title}`)}</Text>}
      </Box>
      <ShSwitch
        checked={val}
        disabled={disabled}
        onChange={(e) => {
          e.stopPropagation(); // Prevent click events from bubbling up from the switch
          setVal(e.target.checked);
        }}
        color={theme.palette.mode === "dark" ? "blue" : "primary"}
      />
    </Box>
  );
};

export default BoolAuthSetting;