import { Box, Button, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, Menu,MenuItem, Paper, Popover, Popper } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiContext, useGridApiRef } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import { mockDataContacts } from "../../data/mockData";
import { useTheme } from "@mui/material";
import Subheading from "../../../components/theme/text/Subheading";
import React, { createElement, useCallback, useContext, useEffect, useRef, useState } from "react";

// import GridFilter from "../components/theme/datagrid/GridFilter";
import { tokens } from "../../../theme";
import GridFilter from "./GridFilter";
import Text from "../text/Text";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { MenuStateContext } from "../../../contexts/MenuState";
import ShGridHeader from "./ShGridHeader";

import cx from 'classnames';
import ShGridEmpty from "./Status/ShGridEmpty";
import ShGridError from "./Status/ShGridError";

function sleep(duration) {
    return new Promise((resolve) => {
        setTimeout(() => {
        resolve();
        }, duration);
    });
}
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const getHiddenColumns = (columns) => {
    let toHide = [];
    for(let i = 0; i < columns.length; i++) { 
        if(typeof columns[i].hidden !== "undefined" && columns[i].hidden) {
            toHide.push(columns[i].field);
        }
    }
    return toHide;
}

const ShDataGrid = (props) => {
    if(props.selectable && typeof props.setSelected === "undefined") throw new Error("setSelected not implemented when selectable is set to true");
    const filterObject = useSelector((state) => state.gridFilter);
    // console.log("filterobj",filterObject);

    // MenuStateContext
    const {collapsed} = useContext(MenuStateContext);
    const [bottomInView, setBottomInView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadedRows, setLoadedRows] = useState([]);
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const mounted = useRef(true);
    const gridRef = useRef();

    const [rowHeight, setRowHeight] = useState(64);
    const [columnHeaderHeight, setColumnHeaderHeight] = useState(32);
    const [footerHeight, setFooterHeight] = useState(72);
    
    // const apiRef = props.apiRef;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const columns = props.columns;

    const [pinnedColumns, setPinnedColumns] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const onSelectionModelChange = (newSelectionModel) => {
        setSelectedItems(newSelectionModel);

        if(typeof props.setSelected !== "undefined"){
            props.setSelected(newSelectionModel);
        }
    };

    const originalClassNames = useRef(props.columns.map(column => column.cellClassName || ''));

    const getCellClassName = (column, index) => {
        let assetClass = "";
        if (pinnedColumns.some(pinnedColumn => pinnedColumn.field === column.field)) {
            assetClass = "fixed-left";
        }
        return `${originalClassNames.current[index]} ${assetClass}`.trim();
    };

    const pinColumn = (column) => {
        var colWidth = getColWidth(column);
        setPinnedColumns((currentPinnedColumns) => [
          ...currentPinnedColumns,
          { field: column, width: colWidth},
        ]);
        setOverlayWidth((oldWidth) => oldWidth + colWidth);
    };


    const unpinColumn = (column) => {
        const columnToUnpin = pinnedColumns.find((col) => col.field === column);
        if (columnToUnpin) {
          const colWidth = columnToUnpin.width;
          setPinnedColumns((currentPinnedColumns) =>
            currentPinnedColumns.filter((col) => col.field !== column)
          );
          setOverlayWidth((oldWidth) => oldWidth - colWidth);
        }
    };

    const applyCustomHeader = (columns, customHeaderComponent) =>
        columns.map((column, index) => ({
            ...column,
            cellClassName: getCellClassName(column, index),
            renderHeader: (params) => createElement(customHeaderComponent, {
                ...params,
                pinColumn,
                unpinColumn,
                isPinned: pinnedColumns.some(pinnedColumn => pinnedColumn.field === column.field)
            }),
        }));

    const language = useSelector((state) => state.settings.language);
    const [columns,setColumns] = useState(applyCustomHeader(props.columns, ShGridHeader));

    useEffect(() => {
        setColumns(applyCustomHeader(props.columns, ShGridHeader));
        setColumnVisibilityModel(createModel(window.innerWidth));
        setScrollOffset(pinnedColumns);
    }, [pinnedColumns,language]);


    const hiddenColumns = getHiddenColumns(columns) ?? [];
    // const rows = props.rows;
    const [rows, setRows] = useState(props.rows ?? []);
    const isSidepanelGrid = props.isSidepanelGrid;
    
    // let getContent = pfilterOptionssrops.content;
    
    // const searchString = useSelector((state) => state.gridFilter.searchString);

    
    const searchString = useSelector((state) => state.gridFilter.searchString);

    // Initialize config with props.config or an empty object
    let [config, setConfig] = useState(props.config ?? {});
    
    // Update config with searchString whenever it changes
    useEffect(() => {
        // Directly set the searchString in the new config object
        const newConfig = { ...config, searchString: searchString };
        setConfig(newConfig);
    }, [searchString]); 
    
    

    const [pageSize, setPageSize] = useState(20); //this

    
    const [itemsLoaded, setItemsLoaded] = useState();
    const [totalItems, setTotalItems] = useState();
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    // Used the set page size max for internal datagrid
    const [paginationState,setPaginationState] = useState({
        pagination: { 
            paginationModel: { 
                pageSize: pageSize
            } 
        },
        // pinnedColumns: { 
        //     left: ['sku2','locatie'], 
        //     // right: ['actions'] 
        // } 
    });

    const [invalidateCache,setInvalidateCache] = useState(true);

    const [itemId, setItemId] = useState(props.itemId ?? null);
    // console.log(typeof props.content);

    const [filterOptions, setFilterOptions] = useState("");
    const [initialized, setInitialized] = useState(false);

    const {
        data,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = typeof props.content !== "undefined" ? props.content({
        args: {
            ...(itemId !== null && {id:itemId}),
            page:activePage,
            size: pageSize,
            body: config
        },
        invalidate: invalidateCache
    }, {
        skip: !pageSize || pageSize < 0
    }) : {}

      // Define a function to generate skeleton rows
    const generateSkeletonRows = () => {
        // Return an array of rows that are clearly marked as skeletons
        // For example, each row could have a `skeleton: true` property
        return [...Array(7)].map((_, index) => ({ id: `skeleton-${index}`, skeleton: true }));
    };

    useEffect(() => {
        if(isFetching) {
            setRows(generateSkeletonRows());
        } else {
            if (data) {
                setRows(data.data);
                // setRows(rows => {
                //     console.log("hewwo",data.data);
                //     return rows.concat(data.data);
                // });
                setItemsLoaded(amount => {
                    return amount ?? 0 + data?.data?.length ?? 0;
                })
                setTotalItems(data.totalItems);
                setTotalPages(data.totalPages);
                if(!initialized) {
                    setInitialized(true);
                }
            } else {
                setRows([]);
            }
        }
    }, [data, isFetching]); // Watch for changes in data

    useEffect(() => {
        return () => {
            mounted.current = true;
          };
    }, []);


    useEffect(() => {
        if(initialized && bottomInView && itemsLoaded !== totalItems) {
            setInvalidateCache(false);
            setActivePage((page) => {
                return page+=1;
            });
        }
    }, [bottomInView])
    // useEffect(() => {
    //     if(gridRef.current != null) {
    //         var pageRect= gridRef.current.getBoundingClientRect();
    //         var height = pageRect.height - columnHeaderHeight - footerHeight;
    //         var maxRows = Math.floor(height / rowHeight);
    //         console.log(maxRows);
    //         setPageSize(maxRows);
    //         // console.log("maxRows ",maxRows);
            
    //     }
    // }, [gridRef.current])

    //Mapping field data
    const columnDataFieldMap = columns.reduce((acc, col) => {
        acc[col.field] = col.dataField || col.field;
        return acc;
      }, {});

    const createModel = (windowSize) => {
        let model = {};
        if(typeof rows !== "undefined" && rows.length > 0) {
            let keys = Object.keys(rows[0]);
            for(let i = 0; i < keys.length; i++) {
                if(keys[i] !== "phoneView") {
                    model[keys[i]] = windowSize > 767 ? true : false;
                } else {
                    model[keys[i]] = windowSize > 767 ? false : true;
                }
            }
            // console.log("blue",rows[0]);

            for(let i = 0; i < hiddenColumns.length; i++) {
                model[hiddenColumns[i]] = false;
            }

            for(let i = 0; i < pinnedColumns.length; i++) {
                model[columnDataFieldMap[pinnedColumns[i].field]] = false;
            }
        } else {
            for(let i = 0; i < columns.length; i++) {
                if(columns[i].field !== "phoneView") {
                    model[columns[i].field] = windowSize > 767 ? true : false;
                } else {
                    model[columns[i].field] = windowSize > 767 ? false : true;
                }
            }
        }
        return model;
    }
    

    const handleResize = () => {
        setColumnVisibilityModel(createModel(window.innerWidth));
        setWindowSize(window.innerWidth);

        // if(window.innerWidth < 768) {
        //     // console.log("blab", props.rows);
        //     setPaginationState({
        //         pagination: { 
        //             paginationModel: { 
        //                 pageSize: props.rows.length
        //             } 
        //         },
        //     });
        // } else {
        //     setPaginationState({
        //         pagination: { 
        //             paginationModel: { 
        //                 pageSize: 14
        //             } 
        //         },
        //     });
        // }
    }
     
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      
      return () => {
        window.removeEventListener("resize", handleResize);
      }
    },[])

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(createModel(window.innerWidth));


    // const showRows = () => {
    //     if(typeof rows !== "undefined" && rows.length > 0) {

    //         return rows.concat(loadedRows);
    //     }
    //     return [];
    // }
    // useEffect(() => {
    //     // const subscription = props.apiRef.current.onRowsChange((newRows) => {
    //     //     console.log('Rows have changed:', newRows);
    //     //   });
      
    //     //   return () => {
    //     //     if (subscription) {
    //     //       subscription.unsubscribe();
    //     //     }
    //     //   };
    //     // console.log(props.apiRef.current.getRowModels());

    // },[props.apiRef])

    // const randPageSize = () => {
    //     setTimeout(3000);
    //     return randomIntFromInterval(0,9);
    // }

    // const [pageS, setPageS] = useState(randomIntFromInterval(0,9));
    // useEffect(() => {
    //     const timer = setTimeout(() => setPageS(randomIntFromInterval(0,9)), 3000)
    //     return () => clearTimeout(timer)
    //    }, [pageS])
    const prevPage = () => {
        if(activePage > 1) {
            setInvalidateCache(false);
            setActivePage((page) => {
                return page -=1
            });
        }
    }
    const nextPage = () => {
        if(activePage < totalPages) {
            setInvalidateCache(false);
            setActivePage((page) => {
                return page+=1;
            });
        }
    }

    const customSort = (model) => {
        // console.log(model);
    }
    
    const [fixedHeader, setFixedHeader] = useState(false);
    const refContainer = useRef(null);
  
    const checkScrollPosition = () => {
      if (refContainer.current) {
        const topPosition = refContainer.current.getBoundingClientRect().top - 76;
        setFixedHeader(topPosition <= 0);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', checkScrollPosition);
  
      return () => {
        window.removeEventListener('scroll', checkScrollPosition);
      };
    }, []);

    const [overlayWidth, setOverlayWidth] = useState(0);
    const colRefs = useRef(null);

    const getColWidth = (column) => {
        if (colRefs.current) {
            const gridRoot = colRefs.current;
            const columnElements = gridRoot.querySelectorAll('.MuiDataGrid-columnHeader');
            const targetColumn = Array.from(columnElements).find(el => el.getAttribute('data-field') === column);
            if (targetColumn) {
                return targetColumn.offsetWidth;
            }
           
        }
        return -1;
    }

    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

    //Get value based on field data path
    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    const getColDef =(pinnedColField) => {
        for(var i = 0; i < columns.length; i++) {
            if(columns[i].field === pinnedColField) {
                return columns[i];
            }
        }
        throw new Error("Unknown column in pinned selection");
    }


    const setScrollOffset = (pinnedColumns) => {
        if(colRefs.current) {
            const gridRoot = colRefs.current;
            const extraPadOffset = pinnedColumns.length > 0 ? 16 : 0;
            
            //Content body
            const contentRenderZone = gridRoot.querySelectorAll(".MuiDataGrid-virtualScrollerRenderZone");
            contentRenderZone.forEach(element => {
                element.style.cssText = `margin-left:${overlayWidth + extraPadOffset}px;`;
            });

            //Content header
            const headerZone = gridRoot.querySelectorAll(".MuiDataGrid-columnHeadersInner");
            headerZone.forEach(element => {
                element.style.cssText = `margin-left:${overlayWidth + extraPadOffset}px;`;
            })
        }
    }


    const handleMouseEnter = (event) => {
        setHoveredRowId(event.target.getAttribute("data-id"));
    }
    const handleMouseLeave = () => {
        setHoveredRowId(null);
    }
    

    const onRowClick = (row) => {
        if(!row.row.skeleton){
            setSelectedRowId(row.id);
            if(typeof props.onRowClick !== "undefined"){
                props.onRowClick(row);
            }
        }
    }
    
    useEffect(() => {
        if (colRefs.current) {
            // alert("owo");
            const rows = colRefs.current.querySelectorAll('.MuiDataGrid-row');
            rows.forEach(row => {
                row.addEventListener('mouseenter', handleMouseEnter);
                row.addEventListener('mouseleave', handleMouseLeave);
                // row.addEventListener('click',handleClick);
            });
        };


        return () => {
            if (colRefs.current) {
                const rows = colRefs.current.querySelectorAll('.MuiDataGrid-row');
                rows.forEach(row => {
                    row.removeEventListener('mouseenter', handleMouseEnter);
                    row.removeEventListener('mouseleave', handleMouseLeave);
                    // row.addEventListener('click',handleClick);
                });
            }
        };
    }, [colRefs.current, pinnedColumns]);


    return (
    <Box ref={refContainer} height={1} display={"flex"} flexDirection={"column"}>
        {isSidepanelGrid && windowSize > 767 
            ?
            <Box 
                display="flex" 
                justifyContent="space-between" 
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]} 
                alignItems="center" 
                paddingX={3} 
                paddingY={3}
                sx={
                    fixedHeader ?
                    {
                        position:"fixed",
                        width:collapsed ? 1 : "calc(100% - 342px)",
                        left:collapsed ? 0 : 342,
                        top:72,
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        zIndex:300,
                        borderTopLeftRadius:24,
                        borderTopRightRadius:24,
                        transition:"left 500ms",
                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"0px",
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            // transition:"250ms"
                        },

                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"0px",
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            // transition:"250ms"
                        },
                        "@media screen and (max-width: 85.375em)" : {
                            width:1,
                            left:0,
                        }
                    } : {
                    }
                }
            >
                {props.title !== undefined && props.title !== "" && <Subheading>{props.title}</Subheading>}
                {props.gridOptions.hide === "undefined" || !props.gridOptions.hide ?
                    <GridFilter 
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                        gridOptions={props.gridOptions} 
                        gridActions={props.gridActions}
                        apiRef={props.apiRef}
                        pinnedColumns={pinnedColumns}
                        hiddenColumns={hiddenColumns}
                    >
                     </GridFilter>
                : null
                }
            </Box>
            : 
            null 
        }

        <Box
            ref={gridRef}
            m="0 24px 0 24px" 
            paddingTop={fixedHeader ? "88px" : null}
            flex={1}
            sx={{
                // display:"flex",
                position:"relative",

                "@media screen and (max-width: 47.937em)" : {
                    margin: "0 !important",
                },

                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    // borderBottom: "none !important",
                    borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                    color: `${colors.txt["primary"]} !important`,
                    fontWeight: 400,
                    "& .MuiCheckbox-root": {
                        paddingLeft:0,
                        color:`${theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[300]}`,
                        "&.Mui-checked" : {
                            color: `${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`
                        }
                    },
                },
                "& .MuiDataGrid-cell:focus": {
                    outlineColor: "transparent !important"
                },
                "& .name-column--cell": {
                    // color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-toolbarContainer": {
                    height:0,
                    padding:0,
                    opacity: 0,
                    display:"none"
                },
                "& .MuiDataGrid-columnHeaders": {
                    // borderBottom: "none !important",
                    borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                    height: `${columnHeaderHeight}px !important`,
                    minHeight: `${columnHeaderHeight}px !important`,
                    lineHeight: `${columnHeaderHeight}px !important`,
                    maxHeight: `${columnHeaderHeight}px !important`,
                    "& .MuiDataGrid-columnHeaderTitleContainerContent" :{
                        width:1,
                        "& .MuiCheckbox-root" : {
                            // paddingLeft:0,
                            color:`${theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[300]}`,
                            "&.Mui-checked" : {
                                color: `${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`
                            },
                            "&:focus" : {
                                outline:"hidden"
                            }
                            // background:"orange"
                        }
                    }
                },
                "& .MuiDataGrid-columnHeader": {
                    // backgroundColor:"orange !important",
                    color: `${colors.txt["secondary"]} !important`,
                    height: `${columnHeaderHeight}px !important`, 
                    minHeight: `${columnHeaderHeight}px !important`,
                    lineHeight: `${columnHeaderHeight}px !important`,
                    maxHeight: `${columnHeaderHeight}px !important`,
                    "&:focus-within" : {
                        outline:"none !important",
                    },
                    "&:focus-visible": {
                        outline:`solid ${colors.primary[400]} !important`,
                        outlineWidth:"1px !important"
                    }
                },
                "& .MuiDataGrid-columnHeader--sorted" : {
                    color: `${colors.txt["primary"]} !important`,
                },
                "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle" : {

                    fontWeight:"500 !important"
                },
                "& .MuiDataGrid-columnHeader:focus" : {
                    outlineColor: "transparent !important"
                },
                "& .MuiDataGrid-columnHeaderTitle" : {
                    fontWeight: 400
                },
                "& .MuiDataGrid-root" : {
                    border:"none",
                },
                "& .MuiDataGrid-virtualScroller" : {
                    // background:"orange",
                    // marginTop: "32px !important",
                },
                " .MuiDataGrid-row" : {
                    /** Classes */
                    "&.Mui-selected":{
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]} !important`
                    },
                    "&:hover":{
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`
                    },
                    "& .MuiDataGrid-cell": {
                        "&.fixed-left" : {
                            visibility:"hidden",
                            opacity:0,
                            background:"orange",
                            position:"sticky",
                            left:"0"
                        }
                    }
                },

                "& .MuiDataGrid-footerContainer": {
                    display:"none",
                    borderTop: "none",
                    // backgroundColor: colors.blueAccent[700]
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                },
                "& .MuiDataGrid-columnSeparator, & .MuiDataGrid-menuIcon, & .MuiDataGrid-iconButtonContainer" : {
                    display:"none !important"
                },
                "@media sceen and (min-width:47.9375em)" : {
                 
                    ".phone-column--cell": {
                        display:"none !important",
                    },   
                },
                ".MuiDataGrid-columnHeader[aria-label=PhoneView]" : {
                    background:"blue",
                    // display:"none"
                },
                ".MuiDataGrid-overlayWrapper" :{
                    minHeight:300,
                    ".MuiDataGrid-overlayWrapperInner" : {
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                    },
                },
                "@media screen and (max-width: 47.9375em)" : {
                    "& .MuiDataGrid-root" : {
                        height:"100% !important",

                        "& .MuiDataGrid-main" : {
                            height:"100% !important",
                            overflow:"unset !important",

                            "& .MuiDataGrid-columnHeaders": {
                                // borderBottom: "none !important",
                                    display:"none"
                            },

                            " > div:nth-of-type(2)" : {
                                height:"100% !important",
                                width: "100% !important"
                            }
                        }
                    },
                    "& .MuiDataGrid-virtualScroller" : {
                        height:"100% !important",
                        overflowY:"auto !important",
                        marginTop: "0px !important",

                        "& .MuiDataGrid-virtualScrollerContent" : {
                            height:"100% !important",
                            width: "100% !important",

                            "& .MuiDataGrid-virtualScrollerRenderZone" : {
                                gap:"16px",
                                padding: "24px !important",
                                width:1,

                                "& .MuiDataGrid-row" : {
                                    flexWrap:"wrap !important",
                                    maxHeight: "unset !important",
                                    minHeight: "unset !important",
                                    width:"100% !important",
                                    borderRadius:2,

                                    "& .MuiDataGrid-cell": {
                                        maxWidth: "unset !important",
                                        maxHeight: "unset !important",
                                        minHeight: "unset !important",
                                        minWidth: "unset !important",
                                        display: "none",
                                        padding:0,

                                        "&.phone-column--cell" : {
                                            display:"block !important",
                                            width:"100%",
                                            borderBottom: "none !important",
                                            overflow:"visible"
                                        },
                                        // "& .MuiCheckbox-root": {
                                        //     paddingLeft:0,
                                        //     color:"orange",
                                        // },
                                    }
                                },
                            }
                        }
                    },
                    "& .MuiDataGrid-footerContainer": {
                        // display:"none"
                    }
                }
            }}


        >
            {pinnedColumns.length > 0 ?
                <Box
                    sx={{
                        position:"absolute",
                        // background:"blue",
                        top:fixedHeader ? 88 : 0,
                        left:0,
                        height:`${fixedHeader ? "calc(100% - 88px)" : 1}`,
                        width:`${overlayWidth}px`,
                        zIndex:200,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"flex-start",
                        flexDirection:"column",
                        boxShadow: `2px 0px 19px 0px ${theme.palette.mode === "dark" ? "rgba(255,255,255,.05)" : "rgba(49, 65, 95, 0.15)"}`,
                        "&:before" : {
                            content: "''",
                            width:"calc(100% + 16px)",
                            height: "calc(100% + 16px)",
                            top:-16,
                            left:-16,
                            background:"transparent",
                            position:"absolute",
                            borderLeft:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`,
                            borderTop:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`,
                            zIndex:-1
                        },
                        "&:after" : {
                            content: "''",
                            width:"calc(100% + 16px)",
                            height: 16,
                            bottom:-16,
                            left:-16,
                            background:"transparent",
                            position:"absolute",
                            borderLeft:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`,
                            borderTop:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`
                        }
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                        }}
                    >
                        {pinnedColumns.map(pinned => {
                            
                            return(
                                <Box
                                    sx={{
                                        minWidth: pinned.width,
                                        maxWidth: pinned.width,
                                        background:theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]
                                    }}
                                    key={pinned.field}
                                >
                                    <Box
                                        sx={{
                                            background:theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"],
                                            height: `${columnHeaderHeight}px !important`,
                                            minHeight: `${columnHeaderHeight}px !important`,
                                            lineHeight: `${columnHeaderHeight}px !important`,
                                            maxHeight: `${columnHeaderHeight}px !important`,
                                            borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                            color: `${colors.txt["secondary"]} !important`,
                                            fontWeight: 400,
                                        }}
                                    >
                                        <ShGridHeader isPinned unpinColumn={unpinColumn} pinColumn={pinColumn} colDef={getColDef(pinned.field)}/>
                                    
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                     
                    {
                        rows.map((row,index) => {

                            return (
                                <Box
                                    data-id={row.id}
                                    className={cx('SH_PinRow', {
                                        'sh_pin_row_hovered': hoveredRowId == row.id,
                                        'sh_pin_row_selected': selectedRowId == row.id,
                                    })}
                                    // className={"SH_PinRow"}
                                    sx={{
                                        display:"flex",
                                        background:theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"],
                                        position:"relative",
                                        "&:after" : {
                                            content:"''",
                                            position:"absolute",
                                            right:-16,
                                            height:1,
                                            width:16,
                                            // zIndex:2
                                            zIndex:-1

                                        },
                                        "&.sh_pin_row_hovered, &.sh_pin_row_selected" : {
                                            // background:"blue"
                                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                            boxShadow: `2px 0px 19px 0px ${theme.palette.mode === "dark" ? "rgba(255,255,255,.05)" : "rgba(49, 65, 95, 0.15)"}`,
                                            "&:after" : {
                                                backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                            }
                                        },
                                    }}
                                    key={index + "mui-pin-row"}
                                >
                                    {pinnedColumns.map(pinned => {
                                        const colDef = columns.find(col => col.field === pinned.field);
                                        const cellValue = getNestedValue(row, columnDataFieldMap[pinned.field]);
                                        const renderCellParams = {
                                            id: row.id,
                                            field: pinned.field,
                                            value: cellValue,
                                            row: row,
                                            colDef: colDef,
                                            cellMode: 'view',
                                            isEditable: false, // Assuming non-editable in this context
                                            hasFocus: false,
                                            tabIndex: -1,
                                            // getValue: (id, field) => /* Implement logic to get value */,
                                            api: null, // You might not have access to the full Grid API
                                            cellElement: null, // Not available in this context
                                            formattedValue: cellValue, // Assuming no formatting
                                            isCellEditable: () => false,
                                        };
                                        return (
                                            <Box
                                                key={"sh_row_col"+pinned.field+row.id}
                                                sx={{
                                                    minWidth: pinned.width,
                                                    maxWidth: pinned.width,
                                                    height:rowHeight,
                                                    display:"flex",
                                                    alignItems:"center",
                                                    color: `${colors.txt["primary"]}`,
                                                    fontWeight: 400,
                                                    overflow:"hidden",
                                                    whiteSpace:"nowrap",
                                                    borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                                }}
                                            >
                                                {colDef && colDef.renderCell ? colDef.renderCell(renderCellParams) : cellValue}
                                            </Box>
                                        )
                                    })}
                                </Box>
                            )
                        })
                    }
                   
                </Box>
            : null }

            {!isError ? 
                <DataGrid
                    ref={colRefs}
                    apiRef={props.apiRef}
                    // rows={[]}
                    onRowClick={onRowClick}
                    rows={rows}
                    onSortModelChange={customSort}
                    columns={columns}
                    components={{ Toolbar: GridToolbar}}
                    loading={loading}
                    // autoHeight
                    initialState={paginationState}
                    // pageSizeOptions={[5, 9]}
                    // hideFooterPagination
                    // pagination={{paginationModel: { pageSize: 12 }}}
                    disableMultipleColumnsFiltering={false}
                    rowHeight={rowHeight}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>{
                        setColumnVisibilityModel(newModel)
                    }}
                    checkboxSelection={props.selectable}
                    selectionM
                    onRowSelectionModelChange={(newSelectionModel) => {
                        onSelectionModelChange(newSelectionModel);
                    }}
                    rowSelectionModel={selectedItems}
                    slots={{
                    noRowsOverlay:() => <ShGridEmpty/>
                    }}
                    // onSelectionModelChange={onSelectionModelChange}
                    
                />
                :
                <ShGridError/>
            }
            
            {/* <BottomInView bottomInView={bottomInView} setBottomInView={setBottomInView} /> */}
            
        </Box>


        {/* Botttombar */}
        {rows.length > 0 &&
            <Box 
                className="bobbi"
                sx={{
                    display:"flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height:`${footerHeight}px`,
                    gap:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        position:"fixed",
                        bottom:104,
                        // background:theme.palette.mode === "dark" ? colors.grey[100] : colors.bg["secondary"],
                        background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        height:48,
                        // paddingRight:2,
                        left:"50%",
                        transform:"translateX(-50%)",
                        borderRadius:8,
                        boxShadow: `0px 0px 10px 0px ${theme.palette.mode === "dark" ? colors.txt["secondary"] : "rgba(49, 65, 95, 0.35)"}`
                    }
                }}
            >
                {/* {Math.ceil(tota)} */}
                {/* <Text>12 - 1200</Text> */}
                <Box
                    sx={{
                        paddingX:2,
                        whiteSpace:"nowrap",

                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                    }}
                >
                    <Text fontWeight="500">{activePage*pageSize - (pageSize-1)} - {activePage < totalPages ? activePage*pageSize : totalItems}</Text> <Text light>of</Text> <Text fontWeight="500">{totalItems}</Text>
                </Box>

                <Box
                component={"button"}
                type="button"
                    className="active"
                    sx={{
                        height:40,
                        width:40,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        borderRadius:12,
                        color: colors.txt["secondary"],
                        position: "relative",
                        cursor: "pointer",
                        userSelect:"none",
                        transition:"250ms",
                        border:"none",
                        background:"transparent",

                        "& .prev_icon" : {
                            position: "absolute",
                            left:20
                        },

                        "&:hover" : {
                            background: colors.grey[50],
                            transition:"250ms",

                        },
                        "&:focus" : {
                            background:"transparent"
                        },
                        "@media screen and (max-width: 47.9375em)" : {
                            width:48,
                            height:48
                        }
                    }}
                    onClick={() => prevPage()}
                >
                    <ArrowBackIos className="prev_icon" fontSize="14"/>
                </Box>

                <Box
                    sx={{
                        display:"none",

                        "@media screen and (max-width: 47.9375em)" : {
                            display:"flex"
                        }
                    }}
                >
                    <Text fontWeight="500">
                        {activePage < 10 ? "0" + activePage : activePage}
                    </Text> 
                </Box>

                <Box
                component={"button"}
                type="button"
                    className="active"
                    sx={{
                        height:40,
                        width:40,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        borderRadius:12,
                        color: colors.txt["secondary"],
                        position: "relative",
                        cursor: "pointer",
                        userSelect:"none",
                        transition:"250ms",
                        border:"none",
                        background:"transparent",

                        "& .prev_icon" : {
                            position: "absolute",
                            left:12
                        },

                        "&:hover" : {
                            background: colors.grey[50],
                            transition:"250ms",

                        },
                        "&:focus" : {
                            background:"transparent"
                        },

                        "@media screen and (max-width: 47.9375em)" : {
                            width:48,
                            height:48
                        }
                    }}
                    onClick={() => nextPage()}
                >
                    <ArrowForwardIos className="next_icon" fontSize="14"/>
                </Box>
            </Box>
        }
    </Box>
    );
}

export default ShDataGrid;

// import React, { useEffect, useRef, useState } from 'react';

export const BottomInView = (props) => {
//   const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        props.setBottomInView(entry.isIntersecting);
        // setIsInView(entry.isIntersecting);
      },
      { threshold: 1 } // Adjust this value based on how much of the item should be visible before triggering
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box
        ref={ref}
        sx={{
            height:32
    }}>
        {props.bottomInView ? (
            <div>Component is in view!</div>
        ) : (
            <div>Component is not in view.</div>
        )}
    </Box>
  );
};