import { Box, useTheme } from "@mui/material";
import Header from "../../../Header"
import Icon from "../../../theme/icons/Icon";
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import Heading from "../../../theme/text/Heading";
import Subheading from "../../../theme/text/Subheading";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Inbound from "./Views/Inbound";
import Return from "./Views/Return";
import Logs from "./Views/Logs";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spReturnDetailsConfig } from "./spReturnDetailsConfig";

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                {t("close")}
            </Shbutton>
            {/* <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primaryGrey" 
                variant="contained" 
                startIcon={<ModeEditOutlinedIcon/>}
            >
                {t("edit")}
            </Shbutton>
            <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="contained" 
                startIcon={<PictureAsPdfOutlinedIcon/>}
            >
                {t("upload_packing_list")}
            </Shbutton> */}

        </SidepanelHeaderActiongroup>
    )
}

const HeaderDateBox = (props) => {
    const [from, setFrom] = useState({});
    const [to, setTo] =  useState({});
    
    useEffect(() => {
        setFrom(getTranslatedDateAndTime(props.data.order?.date_sent, props.t));
        setTo(getTranslatedDateAndTime(props.data.createdAt, props.t));
    },[props.data])

    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"}>
                <Box width={66} marginRight={1}><Text light>Bestelling</Text></Box>
                <Box marginRight={1}><Text bold>{from?.date}</Text></Box>
                <Text>{from?.time}</Text>
            </Box>
            <Box  display={"flex"} alignItems={"center"}>
                <Box width={66}marginRight={1}><Text light>Retour</Text></Box>
                <Box marginRight={1}><Text bold>{to?.date}</Text></Box>
                <Text>{to?.time}</Text>
            </Box>
        </>
    )

}

const getReturnStatus = (data, t)  => {
    let status = data.state;
    let labelcolor = "";
    let labeltext = "";
    switch(status){
        case "announced":
            labelcolor = "blue"
            labeltext = t('announced');
            break;
        case "approved":
            labelcolor = "green"
            labeltext = t('approved');
            break;
        case "declined":
            labelcolor = "red"
            labeltext = t('declined');
            break;
        case "cancelled":
            labelcolor = "grey"
            labeltext = t('cancelled');
            break;
        case "receivedwithoutnotice":
            labelcolor = "purple"
            labeltext = t('receivedwithoutnotice');
            break;
        default:
            labelcolor = "orange"
            labeltext = t('unknown');
            break;
    }
    return {text: labeltext, color: labelcolor};
}

const SpReturnDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data } = props;

    const { t, ready, i18n } = useTranslation();

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: "green",
            content: <Inventory2Icon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && ready) {
            //const { text, color } = getReturnStatus(props.data, t);
            setPanel((prevPanel) => ({
            ...prevPanel,
            title: `${t("order")} ${props.data?.order?.webshopreference}`,
            subTitle: props.data?.reference ?? t("reference_missing"),
            icon :{
                theme: "orange", 
                content: <Inventory2Icon/>
            },
            options : [
                {
                    heading: t("order"),
                    content: props.data?.order?.webshopreference
                },
                {
                    heading: t("reference"),
                    content: props.data?.reference
                },
                {
                    heading: t("name"),
                    content: props.data?.name
                },
                {
                    heading: t("phone"),
                    content: props.data?.phone
                },
                {
                    heading: t("email"),
                    content: props.data?.email
                },
                {
                    heading: t("relation"),
                    content: props.data?.relation?.name
                },
                {
                    component: <HeaderDateBox data={props.data} t={i18n}/>
                },
                {
                    heading: t("warehouse"),
                    content: props.data?.warehouse?.name
                },
                {
                    heading: t("sku_amount"),
                    content: props.data?.skuCount
                },
                {
                    heading: t("items"),
                    content: props.data?.itemCount
                }
            ],
          }));
        }
      }, [data,ready]);

   
    return (
        <Sidepanel2
            tabConfig={spReturnDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <Return config={spReturnDetailsConfig.return} data={props?.data}/>
                    <Inbound config={spReturnDetailsConfig.inbound} data={props?.data}/>
                    <Logs config={spReturnDetailsConfig.logs} data={props?.data?.id}/> 
                </>
        </Sidepanel2>
    );
};

export default SpReturnDetails;
