import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

// import AdvancedFilterView from "./Filter/Views/AdvancedFilterView";
import PopoverModal from "../../Modal/PopoverModal";

import Inventory2Icon from '@mui/icons-material/Inventory2';
import AdvancedFilterView from "./Views/AdvancedFilterView";
import FbContextProvider from "../../../../contexts/FbState";
import TabView from "../../TabViews/TabView";
import SortView from "./Views/SortView";
import FilterSettingsView from "./Views/FilterSettingsView";

const FilterModal = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const apiRef = props.apiRef;

    // const filter = props.filter ?? {groups:[]};

    const title = "Lol";
    
    // console.log("pinnedcol",props.pinnedColumns);

    return (
        <FbContextProvider>
            <PopoverModal
                {...props}
                triangle={{position:"center", color:colors.bg["tertiary"]}}
            >
                <Box 
                    sx={{
                        background: colors.bg["tertiary"],
                        width:520, //fix
                        "@media screen and (max-width: 768px)" : {
                            width:1,
                        }
                    }}
                >
                    
                    <TabView
                        title={title}
                        // views={views}
                        tabClasses={"s56 as-tab"}
                        lighterBody
                        viewType={"popup"}
                        asTab
                    >
                        <>
                            <AdvancedFilterView {...props} apiRef={props.apiRef}/>
                            <SortView {...props}/>
                            <FilterSettingsView {...props}/>
                        </>
                    </TabView>
                </Box>

            </PopoverModal>
        </FbContextProvider>
    );
}

export default FilterModal;