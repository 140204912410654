// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { createApi,fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { JWT_TOKEN } from '../storagekeys'
import { cacheKeys } from './cacheKeys';
import { config } from './apiUrlConfig';
import { ENV_TYPES } from '../components/global/Sidebar';

export const rootSlice = createApi({
    reducerPath: "rootApi",
    baseQuery: fetchBaseQuery({ 
        baseUrl: config.url.API_ROOT_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            const warehouseId = getState().warehouse.activeId;
            const type = getState().settings.environment.selectedEnvironmentType;
            const id = getState().settings.environment.selectedId;
      
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            if (warehouseId) {
                headers.set('warehouse_id', warehouseId);
            }

            if(type === ENV_TYPES[100]){
                //CUSTOMER
                headers.set("customer_id", id);
            } else if(type === ENV_TYPES[200]) {
                //RELATION
                headers.set("relation_id", id);
            }

            headers.set("Content-Type", 'application/json');
            // config.headers['Content-Type'] = 'application/json';
            // config.headers["customer_id"] = 1;
        //   }
      
          return headers
        },
    }),
    refetchOnMountOrArgChange: false,
    endpoints: builder => ({

    })
})

// export const {useGetRandQuery} = apiSlice