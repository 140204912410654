// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../../../../theme';
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    
    const [newLocations, setNewLocations] = useState([{
        barcode: "",
        index: 1
    }]);
    const [locationCount, setLocationCount] = useState(1);

    const handleChange = (index, key, value) => {
        setNewLocations(currentLines => {
            return currentLines.map(line => {
            if (line.index === index) {
                return {
                ...line,
                [key]: value
                };
            }

            return line;
            });
        });  
    };
    
    useEffect(() => {
        const mappedLocations = newLocations
        .filter(x => x.barcode.length > 0)
        .map(x => ({
            barcode: x.barcode
        }));

        if (newLocations[newLocations.length - 1].barcode.length > 0) {
            setNewLocations([...newLocations, {
                barcode: "",
                index: newLocations[newLocations.length - 1].index + 1
            }]);
        }

        setLocationCount(mappedLocations.length);

        handleInput("locations", mappedLocations);
    },[newLocations]);

    useEffect(() => {
        handleInput("capacity", locationCount);
    },[locationCount]);
      
    const handleDeleleteLineRow = (index) => {
        const updatedLocations = newLocations.filter(line => line.index !== index);
        setNewLocations(updatedLocations);
    }

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
    };

    return (
        <>
        <Box 
            sx={{
                background: colors["blue"][100],
                color: colors["blue"][400],
                padding:2,
                width:1,
                marginTop:2,
                marginBottom:2
            }}
        >
            <Typography fontWeight={"bold"} fontSize={"17"}>
                {t("mp_add_pickupcart_locations_title")}
            </Typography>
            <Box sx={{paddingTop:0.5}}>
                <Typography fontSize={"14"}>
                {t("mp_add_pickupcart_locations_text")}
                </Typography>
            </Box>
        </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                
                {newLocations.map((location, index) => (
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap:4,
                        justifyContent:"flex-start",
                    }}
                >
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        {/* <Box
                            sx={{
                                width:40,
                            }}
                        >
                            <Text light>{t("barcode")}</Text>
                        </Box> */}
                        <Box
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <InputBase 
                                sx={{
                                    px:2,
                                    flex:1, 
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                }}
                                value={location.barcode}
                                onChange={(e) => handleChange(location.index, 'barcode', e.target.value)}
                                />
                        </Box>
                    </Box>
                
                        { location.index > 1 ?
                        <>
                        <IconButton type="button" onClick={() => handleDeleleteLineRow(location.index)} sx={{p:1}}>
                            <DeleteIcon/>
                        </IconButton>
                        </> 
                        : null
                        }
                    </Box>
                ))}
            </Box>
            <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                paddingTop:3,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('capacity')}
                    </Text>
                    
                </Box>
                <ShValidatedInput
                            name="capacity"
                            value={locationCount}
                            onChange={(key,val) => setLocationCount(val)}
                            error={props.errors.capacity}
                        />
    </Box>
            </>
    );
};

const PickupcartLocations = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
        tabId="tabLocations" 
        subtitle={t("location_barcodes")} 
        step={2}
        maxSteps={2}
    >
        <Content />
    </ProgressionTab>
    )
}

export default PickupcartLocations;

