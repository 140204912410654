import { ReactComponent as FlagNetherlands } from "../styles/svg/flags/netherlands.svg";
import { ReactComponent as FlagBritain } from "../styles/svg/flags/britain.svg";

export const localeSettings = {
    'nl': {
        language: "Nederlands",
        short:'nl',
        flag: <FlagNetherlands/>,
    },
    'en': {
        language: "English",
        short:'en',
        flag: <FlagBritain/>,
    },
}