import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useGetDashboardOrdersQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { useTranslation } from "react-i18next";
import DonutChart from "../../../theme/charts/DonutChart";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import Heading from "../../../theme/text/Heading";
import Text from "../../../theme/text/Text";


const OrderChartFooter = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const getFooterBorderColor = () => {
        if(props.isModal) return colors.grey[200];
        if(theme.palette.mode === "dark") return colors.grey[300];
        return colors.grey[100];
    }
    return (
        <Box
            className="donut-footer"
            sx={{
                display:"flex",
            }}
        >
            <Box
                // className="donut-footer"
                sx={{
                    borderRight:`2px solid ${getFooterBorderColor()}`,
                    p:3,
                    flex:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        borderRight:`2px solid ${getFooterBorderColor()}`,
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        // p:0,
                    }
                }}
            >
                <Text light>Te laat</Text>
                {props.isLoading ? 
                    <ShTextSkeleton/>
                    :
                    <Heading>{props.data?.late}</Heading>
                }
            </Box>
            <Box
                sx={{
                    p:3,
                    flex:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        // p:0,
                    }
                }}
            >
                <Text light>Geblokkeerd</Text>
                {props.isLoading ? 
                    <ShTextSkeleton/>
                    :
                    <Heading>{props.data?.blocked}</Heading>
                }
            </Box>
        </Box>
    )
}


export const OrdersChart = (props) => {
    
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, isLoading, isFetching, isError,refetch} = useGetDashboardOrdersQuery();
    
    // Function to handle retry
    const handleRetry = () => {
        refetch();
    };
    return (
        <Box
            sx={props.sx ?? {
                "@media screen and (max-width: 768px)" : {
                    gridArea: "1 / 1 / 2 / 3",
                }
            }}
        >
            <DonutChart
                hideFilter
                hideEffect
                title={t("orders")}
                subtitle={t("today")}
                type="donut"
                colors={['purple','primary','green']}
                isError={isError}
                isLoading={isLoading || isFetching}
                categories={data?.categories}
                values={data?.values}
                color={'default'}
                onRetry={handleRetry}
                footer={
                    <OrderChartFooter 
                        isLoading={isLoading || isFetching || isError} 
                        data={data}
                    />
                }
            ></DonutChart>
        </Box>
    );
}