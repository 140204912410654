import { useTranslation } from "react-i18next"

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal"
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper"
import { useCreatePickupCartMutation } from "../../../../newapi/warehouse/pickupCartSlice"
import PickupcartBasicInfo from "./views/PickupcartBasicInfo"
import PickupcartLocations from "./views/PickupcartLocations"

const MpAddPickingCart = (props) => {
    // Call the setResetTab action to reset the state
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useCreatePickupCartMutation} isModal title={t("add_pickupcart")}>
                <PickupcartBasicInfo/>
                <PickupcartLocations/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddPickingCart;