import { apiSlice } from "../createApiSlice";

const API_BASE = "/import";
const buildUrl = (endpoint) => API_BASE + endpoint;

export const uploadApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        uploadFile: builder.mutation({
            query: (args) => {
                const { source, type, file } = args;
                const formData = new FormData();
                formData.append('file', file);

                return {
                    url: buildUrl(`/preprocess/${source}/${type}`),
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        processFile: builder.mutation({
            query: (args) => {
                const { source, type, data } = args;
                console.log(buildUrl(`/process/${source}/${type}`));
                return {
                    url: buildUrl(`/process/${source}/${type}`),
                    method: 'POST',
                    body: data,
                };
            },
        })
    })
});

export const { 
    useUploadFileMutation,
    useProcessFileMutation
} = uploadApiSlice;
