import { Box, IconButton, InputBase, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import Subheading from "../../../../theme/text/Subheading";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import StockOnlocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import StockMutationCard from "../../../../theme/cards/stock/StockMutationCard";
import DownloadAttachmentCard from "../../../../theme/cards/other/DownloadAttachmentCard";
import JokerCard from "../../../../theme/cards/orders/JokerCard";
import LogCard from "../../../../theme/cards/other/LogCard";
import SurprisePack from "../../../../theme/cards/products/SurprisePack";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
// import Textarea from 'muicss/lib/react/textarea';
import { toast } from 'react-toastify';
import { useUpdateWarehouseLocationMutation } from "../../../../../newapi/warehouse/warehouselocationSlice";
// import { updateWarehouseLocation } from "../../../../../api/warehouse/warehouselocation";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [updateLocation,{isLoading, isSuccess}] = useUpdateWarehouseLocationMutation();

    const handleButtonClick = () => {
        const location = { active: props.active, 
            blocked: props.blocked, 
            carrier: props.carrier, 
            enableLinkingWhenBlocked: props.enableLinkingWhenBlocked, 
            quarantine: props.quarantine,
            remark: props.remark
        };

        updateLocation({ id: props.id, data: location })
            .unwrap()
            .then((updatedLocation) => {
                // Handle the success state
                toast.success(t('update_success'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // Handle the error state
                toast.error(t('update_failed'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    };
    // const handleButtonClick = () => {
    //     const location = { active: props.active, 
    //         blocked: props.blocked, 
    //         carrier: props.carrier, 
    //         enableLinkingWhenBlocked: props.enableLinkingWhenBlocked, 
    //         quarantine: props.quarantine,
    //         remark: props.remark};
    //         console.log(location);

    //     // updateWarehouseLocation(location, props.id, (result, data) => {
    //     //     if (result) {
    //     //         toast.success(t(data.message), {
    //     //             position: toast.POSITION.TOP_RIGHT
    //     //           });
    //     //     }
    //     // });
    // };

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={handleButtonClick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const WarehouseLocation = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [remark, setRemark] = useState("");
    const [active, setActive] = useState(false);
    const [bulk, setBulk] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [quarantine, setQuarantine] = useState(false);
    const [carrier, setCarrier] = useState(false);
    const [enableLinkingWhenBlocked, setEnableLinkingWhenBlocked] = useState(false);
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions 
                id={props.data.id}
                active={active}
                blocked={blocked}
                remark={remark}
                carrier={carrier}
                quarantine={quarantine}
                enableLinkingWhenBlocked={enableLinkingWhenBlocked}>
                    
                </GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[active,blocked,remark,carrier,quarantine,enableLinkingWhenBlocked,props.data]);

    useEffect(() => {
        if(!dataHasBeenSet) {
            setRemark(props.data?.remark || "");
            setActive(props.data?.active || false);
            setBlocked(props.data?.blocked ? props.data?.blocked : false);
            setQuarantine(props.data?.quarantine ? props.data?.quarantine : false);
            setCarrier(props.data?.carrier ? props.data?.carrier : false);
            setBulk(props.data?.warehouseLocationType.type === "bulk");
            setEnableLinkingWhenBlocked(props.data?.enableLinkingWhenBlocked ? props.data?.enableLinkingWhenBlocked : false);

            setDataHasBeenSet(true);
        }
    },[]);


    const handleSwitchChange = (event, param = "") => {
        // param is the optional parameter with a default value "defaultParam"
        // event is the event object from the Switch component
        // console.log("Optional parameter:", param);
        // setActive(event.target.checked);
        switch(param) {
            case "active" : setActive(event.target.checked);
            break;
            case "blocked" : setBlocked(event.target.checked);
            break;
            case "carrier" : setCarrier(event.target.checked);
            break;
            case "quarantine" : setQuarantine(event.target.checked);
            break;
            case "enablelinkingwhenblocked" : setEnableLinkingWhenBlocked(event.target.checked);
            break;
        }
    };

    const handleInputChange = (event) => {
        setRemark(event.target.value);
    }

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("location")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    {/* Two columns */}
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t('active')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t('bulk_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t('blocked_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t('quarantine_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t('carrier_location')}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <ShSwitch checked={active} onChange={(event) => handleSwitchChange(event, "active")} color="green" />
                            </Box>
                            <Box>
                                <ShSwitch checked={bulk} disabled color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box>
                                <ShSwitch checked={blocked} onChange={(event) => handleSwitchChange(event, "blocked")} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box>
                                <ShSwitch checked={quarantine} onChange={(event) => handleSwitchChange(event, "quarantine")} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box>
                                <ShSwitch checked={carrier} onChange={(event) => handleSwitchChange(event, "carrier")} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box flex={1}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t('echeck_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t('transit_location')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t('highcare_location')}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box lineHeight={"38px"}>
                                <Text bold  variant={props.data.warehouseLocationType.type === "echeck" ? "green" : undefined}>{props.data.warehouseLocationType.type === "echeck" ? t('yes') : t('no')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text bold variant={props.data.warehouseLocationType.type === "transition" ? "green" : undefined}>{props.data.warehouseLocationType.type === "transition" ? t('yes') : t('no')}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text bold  variant={props.data.highcare ? "green" : undefined}>{props.data.highcare ? t('yes') : t('no')}</Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dropdown>
            <Dropdown title={t("bind_settings")} open={window.innerWidth > 767}>
                <Box sx={{
                    display:"flex",
                    // justifyContent:"space-between",
                    flexDirection:"column"
                }}>
                    {/* Two columns */}
                    <Box
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                            <Box lineHeight={"38px"}>
                                <Text>{t('link_allowed_when_location_is_blocked')}</Text>
                            </Box>
                            <Box lineHeight={"40px"}>
                                <Text>{t('supplier')}</Text>
                            </Box>
                            {/* <Box lineHeight={"40px"}>
                                <Text>SKU gekoppeld product</Text>
                            </Box> */}
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                            <Box>
                                <ShSwitch checked={enableLinkingWhenBlocked} onChange={(event) => handleSwitchChange(event, "enablelinkingwhenblocked")} color={theme.palette.mode === "dark" ? "blue" : "primary"}/>
                            </Box>
                            <Box>
                                <Box display="flex"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                    }}
                                >
                                    <InputBase sx={{ml: 2, flex:1}} placeholder="" />
                                    {/* <IconButton type="button" sx={{p: 1,marginRight:-1}}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                    <IconButton type="button" color="red" sx={{p: 1}}>
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            marginTop:6
                        }}
                    >
                        <Text light>{t('remark')}</Text>

                        <Box display="flex"
                            borderRadius="8px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:1
                            }}
                        >
                            <TextField

                                id="outlined-multiline-static"
                                multiline
                                rows={3}
                                variant="outlined"
                                onChange={handleInputChange}
                                value={remark}
                                sx={{
                                    width:1,
                                    border:"none",
                                    outlineColor:"transparent !important",
                                    color: colors.txt["primary"],
                                    padding:0,
                                    " .MuiOutlinedInput-notchedOutline": {
                                        border:"none !important"
                                    },
                                    " .MuiInputBase-root": {
                                        paddingX:2,
                                        paddingY:1.5
                                    }
                                }}

                            ></TextField>
                            {/* <InputBase sx={{ml: 2, flex:1, height:120, verticalAlign:"top"}} placeholder="" /> */}
                        </Box>
                    </Box>
                </Box>
            </Dropdown>

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
        <GridActions
            id={props.data.id}
            active={active}
            blocked={blocked}
            remark={remark}
            carrier={carrier}
            quarantine={quarantine}
            enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
        </Box>
    );
}

export default WarehouseLocation;