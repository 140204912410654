
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const ECHECK_BASE = "/echeck";
const buildUrl = (endpoint) => ECHECK_BASE + endpoint;


const echeckApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        StartFindOrders: builder.query({
            query: id => ({
                url: buildUrl(`/startfindorders/${id}`),
                method: 'GET'
            }),
            //providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        finalizeOrder: builder.mutation({
            query: (body) => ({
                url: buildUrl(`/finalizeorder?_=${Date.now()}`),
                method: 'POST',
                body: body
            }),
            //providesTags: (result, error, body) => [{ type: `${body.order.id}Single` }]
        }),
        setJoker: builder.mutation({
            query: id => ({
                url: buildUrl(`/joker/${id}`),
                method: 'GET'
            }),
            //providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
    })
});

export const {
    useStartFindOrdersQuery,
    useFinalizeOrderMutation,
    useSetJokerMutation
  } = echeckApiSlice;
