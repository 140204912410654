import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const BASE = "/warehouselocationtypes";
const ApiName = "WarehouseLocationTypes";
const buildUrl = (endpoint) => BASE + endpoint;

const warehouseLocationTypeSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWarehouseLocationTypes: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: ApiName}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouseLocationTypes', ApiName)
        }),
        getWarehouseLocationType: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
        }),
        getWarehouseLocationTypesSelect: builder.query({
            query: () => ({
                url: buildUrl(`/`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: ApiName}],
        }),
        updateWarehouseLocationType: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }, { type: ApiName }],
        }),
        addWarehouseLocationType: builder.mutation({
            query: warehouseLocationTypeInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: warehouseLocationTypeInfo,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName }],
        }),
        deleteWarehouseLocationType: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiName}],
        }),
    })
});

export const { 
    useGetWarehouseLocationTypeQuery,
    useGetWarehouseLocationTypesQuery,
    useGetWarehouseLocationTypesSelectQuery,
    useUpdateWarehouseLocationTypeMutation,
    useAddWarehouseLocationTypeMutation,
    useDeleteWarehouseLocationTypeMutation
} = warehouseLocationTypeSlice;
