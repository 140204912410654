import { Box, Divider, IconButton, InputBase, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditCarrierMutation } from "../../../../../newapi/carrier/carrierSlice";
import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import {checkAllPropertiesTruthy } from "../../../../../utils"

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { useGetCarriersTypesQuery } from "../../../../../newapi/carrier/carrierSlice";
import { useGetContainerSizesSelectQuery, useUpdatePickupCartMutation, useUpdatePickupCrateMutation } from "../../../../../newapi/warehouse/pickupCartSlice";
import { useGetReachTypesSelectQuery } from "../../../../../newapi/warehouse/reachTypeSlice";
import { useGetWarehouseZonesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import Add from "@mui/icons-material/Add";
import MAddContainerSize from "../../../Modals/MAddContainerSize";
import { yupRequired } from "../../../../../utils/validation";
import * as yup from "yup";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const data = props.data;
    const { data: containerSizes, isLoadingx } = useGetContainerSizesSelectQuery();
    const [updatePickupCrate,{isLoading}] = useUpdatePickupCrateMutation();
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(0);

    
    const [pickingCrateInfo, setPickingCrateInfo] = useState();
    useEffect(() => {
        setPickingCrateInfo(data);
    },[data]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        barcode: yupRequired('name', t)
    };
        
    const schema = yup.object().shape(rules);
      
    const OpenCreateContainerSizeModal = () => {
        setModalData({
            title: 'create_new_containersize',
            confirm: confirmModalContainerSize
        });
        setOpen(true);
    }

    const confirmModalContainerSize = (obj) => {
        setPickingCrateInfo({
            ...pickingCrateInfo,
            containerSizeId: obj.id,
          });
          setOpen(false);
    }

    const createContainerSizeModal = {
        open: open,
        setOpen: setOpen,
        data:modalData,
        confirm: confirmModalContainerSize
    }

    
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setPickingCrateInfo({
          ...pickingCrateInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };
      

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={pickingCrateInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[pickingCrateInfo]);

    const handleSaveClick = async () => {
        const canSave = true; // This should be replaced with actual validation logic
        if (canSave) {
            try {
                const data = await updatePickupCrate({id: pickingCrateInfo.id, data: pickingCrateInfo}).unwrap();
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, err);
            }
        } else {
            if (!isLoading) {
                toast.error(t("not_all_fields_filled"), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    };

    return (
        <>
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                sx={{
                    "@media screen and (min-width: 48.1em)"  :{
                        marginBottom:11
                    }
                }}
            >
                <Dropdown title={t("basic")} open>
                    <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        },
                        flexDirection:"column"
                    }}>
                        
                       <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                paddingTop:3,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('barcode')}
                    </Text>
                    
                </Box>
                <ShValidatedInput
                            name="barcode"
                            value={pickingCrateInfo?.barcode ?? ""}
                            onChange={handleChange}
                             //error={props.errors.barcode}
                        />
    </Box>
           </Box>
                </Dropdown>
                <Dropdown title={t("measurements")} open>
                <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        },
                        flexDirection:"column"
                    }}>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("size")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            // backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            
                            // height:32
                        }}
                    >

                  
                <InputDropdown
                    displayName={"description"} 
                    onChange={(selected) => handleChange("containerSizeId", containerSizes[selected] ?? -1)}
                    noSelection={t("choose_containersize")} //Custom text when nothing is selected
                    options={containerSizes}
                    selected={containerSizes ? containerSizes?.findIndex(containerSize => containerSize.id === pickingCrateInfo?.containerSizeId) ?? -1 : -1}
                    closeOnSelection
                />
                <ButtonGroup version={2} >
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    startIcon={<Add/>}
                    onClick={() => OpenCreateContainerSizeModal()}
                    >{t("new")}</Shbutton>
                </ButtonGroup>
    </Box></Box>
            </Box>
                </Dropdown>

                <Box
                    sx={{
                        position:"absolute",
                        bottom:0,
                        right:0,
                        padding:3,
                        width:1,
                        background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                        "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                            padding:2,
                            paddingY:2.5,
                        }
                    }}
                >
                     {/* Pass the necessary state values and setter functions to GridActions */}
            <GridActions
                id={props.data.id}
                action={handleSaveClick}
                // active={active}
                // blocked={blocked}
                // remark={remark}
                // carrier={carrier}
                // quarantine={quarantine}
                // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
            />
                </Box>
            </Box>
                <MAddContainerSize
                    open={createContainerSizeModal.open}
                    handleClose={() => createContainerSizeModal.setOpen(false)}
                    data={createContainerSizeModal.data}
                    confirm={createContainerSizeModal.confirm}
                />
            </>
    );
}

export default DetailsPanel;