

export const builderConditionExtensionTypes = [
    'and',
    'or'
];
export const conditionExtensionTypes = [
    'where',
    ...builderConditionExtensionTypes
]

const conditions =  {
    text: [
        'Equal to',
        'Except',
        'Contains',
        'Starts with',
        'Ends with'
    ],
    string: [
        'Equal to',
        'Except',
        'Contains',
        'Starts with',
        'Ends with'
    ],
    number: [
        'Equal to',
        'Bigger than',
        'Smaller than',
        'Between'
    ],
    date : [
        'After',
        'Before',
        'Between'
    ],
    boolean: [
        'is',
        'is not'
    ],
    options: [
        'is',
        'is not'
    ]
};

export const conditionsFromType =(type) => {
    return conditions[type];
};

export const getConditions = () =>{
    return conditions;
}

export const getConditionExtensionTypes = () => {
    return conditionExtensionTypes;
}

export const getBuilderConditionExtensionTypes = () => {
    return builderConditionExtensionTypes;
}

// {
//     column : "relation",
//     conditionExtensionType : "or",
//     type : {
//         name: 'text', 
//         value: "endsWith"
//     },
//     value : "uiwehfwif"
// }
// (relation == X || relation == Y || relation == Z)