
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
export const relationSettingsReturnPortalConfig = {
    webshopIntegrations:  {
        title: 'webshop_integrations',
        subtitle: 'webshop_all_integrations',
        icon: <Inventory2OutlinedIcon/>,
    },
};
