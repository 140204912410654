import { Box } from "@mui/material"

const TopRight = (props) => {
    return (
        <Box
            sx={{
                position: "absolute",
                top:0,
                right:0,
                display:"flex",
            }}
        >
            {props.children}
        </Box>
    )
}

export default TopRight;