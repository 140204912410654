import { Box, Stack} from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WidgetsIcon from '@mui/icons-material/Widgets';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LineWeight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {useTranslation} from 'react-i18next';

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        </Box>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Replenish = (props) => {
    const{t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    
    

    const columns = [
        {field: "quantity", headerName: t("quantity"), renderCell : (cellValues) => {
            return (
                <Box paddingLeft={0}>
                    <Text semibold>{cellValues.value}</Text>
                </Box>
            )
        }},
        {field: "description", headerName: t("description"), flex:1, renderCell : (cellValues) => {
            return (
                <Box>
                    <Text semibold>{cellValues.value}</Text>
                </Box>
            )
        }},
        {field: "sku", headerName: t("sku"),flex:1,renderCell : (cellValues) => {
            return (
                <Box>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
                </Box>
            )
        }},
        
        {field: "location", headerName: t("location"), flex:1, renderCell : (cellValues) => {
            return (
                <Box>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
                </Box>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {cellValues.row.id + " " + cellValues.row.name}
                        <br/>
                        {cellValues.row.age}
                        <br/>
                        {cellValues.row.phone}
                    </Box>
                )
            }
        }
    ];

    const mockDataTeam = [
        {
            id: randomIntFromInterval(0, 99999999),
            quantity: "-10",
            description: "JUMBO DC POLO KM",
            sku: "JUMDC-C1100-M",
            location: "10-AA-013",
            phoneView:{},
        },
        {
            id: randomIntFromInterval(0, 99999999),
            quantity: "-10",
            description: "JUMBO DC POLO KM",
            sku: "JUMDC-C1100-M",
            location: "10-AA-013",
            phoneView:{},
        },
        {
            id: randomIntFromInterval(0, 99999999),
            quantity: "-10",
            description: "JUMBO DC POLO KM",
            sku: "JUMDC-C1100-M",
            location: "10-AA-013",
            phoneView:{},
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            {/* <ShDataGrid  
                // gridActions={<GridActions></GridActions>} 
                title={t("rows_to_be_replenished")}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                apiRef={props.apiRef} 
                columns={columns} 
                rows={props.data.replenish}
                components={{
                    NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        {t("no_products_to_replenish")}
                    </Stack>
                    )
                }}>
            </ShDataGrid> */}
        </Box>
    );
}



export default Replenish;