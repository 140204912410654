import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import BottomRight from "../../positionals/BottomRight";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";

const StockMutationCard = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [date, setDate] = useState(new Date(props.createdAt).toLocaleDateString(i18n.language));
    const [time, setTime] = useState(new Date(props.createdAt).toLocaleTimeString(i18n.language));

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"} width={props.skeleton ? "70%": null}>
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/>    
                            :
                            <>
                                <Text bold>{props.stock.product?.sku}</Text>
                                <Text light>{props.stock.product?.description}</Text>
                            </>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel 
                            hasright 
                            background={theme.palette.mode === "dark" ? colors.grey[400] : null} 
                            color={theme.palette.mode === "dark" ? colors.txt["primary"] : null} 
                            
                            variant="grey"  
                            position="child-tr"
                        >

                            {props.skeleton ? "" : props.mutation}
                        </CardLabel>
                        <CardLabel 
                            hasleft 
                            background={theme.palette.mode === "dark" ?  colors.txt["secondary"] : null} 
                            color={theme.palette.mode === "dark" ? colors.txt["primary"] : null} 

                            variant="grey" 
                            colorflip 
                            position="child-tr"
                        >
                            {props.skeleton ? null :
                                <CommentBankOutlinedIcon fontSize="small"/>
                            }
                        </CardLabel>
                    </TopRight>

                    <BottomRight>
                        <CardLabel 
                            // pastel={"st"}
                            palette={"pastel"}
                            variant={"blue"} 
                            // onClick={props.pickbonClick} 
                            position="bottom-right"
                        >

                            {props.skeleton ? null : props.stock.warehouseLocation?.location}
                        </CardLabel>
                    </BottomRight>

                    {/* Holds tht date and batch */}
                    <Box marginTop={3} display={"flex"} width={props.skeleton ? "70%": null} flexDirection={"column"}>

                        {props.skeleton ?
                            <ShDoubleTextSkeleton/>    
                            :
                            typeof props.createdAt !== "undefined" ?
                                <>
                                    <Text fontWeight="400">{date}</Text>
                                    <Text light>{time}</Text>
                                </>
                            : null
                        }
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default StockMutationCard;