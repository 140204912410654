import { Box, Skeleton, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

const ShDoubleTextSkeleton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const type = props.type ?? "light";
    const variant = props.variant ?? "grey";
    const palette = props.palette ?? "normal"

    const getBackground = () => {
        if(palette === "pastel") {
            return colors.pastel[type][variant][400];
        }
        if(variant !== "grey") {
            return colors[variant][200];
        } 
        return theme.palette.mode === "dark" ? colors.grey[400]: colors.grey[100]
    }

    const getSecondaryBackground = () => {
        if(palette === "pastel") {
            return colors.pastel[type][variant][400];
        }
        if(variant !== "grey") {
            return colors[variant][200];
        } 
        return theme.palette.mode === "dark" ? colors.grey[300]: colors.grey[50]
    }

    return (
        <Box
            sx={{
                // height:32,
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start",
                justifyContent:"center",
                width:1,
                gap:0.5,
            }}
        >
        
        <Skeleton 
            sx={{
                display:"block",
                width:1,
                height:12,
                marginRight:2, 
                bgcolor: getBackground(),
                transform:"none"
            }} 
            animation="wave"
        ></Skeleton>

        <Skeleton 
            sx={{
                display:"block",
                width:1,
                height:12,
                marginRight:2, 
                bgcolor: getSecondaryBackground(),
                transform:"none"
            }} 
            animation="wave"
        ></Skeleton>
        </Box>
    )
}

export default ShDoubleTextSkeleton;