import AddIcon from '@mui/icons-material/Add';
import CropFreeIcon from '@mui/icons-material/CropFree';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../../../../theme";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import ShLabel from "../../../../theme/label/ShLabel";
import Text from "../../../../theme/text/Text";

import { useTranslation } from "react-i18next";
import OrderLinesCard from "../../../../theme/cards/orders/OrderLinesCard";
import SpStockDetails from "../../SpStockDetails/SPStockDetails";
import { useGetInboundLinesQuery } from '../../../../../newapi/inbound/inboundsSlice';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import ShLabelSkeleton from '../../../../theme/skeleton/ShLabelSkeleton';
import { useGetProductQuery } from '../../../../../newapi/warehouse/productSlice';
import ShDropdown from '../../../../theme/dropdowns/Dropdown';
import DropOut from '../../../../theme/dropout/DropOut';
import { getTranslatedDateAndTime } from '../../../../../utils';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
// import classNames from "classnames";
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("save")}</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "productsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};




const Inbound = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [rowSelected, setRowSelected] = useState(false);

    

    const [productId, setProductId] = useState({});
    const getProductDetails = (id) => {
        setProductId(id);
        setRowSelected(true);
    };
    const { data: productData, isSuccess } = useGetProductQuery(productId, {skip: !rowSelected});
    useEffect(() => {
        if (isSuccess) {
            setIsExtended(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    

    const columns = [
        {field: "product.sku", headerName: t("sku"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <div onClick={() => getProductDetails(params.row.product.id)}>
                <Box>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
                </Box>
                </div>
            )
        }},
        {field: "product.description", headerName: t("description"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{params.row.product.description}</Text>
                </Box>
            )
        }},
        {field: "product.barcode", headerName: t("barcode"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold> {params.row.product.barcode}</Text>
                </Box>
            )
        }},
        {field: "amountForecasted", headerName: t("forecasted"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {field: "amountReceived", headerName: t("received"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "notreceived":
                        labelcolor = "grey"
                        labeltext = t('not_received');
                        break;
                    case "partlyreceived":
                        labelcolor = "blue"
                        labeltext = t('partly_received');
                        break;
                    case "fullyreceived":
                        labelcolor = "green"
                        labeltext = t('fully_received');
                        break;
                    default:
                        labelcolor = "red"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {field: "remark", headerName: t("remark"), flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.row.remark !== ""){
                return (
                    <Text light><MessageIcon/></Text>
                    
                  );
            } else {
                
            }
            
          }},
        {
            field: "phoneView", 
            headerName: "phoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrderLinesCard skeleton={params.row.skeleton ?? false} {...params}/>
                )
            }
        }
    ];

    return (
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
           <DropOut title={t("basic")} open>
           <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        }
                    }}>
                        <Box flex={4}
                            sx={{
                                display:"flex",
                                gap:4,
                                justifyContent:"flex-start"
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box lineHeight={"38px"}>
                                    <Text>{t("relation")}</Text>
                                </Box>
                                <Box lineHeight={"38px"}>
                                    <Text>{t("reference")}</Text>
                                </Box> 
                                <Box lineHeight={"38px"}>
                                    <Text>{t("scheduled")}</Text>
                                </Box>
                                <Box lineHeight={"38px"}>
                                    <Text>{t("shipped")}</Text>
                                </Box> 
                                
                            </Box>
                            <Box>
                            <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text bold>{props.data?.relation?.name}</Text>
                                </Box>
                                <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text bold>{props.data?.inbound?.reference}</Text>
                                </Box>
                                <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text bold>{getTranslatedDateAndTime(props.data?.inbound?.receivedFromDate, t).date}</Text>
                                </Box>
                                <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text bold>{getTranslatedDateAndTime(props.data?.inbound?.receivedToDate, t).date}</Text>
                                </Box>
                            </Box>
                        </Box>

                        <Box flex={4}
                            sx={{
                                display:"flex",
                                gap:4,
                                justifyContent:"flex-start"
                            }}
                        > 
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1,
                                        // width:1
                                    }
                                }}
                            >
                                <Box lineHeight={"38px"}>
                                    <Text>{t("pallets")}</Text>
                                </Box>
                               <Box lineHeight={"38px"}>
                                    <Text>{t("colli")}</Text>
                                </Box>
                                <Box lineHeight={"38px"}>
                                    <Text>{t("sku_amount")}</Text>
                                </Box>
                                <Box lineHeight={"38px"}>
                                    <Text>{t("items")}</Text>
                                </Box>
                            </Box>
                            <Box>
                            <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text>{props.data?.inbound?.pallets}</Text>
                                </Box>
                                <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text>{props.data?.inbound?.colli}</Text>
                                </Box>
                                <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text>{props.data?.inbound?.skuCount}</Text>
                                </Box>
                                <Box 
                                    sx={{
                                        lineHeight:"38px",
                                        "@media screen and (max-width:47.9375em)" : {
                                            display:"flex",
                                            justifyContent:"flex-end"
                                        }
                                    }}
                                >
                                    <Text>{props.data?.inbound?.itemCount}</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
           </DropOut>
           <DropOut title={t("products")} >
       
            <Box height={1} flex={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
              <ShDataGrid2
                itemId={props.data?.inboundId}
                content={useGetInboundLinesQuery}
                // gridActions={<GridActions></GridActions>}
                //title={t("inbound")}
                gridOptions={gridOptions}
                isSidepanelGrid
                columns={columns}
              />
            </Box>
           </DropOut>
            <SpStockDetails
              isExtended={isExtended}
              setIsExtended={setIsExtended}
              data={productData}
            />
          </Box>
      );
      
}

export default Inbound;