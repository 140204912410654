
// import { ReactComponent as TableEmpty } from '../../../..styles/svg/tableempty.svg'
import { ReactComponent as TableError } from '../../../../styles/svg/tableerror.svg'
import { useTranslation } from "react-i18next";
import Subheading from "../../text/Subheading";
import { Box, useTheme } from '@mui/material';
import Text from '../../text/Text';
import { tokens } from '../../../../theme';
import Shbutton from '../../buttons/Shbutton';

export const ChartError = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    return (
        <Box 
            sx={{
                position:"absolute",
                top:0,
                width:props.type === "donut" ? 1 : "calc(100% + 24px)",
                left:props.type === "donut" ? 0 : -24,
                height:1,
                background:theme.palette.mode === "dark" ? "rgba(31,31,40,.55)" : "rgba(255,255,255,.55)",
                display:"flex",
                justifyContent:"center",
                backdropFilter:"blur(6px)",
                zIndex:4,
            }}
        >
            <Box
                sx={{
                    mt:-3,
                    // maxWidth:300,
                    display:"flex",
                    justifyContent:"center",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >
                <TableError/>
                <Box
                    sx={{
                        mt:3,
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        gap:0.5
                    }}
                >
                    <Subheading align="center">{t("something_went_wrong")}</Subheading>
                    {/* <Text align="center" light>Er is geen informatie gevonden voor xxx</Text> */}
                    <Box sx={{mt:3}}>
                        <Shbutton color={"red"} variant={"contained"} onClick={() => props.onRetry()}>{t("try_again")}</Shbutton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}