
import CropFreeIcon from '@mui/icons-material/CropFree';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const spStreamDetailsConfig = {
    details: {
        title: 'details',
        icon: <CropFreeIcon/>,
    },
    shipmentPanel: {
        title: 'shipment',
        icon: <CropFreeIcon/>,
    },
    streamShippers: {
        title: 'shipper_rules',
        icon: <CropFreeIcon/>,
    },
    
};
