import { useTheme } from "@mui/material"
import { tokens } from "../../../../../../theme";
import SelectGroup from "../../../../Select/SelectGroup";
import { useContext, useEffect, useState } from "react";
import { useFilterbuilder } from "../../../../../../contexts/FbState";
import { getBuilderConditionExtensionTypes, getConditionExtensionTypes } from "../../Settings";
import { useDispatch, useSelector } from "react-redux";
import { updateConditionExtensionType } from "../../../../../../newapi/global/filter/builder/gridFilterBuilderRuleSlice";
import { setFilterBuilderConditionExtensionType } from "../../../../../../newapi/global/filter/builder/gridFilterBuilderSettingsSlice";

const SelectFilterGroupExtensionTypeViewBody = (props) => {
    const [selected, setSelected] = useState(-1);
    const [columns, setColumns] = useState(getBuilderConditionExtensionTypes());

    const filterBuilder = useFilterbuilder();

    const dispatch = useDispatch();
    // const builderRule = useSelector(state => state.gridFilterBuilderRule);
    // const builderSettings = useSelector(state => state.gridFilterBuilderRule);

    //Select element and go to next screen
    const select = (selected) => {
        setSelected(selected);

        //settings
        dispatch(setFilterBuilderConditionExtensionType(selected+1));

        //First rule on the new group is ALWAYS where
        dispatch(updateConditionExtensionType(0));

        //Create a new group and update settings
        // let settings = filterBuilder.settings;
        // settings.newGroup.conditionExtensionType = selected+1;
        // filterBuilder.updateSettings(settings);

        // //First rule on the new group is ALWAYS where
        // filterBuilder.updateConditionExtensionType(0);

        if(typeof props.builderRef !== "undefined" && props.builderRef.current !== null) {
            props.builderRef.current.nextView();
        }
    }
    
    return (
        <SelectGroup selected={selected} setSelected={select} options={columns} />
    )
}

const SelectFilterGroupExtensionTypeView = (props, builderRef, skipStep) => {
    return {
        skipStep: skipStep,
        header : {
			title: "Pick an extension type",
			// preview: <FiltergroupPreview/>
        },
        body : {
            component: <SelectFilterGroupExtensionTypeViewBody {...props} builderRef={builderRef}/>
        }
    }
}

export default SelectFilterGroupExtensionTypeView;