import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import Subheading from "../../../../theme/text/Subheading";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import SaveIcon from '@mui/icons-material/Save';
import StockOnlocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import StockMutationCard from "../../../../theme/cards/stock/StockMutationCard";
import DownloadAttachmentCard from "../../../../theme/cards/other/DownloadAttachmentCard";
import JokerCard from "../../../../theme/cards/orders/JokerCard";
import LogCard from "../../../../theme/cards/other/LogCard";
import SurprisePack from "../../../../theme/cards/products/SurprisePack";
import { useTranslation } from "react-i18next";
import { useGetOrderColliQuery } from "../../../../../newapi/order/ordersSlice";
import { toast } from "react-toastify";
import { useEditShipperMutation } from "../../../../../newapi/shipper/shipperSlice";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
// import { useGetOrderColliQuery } from "../../../../../newapi/order/ordersSlice";

// import classNames from "classnames";
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};







const Pickup = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    
    

    const [shipperInfo, setShipperInfo] = useState({
        state: "active",
        id: 2,
        name: "DHL",
        reference: null,
        type: null,
        standard_time: "12:00",
        standard_time_saturday: "14:00",
        standard_time_sunday: "14:30",
        maxWeight: "120000",
        maxLength: 2000,
        maxWidth: 2000,
        maxHeight: 2500,
        maxVolume: "10000000000",
        alternativeAddress: null,
        street: null,
        house_nr: null,
        house_nr_extra: null,
        postal: null,
        city: null,
        country: null,
        company: null,
        contactperson: null,
        public_key: null,
        private_key: null
    });

    useEffect(() => {
        setShipperInfo(props.data);
    },[]);
    

    const handleChange = (key, value) => {
        setShipperInfo({
          ...shipperInfo,
          [key]: value
        });
      };

      const [EditShipper,{isLoading}] = useEditShipperMutation();
    const handleSaveClick = async () => {
        console.log(shipperInfo);
        const canSave = true;// checkAllPropertiesTruthy(carrierInfo) && !isLoading;
        if(canSave){    
            try {
                const data = await EditShipper(shipperInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }   
        }        
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions></GridActions>,
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
                <Dropdown title={t("basic")} open>
                    <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        }
                    }}>
                        
                        {/* Two columns */}
                        
                        <Box flex={4}
                            sx={{
                                display:"flex",
                                gap:4,
                                justifyContent:"flex-start"
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box lineHeight={"38px"}>
                                    <Text>{t("company")}</Text>
                                </Box>
                            </Box>
                            <Box>
                                <Box display="flex"
                                    height="32px"
                                    // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        marginTop:0.5,
                                        marginBottom:0.5,
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <InputBase sx={{ml: 2, flex:1}} placeholder="" value={shipperInfo.company} onChange={(e) => handleChange('company', e.target.value)}/>
                                </Box>
                                
                            </Box>
                        </Box>

                        <Box flex={4}
                            sx={{
                                display:"flex",
                                gap:4,
                                justifyContent:"flex-start"
                            }}
                        > 
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1,
                                        // width:1
                                    }
                                }}
                            >
                                <Box lineHeight={"38px"}>
                                    <Text>{t("contactperson")}</Text>
                                </Box>
                               
                            </Box>
                            <Box>
                                <Box display="flex"
                                    height="32px"
                                // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        marginTop:0.5,
                                        marginBottom:0.5,
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <InputBase sx={{ml: 2, flex:1}} placeholder="" value={shipperInfo.contactperson} onChange={(e) => handleChange('contactperson', e.target.value)}/>
                                </Box>
                               
                            </Box>
                        </Box>
                    </Box>
                </Dropdown>
                <Dropdown title={t("alternative_address")} open>
                <Box paddingX={1.5}
                    sx={{
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    <Subheading variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{t("alternativeaddress_onoff")}</Subheading>
                    <ShSwitch></ShSwitch>
                </Box>
                {/* Details */}
                <Box 
                    sx={{
                        display:"flex",
                        borderRadius:3,
                        overflow:"hidden",
                        marginTop:3,
                        marginBottom:1,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                        "@media screen and (max-width: 47.9375em)" : {
                            marginTop:0,
                            background:"none",
                            flexDirection:"column",
                            overflow:"unset"
                        }
                    }}
                >
                    {/* Editable content */}
                    <Box padding={1.5} paddingRight={4} paddingBottom={3} flex={1}
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                paddingY:0,
                                paddingRight:1.5,
                            }
                        }}
                    >
                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:0.5,
                                    alignItems:"flex-start",
                                }
                            }}
                        >
                            <Subheading>{t("details")}</Subheading>
                            <Text>{props.data.deliveryName}</Text>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                marginTop:3,
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("street")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" value={props.data.deliveryStreet} sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("house_nr")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        backgroundColor={colors.grey[200]}
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" 
                                        value={props.data.deliveryHousenumber}
                                            sx={{
                                                px: 2, 
                                                width:56, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                        <Box 
                                            sx={{
                                                marginY:0.5,
                                                height:24,
                                                width:"1px",
                                                background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                            }}
                                        />
                                        <InputBase type="text"value={props.data.deliveryHousenumberAddition} sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("postal_code")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" 
                                        value={props.data.deliveryPostalCode}
                                            sx={{
                                                px: 2, 
                                                width:96, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                        {/* <Box 
                                            sx={{
                                                marginY:0.5,
                                                height:24,
                                                width:"1px",
                                                background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                            }}
                                        />
                                        <InputBase type="text" sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} /> */}
                                    </Box>
                                </Box>
                            </Box>

                            
                        </Box>


                        <Box
                            sx={{
                                display:"flex",
                                marginTop:3,
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                    marginTop:4
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("city")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text"
                                        value={props.data.deliveryCity}
                                            sx={{
                                                px: 2, 
                                                flex:1, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("country")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}}
                                        value={props.data.deliveryCountry} />
                                    </Box>
                                </Box>
                            </Box>

                            
                        </Box>
                    </Box>
                
            </Box>
            </Dropdown>
            <Dropdown title={t("pickup_times")} subTitle={t("pickup_times_description")} open={window.innerWidth > 767} isLast sx={{paddingBottom:"0px"}}>
                {/* <Divider></Divider> */}
                    <Box sx={{
                        display:"flex",
                        flexDirection:"column"
                    }}>
                        {/* Two columns */}
                        <Box
                            sx={{
                                display:"flex",
                                gap:4,
                                justifyContent:"flex-start"
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box 
            sx={{
                width:1,
                paddingTop: 2,
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                gap:7
            }}
        >

            <Box
                sx={{
                    width:120,
                }}
            >
                <Text>{t("mon_fri")}</Text>&nbsp;
            </Box>
            
            <Box    
                sx={{
                    flex:1,
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:4,
                    display:"flex",
                    height:32
                }}
            >
                <TimePicker btnSize={"24"} 
                showTimeSelect showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                format="HH:mm"
                value={data.mon_fri}
                onChange={(e) => handleChange('standard_time', e)}/>
            </Box>
        </Box>
        <Box 
            sx={{
                width:1,
                paddingTop: 2,
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                gap:7
            }}
        >

            <Box
                sx={{
                    width:120,
                }}
            >
                <Text>{t("saturday")}</Text>&nbsp;
            </Box>
            
            <Box    
                sx={{
                    flex:1,
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:4,
                    display:"flex",
                    height:32
                }}
            >
                <TimePicker btnSize={"24"} 
                showTimeSelect showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                format="HH:mm"
                value={data.saturday}
                onChange={(e) => handleChange('standard_time_saturday', e)}/>
            </Box>
        </Box>
        <Box 
            sx={{
                width:1,
                paddingTop: 2,
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                gap:7
            }}
        >

            <Box
                sx={{
                    width:120,
                }}
            >
                <Text>{t("sunday")}</Text>&nbsp;
            </Box>
            
            <Box    
                sx={{
                    flex:1,
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:4,
                    display:"flex",
                    height:32
                }}
            >
                <TimePicker btnSize={"24"} 
                showTimeSelect showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                format="HH:mm"
                value={data.sunday}
                onChange={(e) => handleChange('standard_time_sunday', e)}/>
            </Box>
        </Box>
        </LocalizationProvider>              
                        </Box>
                        </Box>
                </Dropdown>
            <GridActions
                id={props.data.id}
                 action={handleSaveClick}
                // active={active}
                // blocked={blocked}
                // remark={remark}
                // carrier={carrier}
                // quarantine={quarantine}
                // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
            />
        </Box>
        
    );
}


export default Pickup;