import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useUpdateProductMutation } from "../../../../../newapi/warehouse/productSlice";
import { useGetEnumsQuery } from "../../../../../newapi/global/generalApiSlice";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    
    const [updateProduct,{isLoading, isSuccess}] = useUpdateProductMutation();

    const handleButtonClick = () => {
        const product = { 
            active: props.data.active, 
            sku: props.data.sku, 
            secondary_sku: props.data.secondarySku, 
            barcode: props.data.barcode, 
            safetyStock: props.data.safetyStock, 
            food: props.data.food, 
            fragile: props.data.fragile, 
            dangerous: props.data.dangerous, 
            informShipper: props.data.informShipper, 
            highcare: props.data.highcare, 
            enterDimensionsAfterInbound: props.data.enterDimensionsAfterInbound, 
            DaWBlockedByWarehouse: props.data.DaWBlockedByWarehouse, 
            quarantineAfterReturn: props.data.quarantineAfterReturn,
            customInboundSettings: props.data.customInboundSettings,
            inboundProcessType: (props.data.inboundProcessType ? props.data.inboundProcessType : null),
            lotPossible: props.data.lotPossible, 
            lotRequired: props.data.lotRequired, 
            batchPossible: props.data.batchPossible, 
            batchRequired: props.data.batchRequired, 
            repackingRequired: props.data.repackingRequired
        };

        updateProduct({ id: props.id, data: product })
            .unwrap()
            .then((updatedLocation) => {
                // Handle the success state
                toast.success(t('update_success'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // Handle the error state
                toast.error(t('update_failed'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    };

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={handleButtonClick}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};


const Info = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();

    const [data, setData] = useState({
        active: "false",
        sku: '',
        barcode: '',
        secondarySku: '',
        safetyStock: 0,
        food: false,
        fragile: false,
        dangerous: false,
        informShipper: false,
        highcare: false,
        enterDimensionsAfterInbound: false,
        DaWBlockedByWarehouse: false,
        quarantineAfterReturn: false,
        lotPossible: false,
        lotRequired: false,
        batchPossible: false,
        batchRequired: false,
        customInboundSettings: false,
        inboundProcessType: '',
        repackingRequired: false,
    });


    const  { t } = useTranslation();

    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery();

    const [view, setView] = useState({
        title: 'info',
        icon: <InfoOutlinedIcon/>,
        mobileActions:<GridActions></GridActions>
    })

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={data} id={props.data.id}  ></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[data,props.data]);
    
    useEffect(() => {
        if (!dataHasBeenSet) {
            // Update the entire data state based on props.data
            setData(prevState => ({
                ...prevState,
                active: props.data.active?.toString() || "",
                barcode: props.data.barcode || "",
                sku: props.data.sku || "",
                secondarySku: props.data.secondary_sku || "",
                safetyStock: props.data.safetyStock || 0,
                food: props.data.food || false,
                fragile: props.data.fragile || false,
                dangerous: props.data.dangerous || false,
                informShipper: props.data.informShipper || false,
                highcare: props.data.highcare || false,
                enterDimensionsAfterInbound: props.data.enterDimensionsAfterInbound || false,
                DaWBlockedByWarehouse: props.data.DaWBlockedByWarehouse || false,
                quarantineAfterReturn: props.data.quarantineAfterReturn || false,
                lotPossible: props.data.lotPossible || false,
                lotRequired: props.data.lotRequired || false,
                batchPossible: props.data.batchPossible || false,
                batchRequired: props.data.batchRequired || false,
                repackingRequired: props.data.repackingRequired || false,
                customInboundSettings: props.data.customInboundSettings || false,
                inboundProcessType: props.data.inboundProcessType || "",
            }));
            setDataHasBeenSet(true);
        }
    }, [props.isActive, props.data, dataHasBeenSet]);

    const handleInputChange = (event, field) => {
        setData({ ...data, [field]: event.target.value });
    };

    const handleSwitchChange = (event, field) => {
        setData({ ...data, [field]: event.target.checked });
    };

    const handleToggleButtonChange = (event, newActive) => {
        if(newActive !== null){
         setData({ ...data, active: newActive });
        }
    };



    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}>
            {/* Information Section */}
            <Dropdown title={t('information')} open>
                <Box
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                        gap:1,
                    }}
                >
                       <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('active')}</Text>
                        </Box>
                    {/* Active Status */}
                        <ToggleButtonGroup
                            color={data.active === "true" ? "green" : "red"}
                            value={data.active}
                            exclusive
                            onChange={handleToggleButtonChange}
                            aria-label="Active"

                            className={cx(`${data.active}`, {
                                'active': data.active === "true"
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                className={cx(``, {
                                    'active': data.active === "false"
                                })}
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.active,&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('inactive')}
                            </ToggleButton>
                            <ToggleButton 
                                className={cx(``, {
                                    'active': data.active === "true"
                                })}
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.active,&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('active')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    {/* <ToggleButtonGroup
                        value={data.active}
                        exclusive
                        onChange={handleToggleButtonChange}
                        aria-label="Active status"
                    >
                        <ToggleButton value="true">{t('active')}</ToggleButton>
                        <ToggleButton value="false">{t('inactive')}</ToggleButton>
                    </ToggleButtonGroup> */}

                    {/* SKU */}

                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('sku')}</Text>
                        </Box>
                        
                            <Box display="flex"
                                borderRadius="20px"
                                backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                                sx={{
                                    pl:2,
                                }}
                            >
                            <InputBase 
                                value={data.sku}
                                onChange={(e) => handleInputChange(e, 'sku')}
                                endAdornment={
                                    <IconButton>
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                }
                            />
                        </Box>
                    </Box>

                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('secondary_sku')}</Text>
                            </Box>
                            {/* Secondary SKU */}

                            <Box display="flex"
                                borderRadius="20px"
                                backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                                sx={{
                                    pl:2,
                                }}
                            >
                            <InputBase 
                                value={data.secondarySku}
                                onChange={(e) => handleInputChange(e, 'secondarySku')}
                                endAdornment={
                                    <IconButton>
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                }
                            />
                        </Box>
                    </Box>

                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            gap:1,
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:130,
                            }}
                        >
                            <Text light>{t('barcode')}</Text>
                        </Box>
                            {/* Secondary SKU */}

                        <Box display="flex"
                            borderRadius="20px"
                            backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
                            sx={{
                                pl:2,
                            }}
                        >
                            {/* Barcode */}
                            <InputBase 
                                value={data.barcode}
                                onChange={(e) => handleInputChange(e, 'barcode')}
                                endAdornment={
                                    <IconButton>
                                        <ModeEditOutlineOutlinedIcon />
                                    </IconButton>
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </Dropdown>

            {/* Settings Section */}
            <Dropdown title={t('settings')} open>
                <Box>
                    {/* Food Switch */}
                    <Text light>{t('food')}</Text>
                    <ShSwitch
                        checked={data.food}
                        onChange={(e) => handleSwitchChange(e, 'food')}
                    />

                    {/* Fragile Switch */}
                    <Text light>{t('fragile')}</Text>
                    <ShSwitch
                        checked={data.fragile}
                        onChange={(e) => handleSwitchChange(e, 'fragile')}
                    />

                    {/* Dangerous Product Switch */}
                    <Text light>{t('dangerous_product')}</Text>
                    <ShSwitch
                        checked={data.dangerous}
                        onChange={(e) => handleSwitchChange(e, 'dangerous')}
                    />

                    {/* Inform Shipper Switch */}
                    <Text light>{t('let_shipper_know')}</Text>
                    <ShSwitch
                        checked={data.informShipper}
                        onChange={(e) => handleSwitchChange(e, 'informShipper')}
                    />

                    {/* High Care Switch */}
                    <Text light>{t('high_care')}</Text>
                    <ShSwitch
                        checked={data.highcare}
                        onChange={(e) => handleSwitchChange(e, 'highcare')}
                    />

                    {/* Enter Dimensions After Inbound Switch */}
                    <Text light>{t('re_enter_size_at_inbound')}</Text>
                    <ShSwitch
                        checked={data.enterDimensionsAfterInbound}
                        onChange={(e) => handleSwitchChange(e, 'enterDimensionsAfterInbound')}
                    />

                    {/* DaW Blocked By Warehouse Switch */}
                    <Text light>{t('size_and_weight_locked_by_warehouse')}</Text>
                    <ShSwitch
                        checked={data.DaWBlockedByWarehouse}
                        onChange={(e) => handleSwitchChange(e, 'DaWBlockedByWarehouse')}
                    />

                    {/* Quarantine After Return Switch */}
                    <Text light>{t('quarantine_at_return')}</Text>
                    <ShSwitch
                        checked={data.quarantineAfterReturn}
                        onChange={(e) => handleSwitchChange(e, 'quarantineAfterReturn')}
                    />
                </Box>
            </Dropdown>

            {/* Inbound Section */}
            <Dropdown title={t('inbound')} open>
                <Box>
                    {/* Custom Inbound Settings Switch */}
                    <Text light>{t('custom_inbound_settings')}</Text>
                    <ShSwitch
                        checked={data.customInboundSettings}
                        onChange={(e) => handleSwitchChange(e, 'customInboundSettings')}
                    />

                    {/* Conditional rendering based on customInboundSettings */}
                    {data.customInboundSettings && (
                        <>
                            {/* Lot Possible Switch */}
                            <Text light>{t('lot_possible')}</Text>
                            <ShSwitch
                                checked={data.lotPossible}
                                onChange={(e) => handleSwitchChange(e, 'lotPossible')}
                            />

                            {/* Lot Required Switch */}
                            <Text light>{t('lot_required')}</Text>
                            <ShSwitch
                                checked={data.lotRequired}
                                onChange={(e) => handleSwitchChange(e, 'lotRequired')}
                            />

                            {/* Batch Possible Switch */}
                            <Text light>{t('batch_possible')}</Text>
                            <ShSwitch
                                checked={data.batchPossible}
                                onChange={(e) => handleSwitchChange(e, 'batchPossible')}
                            />

                            {/* Batch Required Switch */}
                            <Text light>{t('batch_required')}</Text>
                            <ShSwitch
                                checked={data.batchRequired}
                                onChange={(e) => handleSwitchChange(e, 'batchRequired')}
                            />

                            {/* Repacking Required Switch */}
                            <Text light>{t('repacking_required')}</Text>
                            <ShSwitch
                                checked={data.repackingRequired}
                                onChange={(e) => handleSwitchChange(e, 'repackingRequired')}
                            />

                            {/* Inbound Process Type Dropdown */}
                            <Text light>{t('inbound_process_type')}</Text>
                            <InputDropdown
                                options={globalEnums["inboundline.process.type"]}
                                selected={data.inboundProcessType}
                                onChange={(selected) => handleInputChange({ target: { value: selected } }, 'inboundProcessType')}
                            />
                        </>
                    )}
                </Box>
            </Dropdown>

            {/* Safety Stock Section */}
            <Dropdown title={t('extra_options')} open>
                <Box>
                    {/* Safety Stock Input */}
                    <Text light>{t('safety_stock')}</Text>
                    <NumberInput
                        value={data.safetyStock}
                        onChange={(e) => handleInputChange(e, 'safetyStock')}
                    />
                </Box>
            </Dropdown>

            {/* Actions Box */}
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    padding: 3,
                    width: 1,
                    background: theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                }}
            >
                <GridActions data={data} id={props.data.id} />
            </Box>
        </Box>
    );
}


export default Info;