import { Box, useTheme } from "@mui/material";
import SHModal from "../Modal/SHModal";
import { tokens } from "../../../theme";
import React, { useState } from "react";

const ShChart = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if(window.innerWidth < 768){
            setOpen(true);
        }
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            {React.cloneElement(props.children, { onClick: () => handleOpen()})}

            <SHModal
                showClose={false}
                open={open}
                onClose={handleClose}
            >
                {React.cloneElement(props.children, {isModal: true})}
                <Box
                    sx={{
                        position:"relative",
                        zIndex:2,
                        marginTop:-5,
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        padding:3,
                        minHeight:250,
                        borderRadius:4,
                        borderTopLeftRadius:24,
                        borderTopRightRadius:24,
                    }}
                ></Box>
            </SHModal>
        </>
    );
}
export default ShChart;