import { useTranslation } from "react-i18next"

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal"
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper"
import ChooseShipperType from "./views/ChooseShipperType"
import UserEmail from "./views/UserEmail"
import { useDispatch } from "react-redux"
import { setResetTab } from "../../../../newapi/global/progressionTabSlice"
import UserBasicInfo from "./views/UserBasicInfo"
import UserConfirm from "./views/UserConfirm"
import { useSignupMutation } from "../../../../newapi/user/userSlice"

const MpAddUser = (props) => {
    // Call the setResetTab action to reset the state
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useSignupMutation} isModal title={t("add_user")}>
                <UserEmail/>
                <UserBasicInfo />
                <UserConfirm />
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddUser;