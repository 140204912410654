import { tokens } from "../../theme";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../styles/sh_daterangepicker.css';
import { Calendar, DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range';
import { Box, Popover, Slide, useTheme } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { addDays, format } from 'date-fns';
import Text from "./text/Text";
import Subheading from './text/Subheading';

import { ReactComponent as ArrowAlt } from '../../styles/svg/arrow_alt.svg';
import Shbutton from "./buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import {ReactC}
import Grow from '@mui/material/Grow';
import PopoverModal from "./Modal/PopoverModal";
import ButtonGroup from "./buttons/ButtonGroup";
import AddIcon from '@mui/icons-material/Add';
import { InputDropdown } from "./dropdowns/InputDropdown";
import { useTranslation } from "react-i18next";

// forwardRef
const SlideUpTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={500} ref={ref} {...props} />;
});

const GrowTransition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props}></Grow>
})

const Calendarbody = (props) => {
    const changeDate = (item) => {
        props.handleChange({
            selection: {
                startDate: item,
                endDate: item,
                key: "selection"
            }
        })
        if(window.innerWidth >= 768) {
            props.handleExportPopoverClose();
        }
    }
    return(
        <Calendar 
            onChange={item => changeDate(item)}
            // locale={locales[locale]} 
            date={typeof props.startDate !== "undefined" && props.startDate !== null ? new Date(props.startDate) : null} 
        />
    );
}

export const DateRangePickerBody = (props) => {
    const theme = useTheme();
    
    return(
        <DateRangePicker
            className={theme.palette.mode}
            onChange={item => props.handleChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            minDate={addDays(new Date(), -300)}
            maxDate={addDays(new Date(), 900)}
            ranges={props.state}
        />
    );
}

const PickerBody = (props) => {
    return (
        !props.singleSelect ? 
            <DateRangePickerBody {...props}></DateRangePickerBody>
        :
            <Calendarbody {...props}></Calendarbody>
    );
}

const data = []

const ShDateRangePicker = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const singleSelect = props.singleSelect ?? false;
    const darker = props.darker ?? false;

    const size = props.size ?? 40;

    const getPaddingAndGap = () => {
        if (size === 48) return 3
        if (size === 40) return 2.5
        if (size < 40) return 2
    }

    const getRadius = () => {
        if (size === 48) return 6
        if (size === 40) return 5
        if (size === 32) return 4
        if (size === 24) return 3
        if (size === 16) return 2
    }

    let isStandAlone = false;

    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const exportPopoverOpen = Boolean(exportAnchorEl);
    const exportPopoverId = exportPopoverOpen ? 'simple-popover' : undefined;

    const [state, setState] = useState([
        {
          startDate: typeof props.startDate !== "undefined" && props.startDate !== null ? new Date(props.startDate) : new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);
    useEffect(() => {
        if (typeof props.select === "function") {
            // If startDate is provided in props, use it; otherwise, use the default startDate from state
            const defaultStartDate = props.startDate ? new Date(props.startDate) : state[0].startDate;
            const defaultEndDate = props.startDate ? new Date(props.startDate) : state[0].endDate;
            props.select([{ startDate: defaultStartDate, endDate: defaultEndDate, key: 'selection' }]);
        }
    }, []); 
    const [selectedMobileRange, setSelectedMobileRange] = useState(-1);
    

    const handleExportPopoverClose = () => {
        setExportAnchorEl(null);
    };

    const handleExportPopoverClick = (event) => {
        setExportAnchorEl(event.currentTarget);
    };

    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (item) => {
        setState([item.selection]);
        if(typeof props.select !== "undefined") {
            props.select([item.selection]);
        }
    }

    const handleSelect = (selectedIndex) => {
        if(selectedIndex > -1){
            const selected = defaultStaticRanges[selectedIndex];
            const range = selected.range();
            handleChange({selection: {
                ...range,
                key:"selection"
            }});
        }
    }
    return (
        <Box flex={1}>
            <Box
             aria-describedby={exportPopoverId} icon onClick={handleExportPopoverClick}
                sx={{
                    height:size,
                    background: !darker ? `${theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"]}` : `${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]}` ,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    width:"100%",
                    gap:getPaddingAndGap(),
                    // width:"fit-content",
                    paddingX:getPaddingAndGap(),
                    borderRadius:getRadius(),
                    userSelect:"none",
                    transition:"250ms",
                    cursor:"pointer",
                    // background:"blue",
                    "& .arrow *" : {
                        transition:"250ms",
                        fill: !darker ? colors.grey[300] : colors.grey[300]
                    },
                    "& .arrow.dark *" : {
                        fill: colors.txt["secondary"]
                    },
                    "&:hover" :{
                        // background: colors.grey[200],
                        background:`${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]}`,
                        transition:"250ms",

                        "& .arrow *" : {
                            transition:"250ms",
                            fill:colors.grey[300]
                        },
                        "& .arrow.dark *" : {
                            fill: colors.txt["secondary"]
                        }
                    },
                }}
            >
                {!props.singleSelect ?
                    <>
                        <Text semibold>
                            {size > 40 ? format(state[0].startDate,'dd/MM/yyyy') : format(state[0].startDate, 'dd/MM')}
                        </Text>
                        <ArrowAlt className={`arrow ${theme.palette.mode === "dark" ? "dark" : null}`} />
                        <Text semibold>
                            {size > 40 ? format(state[0].endDate,'dd/MM/yyyy') : format(state[0].endDate, 'dd/MM')}
                        </Text>
                    </>
                :
                    <>
                        <Text semibold>
                            {size > 40 ? format(state[0].startDate,'dd/MM/yyyy') : format(state[0].startDate, 'dd-MM-yyyy')}
                        </Text>
                    </>
                }
            </Box>
            
            <PopoverModal
                id={exportPopoverId}
                open={exportPopoverOpen}
                anchorEl={exportAnchorEl}
                onClose={handleExportPopoverClose}
            >
                
                <Box 
                    sx={{
                        background:colors.bg["tertiary"]
                    }}
                >
                    <Box sx={{
                        display:"none",
                        "@media screen and (max-width:48em)" : {
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            flexDirection:"column",
                            paddingY:4
                            // textAlign:"center"
                        }
                    }}>
                        <Subheading textAlign="center">Datum</Subheading>
                        <Box
                            sx={{
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center",
                                width:1,
                                paddingX:3,
                                paddingTop:3,
                                "& .arrow.dark *" : {
                                    fill: colors.txt["secondary"]
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    "& span" : {
                                        display:"block"
                                    }
                                }}
                            >
                                <Text light>Start date</Text>
                                <Text semibold>{format(state[0].startDate,'MM/dd/yyyy')}</Text>
                            </Box>

                            <ArrowAlt className={`arrow ${theme.palette.mode === "dark" ? "dark" : null}`} />
                            <Box
                                sx={{
                                    "& span" : {
                                        display:"block"
                                    }
                                }}
                            >
                                <Text light>End date</Text>
                                <Text semibold>{format(state[0].endDate,'MM/dd/yyyy')}</Text>
                            </Box>
                        </Box>

                        
                    </Box>
                    <Box
                        sx={{
                            borderTopLeftRadius:24,
                            borderTopRightRadius:24,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        }}
                    >                
                        {!props.singleSelect &&
                            <Box
                                sx={{
                                    px:3,
                                    pt:3,
                                    display:"none",
                                    "@media screen and (max-width:48em)" : {
                                        display:'block'
                                    }
                                }}
                            >
                                <InputDropdown
                                    displayName={"label"}
                                    selected={selectedMobileRange > -1 ? defaultStaticRanges[selectedMobileRange]: -1}
                                    onChange={(selected) => handleSelect(selected)}
                                    options={defaultStaticRanges}
                                    noSelection={t("custom_date_selected")}
                                    closeOnSelection
                                />
                            </Box>
                        }

                        <PickerBody
                            handleExportPopoverClose={handleExportPopoverClose}
                            singleSelect={singleSelect}
                            startDate={props.startDate}
                            handleChange={handleChange}
                            state={state}
                        />
                    </Box>
                    
                </Box>

                <Box
                    component="footer"
                    sx={{
                        
                        display:"none",
                        "@media screen and (max-width: 48em)" : {
                            display:"block",
                            width:1,
                            paddingBottom: isStandAlone ? "32px" : "16px",
                            display:"flex",
                            // paddingRight:3,
                            zIndex:1058,
                            position:"relative",
                            alignItems:"center",
                            paddingTop:2,
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]}`,
                            // marginTop:"-80px"
                        }
                    }}
                    >

                        <Shbutton isCancel={true} onClick={handleExportPopoverClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>Cancel</Shbutton>
                        <ButtonGroup version={2}>
                        {/* <ButtonGroup options={[]}> */}
                            <Shbutton onClick={handleExportPopoverClose} className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>Select</Shbutton>
                        </ButtonGroup>
                        {/* <Shbutton color={theme.palette.mode === "dark" ? "blue" : "primary"}>Save</Shbutton> */}
                        
                        {/* {typeof activeTab.mobileActions !== "undefined" ? 
                            activeTab.mobileActions
                        : <Box flex={1}></Box>} */}
                        {/* <Shbutton isSidepanel={true} color="primary" variant="contained" endIcon={<ArrowRightAltIcon/>}>Edit</Shbutton> */}
                </Box>
            </PopoverModal>
        </Box>
    )
}

export default ShDateRangePicker;