import { Box, useTheme } from "@mui/material";
import Subheading from "../../../../components/theme/text/Subheading";
import Text from "../../../../components/theme/text/Text";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../../components/theme/sidepanel/SidepanelHeaderActiongroup";
import Orders from "../../../../environments/warehouse/outbound/dashboard/views/Orders";
import { useGridApiRef } from "@mui/x-data-grid";
import OverflowRow from "../../../../components/theme/OverflowRow";

import SPOrderDetails from "../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails";
import { useTranslation } from "react-i18next";
import TabView from "../../../../components/theme/TabViews/TabView";
import Heading from "../../../../components/theme/text/Heading";
import LineChart from "../../../../components/theme/charts/LineChart";
import linedata from "../../../../components/theme/charts/mockdata/line";
import AreaChart from "../../../../components/theme/charts/AreaChart";
import { useGetOrderCompareCountsDashboardQuery } from "../../../../newapi/order/ordersSlice";
import { OutboundChart } from "../../../../components/charts/outbound/fulfillment/OutboundChart";
import { useGetOutboundDashboardQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import { warehouseOutboundDashboardConfig } from "./warehouseOutboundDashboardConfig";

const OutboundDashboard = () => {
    const {t,ready} = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isExtended, setIsExtended] = useState(false);

    const [data, setData] = useState([]);
    const [chart, setChart] = useState([]);

    const { data : incData, isLoading } = useGetOrderCompareCountsDashboardQuery();
    useEffect(() => {
    
        if (!isLoading && incData) {
            setData(incData);
        }
    }, [incData, isLoading]);

    const {data: dashData, chartIsLoading} = useGetOutboundDashboardQuery();
    useEffect(() => {
        if (!chartIsLoading && dashData) {
            
            setChart(dashData);
        }
    }, [dashData, chartIsLoading]);
    
    const dates = Object.keys(chart); // Assuming data is an object with dates as keys
    const values = dates.map(date => dashData[date]);
    const chartData = [{
        name: 'Outbounds',
        data: values
      }];

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            {ready ?
                <>
                    <OverflowRow>
                        <Box
                            sx={{
                                
                                display:"grid",
                                gridTemplateColumns:"repeat(2, 1fr)",
                                gridTemplateRows:"repeat(2, 1fr)",
                                columnGap:3,
                                rowGap:3,
                                flex:2,
                                "@media screen and (max-width: 64em)" : {
                                    minWidth:"calc(100% - 56px)",
                                    // minWidth:1,
                                },
                                "@media screen and (max-width: 47.9375em)" : {
                                    minWidth:"calc(100% - 48px)",
                                    // minWidth:1,
                                }
                            }}>
                                <Box
                                    sx={{
                                        background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                        borderRadius:4,
                                        padding:3,
                                    }}
                                >
                                    <Subheading fontWeight="normal">{t("total_this_month")}</Subheading>
                                    <Box
                                        sx={{
                                            paddingTop:3,

                                        }}
                                    >
                                        <Heading>{incData?.total?.thisMonth ?? 0}</Heading>
                                        <Box>
                                            <Text light>vs</Text>&nbsp;<Text>{incData?.total?.lastMonth ?? 0}</Text>&nbsp;<Text light>{t("last_month")}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                                
                                <Box
                                    sx={{
                                        background:`${colors.green[400]}`,
                                        borderRadius:4,
                                        padding:3,
                                    }}
                                >
                                    <Subheading variant={theme.palette.mode === "dark" ? "grey" : "white"} fontWeight="normal">{t("overdue_today")}</Subheading>
                                    <Box
                                        sx={{
                                            paddingTop:3,

                                        }}
                                    >
                                        <Heading color={theme.palette.mode === "dark" ? "#31415f" : "#fff"}>{incData?.overdue?.today ?? 0}</Heading>
                                        {/* <Heading>870</Heading> */}
                                        <Box>
                                            <Text variant={theme.palette.mode === "dark" ? "grey" : "white"}>vs</Text>&nbsp;
                                            <Text variant={theme.palette.mode === "dark" ? "grey" : "white"} bold>{incData?.overdue?.yesterday ?? 0}</Text>&nbsp;
                                            <Text variant={theme.palette.mode === "dark" ? "grey" : "white"} >{t("yesterday")}</Text>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        // width:0.5,
                                        background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                        borderRadius:4,
                                        padding:3,
                                    }}
                                >

                                    <Subheading fontWeight="normal">{t("shipped_today")}</Subheading>
                                    <Box
                                        sx={{
                                            paddingTop:3,

                                        }}
                                    >
                                        <Heading>{incData?.shipper?.today ?? 0}</Heading>
                                        <Box>
                                            <Text light>vs</Text>&nbsp;<Text>{incData?.shipper?.yesterday ?? 0}</Text>&nbsp;<Text light>{t("yesterday")}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        // width:0.5,
                                        background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                        borderRadius:4,
                                        padding:3,
                                    }}
                                >

                                    <Subheading fontWeight="normal">{t("open_today")}</Subheading>
                                    <Box
                                        sx={{
                                            paddingTop:3,

                                        }}
                                    >
                                        <Heading>{incData?.open?.today ?? 0}</Heading>
                                        <Box>
                                            <Text light>vs</Text>&nbsp;<Text>{incData?.total?.yesterday ?? 0}</Text>&nbsp;<Text light>{t("yesterday")}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                        </Box>
                        <OutboundChart/>
                        
                       
                    </OverflowRow>

                    <TabView3
                        tabConfig={warehouseOutboundDashboardConfig}
                        sx={{
                            background:"green",
                            minHeight:"calc(100% - 431px)",
                            background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            position:"relative",
                            zIndex:2,

                            display:"flex",
                            flexDirection:"column",

                            "&:before" : {
                                content: "''",
                                display:"block",
                                width:"32px",
                                height:"32px",
                                position:"absolute",
                                left:0,
                                top:0,
                                zIndex:1,
                                backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                top:"40px",
                                height:"48px",
                                width:"24px",
                                borderTopLeftRadius:"24px",
                                boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                                transition:"250ms"
                            },

                            "&:after" : {
                                content: "''",
                                display:"block",
                                width:"32px",
                                height:"32px",
                                position:"absolute",
                                top:0,
                                zIndex:1,
                                backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                top:"40px",
                                right:0,
                                height:"48px",
                                width:"24px",
                                borderTopLeftRadius:"24px",
                                boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                                transform:"scaleX(-1)",
                                transition:"250ms"
                            },

                            "@media screen and (max-width: 47.9375em)" : {
                                "&:after, &:before" : {
                                    top:"-0px"
                                }
                            }
                        }}
                        title={t("outbound")}
                       
                    >
                        <>
                            <Orders config={warehouseOutboundDashboardConfig.orders}/>
                        </>
                    </TabView3>
                </> 
            : null }
        </Box>
    );
};

export default OutboundDashboard;