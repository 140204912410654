import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const STOCK_BASE = "/orders";
const buildUrl = (endpoint) => STOCK_BASE + endpoint;

const ordersApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrders: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Orders' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrders', 'Orders'),
        }),
        getOrder: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'OrdersSingle' }],
        }),
        getOrderPickingList: builder.query({
            query: body => ({
                url: buildUrl(`/pickinglines`),
                method: 'POST',
                body: body,
                meta: { customContentType: true }, 
            }),
            invalidatesTags: [`OrderPickingList`],
        }),
        updateOrderPickingLine: builder.mutation({
            query: pickingLineInfo => ({
                url: buildUrl(`/${pickingLineInfo.orderId}/pickinglines/${pickingLineInfo.id}`),
                method: 'PUT',
                body: pickingLineInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrderPickingLines' }],
        }),
        getOrderPickingListPDF: builder.mutation({
            query: body => ({
                url: buildUrl(`/pickinglines?type=pdf`),
                method: 'POST',
                body: body,
                responseHandler: async (response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    // const blob = await response.blob();
                    const blob = new Blob([await response.blob()], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    return {data: url};
                },
            }),
        }),
        getOrderPickingLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/pickinglines`),
                method: 'POST',
                body: args.body,
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'OrderPickingLines' }],
        }),
        getOrderStatusCounts: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/orderstatuscounts?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrdersStatusCounts' }], 
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrdersStatusCounts', 'OrdersStatusCounts')
        }),
        getDashboardOrderStatusCounts: builder.query({
            query: () => ({
                url: buildUrl(`/orderstatuscounts/dashboard`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: () => [{ type: 'OrdersStatusCountsDashboard' }], 
            //onQueryStarted: muiDataGridStarted.bind(null, 'getDashboardOrdersStatusCounts', 'DashboardOrdersStatusCounts')
        }),
        getOrderCompareCountsDashboard: builder.query({
            query: () => ({
                url: buildUrl(`/ordercomparecountsdashboard`),
                method: 'GET',
            }),
            providesTags: () => [{ type: 'getOrderCompareCountsDashboard' }], 
            //onQueryStarted: muiDataGridStarted.bind(null, 'getDashboardOrdersStatusCounts', 'DashboardOrdersStatusCounts')
        }),
        getOrderLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/orderlines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrderLines' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrderLines', 'OrderLines')
        }),
        getOrderColli: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/colli?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrderColli' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrderColli', 'OrderColli')
        }),
        getOrderMutations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/mutations?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'OrderMutations' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getOrderMutations', 'OrderMutations')
        }),
        reserverOrderLine: builder.mutation({
            query: orderLineInfo => ({
                url: buildUrl(`/${orderLineInfo.orderId}/reserve`),
                method: 'POST',
                body: orderLineInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrdersSingle'}, { type: 'OrderLines' }, { type: 'Orders' }],
        }),
        splitOrder: builder.mutation({
            query: info => ({
                url: buildUrl(`/${info.id}/split`),
                method: 'POST',
                body: {products:info.products},
            }),
            invalidatesTags: (result, error, arg) => [{type: 'OrderPickingLines' }, {type: 'OrderLines'}, {type: 'Orders'}],
        }),
        
    })
});

// console.log(apiSlice);
export const { 
    useGetOrdersQuery,
    useGetOrderQuery,
    useGetOrderStatusCountsQuery,
    useGetDashboardOrderStatusCountsQuery,
    useGetOrderCompareCountsDashboardQuery,
    useGetOrderLinesQuery,
    useGetOrderColliQuery,
    useGetOrderMutationsQuery,
    useGetOrderPickingListQuery,
    useGetOrderPickingListPDFMutation,
    useReserverOrderLineMutation,
    useGetOrderPickingLinesQuery,
    useUpdateOrderPickingLineMutation,
    useSplitOrderMutation
} = ordersApiSlice;
