import { Box, ButtonBase, Fade, InputBase, Typography, useTheme } from "@mui/material"

import { ReactComponent as StockVisual } from '../../../styles/svg/rmaportal/stockvisual.svg';
import { ReactComponent as Triangle } from "../../../styles/svg/triangle.svg";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";
import cx from 'classnames';
import React, { createContext, useContext, useRef, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import Text from "../../theme/text/Text";


const HoverContext = createContext();
export const HoverProvider = ({ children }) => {
    const [hoverStack, setHoverStack] = useState([]);
    const [activeElement, setActiveElement] = useState(null);

    const addHover = (element) => {
        setHoverStack((prevStack) => [...prevStack, element]);
    };

    const removeHover = (element) => {
        setHoverStack((prevStack) => prevStack.filter((el) => el !== element));
    };

    const getTopHover = () => {
        return hoverStack[hoverStack.length - 1];
    };

    return (
        <HoverContext.Provider value={{ addHover, removeHover, getTopHover, activeElement, setActiveElement }}>
            {children}
        </HoverContext.Provider>
    );
};

export const useHover = () => useContext(HoverContext);

const Editable = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { addHover, removeHover, getTopHover, activeElement, setActiveElement } = useHover();
    const elementRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);

    const handleMouseEnter = () => {
        addHover(elementRef.current);
    };

    const handleMouseLeave = () => {
        removeHover(elementRef.current);
    };
    // const handleClick = () => {
    //     setIsClicked(!isClicked);
    // };
    // const handleClick = (event) => {
    //     if (getTopHover() === elementRef.current) {
    //         setIsClicked(!isClicked);
    //         event.stopPropagation(); // Stop event propagation
    //     }
    // };    
    const handleClick = (event) => {
        setActiveElement(elementRef.current);
        event.stopPropagation(); // Stop event propagation
    };

    const isHovered = getTopHover() === elementRef.current;
    const isActive = activeElement === elementRef.current;


    return (
        <Box
            ref={elementRef}
            sx={{
                width:props.width ?? null,
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    borderRadius: props.radius ?? null, // Apply the captured borderRadius
                    background: isHovered ? "rgba(255, 255, 255, 0.2)" : "transparent", // More prominent background
                    border: isHovered || isActive ? "2px solid rgba(255, 255, 255, 0.8)" : "2px solid transparent", // More prominent border
                    zIndex: 1, // Ensure overlay is above the background
                    pointerEvents: "none", // Allow interactions to pass through
                    transition: "background 200ms cubic-bezier(0.4, 0, 0.2, 1), border 200ms cubic-bezier(0.4, 0, 0.2, 1)",
                },
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            {props.children}
            <Fade
                in={isActive}
                timeout={200}
                easing={{
                    enter: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
                }}
            >
                <Box
                    sx={{
                        top:"calc(100% + 12px)",
                        left:"50%",
                        transform:"translateX(-50%)",
                        // marginTop: 2,
                        height:40,
                        display:"flex",
                        pl:0.5,
                        alignItems:"center",
                        background: theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                        position:"absolute",
                        zIndex:18,
                        borderRadius: 5,
                        gap:1.5,
                        pr:2,
                        "& .popover-triangle" : {
                            position:"absolute",
                            left:"50%",
                            top:-11,
                            width:32,
                            height:12,
                            zIndex:200,
                            transform:"translateX(-50%)",
                            "& *" : {
                                fill: theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                            }
                        },
                    }}
                >
                    {/* <Triangle 
                        className="popover-triangle"
                    ></Triangle> */}
                    <Box
                        sx={{
                            height:32,
                            width:32,
                            borderRadius:4,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:'center',
                            background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                            color: theme.palette.mode === "dark" ? colors.pastel.light.blue[600] : colors.primary[400]
                        }}
                    >
                        <EditIcon fontSize="small"/>
                    </Box>
                    <Text semibold>Editting</Text>
                </Box>
            </Fade>
        </Box>
    );
}

const EditRegisterReturn = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <HoverProvider>
            <Box
            component={"main"}
                sx={{
                    height:1,
                    // minHeight:"calc(100%)"
                    zIndex:1,
                }}
            >
                <Editable radius={4}>
                    <Box
                        component={"section"}
                        sx={{
                            borderTopLeftRadius:16,
                            borderTopRightRadius:16,
                            background:"rgba(65,128,246,.10)",
                            background: "radial-gradient(circle, rgba(65,128,246,0.25) 0%, rgba(65,128,246,0.10) 100%)",
                            width:1,
                            minHeight:"800px",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            height:"75vh",
                            height:1,
                            position:'relative',
                            overflow:"hidden",

                            ".stock-visual" : {
                                position:'absolute',
                                bottom:"0",
                                width:1,
                            }
                        }}
                    >

                        <Box
                            sx={{
                                width:500,
                            }}
                        >

                            <Editable radius={8}>   
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize:"2.75em",
                                        fontWeight:"bold",
                                        color:"#31415f",
                                        textAlign:"center",
                                        mb:3,
                                    }}
                                >
                                    {t("create_return")}
                                </Typography>
                            </Editable>

                            <Editable radius={6}>   
                                <Box 
                                    display="flex"
                                    sx={{
                                        width:1,
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"rgba(65,128,246,.15)",
                                        border:"1px solid rgba(65,128,246,.15)",
                                        height:48,
                                        pl:1,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#fff",
                                            "webkit-text-fill-color":"#fff",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #23232329",
                                            WebkitTextFillColor: "white",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        // 'error': !!touched.ordernumber && !!errors.ordernumber
                                    })}
                                >
                                    <Editable radius={4}>   
                                        <Box
                                            className={cx({
                                                // 'error': !!touched.ordernumber && !!errors.ordernumber
                                            })}
                                            sx={{
                                                height:32,
                                                width:142,
                                                px:2,
                                                borderRadius:4,
                                                textAlign:"center",
                                                fontWeight:"bold",
                                                color: "rgb(65,128,246)",
                                                background:colors.primary[100],
                                                display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",

                                                "&.error": {
                                                    background:colors.red[100],
                                                    color: colors.red[400]
                                                },
                                            }}
                                        >
                                            {t("ordernumber")}
                                        </Box>
                                    </Editable>  
                                    <Editable radius={4}>    
                                        <InputBase 
                                            autoFocus
                                            type={"text"}
                                            sx={{
                                                pl:1.5,
                                                flex:1,
                                                color:"#31415f",
                                                fontSize:16,
                                                fontWeight:"500"
                                            }} 
                                            placeholder="#" 
                                            
                                        />
                                    </Editable> 
                                </Box>
                            </Editable>   

                            <Editable radius={8}>   
                                <Box 
                                    display="flex"
                                    sx={{
                                        mt:2,
                                        width:1,
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"rgba(65,128,246,.15)",
                                        border:"1px solid rgba(65,128,246,.15)",
                                        height:48,
                                        pl:1,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#fff",
                                            "webkit-text-fill-color":"#fff",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #23232329",
                                            WebkitTextFillColor: "white",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        // 'error': !!touched.email && !!errors.email
                                    })}
                                >
                                    <Editable radius={6}>   
                                        <Box
                                            className={cx({
                                                // 'error': !!touched.email && !!errors.email
                                            })}
                                            sx={{
                                                height:32,
                                                width:142,
                                                
                                                px:2,
                                                borderRadius:4,
                                                textAlign:"center",
                                                fontWeight:"bold",
                                                color: "rgb(65,128,246)",
                                                background:colors.primary[100],
                                                display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                "&.error": {
                                                    background:colors.red[100],
                                                    color: colors.red[400]
                                                },
                                            }}
                                        >
                                            {t("email")}
                                        </Box>
                                    </Editable>
                                    <Editable radius={6}>   
                                        <InputBase 
                                            type={"email"}
                                            sx={{
                                                pl:1.5,
                                                flex:1,
                                                color:"#31415f",
                                                fontSize:16,
                                                fontWeight:"500"
                                            }} 
                                            placeholder={t('email')} 
                                            
                                        />
                                    </Editable>
                                </Box>
                            </Editable>   
                            
                            <Box
                                sx={{
                                    display:"flex",
                                    justifyContent:"flex-end",
                                    mt:2,
                                    width:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        // marginTop:4
                                    }

                                }}
                            >
                                <Editable radius={8} width={1}>   
                                    
                                    <Box
                                // onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                                        sx={{
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            color:"white",
                                            fontWeight:"500",
                                            fontSize:16,
                                            // justifyContent:"flex-start",
                                            height:"48px",
                                            width:"48px",
                                            alignItems:"center",
                                            // pl:2,
                                            // background:"rgba(255,255,255,.15)",
                                            background:"rgba(65,128,246,1)",
                                            borderRadius:6,
                                            flexShrink:0,
                                            width:1,
                                            transition:"250ms",
                                            // "&:hover" : {
                                            //     background:"#0057FA",
                                            //     transition:"250ms"
                                            // },    
                                            
                                        }}
                                    >
                                        {t("request_return")}
                                        {/* <ArrowBackIcon fontSize="small"/> */}
                                    </Box>
                                </Editable>
                                {/* <Button type="submit" color="primary" variant="contained">
                                    Create new user
                                </Button> */}
                            </Box>

                            <Typography
                                sx={{
                                    mt:3,
                                    textAlign:"center",
                                    fontWeight:"500",
                                    color:"#31415f",
                                }}
                            >
                                {t("retour_check_status")}
                            </Typography>
                        </Box>

                        <StockVisual className="stock-visual"/>
                    </Box>
                </Editable>
            </Box>
        </HoverProvider>
    )
}

export default EditRegisterReturn;