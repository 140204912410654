
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import InputIcon from '@mui/icons-material/Input';

export const settingsWarehouseOutBoundCarriersConfig = {
    all:  {
        title: "all_carriers",
        subTitle: "all_carriers_registered",
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
    //materials not added uncomment below when you're done adding
    // assginedMaterials:  {
    //     title: "assigned_materials",
    //     icon: <ExitToAppOutlinedIcon/>,
    //     isSingleDataGrid: true,
    // },
    //standard not added uncomment below when you're done adding
    // standard: {
    //     title: "standard_carriers",
    //     subTitle: "standard_carriers_description",
    //     // subTitle: "These carriers are the ones that will be default to orders when conditions meet",
    //     icon: <ChecklistOutlinedIcon/>,
    //     isSingleDataGrid: true,
    // }
};
