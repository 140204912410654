import { Box, ButtonBase} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';

import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import { useTranslation } from "react-i18next";
import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import StreamRule from "../../../../Streams/StreamRule";

import { DndContext, KeyboardSensor, MouseSensor, PointerSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import Heading from "../../../../theme/text/Heading";
import { useGetCarriersTypesQuery } from "../../../../../newapi/carrier/carrierSlice";
import { countriesFromLocale } from "../../../../../utils/countries";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { showToastMessage, showToastMessagePostRequest, showToastMessageRequestError } from "../../../../../utils/toasts";
import MAddStreamRuleShipper from "../../../Modals/MAddStreamRuleShipper";
import { useEditStreamRulesMutation, useGetStreamRulesQuery } from "../../../../../newapi/global/stream/streamSlice";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box sx={{
            display:"flex",
            justifyContent:"space-between",
            width:1,
            // justifyContent:"flex-end",
            alignItems:"center",
            // borderRadius:6,
            overflow:"hidden",
            "@media screen and (max-width: 47.9375em)" : {
                // pl:1.5,
                justifyContent:"flex-end",
            }
        }}>
            <Box
                sx={{
                    width:500,
                    ml:5,
                    "@media screen and (max-width: 47.9375em)" : {
                        // pl:1.5,
                        display:"none"
                    }
                }}
            >
                <Shbutton
                    block
                    onClick={() => props.handleOpen()}
                    className={"group-btn space-end-icon"}
                    // onClick={props.action}
                    color={"primaryGrey"}
                    variant="contained"
                    endIcon={<AddIcon />}
                >
                    {t('create_rule')}
                </Shbutton>
            </Box>
            <ButtonGroup version={2}>
                <Box
                    sx={{
                        width:1,
                        flex:1,
                        "@media screen and (min-width: 47.9375em)" : {
                            display:"none"
                        },
                        mx:"auto",
                    }}
                >
                <Shbutton
                    onClick={() => props.handleOpen()}
                    className={"group-btn"}
                    // onClick={props.action}
                    color={"primaryGrey"}
                    variant="contained"
                    endIcon={<SaveIcon />}
                >
                    {t('create_rule')}
                </Shbutton>
                </Box>
                <Shbutton
                    onClick={() => props.saveStreamRules()}
                    className={"group-btn"}
                    // onClick={props.action}
                    color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained"
                    endIcon={<SaveIcon />}
                >
                    {t('save')}
                </Shbutton>
            </ButtonGroup>
        </Box>
    );
};

const StreamShippers = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const { data: shippers } = useGetShippersSelectQuery();
    const {data: packageAdvices} = useGetCarriersTypesQuery();
    
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);
    const [editStreamRules, { 
        isSuccess : logOutSuccess, 
        isError: logOutIsError,
        error: logOutError
    }] = useEditStreamRulesMutation();


    const {data, isLoading,  isFetching, isError,refetch} = useGetStreamRulesQuery(props.streamId,        {
        skip: !props.streamId
    });


    const [streamRules, setStreamRules] = useState([]); // Ensure each rule has a unique identifier

    useEffect(() => {
        if (!isLoading && !isError && data) {
            // console
            setStreamRules(data.streamrules)
            // setStreamRules(data);
        }
    }, [data, isLoading, isError]);

    const saveStreamRules = async () => {
        const newRules = streamRules?.map((rule, index) => {
            return {
                ...rule, // Spread the properties of the current rule
                priority: parseInt(index), // Assign the new priority value
                streamrulelines: rule.streamrulelines.map(line => ({
                    ...line,
                    value: parseInt(line.value, 10) // Ensure value is an integer
                }))
            };
        });


        //Sanity checker
        const p = !newRules.some((rule) => {
            const hasValidStreamRuleLines = rule.streamrulelines.length > 0;
            const hasInvalidValue = rule.streamrulelines.some((line) => 
                {
                    
                    return Object.values(line).some((value) => value === -1);
                }
            );
        
            if (!hasValidStreamRuleLines || hasInvalidValue) {
                showToastMessage("error", "Een van de regels is niet geldig.");
                return true; 
            }
            return false; 
        });

        if(p) { 
            try {
                // If validation is successful, proceed with the addSupplier call
                const data = await editStreamRules({streamId: props.streamId,streamRules: newRules}).unwrap();
                showToastMessagePostRequest(t, data);
                setStreamRules(newRules);
            } catch (err) {
                showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
            }
        }
     }

     const [addModalOpen, setAddModalOpen] = useState(false);
     const addModal = {
         open: addModalOpen,
         setOpen: setAddModalOpen
     }
 
     const handleClose = (addRule, selectedShipperIndex) => {
        setAddModalOpen(false);
        if (addRule) {
            const highestId = streamRules.reduce((maxId, rule) => Math.max(maxId, parseInt(rule.id, 10)), 0);
            const highestPriority = streamRules.reduce((maxPriority, rule) => Math.max(maxPriority, parseInt(rule.priority, 10)), 0);
            // const highest = Math.max(highestId, highestPriority);
    
            setStreamRules(prevRules => [
                ...prevRules,
                {
                    id: (highestId + 1).toString(),
                    priority: (highestPriority + 1).toString(),
                    shipperId: shippers[selectedShipperIndex].id,
                    streamrulelines: [
                        {
                            key: -1,
                            nominator: -1,
                            value: -1
                        }
                    ]
                }
            ]);
        }
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions saveStreamRules={saveStreamRules} handleOpen={() => setAddModalOpen(true)}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[saveStreamRules]);


    const [active, setActive] = useState(null)
    


    const onDragEnd = event => {
    const {active, over} = event;
        if(over === null) return;
        if(active.id === over.id) return;
        setStreamRules(rules => {
            const oldIndex = streamRules?.findIndex((rule) => rule.id === active.id);
            const newIndex = streamRules?.findIndex((rule) => rule.id === over.id);
            return arrayMove(streamRules,oldIndex,newIndex);
        })

        setActive(null);
    }
    
    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 2
        }
    })
    const mouseSensor = useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
    });

    //   const touchSensor = useSensor(TouchSensor);
    const keyboardSensor = useSensor(KeyboardSensor)
    
    const sensors = useSensors(
        mouseSensor,
        touchSensor,
        keyboardSensor,
        pointerSensor
    )

    return (
        <Box height={1}
            sx={{
                display:"flex",
                // position:'relative',
                // zIndex:1,
                gap:3,
                padding:3,
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:9
                },
                "@media screen and (max-width: 47.9375em)" : {
                    // pl:1.5,
                }
            }}
        >
            {/* <Box>
                {streamRules.map((rule,index) => (
                    <Heading>{index}.</Heading>
                ))}
            </Box> */}
            
            <Box 
                sx={{
                    display:"flex",
                    gap: 3,
                    overflow: "hidden",
                    flexDirection: 'column',
                }}
            >
            {/* <DropWrapper> */}
                <DndContext 
                    sensors={sensors} 
                    collisionDetection={closestCenter} 
                    autoScroll 
                    onDragEnd={onDragEnd}

                    onDragStart={({ active }) => {
                        setActive(active);
                    }}
                    onDragCancel={() => {
                    setActive(null);
                    }}
                >
                    <SortableContext items={streamRules} strategy={verticalListSortingStrategy}>
                        {streamRules !== null && streamRules.map((rule,index) => (

                            <Box
                            key={rule.id}
                            sx={{
                                touchAction:"none",
                                width:1,
                                // width: 'calc(50% - 12px)', // Subtract the gap from the total width
                                display: 'flex',
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    gap:1.5,
                                    position:"relative",
                                }
                            }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            position:"absolute",
                                            top:20,
                                            left:16,
                                            zIndex:4000,
                                        }
                                    }}
                                >
                                    <Heading>{(index+1).toString() + "."}</Heading>
                                </Box>
                                <StreamRule
                                    countries={countries}
                                    shippers={shippers}
                                    packageAdvices={packageAdvices}
                                    setStreamRules={setStreamRules}
                                    streamRule={rule}
                                    // Other props if needed
                                />
                            </Box>
                        ))}
                    </SortableContext>
                </DndContext>
            </Box>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    zIndex:21232231,
                    backdropFilter:"blur(12px)",
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    },
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                <GridActions
                    handleOpen={() => setAddModalOpen(true)}
                    saveStreamRules={saveStreamRules}
                />
            </Box>
            <MAddStreamRuleShipper shippers={shippers} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            {/* <MAddStre */}
            {/* // <MAddSupplier onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/> */}
            {/* </DropWrapper> */}
        </Box>
    );
}

export default StreamShippers;

