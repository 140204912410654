import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import { useAddProductMutation } from "../../../../newapi/warehouse/productSlice";
import Details from "./views/Details";
import ChildProducts from "./views/ChildProducts";

const MpAddWebshop = (props) => {
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useAddProductMutation} isModal title={t("add_virtual_product")}>
                <Details/>
                <ChildProducts/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddWebshop;