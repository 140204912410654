import { Box, useTheme } from "@mui/material"
import BaseCard from "../../BaseCard"
import { tokens } from "../../../../../theme";
import Text from "../../../text/Text";
import CardLabel from "../../../label/CardLabel";
import TopRight from "../../../positionals/TopRight";
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../../skeleton/ShTextSkeleton";
import BottomRight from "../../../positionals/BottomRight";
import BottomLeft from "../../../positionals/BottomLeft";

const RelationVirtualProductCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"} 
                        gap={props.skeleton ? 0.5 : null} 
                        width={props.skeleton ? "58%" : null}
                    >
                        {props.skeleton ?
                            <ShTextSkeleton/>
                            :
                            <Text noBreak bold>{props.description}</Text>
                        }
                        {props.skeleton ?
                            <ShTextSkeleton />
                            :
                            <Text light>{props.sku}</Text>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel 
                        variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                            // variant={"grey"}  
                            // background={theme.palette.mode === "dark" ? colors.grey[400] : null} 
                            // color={theme.palette.mode === "dark" ? colors.txt["primary"] : null} 
                            position="child-tr"
                        >
                            {props.childProducts ? props.childProducts.length : 0}
                        </CardLabel>
                    </TopRight>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default RelationVirtualProductCard;