import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Text from "../../../../../components/theme/text/Text";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme"; 
import Subheading from "../../../../../components/theme/text/Subheading";
import Heading from "../../../../../components/theme/text/Heading";
import Inventory2Icon from '@mui/icons-material/Inventory2';

// import Echeck from "./views/Echeck";
import { useGridApiRef } from "@mui/x-data-grid";
import All from "./views/All";
import MSelectWarehouses from "../../../../../components/global/Modals/MSelectWarehouses";
import TabView from "../../../../../components/theme/TabViews/TabView";
import TabView3 from "../../../../../components/theme/TabView3/TabView3";
import { settingsWarehouseOfficePrintersConfig } from "./settingsWarehouseOfficePrintersConfig";

const Printers = forwardRef((props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const streamApiRef = useGridApiRef();

    const [open, setOpen] = useState(false);
    const warehouseModal = {
        open: open,
        setOpen: setOpen
    }

    const [addStandardOpen, setAddStandardOpen] = useState(false);
    const addStandardModal = {
        open: addStandardOpen,
        setOpen: setAddStandardOpen
    }

    useEffect(() => {
        console.log(open);
    },[addStandardModal])

    useImperativeHandle(ref, () => ({
    }));
    return (
        <Box 
        sx={{
            background:"", 
            width:1,
            paddingTop:9,
            position:"relative",
            "@media screen and (max-width:47.9375em)" : {
                    background:"", 
                width:1,
                marginTop:9,
                height: "calc(100% - 72px)",
                // position:"relative",
                paddingX:4,
                paddingTop:4,
                overflow:"hidden",
                display:"flex",
                flexDirection:"column",
                
            },
            "@media screen and (max-width: 47.9375em)" : {
                paddingX:0,
                paddingTop:3,
                background:colors.bg["tertiary"],
                "&:before,&:after":{
                    display:"none"
                }
            },
        }}
        >

            <Box
                sx={{
                    display:"none",
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        width:1,
                        marginBottom:4,
                        paddingX:3,
                        // marginBottom:6.5,
                    }
                }}
            >
                <Box
                
                    sx={{
                        display: "flex",
                        alignItems:"flex-start",
                        flexDirection:"column",
                        gap:0.5,
                    }}
                >
                    <Heading>Stream settings</Heading>
                    <Text light>All settings in this category pertain to streams</Text>
                </Box>

                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        gap:2,
                        height:40,
                        background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                        borderRadius:5,
                        paddingX:2,
                        color:"#fff",
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                    onClick={() => warehouseModal.setOpen(true)}
                >
                    <Box>
                        <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
                    </Box>
                    <KeyboardArrowDownIcon color="white"/>
                </Box>
            </Box>

                    
            <TabView3
                tabConfig={settingsWarehouseOfficePrintersConfig}
                // viewType="bodytabs"
                sx={{

                    background:"green",
                    minHeight:"calc(100%)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,
                    display:"flex",
                    flexDirection:"column",
                    // "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },
                        "@media screen and (max-width: 47.9375em)" : {

                            "&:before, &:after" : {
                                top:0,
                            }
                        }
                    // }
                  
                }}
                // title={panel.title}
                // views={panel.views}
                // tabClasses={"btn-body-tab"}
            >
                <>
                    <All config={settingsWarehouseOfficePrintersConfig}  warehouseModal={warehouseModal}/>
                    {/* <Standard apiRef={standardCarriersApiRef}/>
                    <Materials apiRef={materialsApiRef}/> */}
                </>
            </TabView3>

            <MSelectWarehouses
                open={warehouseModal.open}
                handleClose={() => warehouseModal.setOpen(false)}
            />

        </Box>
    )
});

export default Printers;