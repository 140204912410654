import { Box, Modal, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import SHModal from "../../../Modal/SHModal";
import Text from "../../../text/Text";
import Subheading from "../../../text/Subheading";
import { tokens } from "../../../../../theme";
import FbContextProvider, { useFilterbuilder } from "../../../../../contexts/FbState";
import Shbutton from "../../../buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import Progressbar from "../../../Progressbar";


const getVisibleViews = (views) => {
    let newViews=  [];
    for(let i =0; i < views.length; i++) {
        if(typeof views[i].skipStep === undefined || !views[i].skipStep) {
            newViews.push(views[i]);
        }
    }
    return newViews;
}

const FilterRuleBuilder = forwardRef((props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentView, setCurrentView] = useState(props.defaultView ?? 0);
    const updateViewState = (value) => setCurrentView(value);
    const primaryViews = props.views ?? alert("Builder views cannot be empty");
    const views = getVisibleViews(primaryViews);
    const filter = useFilterbuilder();
    const progressRef = useRef();

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    useEffect(() => {
        if(typeof progressRef.current !== "undefined" && progressRef.current !== null) {
            progressRef.current.setProgress((currentView+1) / views.length);
        }
    },[currentView,views]);
    
    useImperativeHandle(ref, () => ({
        setView : (view) => {
            updateViewState(view);
        },
        nextView: () => {
            let view = currentView + 1;
            updateViewState(view);
        },

        previousView() {
            let view = currentView -1;
            updateViewState(view);
        },

        toDefaultView() {
            setCurrentView(() => props.defaultView ?? 0);
        }
    }));
    return (
        <SHModal
            open={props.open}
            onClose={() => props.handleClose(false)}>
            {/* Header */}
            <Box
                sx={{
                    padding:3,
                    paddingBottom:4
                }}
            >
                {/* Progressbar */}
                <Progressbar ref={progressRef}></Progressbar>
                
                {/* Header text */}
                <Box marginTop={4}>
                    <Text light>STEP {currentView+1}/{views.length}</Text>
                    <Subheading>{views[currentView].header.title}</Subheading>
                    {typeof views[currentView].header.subtitle !== "undefined" ? <Text>{views[currentView].header.subtitle}</Text> : null}
                </Box>

                {/* Preview */}
                {typeof views[currentView].header.preview !== "undefined" ? views[currentView.header.preview] : null}
            </Box>
            
            {/* body */}
            {/* {alert(currentView === 0 && isStandAlone)} */}
            <Box
                sx={{
                    padding:typeof views[currentView].body.smallerPadding !== undefined && views[currentView].body.smallerPadding ? 1.5 : 3,
                    // paddingBottom: currentView == 0 && isStandAlone ? 5 : 8,
                    paddingBottom: currentView == 0 && isStandAlone ?5 : null,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderTopLeftRadius:"16px",
                    borderTopRightRadius:"16px",
                    "@media screen and (max-width:47.9375em)" : {
                        maxHeight:300,
                        overflow:"auto"
                    }
                }}
            >
                {views[currentView].body.component}
            </Box>
            {currentView > 0 ?
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    // marginTop:"-80px"
                }}
            >
                
                    <Shbutton isCancel={true} className={"always-visible"} onClick={() => ref.current.previousView()} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>Back</Shbutton>
                    {currentView === views.length-1 ?
                        <Shbutton isSidepanel color="green" onClick={() => props.handleClose(true)} variant="contained" endIcon={<AddIcon/>}>Add</Shbutton>
                    :null}
                    {/* {typeof activeTab.mobileActions !== "undefined" ? 
                        activeTab.mobileActions
                    : <Box flex={1}></Box>} */}
                    {/* <Shbutton isSidepanel={true} color="primary" variant="contained" endIcon={<ArrowRightAltIcon/>}>Edit</Shbutton> */}
            </Box>
            : null}

        </SHModal>
    )
});

export default FilterRuleBuilder;