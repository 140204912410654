/////DEPRECATED

import { Box, ButtonBase, Typography, useTheme } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { tokens } from "../../theme";

import cx from "classnames";
import * as yup from "yup";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as StockVisual } from '../../styles/svg/rmaportal/stockvisual.svg';
import Subheading from "../../components/theme/text/Subheading";
import Heading from "../../components/theme/text/Heading";

import { ReactComponent as PostNLLogo } from '../../styles/svg/rmaportal/postnl-detail.svg';
import CheckIcon from '@mui/icons-material/Check';
import { useGetOrderForReturnQuery, useGetStatusQuery } from "../../newapi/return/returnsSlice";
import { useEffect } from "react";

const SliderLogo = (props) => {
    const active = (props.value-1) === props.status;
    const completed = props.status > (props.value-1);

    const content = () => {
        if(props.status > (props.value-1)) return <CheckIcon/>;
        return props.value;
    }

    // const color = () => {
    //     if(props.status > (props.value-1)) return <CheckIcon/>;
    //     return props.value;
    // }

    return (
        <Box
            sx={{
                width:props.sliderItemWidth,
                flexShrink:0,
                // background:"orange",
                borderRadius:5,
                float:"left",
            }}
        >
            <Box

                className={cx(``, {
                    'active': active,
                    'completed':completed
                })}
                sx={{
                    mt:"-12px",
                    height:56,
                    width:56,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    borderRadius:8,
                    background:"#83ACFA",
                    boxShadow:"0px 0px 19px 0px rgba(65,128,248,.35)",
                    color:"#31415f",
                    "&.active" : {
                        background:"#0057FA",
                        boxShadow:"none",
                        color:"#fff",
                    },
                    "&.completed" : {
                        background:"#0AB2BD",
                        color:"#fff",
                        boxShadow:"none",
                    },
                }}
            >
                <Typography
                    sx={{
                        fontWeight:"bold",
                        fontSize:18,
                        display:"flex"
                    }}
                >
                    {content()}
                    {/* {(props.status+1) === props.value ? props.value} */}
                </Typography>
            </Box>
        </Box>
    )
}

const RmaRegistered = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                width:props.width,
                pr:8,
                // background:"blue",
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontWeight:700,
                    fontSize:21,
                    color:"#31415f",
                }}
            >
                Retournering Aangemeld
            </Typography>

            <Typography
                variant="span"
                sx={{
                    // fontWeight:700,
                    fontSize:16,
                    color:"#31415f",
                }}
            >
                Je retounering is aangemeld en bij ons bekend, maar
                is nog niet overgedragen aan de vervoerder
            </Typography>
            
        </Box>
    )
}

const RmaDeposited = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                width:props.width,
                pr:8,
                // background:"blue",
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontWeight:700,
                    fontSize:21,
                    color:"#31415f",
                }}
            >
                Retournering Afgegeven
            </Typography>

            <Typography
                variant="span"
                sx={{
                    // fontWeight:700,
                    fontSize:16,
                    color:"#31415f",
                }}
            >
                Je retounering is afgegeven aan PostNL en komt onze kant op,
                je krijgt bevestiging zodra dit is gebeurd.
            </Typography>
            
        </Box>
    )
}

const RmaStatus = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    
    const {relation} = useParams();
    console.log(relation);
    const {data: statusData, isError} = useGetStatusQuery(relation, {
        skip: !relation
    });  

    const navigate = useNavigate();

    const sliderItemWidth = window.innerWidth > 767 ? 400 : 350;

    const handleFormSubmit = async (values) => {

    }

    useEffect(() => {
        console.log(statusData);
        if(statusData && !statusData?.found ){
            navigate(`/notfound`);
        } else if(isError) {
            navigate(`/notfound`);
        } else {
            props.setRelation(statusData?.return.relation.name);
        }
    }, [statusData, isError])

    const status = 2;
    const statusToRender = () => {
        if(status === 0) { 
            return <RmaRegistered width={sliderItemWidth}/>
        }
        if(status === 1) { 
            return <RmaDeposited width={sliderItemWidth}/>
        }
        if(status === 2) { 
            return <RmaDeposited width={sliderItemWidth}/>
        }
        if(status === 3) { 
            return <RmaDeposited width={sliderItemWidth}/>
        }
        if(status === 4) { 
            return <RmaDeposited width={sliderItemWidth}/>
        }
    }

    return (
        <Box
            component={"main"}
            sx={{
                // minHeight:"calc(100%)"
                zIndex:1,
            }}
        >
            <Box
                component={"section"}
                sx={{
                    // background:"rgba(65,128,246,.10)",
                    background: "rgba(65,128,246,.10)",
                    background: "radial-gradient(circle, rgba(65,128,246,0.25) 0%, rgba(65,128,246,0.10) 100%)",
                    width:1,
                    minHeight:"100vh",
                    // paddingTop:"300px",
                    // paddingBottom:"200px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"flex-start",
                    // height:"100vh",
                    position:'relative',
                    overflow:"hidden",
                    "@media screen and (max-width: 1280px)" : {
                        // py:"120px",
                    },

                    ".stock-visual" : {
                        position:'absolute',
                        bottom:"0",
                        width:1,
                        zIndex:0,
                    }
                }}
            >

                <Box
                    sx={{
                        overflow:"hidden",
                        zIndex:1,
                    }}
                >
                    <Box
                         sx={{
                            ml:40,
                            width:600,
                            zIndex:2,
                            "@media screen and (max-width: 1368px)" : {
                                ml:25,
                            },
                            "@media screen and (max-width: 1120px)" : {
                                ml:15,
                            },
                            "@media screen and (max-width: 768px)" : {
                                ml:8,
                            },
                            "@media screen and (max-width: 575px)" : {
                                ml:3,
                                width:1,
                            },
                           
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize:"2.75em",
                                fontWeight:"100",
                                color:"#31415f",

                                "@media screen and (max-width: 1368px)" : {
                                    fontSize:"2.25em",
                                },
                                "@media screen and (max-width: 767px)" : {
                                    width:350,
                                    fontSize:"1.75em",
                                },
                            }}
                        >
                            Retour status voor order <b>{statusData?.order?.webshopreference}</b>
                            van <b>{statusData?.return?.name}</b>
                        </Typography>
                    </Box>
                    <Box
                        className={"slider-container"}
                        sx={{
                            ml:40,
                            gap:3,
                            mt:6,
                            mb:4,
                            "@media screen and (max-width: 1368px)" : {
                                ml:25,
                            },
                            "@media screen and (max-width: 1120px)" : {
                                ml:15,
                            },
                            "@media screen and (max-width: 768px)" : {
                                ml:8,
                            },
                            "@media screen and (max-width: 575px)" : {
                                ml:3,
                            },
                        }}
                    >
                      <Box 
                        sx={{
                            height:32,
                            background:"#D3E2FE",
                            background: "rgba(65,128,246,.20)",
                            borderRadius:4,
                            display:"inline-flex",
                            width:"min-content",
                            // width:1,
                            transform:`translateX(-${(status * sliderItemWidth)}px)`,
                            transition: "transform 500ms"
                        }}
                      >
                        <SliderLogo
                            status={status}
                            sliderItemWidth={sliderItemWidth}
                            value={1}
                        />
                        <SliderLogo
                            status={status}
                            sliderItemWidth={sliderItemWidth}
                            value={2}
                        />
                        <SliderLogo
                            status={status}
                            sliderItemWidth={sliderItemWidth}
                            value={3}
                        />
                        <SliderLogo
                            status={status}
                            sliderItemWidth={sliderItemWidth}
                            value={4}
                        />
                        <SliderLogo
                            status={status}
                            sliderItemWidth={sliderItemWidth}
                            value={5}
                        />
                       

                      </Box>
                    </Box>

                    <Box
                        sx={{
                            ml:40,
                            "@media screen and (max-width: 1368px)" : {
                                ml:25,
                            },
                            "@media screen and (max-width: 1120px)" : {
                                ml:15,
                            },
                            "@media screen and (max-width: 768px)" : {
                                ml:8,
                            },
                            "@media screen and (max-width: 575px)" : {
                                ml:3,
                            },
                        }}
                    >
                        {statusToRender()}
                        {/* <Box
                            sx={{
                                mt:2.5,
                            }}
                        >
                            <ButtonBase
                                sx={{
                                    px:4,
                                    height:48,
                                    borderRadius:6,
                                    fontSize:16,
                                    fontWeight:600,
                                    background: "#0057FA",
                                    color:"#fff"
                                }}
                            >
                                Bekijk retournatie
                            </ButtonBase>
                        </Box> */}
                    </Box>


                </Box>

                <StockVisual className="stock-visual"/>
            </Box>
        </Box>
    )
}

export default RmaStatus