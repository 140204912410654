import { Box, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import Chart from "react-apexcharts";
import Text from "../text/Text";
import ShChart from "./ShChart";
import ChartCard from "../cards/ChartCard";

import cx from 'classnames';
import { useMemo } from "react";
import { NoChartData } from "./Status/NoChartData";
import { ChartError } from "./Status/ChartError";



const DonutChartContent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const labels = props.isLoading ? ['Loading'] : props.isError ? ['Error'] : (props.categories || ['Shipped', 'Picked', 'Queued']);
    const values = props.isLoading || props.isError ? [100] : props.values;

    const allValuesAreZero = useMemo(() => {
        return values.every(item => item === 0 || isNaN(item));
    }, [values]);

    const colorType = theme.palette.mode === "dark" ? "light" : "st"; 

    const uniqueColors = () => {
        if (props.isLoading || allValuesAreZero) return theme.palette.mode === "dark" ? [colors.grey[300]] : [colors.grey[100]];
        
        if (props.isError) return colors.red[200];
        
        const unique = props.colors.filter((item, pos) => props.colors.indexOf(item) === pos);
        return unique.map((color) => chartTokens[color][colorType][700]);
    };

    return (
        <Box
            className={cx([props.className,"hellowo"], {
                'loading': props.isLoading,
            })} 
            sx={{
                flex:1,
                paddingTop:3,
                display:"flex",
                alignItems: "center",
                justifyContent:"center",
                flexDirection:"column",
                position:"relative",
                // justifyContent:props.isModal ? "center": null,
                "& .apexcharts-canvas" : {
                    // overflow:"hidden",
                    position:"relative",
                    zIndex:3,
                    // position:"unset",
                    "& svg" : {
                        // marginLeft:props.isModal ? "-16px" : "-37px",
                        marginTop:"-6px",
                        position:"aboslute",
                    },
                    "& .apexcharts-toolbar" : {
                        // right:"50% !important",
                        right:"0px !important",
                        top:"-28px !important",
                        "@media screen and (max-width: 64em)" : {
                            display:"none"
                        }
                        // transform:"translateX(50%)"
                    },
                    "& .apexcharts-tooltip, .apexcharts-xaxistooltip, .apexcharts-yaxistooltip" : {
                        background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                        color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.white[400],
                        border:"none",
                        "& .apexcharts-tooltip-title" : {
                            display:"none"
                        }
                    }
                },
                "&.loading .sh-donut-chart" : {
                    animation: 'pulse 2.5s infinite'
                },
                "@media screen and (max-width: 47.9375em)" : {
                    justifyContent:"center",
                    "& .apexcharts-canvas" : {
                        // position:"unset",
                        "& svg" : {
                            marginLeft:"-16px"
                        },
                    },
                }
            }}
        >
            <Chart 
                className="sh-donut-chart"
                // height={224}
                series={allValuesAreZero ? [100] : values} 
                labels={labels} 
                options={{
                    chart: {
                        animations: {
                            enabled:false
                        },
                        events: {
                            click: function(e, context, config) {
                                // e.preventDefault();
                                // e.stopPropagation();
                                // alert("hi");
                            }
                        },
                        // offsetX:-00,
                    },
                    tooltip: {
                        // enabled:false
                    },
                    colors:uniqueColors(),
                    stroke: {
                        curve: 'smooth',
                        colors: "#D2DFF5",
                        width:0
                    },
                    legend:{
                        show:false,
                    },
                }} 
                type="donut"
            />
            {!props.isModal ?
                <Box
                    sx={{
                        position:'relative',
                        zIndex:3,
                        display:"flex",
                        gap:5,
                        pb:3,
                        // marginTop:"-6px",
                        // marginLeft:"-32px",
                        "@media screen and (max-width: 47.9375em)" : {
                            // display:"none",
                        }
                    }}
                >
                    {!props.isLoading && !props.isError && values.map((value,index) => (
                        <Box
                            sx={{
                                display:"flex",
                                gap:1,
                                // alignItems:"center",
                                // height:32
                            }}
                        >
                            <Box
                                sx={{
                                    width:12,
                                    height:12,
                                    backgroundColor:uniqueColors()[index],
                                    borderRadius:2,
                                    marginTop:0.5,
                                    // marginRight:1.5,
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column"
                                }}
                            >

                                <Text italic color={!props.hideEffect ? chartTokens.text.secondary : colors.txt["secondary"]}>({labels[index]})</Text>
                                {/* {} */}
                                <Text bold color={!props.hideEffect ? chartTokens.text.primary : colors.txt["primary"]}>{value}</Text>
                            </Box>
                        </Box>
                        
                    ))}
                </Box>
            : null}

            {allValuesAreZero && <NoChartData type="donut"/>}
            {props.isError && <ChartError type="donut" onRetry={props.onRetry}/>}
        </Box>
    );
}

const DonutChart = (props) => {
    return (
        <ShChart>
            <ChartCard 
                {...props}
            >
                <DonutChartContent {...props} categories={props.categories ? props.categories : null} values={props.values}/>
            </ChartCard>
        </ShChart>
    );
}
export default DonutChart;