// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { InputDropdown } from '../../../../theme/dropdowns/InputDropdown';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };

    const [measureMentInfo, setMeasureMentInfo] = useState({weight: "", dimensions: ""});

    const handleMeasureMentsChange = (key, val) => {
        setMeasureMentInfo({
            ...measureMentInfo,
            [key]: val
        });
    }
    
    useEffect(() => {
        const credentials = {...data.credentials, measuring_units: measureMentInfo}
        props.handleDataUpdate({ credentials: credentials });
    }, [measureMentInfo]);

    const dimensionUnits = [
        {value: "cm", name: "cm"},
        {value: "mm", name: "mm"},
    ];

    const weightUnits = [
        {value: "kg", name: "kg"},
        {value: "gram", name: "gram"},
    ];

    return (
        <div>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("unit_weight")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <InputDropdown
                        variant="darker"
                        displayName={"name"} 
                        onChange={(selected) => handleMeasureMentsChange('weight', weightUnits[selected].value)}
                        noSelection={t("unit_weight")} //Custom text when nothing is selected
                        options={weightUnits ?? null}
                        selected={weightUnits?.findIndex(type => type.value === measureMentInfo.weight) ?? -1}
                        closeOnSelection
                    />
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("unit_dimensions")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <InputDropdown
                        variant="darker"
                        displayName={"name"} 
                        onChange={(selected) => handleMeasureMentsChange('dimensions', dimensionUnits[selected].value)}
                        noSelection={t("unit_dimensions")} //Custom text when nothing is selected
                        options={dimensionUnits ?? null}
                        selected={dimensionUnits?.findIndex(type => type.value === measureMentInfo.dimensions) ?? -1}
                        closeOnSelection
                    />
                </Box>
            </Box>
    </div>
    );
};

const MeasurementUnits = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
            tabId="tabMeasurementUnits" 
            subtitle={t("unit_weight")} 
            step={3}
            maxSteps={3}
        >
            <Content />
        </ProgressionTab>
    )
}

export default MeasurementUnits;

