import { Box, Button, Typography, useTheme } from "@mui/material";
// import { tokens } from "../../../../theme";

// import AdvancedFilterView from "./Filter/Views/AdvancedFilterView";
// import PopoverModal from "../../Modal/PopoverModal";
import {  tokens } from "../../../theme";
import PopoverModal from "../../theme/Modal/PopoverModal";
import Text from "../../theme/text/Text";

import  SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import  LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import  DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import PublicIcon from '@mui/icons-material/Public';

import { ReactComponent as NoteIcon } from "../../../styles/svg/note.svg";
import { ReactComponent as IntegrationsIcon } from "../../../styles/svg/Integrations.svg";
import ShSwitch from "../../theme/inputs/ShSwitch";
import { useContext, useMemo } from "react";
import { localeSettings } from "../../../utils/locales";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../newapi/global/settingsSlice";
import { countriesFromLocale } from "../../../utils/countries";

import cx from 'classnames';

export const TopbarActionButton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Button
            onClick={props.onClick}
            TouchRippleProps={{ 
                style: { 
                    color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                } 
            }} // Directly styling the ripple here
            variant='contained'
            
            className={cx('', {
                'active': props.active,
             })}
            sx={{
                background:theme.palette.mode === "dark" ? colors.grey[300]  : "#fff",
                width:1,
                height:40,
                borderRadius:"8px",
                padding:0,
                minWidth:0,
                paddingX:1.5,
                display: "flex",
                alignItems:"center",
                justifyContent:"flex-start",
                textTransform:"none",
                minWidth:250,
                boxShadow: "none",
                opacity:1,
                color:"#31415f",
                flexShrink:0,
                "&:hover" : {
                    transition:"250ms",
                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                    boxShadow:"none"
                },
                "&.active" : {
                    background:colors.primary[100],
                    color: colors.primary[400]
                }
            }}
        >
            {props.children}
        </Button>
    );

}

const MPOChooseLanguage = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const language = useSelector((state) => state.settings.language);
    const dispatch = useDispatch();

    const locales = useMemo(() => Object.values(localeSettings),[localeSettings]);
    const localeKeys = useMemo(() => Object.keys(localeSettings),[localeSettings]);
    const countries = countriesFromLocale(language);
    const filteredCountries = useMemo(() => countries.filter(country => localeKeys.includes(country.alpha2.toLowerCase())), [language]);
    
    

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    
    const toggleLanguage = (shortName) => {
        dispatch(setLanguage(shortName))
       

        // console.log(countries[shortName]);
    }

    return (
        <PopoverModal
            {...props}
        >
            <Box 
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[300] : "#fff",
                    padding:1.5,
                    paddingBottom: isStandAlone ? 4 : 1.5,
                    display:"flex",
                    gap:1,
                    flexDirection:"column",
                    borderRadius:"24px",
                    borderBottomLeftRadius:0,
                    borderBottomRightRadius:0,
                    maxHeight:420,
                    overflow:"auto",
                    "@media screen and (max-width: 48em)" : {
                        borderRadius:"32px",
                        borderBottomLeftRadius:0,
                        borderBottomRightRadius:0,
                    }
                }}
            >
                {filteredCountries.map((country,index) => (

                    <TopbarActionButton
                        onClick={() => toggleLanguage(country.alpha2)}
                        active={country.alpha2 === language}
                    >
                        <Box
                            sx={{
                                // marginLeft:"auto",
                                mr:2,
                                display:"flex",
                                alignItems:"center"
                            }}
                        >
                            {localeSettings[country.alpha2].flag}
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily:'Roboto, sans-serif',
                                    fontSize:14,
                                    fontWeight:500
                                }}
                            >
                                {country.name}
                                {/* {countries.name} */}
                            </Typography>
                        </Box>
                    </TopbarActionButton>

                ))}

            </Box>

        </PopoverModal>
    );
}

export default MPOChooseLanguage;