
import WarehouseIcon from '@mui/icons-material/Warehouse';
export const relationProductConfig = {
    productOverview:  {
        title: 'products',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
        //mobileactions added later
    },
    virtualProductOverview: {
        title: 'virtual_products',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
        //mobileactions added later
    },
};
