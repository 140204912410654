import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import ShSwitch from "../../theme/inputs/ShSwitch";
import Text from "../../theme/text/Text";
import { useTranslation } from "react-i18next";
import { useUpdateSettingMutation } from "../../../newapi/global/generalApiSlice";


// variants
//    1: default has both a title and subtitle
//    2: has no subtitle ans text is light
const BoolSetting = (props) => {
  const { title = true, subtitle = true, variant = 1} = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [updateSetting] = useUpdateSettingMutation();


  const [setting, setSetting] = useState();
  const [val, setVal] = useState();

  const handleBoxClick = () => {
    // Toggle the switch only if the click didn't originate from the switch itself
    setVal(!val);
    handleChange(!val);
  };

  const handleChange = async (value) => {
    await updateSetting({
      key: setting.key,
      value: value,
      warehouseId: setting.warehouseId,
    });
  };

  useEffect(() => {
    if(props.skey && typeof props.settings !== "undefined"){
        let xsetting = props.settings.find((setting) => setting.key === props.skey);
        if (!xsetting) {
            xsetting = { key: props.skey, value: false, warehouseId: -1 };
        }
        setSetting(xsetting);
        let xval = false;
        if (xsetting.value === "true") xval = true;
        setVal(xval);
    }
  }, [props.settings, props.skey]);

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
        height:40,
      }}
      onClick={handleBoxClick} // Add the click handler to the Box
    >
      <Box
        sx={{
          display:"flex",
          flexDirection:"column",
          overflow:"hidden",
          cursor:"pointer"
        }}
      >
        {title && <Text noBreak light={variant === 2} size={variant === 2 ?? "fs13"} semibold={variant === 1}>{t(`${setting?.key}_title`)}</Text>}
        {subtitle && variant !== 2 && <Text light>{t(`${setting?.key}_subtitle`)}</Text>}
      </Box>
      <ShSwitch
        checked={val}
        onChange={(e) => {
          e.stopPropagation(); // Prevent click events from bubbling up from the switch
          handleChange(e.target.checked);
        }}
        // onChange={(e) => handleChange(e.target.checked)}
        color={theme.palette.mode === "dark" ? "blue" : "primary"}
      />
    </Box>
  );
};

export default BoolSetting;