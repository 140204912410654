import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    column: -1,
    conditionExtensionType: "where",
    type: {
      name: "",
      value: -1
    },
    value: null
  };

const gridFilterBuilderRuleSlice = createSlice({
  name: 'filterBuilderRule',
  initialState: initialState,
  reducers: {
    updateColumn: (state, action) => {
      state.column = action.payload;
    },
    updateConditionExtensionType: (state, action) => {
      state.conditionExtensionType = action.payload;
    },
    updateType: (state, action) => {

    //   props.addView({
    //     ...view,
    //     apiRef: props.apiRef
    // },false);
      state.type = {...state.type, ...action.payload};
      // state.type = action.payload;
    },
    updateValue: (state, action) => {
      state.value = action.payload;
    },
    resetFilterBuilderRule: (state, action) => {
        return initialState;
    }
  }
});

export const { 
  updateColumn, 
  updateConditionExtensionType, 
  updateType, 
  updateValue,
  resetFilterBuilderRule
} = gridFilterBuilderRuleSlice.actions;

export default gridFilterBuilderRuleSlice;
