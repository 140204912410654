import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
// import Warehouse from "./Views/Warehouse";
import { useGridApiRef } from "@mui/x-data-grid";
import Info from "./Views/Info";
import SizeAndWeight from "./Views/SizeAndWeight";
import AvailableStock from "./Views/AvailableStock";
import Batches from "./Views/Batches";
import Instructions from "./Views/Instructions";
import Efullfillment from "./Views/Efullfillment";
import Logs from "./Views/Logs";
import { useTranslation } from 'react-i18next';
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import Warehouse from "./Views/Warehouse";
import InventoryIcon from '@mui/icons-material/Inventory';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spStockDetailsConfig } from "./spStockDetailsConfig";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SPStockDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const { data } = props;
    
    const warehouseApiRef = useGridApiRef();
    // const batchesApiRef = useGridApiRef();
    const instructionsApiRef = useGridApiRef();
    const logsApiRef = useGridApiRef();
    const infoApiRef = useGridApiRef();
    const sizeAndWeightApiRef = useGridApiRef();
    const availableStockApiRef = useGridApiRef();

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: "primary",
            content: <InventoryIcon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && data.length > 0) {
          let locations = data.stocks.map((stock) => stock.warehouseLocation.location);
          let combinedLocations = locations.join(", ");
      
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data.description,
            subTitle: data.sku,
            options: [
              {
                heading: t('location'),
                content: combinedLocations === "" ? t('no_location') : combinedLocations,
              },
              { heading: t('total_available'), content: data.totalStockAvailable },
            ],
          }));
        }
      }, [data]);

    return (
        <Sidepanel2
            tabConfig={spStockDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <Warehouse config={spStockDetailsConfig.warehouse} productId={props.data ? props.data.id : -1} />
                    <Info config={spStockDetailsConfig.info} data={props.data}/>
                    <SizeAndWeight config={spStockDetailsConfig.sizeAndWeight} data={props.data} />
                    <AvailableStock config={spStockDetailsConfig.stock} data={props.data}/>
                    {/* <Batches apiRef={batchesApiRef} /> */}
                    <Instructions config={spStockDetailsConfig.instructions} data={props.data}/>
                    <Efullfillment config={spStockDetailsConfig.efullfillment}/>
                    <Logs config={spStockDetailsConfig.logs} data={props.data}/>
                </>
        </Sidepanel2>
    );
};

export default SPStockDetails;