import { Box, InputBase, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import ShDateRangePicker from "../../ShDateRangePicker";
import ShLabel from "../../label/ShLabel";
import { conditionsFromType } from "./Settings";


const LabelValue = (props) => {
    const [option, setOption] = useState({name:"",variant:"grey"});

    useEffect(() => {
        if(typeof props.column !== "undefined") {
            setOption(props.column.shFilter.options[props.condition.value]);
        }
    },[props.column]);

    return (            
        <ShLabel size={32} variant={option.variant}>
            {option.name}
        </ShLabel>
    );
}

const FilterValue = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [value, setValue] = useState(props.condition.value ?? "");
    const isInputCondition =  props.condition.type.name === "number" || props.condition.type.name === "text";
    const isDate = props.condition.type.name === "date";
    const isBoolean = props.condition.type.name === "boolean";

    const columnName = typeof props.column !== "undefined" ? props.column.headerName : "";

    return (
        isInputCondition ?
            <Box
                sx={{
                    height:"32px",
                    background: theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200],
                    display:"flex",
                    alignItems:"center",
                    padding:1.5,
                    borderRadius:4,
                    // maxWidth:getMaxWidth(),
                    // "@media screen and (max-width: 48em)" : {
                        maxWidth:"unset",
                        flex:1
                    // }
                }}
            >
                <InputBase
                    type={props.condition.type.name}
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    sx={{
                        height:"32px",
                        width:40,
                        color:colors.txt["primary"],
                        // "@media screen and (max-width: 48em)" : {
                            width:1
                        // }
                        
                    }}
                />
            </Box>
        :
        isDate ?
            <ShDateRangePicker darker size={32}></ShDateRangePicker>
        :
        isBoolean ?
            <ShLabel size={32} variant={props.condition.type.value === 0 ? "green" : "red"}>
                {columnName}
            </ShLabel>
        :
        <LabelValue {...props}></LabelValue>
        
    )
}

export default FilterValue;