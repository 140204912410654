
import WarehouseIcon from '@mui/icons-material/Warehouse';


export const relationInboundsConfig = {
    inbound:  {
        title: 'all_inbounds',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    inboundLines: {
        title: 'all_inboundlines',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    returns: {
        title: 'all_returns', // Changed from all_inbounds to all_returns
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
};
