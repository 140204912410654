import { store } from '../newapi/store';
import { ENV_TYPES } from '../components/global/Sidebar';
export const UNAUTHORIZED_ENDPOINT = '/warehouse';

export const checkUserPermission = (requiredPermission) => {
    const state = store.getState();
    const activeWarehouse = state.warehouse.activeId;
    const permissions = state.auth.permissions;
    const type = state.settings.environment.selectedEnvironmentType;
    const id = state.settings.environment.selectedId;

    let relation_id = 0;
    let customer_id = 0;
    
    if(type === ENV_TYPES[100]){
        customer_id = id;
    } else if(type === ENV_TYPES[200]) {
        relation_id = id;
    }

    if (requiredPermission.length > 0 && requiredPermission[0] === "outbound_manage") {
        return false;
    }

    let permissionObject = permissions.find(permission => ((customer_id > 0 && 
        permission.customerId === parseInt(customer_id) &&
        permission.warehouseId === parseInt(activeWarehouse)) ||
        (relation_id > 0 && permission.relationId === parseInt(relation_id)))
    );

    if (permissionObject) {
        if (permissionObject.admin || requiredPermission.some(p => permissionObject.permissions.includes(p))) {
            return true;
        }
    } else {
        // Check general warehouse permission
        let permissionObject = permissions.find(permission => (customer_id > 0 && 
            permission.customerId === parseInt(customer_id) &&
            permission.warehouseId === -1)
        );

        if (permissionObject?.admin || requiredPermission.some(p => permissionObject?.permissions.includes(p))) {
            return true;
        }
    }

    return false;
};

// UserPrivileges
export const UserPrivileges = {
    BACKOFFICE_GENERAL: "backoffice_general",
    CARRIER_MANAGE: "carrier_manage",
    INBOUND_READ: "inbound_read",
    INBOUND_MANAGE: "inbound_manage",
    ECHECK: "echeck",
    OUTBOUND_READ: "outbound_read",
    OUTBOUND_MANAGE: "outbound_manage",
    PICKINGJOB_MANAGE: "pickingjob_manage",
    PICKINGJOB_READ: "pickingjob_read",
    PRODUCT_IMPORT: "product_import",
    PRODUCT_MANAGE: "product_manage",
    PRODUCT_READ: "product_read",
    RELATION_MANAGE: "relation_manage",
    RELATION_READ: "relation_read",
    REPLENISH: "replenish",
    SETTINGS_ALL: "settings_all",
    SETTINGS_GENERAL: "settings_general",
    SETTINGS_USERPRIVILEGES: "settings_userprivileges",
    SHIPPER_MANAGE: "shipper_manage",
    SHIPPER_READ: "shipper_read",
    STOCK_READ: "stock_read",
    STOCK_MANAGE: "stock_manage",
    STOCK_TRANSFER: "stock_transfer",
    WAREHOUSE_READ: "warehouse_read",
    WAREHOUSE_MANAGE: "warehouse_manage",
    WAREHOUSELOCATION_READ: "warehouselocation_read",
    WAREHOUSELOCATION_MANAGE: "warehouselocation_manage",
    WEBSHOPCREDENTIALS_RELATION: "webshopcredentials_relation",
    WEBSHOPCREDENTIALS_MANAGE: "webshopcredentials_manage"
};