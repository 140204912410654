import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { tokens } from "../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../../components/theme/datagrid/ShDataGrid";
import Subheading from "../../../../../../components/theme/text/Subheading";
import Text from "../../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";

import { useTranslation } from "react-i18next";
import MpAddStream from "../../../../../../components/global/ModalsProgression/MpAddStream/MpAddStream";
import SpStreamDetails from "../../../../../../components/global/Sidepanels/SpStreamDetails/SpStreamDetails";
import { useFindAllPickupCartsQuery, useFindOnePickupCartQuery } from "../../../../../../newapi/warehouse/pickupCartSlice";
import MpAddPickingCart from "../../../../../../components/global/ModalsProgression/MpAddPickingCart/MpAddPickingCart";
import SpPickingCartDetails from "../../../../../../components/global/Sidepanels/SpPickingCartDetails/SpPickingCartDetails";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";


const GridActions = (props, i18n) => {
    const theme = useTheme();
    
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_pickingcart")}</Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const PickingCarts = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const apiRef = props.apiRef;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    // const { getCarrier} = useGetCarrierQuery();
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    const { data: pickupcartData, error, isLoading } = useFindOnePickupCartQuery(
       selectedRowId,
        {
            skip: !rowSelected
        }
    );
    


    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const getRowDetails = (row) => {  
        if (!row) return;
        setSelectedRowId(row.id);
        setRowSelected(true);
    };

    useEffect(() => {
        if (!isLoading && pickupcartData && rowSelected) {
            setIsExtended(true);
        }
    }, [pickupcartData, isLoading, rowSelected]);

    useEffect(() => {
        if(!isExtended) {
            setRowSelected(false);
        }
    },[isExtended])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.warehouseModal]);
    
    
    const columns = [
        {field: "barcode", headerName: t("pickupcart") , flex:2, cellClassName: "stream", renderCell : (cellValues) => {
            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "reachType", headerName: t("reachtype"), flex:1, renderCell : (cellValues) => {
            return (
                <Text>{cellValues.row.reachType?.description}</Text>
            )
        }},
        {field: "warehouseZone", headerName: t("zone"), flex:1, renderCell : (cellValues) => {
            return (
                <Text>{cellValues.row.warehouseZone?.description}</Text>
            )
        }},
        {field: "pickupCartLocations", headerName: t("locations"), flex:1, renderCell : (cellValues) => {
            return (
                <Text>{cellValues.row.pickupCartLocations?.length}</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
               return (
                    <Box>
                        <Text>{cellValues.row.name}</Text>
                    </Box>
               )
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));

    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <Box
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    flex:1,
                    height:1,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    "@media screen and (max-width: 48em)" : {
                        // display:"none"
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    }
                }}
            >
                <ShDataGrid2
                    content={useFindAllPickupCartsQuery}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                    title={t("overview")}
                    gridOptions={gridOptions} 
                    columns={columns}
                    isSidepanelGrid
                    onRowClick={getRowDetails}
                    rows={data}>
                </ShDataGrid2>
            </Box>

            <MpAddPickingCart onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            { <SpPickingCartDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                data={pickupcartData}                
            ></SpPickingCartDetails> }
        </Box>
    )
});


// const All = (apiRef,props) => {
//     return {
//         title: "All carriers",
//         subTitle: "All carriers registered",
//         icon: <LocalShippingOutlinedIcon/>,
//         view: <AllBody apiRef={apiRef}/>,
//         topRight:<WareHouseDropdown {...props.warehouseModal}/>,
//         isSingleDataGrid: true,
//         apiRef: apiRef,
//         gridOptions:gridOptions,
//         mobileActions:<GridActions></GridActions>
//     }
// }

export default PickingCarts;