
import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import NumberInput from "../../../../theme/inputs/NumberInput";
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import { useEditSupplierMutation } from "../../../../../newapi/global/supplier/supplierSlice";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { tokens } from "../../../../../theme";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Subheading from "../../../../theme/text/Subheading";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [supplierInfo, setSupplierInfo] = useState({
        id: null,
        state: null,
        name: "",
        street: "",
        number: "",
        insertion: "",
        postal: "",
        city: "",
        province: "",
        country: "",
        phone: "",
        email: "",
        remark: "",
        relation: null,
        inbounds: []
    });

    useEffect(() => {
        setSupplierInfo(props.data);
    }, []);

    const handleChange = (key, value) => {
        setSupplierInfo({
            ...supplierInfo,
            [key]: value
        });
    };

    const [EditSupplier, { isLoading }] = useEditSupplierMutation();

    const handleSaveClick = async () => {
        const canSave = true; // Add validation logic here
        if (canSave) {
            try {
                const data = await EditSupplier(supplierInfo).unwrap();
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }  
        }
    };

    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:  <GridActions id={props.data.id}  action={handleSaveClick}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.data,handleSaveClick]);

    

     return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("name")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={supplierInfo.name} onChange={(e) => handleChange('name', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>

                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    > 
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("phone")}</Text>
                            </Box>
                            
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                            // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={supplierInfo.rephonemark} onChange={(e) => handleChange('phone', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("remark")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={supplierInfo.remark} onChange={(e) => handleChange('remark', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>

                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    > 
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("email")}</Text>
                            </Box>
                            
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                            // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={supplierInfo.email} onChange={(e) => handleChange('email', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
            </Dropdown>
            <Dropdown title={t("alternative_address")} open>
                <Box 
                    sx={{
                        display:"flex",
                        borderRadius:3,
                        overflow:"hidden",
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                        "@media screen and (max-width: 47.9375em)" : {
                            marginTop:0,
                            background:"none",
                            flexDirection:"column",
                            overflow:"unset"
                        }
                    }}
                >
                    {/* Editable content */}
                    <Box padding={1.5} paddingRight={4} paddingBottom={3} flex={1}
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                paddingY:0,
                                paddingRight:1.5,
                            }
                        }}
                    >
                        <Box 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:0.5,
                                    alignItems:"flex-start",
                                }
                            }}
                        >
                            <Subheading>{t("details")}</Subheading>
                            {/* <Text>blabla</Text> */}
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                marginTop:3,
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("street")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" value={supplierInfo.street} onChange={(e) => handleChange('street', e.target.value)} sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("house_nr")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        backgroundColor={colors.grey[200]}
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" 
                                        value={supplierInfo.number}
                                        onChange={(e) => handleChange('number', e.target.value)}
                                            sx={{
                                                px: 2, 
                                                width:56, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                        <Box 
                                            sx={{
                                                marginY:0.5,
                                                height:24,
                                                width:"1px",
                                                background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[300]
                                            }}
                                        />
                                        <InputBase type="text"value={supplierInfo.insertion} onChange={(e) => handleChange('insertion', e.target.value)} sx={{px: 2, width:56, height:32,boxSizing:"border-box"}} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("postal_code")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" 
                                        value={supplierInfo.postal}
                                        onChange={(e) => handleChange('postal', e.target.value)}
                                            sx={{
                                                px: 2, 
                                                width:96, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                    </Box>
                                </Box>
                            </Box>

                            
                        </Box>


                        <Box
                            sx={{
                                display:"flex",
                                marginTop:3,
                                gap:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column",
                                    gap:1,
                                    alignItems:"flex-start",
                                    marginTop:4
                                }
                            }}
                        >
                             <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("province")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}}
                                        value={supplierInfo.province} onChange={(e) => handleChange('province', e.target.value)} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("city")}</Text>
                                </Box>

                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >

                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text"
                                        value={supplierInfo.city}
                                        onChange={(e) => handleChange('city', e.target.value)}
                                            sx={{
                                                px: 2, 
                                                flex:1, 
                                                height:32,
                                                boxSizing:"border-box",
                                                "@media screen and (max-width: 47.9375em)" : {
                                                    flex:1,
                                                    width:"unset"
                                                }
                                            }}/>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:0.5,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"row",
                                        gap:5,
                                        alignItems:"center",
                                        width:1
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:80
                                        }
                                    }}
                                >
                                    <Text light>{t("country")}</Text>
                                </Box>
                                <Box
                                    sx={{
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flex:1
                                        }
                                    }}
                                >
                                    <Box display="flex"
                                        borderRadius="20px"
                                        sx={{
                                            marginTop:0.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200],
                                            "@media screen and (max-width: 47.9375em)" : {
                                                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100],
                                            }
                                        }}
                                    >
                                        <InputBase type="text" sx={{px: 2, flex:1, height:32,boxSizing:"border-box"}}
                                        value={supplierInfo.country} onChange={(e) => handleChange('country', e.target.value)} />
                                    </Box>
                                </Box>
                            </Box>

                            
                        </Box>
                    </Box>
                
                </Box>
            </Dropdown>
            
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                <GridActions
                    id={props.data.id}
                    action={handleSaveClick}
                />
            </Box>
        </Box>
    );
}


export default DetailsPanel;
