import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { tokens } from "../../../theme";

const getMappedOptions = (primaryOptions) => {
    let options = [];
    for (let i = 0; i < primaryOptions.length; i++) {
        options.push(primaryOptions[i].name);
    }
    return options;
};

const SelectGroup = forwardRef((props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const multiSelect = props.multiSelect;

    const primaryOptions = props.options ?? alert("no options set");
    const mappedOptions = typeof props.colored !== "undefined" && props.colored ? getMappedOptions(primaryOptions) : props.options;
    

    useEffect(() => {
       // console.log("mappedOptions", mappedOptions);
    },[mappedOptions]);

    const [selectedOption, setSelectedOption] = useState(props.selected ?? -1);


    useImperativeHandle(ref, () => ({
        getSelectedOption() {
            return selectedOption;
        },
        select(index) {
            setSelectedOption(index);
            if (typeof props.setSelected !== "undefined") {
                props.setSelected(index);
            }
        }
    }));

    const select = (index) => {
        setSelectedOption(index);
        if (typeof props.setSelected !== "undefined") {
            props.setSelected(index);
        }
    };

    const getBackground = (index) => {
        // print(index);
        // console.log("index",index);
        // console.log("selectedOption",selectedOption);
        if (index === selectedOption) {
            if (props.colored) {
                if(typeof primaryOptions[index].selectionColor !== "undefined" && primaryOptions[index].selectionColor !== null) {
                    return primaryOptions[index].selectionColor.background;
                }
                return colors.pastel.light[primaryOptions[index].variant][100];
            } else {
                return colors.pastel.light.blue[100];
            }
        } else {
            if(typeof primaryOptions[index] !== "undefined" && typeof primaryOptions[index].color !== "undefined" && primaryOptions[index].color !== null) {
                return primaryOptions[index].color;
            }
            if (theme.palette.mode === "dark") {
                return colors.grey[300];
            } else {
                return colors.grey[100];
            }
        }
    };

    const getBorder = (index) => {
        if (index === selectedOption) {
            if (props.colored) {
                if(typeof primaryOptions[index].selectionColor !== "undefined" && primaryOptions[index].selectionColor !== null) {
                    return primaryOptions[index].selectionColor.border;
                }
                return colors.pastel.light[primaryOptions[index].variant][600];
            } else {
                return colors.pastel.light.blue[600];
            }
        } else {
            if (theme.palette.mode === "dark") {
                return colors.grey[300];
            } else {
                return colors.grey[100];
            }
        }
    };

    const getTextColor = (index) => {
        if (index === selectedOption && !props.defaultTextColor) {
            if (props.colored) {
                return colors.pastel.light[primaryOptions[index].variant][600];
            } else {
                return colors.pastel.light.blue[600];
            }
        } else {
            return colors.txt["primary"];
        }
    };

    if(props.image){
    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", gap: 1.5 }}>
          {mappedOptions.map((option, index) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
                px:3,
                py: 2,
                background: getBackground(index),
                border: `1px solid ${getBorder(index)}`,
                color: getTextColor(index),
                fontWeight: 500,
                fontSize: 14,
                borderRadius: 3,
                userSelect: "none",
                minWidth:150,
              }}
              key={index}
              onClick={() => select(index)}
            >
               <Box 
                    sx={{ 
                        display: "flex", 
                        flexDirection: "column",  
                        gap: 3, 
                        alignItems: "flex-start",
                        
                    
                    }}
                >
                    <Box 
                        sx={{
                            width:24,
                            height:24,
                            "& svg":{
                                width:24,
                                height:24,
                            }
                        }}
                    >
                        {option.logo}
                    </Box>
                  <span>{option.title}</span>
                </Box>
            </Box>
          ))}
        </Box>
      );
        } else {
                return (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                {mappedOptions.map((option, index) => (
                    <Box
                    sx={{
                        height:40,
                        lineHeight:"40px",
                        paddingX:2,
                        background: getBackground(index),
                        border : `1px solid ${getBorder(index)}`,
                        color: getTextColor(index),
                        fontWeight:500,
                        fontSize:14,
                        borderRadius:5,
                        userSelect:"none"
                    }}
                    key={index}
                    onClick={() => select(index)}
                    >
              {props.displayName && option[props.displayName] || option.title}
            </Box>
          ))}
        </Box>
);
              }
    });

export default SelectGroup;