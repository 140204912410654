import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

export const NoTextLabel = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const type = props.type ?? "light";
    const variant = props.variant ?? "grey";
    

    const palette = props.palette ?? "pastel"


    const getBackground = () => {
        if(palette === "pastel") {
            return colors.pastel[type][variant][600];
        }
        return colors[variant][400];
    }

    return (
        <Box
            sx={{
                background:getBackground(),
                borderRadius: 4,
                height: 8,
                width:32,
            }}
        >
        </Box>
    )
}