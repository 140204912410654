import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import Text from "../../../../theme/text/Text";
import StockOnLocationCard from "../../../../theme/cards/stock/StockOnLocationCard";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import SPStockDetails from "../../SpStockDetails/SPStockDetails";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import { useGetStockQuery } from "../../../../../newapi/warehouse/stockSlice";
import { useGetProductQuery } from "../../../../../newapi/warehouse/productSlice";
import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const StockOnLocation = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState({"warehouseLocationId": props.warehouseLocationId});
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        },false);

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);



    const [selectedRowId, setSelectedRowId] = useState();
    const selectedRowIdRef = useRef(selectedRowId);
    useEffect(() => {
        selectedRowIdRef.current = selectedRowId;
    }, [selectedRowId]);

    const { data: productData, isLoading: isLoading } = useGetProductQuery(selectedRowId,
        {
            skip: !selectedRowId
        });
        
    const getProductDetails = (params) => {
        if (!params) return;
        if (typeof selectedRowIdRef.current !== "undefined" &&  parseInt(selectedRowIdRef.current) === parseInt(params.id)) {
            setIsExtended(true);
        } else {
            setSelectedRowId(params.id);
        }
    };

    useEffect(() => {
        if (!isLoading && productData) {
            setIsExtended(true);
        }
    }, [productData, isLoading]);
    


    

    const columns = [
        {
            field: "sku", 
            headerName: t('sku'),
            flex:1.5, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }
                return (
                    <Box paddingLeft={0} onClick={() => getProductDetails(params.row.product)}>
                        <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text><br/>
                        <Text clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.description}</Text>
                    </Box>
                )
            }
        },
        ...isFulfilment ? [{
            field: "relation", 
            headerName: t('relation'),
            flex:2,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.product.relation.name
            }
        }] : []
        ,
        // {
        //     field: "batch", 
        //     headerName: "Batch/THT date",
        //     flex:1, 
        //     renderCell : (cellValues) => {
        //         return (
        //             <Box paddingLeft={0}>
        //                 <Text semibold>{cellValues.value}</Text><br/>
        //                 <Text light>{cellValues.row.thtDate}</Text>
        //             </Box>
        //         )
        //     }
        // },
        {
            field: "quantity", 
            headerName: t('physical'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.quantity
            }
        },
        {
            field: "reserved", 
            headerName: t('reserved'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.reserved
            }
        },
        {
            field: "pickingprocess", 
            headerName: t('pickingprocess'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.pickingprocess
            }
        },
        {
            field: "available", 
            headerName: t('available'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.available
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <StockOnLocationCard skeleton={params.row.skeleton ?? false} {...params}/>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2
                content={useGetStockQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('stock_on_location')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
        <SPStockDetails
            isExtended={isExtended}
            setIsExtended={setIsExtended}
            data={productData} // Pass the productData as a prop
        ></SPStockDetails>
        </Box>
    );
}

export default StockOnLocation;