import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../theme"
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Text from "../../../../components/theme/text/Text";
import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import SpInboundDetails from "../../../../components/global/Sidepanels/SpInboundDetails/SpInboundDetails";
import { useGetInboundsQuery, useGetInboundQuery } from "../../../../newapi/inbound/inboundsSlice";
import InboundsCard from "../../../../components/theme/cards/inbounds/InboundsCard";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../components/theme/skeleton/ShLabelSkeleton";
import MpAddInbound from "../../../../components/global/ModalsProgression/MpAddInbound/MpAddInbound";

import { useNavigate } from 'react-router-dom';
import { checkUserPermission, UserPrivileges, UNAUTHORIZED_ENDPOINT } from '../../../../utils/permissions';
import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                New
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const InboundInbounds = (props) => {
    // Check if user has permission to access the page
   const navigate = useNavigate();
   useEffect(() => {
       if (!checkUserPermission([UserPrivileges.INBOUND_READ])) {
         navigate(UNAUTHORIZED_ENDPOINT); // Use navigate instead of history.push
       }
     }, [navigate]);
     
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState({"relationId": useSelector((state) => state.settings.environment.selectedId)});

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>,
        });

        return () => {
            
        }
    },[]);

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };
    
    
    const [selectedRowId, setSelectedRowId] = useState();
    const selectedRowIdRef = useRef(selectedRowId);
    useEffect(() => {
        selectedRowIdRef.current = selectedRowId;
    }, [selectedRowId]);

    const { data: inboundData, isLoading: isLoading } = useGetInboundQuery(selectedRowId,
        {
            skip: !selectedRowId
        });
        
    const getInboundDetails = (params) => {
        if (!params) return;
        if (typeof selectedRowIdRef.current !== "undefined" &&  parseInt(selectedRowIdRef.current) === parseInt(params.row.id)) {
            setIsExtended(true);
        } else {
            setSelectedRowId(params.row.id);
        }
    };

    useEffect(() => {
        if (!isLoading && inboundData) {
            setIsExtended(true);
        }
    }, [inboundData, isLoading]);

    const columns = [
             
        // {field: "id", headerName: t("relation"),flex:1,
        //     renderCell: (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
        //         return(
        //         <Text >
        //             {params.row.relation?.name}
        //         </Text>)
        //     }
        // },
        {
            field: "reference", 
            headerName: t("reference"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.reference;
            }
        },
        {
            field: "shipmentDate", 
            headerName: t("shipment_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.shipmentDate);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {field: "supplier.name", headerName: t("supplier"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text>
                        {params.row.supplier?.name}
                    </Text>
                )
            }
        },

        {field: "skuCount", headerName: t("sku_count"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.skuCount;
            }
        },        
        // {field: "trackandtrace", headerName: t("track_and_trace"),flex:1},
        {field: "pallets", headerName: t("pallets"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.pallets;
            }
        },
        {field: "itemCount", headerName: t("items"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.itemCount;
            }
        },
        {field: "colli", headerName: t("colli"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.colli;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "announced":
                        labelcolor = "blue"
                        labeltext = t('announced');
                        break;
                    case "approved":
                        labelcolor = "green"
                        labeltext = t('approved');
                        break;
                    case "declined":
                        labelcolor = "red"
                        labeltext = t('declined');
                        break;
                    case "cancelled":
                        labelcolor = "pink"
                        labeltext = t('cancelled');
                        break;
                    case "receivedwithoutnotice":
                        labelcolor = "purple"
                        labeltext = t('receivedwithoutnotice');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {
            field: "receivedDate", 
            headerName: t("received_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                let date = new Date(params.row.receivedFromDate);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <InboundsCard skeleton={params.row.skeleton ?? false} {...params.row} />
                // <Box padding={3} sx={{background:"orange"}}></Box>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >
                    //     {cellValues.row.id + " " + cellValues.row.name}
                    //     <br/>
                    //     {cellValues.row.age}
                    //     <br/>
                    //     {cellValues.row.phone}
                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2
                content={useGetInboundsQuery}
                config={config}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('all_inbounds') }
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}
                onRowClick={getInboundDetails}>
            </ShDataGrid2>
            <SpInboundDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    data={inboundData}
                ></SpInboundDetails>
            <MpAddInbound onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

export default InboundInbounds;