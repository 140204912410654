import { Box, IconButton, InputBase, TextField, useTheme } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import { tokens } from "../../../../theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { tokens } from "../../../../theme";
import Text from "../../../../components/theme/text/Text";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import { useTranslation } from "react-i18next";
import Subheading from "../../../../components/theme/text/Subheading";
import NumberInput from "../../../../components/theme/inputs/NumberInput";
import ShSwitch from "../../../../components/theme/inputs/ShSwitch";
import { useUpdateSettingMutation } from "../../../../newapi/global/generalApiSlice";
import BoolSetting from "../../../../components/global/Settings/BoolSetting";
import NumberSetting from "../../../../components/global/Settings/NumberSetting";
import TextInput from "../../../../components/theme/inputs/TextInput";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../newapi/user/authentication/authSlice";
import SettingGroup from "../../../../components/theme/settings/SettingGroup";
import { useEditUserMutation, useGetUserQuery } from "../../../../newapi/user/userSlice";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import { showToastMessageUpdateRequest } from "../../../../utils/toasts";

const Personal = ({ settings = [], ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [view, setView] = useState({
    title: 'personal',
    icon: <UpdateOutlinedIcon />,
    // topRight: <WareHouseDropdown />,
  });

  useEffect(() => {
    props.addView(
      {
        ...view,
        apiRef: props.apiRef,
      },
      false
    );

    return () => {};
  }, []);

  const user = useSelector(selectUser);
  
  const { data: incData, error, isLoading } = useGetUserQuery(
    user.id,
 );

 useEffect(() => {
  setUserData(incData);
}, [incData]);
 const [userData, setUserData] = useState();

 const handleChange = (key, value) => {
  setUserData({
    ...userData,
    [key]: value
  });
  console.log(userData);
};

 const [updateUser] = useEditUserMutation()

 const SaveSettings = async () => {
  
    const response = await updateUser(userData);
    console.log(response);
    showToastMessageUpdateRequest(t,response.data)
 };


  return props.isActive ? (
    <Box
      sx={{
        pt:4,
        height: 1,
        "@media screen and (max-width: 47.9375em)": {
          paddingTop: 0,
          paddingX: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          gap: 3,
          overflow: "hidden",
          // background:
          //   theme.palette.mode === "dark"
          //     ? colors.grey[300]
          //     : colors.bg["tertiary"],
          paddingX: 4,
          // paddingTop: 4,
          height: 1,
          position: "relative",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          "@media screen and (max-width: 47.9375em)": {
            paddingTop: 0,
            paddingX: 0,
            background: colors.bg["tertiary"],
          },
          "& .carrier": {
            paddingLeft: 0,
            marginRight: 2,
          },
          "& [aria-label='Carrier']": {
            // background:"purple",
            paddingLeft: 0,
            marginRight: 2,
          },
        }}
      >
        <Box
          sx={{
            display:"flex",
            justifyContent: "space-between",
            "@media screen and (max-width: 47.9375em)": {
              display: "none",
            },
          }}
        >
          <Subheading>{t("account_personal_title")}</Subheading>
          <ButtonGroup version={2}>
        <Shbutton className={"group-btn"} color={"blue"} variant="contained" onClick={SaveSettings}>{t("save")}</Shbutton>
      </ButtonGroup>
        </Box>
      
        <Box
          sx={{
            background:
              theme.palette.mode === "dark"
                ? colors.grey[300]
                : colors.bg["tertiary"],
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems:"flex-start",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomLeftRadius: 16,
            borderBottomightRadius: 16,
            gap: 2,
            padding: 2,
            "@media screen and (max-width: 1023px)": {
              display:"block",
              // padding:0,
              // pt:3,
              // px:0,
              // display:"none"
              // background:
              //   theme.palette.mode === "dark"
              //     ? colors.grey[200]
              //     : colors.grey[0],
            },
            "@media screen and (max-width: 767px)": {
              pt:0,
              px:0.5,
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[200]
                  : colors.grey[0],

            }
          }}
        >
          <Box
            sx={{
              background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
              flex:1,
              borderRadius:4,
              padding:3,
              "@media screen and (max-width: 1023px)" : {
                p:1.5,
                py:3,
              },
              "@media screen and (max-width: 767px)" : {
                px:0,
              }
            }}
          >
            <SettingGroup title={t("name")}>
              <Box
                sx={{
                  mt:1.5,
                  display:"flex",
                  flexDirection:"column",
                  gap:2,
                  "@media screen and (max-width: 1023px)" : {
                    my:2,
                    mx:1,
                  }
                }}
              >
                <Box className={"setting-group"}
                  sx={{
                    display:"flex",
                    alignItems:'center',
                    justifyContent:"space-between",
                    width:1,
                  }}
                >
                  <Text light>{t('first_name')}</Text>
                  <TextInput
                    value={userData?.firstname}
                    onChange={(e) => handleChange("firstname", e.target.value)}
                  />
                </Box>

                <Box className={"setting-group"}
                  sx={{
                    display:"flex",
                    alignItems:'center',
                    justifyContent:"space-between",
                    width:1,
                  }}
                >
                  <Text light>{t('insertion')}</Text>
                  <TextInput
                    value={userData?.insertion}
                    onChange={(e) => handleChange("insertion", e.target.value)}
                  />
                </Box>

                <Box className={"setting-group"}
                  sx={{
                    display:"flex",
                    alignItems:'center',
                    justifyContent:"space-between",
                    width:1,
                  }}
                >
                  <Text light>{t('last_name')}</Text>
                  <TextInput
                    value={userData?.lastname}
                    onChange={(e) => handleChange("lastname", e.target.value)}
                  />
                </Box>
              </Box>
            </SettingGroup>
          </Box>
          <Box
            sx={{
              background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
              flex:1,
              borderRadius:4,
              padding:3,
              "@media screen and (max-width: 1023px)" : {
                p:1.5,
                py:3,
                mt:2,
              },
              "@media screen and (max-width: 767px)" : {
                mt:0,
                pt:0,
              },
              "@media screen and (max-width: 767px)" : {
                px:0,
                pt:0,
              }

            }}
          >
            <SettingGroup title={t("contact_details")}>
              <Box
                sx={{
                  mt:1.5,
                  display:"flex",
                  flexDirection:"column",
                  gap:2,
                  "@media screen and (max-width: 1023px)" : {
                    my:2,
                    mx:1,
                  }
                }}
              >
                <Box className={"setting-group"}
                  sx={{
                    display:"flex",
                    alignItems:'center',
                    justifyContent:"space-between",
                    width:1,
                  }}
                >
                  <Text light>E-mail</Text>
                  <TextInput
                    value={userData?.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                  />
                </Box>
              </Box>
            </SettingGroup>
          </Box>
          
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default Personal;
