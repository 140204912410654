import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from "react-i18next";
import Details from "./Views/Details";
import StreamShippers from "./Views/StreamShippers";
import ShipmentPanel from "./Views/Shipment";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spStreamDetailsConfig } from "./spStreamDetailsConfig";

const SidePanelActions = (props) => {
  return (
    <SidepanelHeaderActiongroup>
      <Shbutton
        isCancel={true}
        onClick={() => props.setIsExtended(false)}
        color="primary"
        variant="text"
        startIcon={<ArrowRightAltIcon />}
      >
        Cancel
      </Shbutton>
    </SidepanelHeaderActiongroup>
  );
};

const SpStreamDetails = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data } = props;

  const detailsApiRef = useGridApiRef();
  const shipmentApiRef = useGridApiRef();
  const authorisationApiRef = useGridApiRef();

  const [panel, setPanel] = useState({
    title: "",
    subTitle: "",
    icon: {
      theme: "green",
      content: <Inventory2Icon />,
    },
    options: [],
    actions: <SidePanelActions setIsExtended={props.setIsExtended} />,
  });

  useEffect(() => {
    if (
      typeof data !== "undefined" &&
      data !== null &&
      typeof data.id !== "undefined"
    ) {
      setPanel((prevPanel) => ({
        ...prevPanel,
        title: data.name,
        subTitle: data.reference ?? "",
        options: [],
      }));
    }
  }, [data]);

  return (
    <Sidepanel2
      tabConfig={spStreamDetailsConfig}
      content={panel}
      isExtended={props.isExtended}
      setIsExtended={props.setIsExtended}
    >
      <>
        <Details config={spStreamDetailsConfig.details} data={props.data} />
        <ShipmentPanel config={spStreamDetailsConfig.shipmentPanel} data={props.data} />
        <StreamShippers config={spStreamDetailsConfig.streamShippers} streamId={props.data?.id}/>
        
        {/* <Pickup data={props.data} apiRef={pickupApiRef}/>
                    <Authorisation data={props.data} apiRef={authorisationApiRef} /> */}
      </>
    </Sidepanel2>
  );
};

export default SpStreamDetails;
