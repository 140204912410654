import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
// import SHModal from "../../theme/Modal/SHModal"
// import Shbutton from "../../theme/buttons/Shbutton"
// import { tokens } from "../../../theme";
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../../theme";
import SHModal from "../../../theme/Modal/SHModal";
import Shbutton from "../../../theme/buttons/Shbutton";
import Subheading from "../../../theme/text/Subheading";
import Text from "../../../theme/text/Text";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import NumberInput from "../../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import cx from "classnames"
import { useAddNewCarrierMutation } from "../../../../newapi/carrier/carrierSlice";
import {checkAllPropertiesTruthy } from "../../../../utils"
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../../utils/validation";
import { InputDropdown } from "../../../theme/dropdowns/InputDropdown";
import { useGetCarriersTypesQuery } from "../../../../newapi/carrier/carrierSlice";

const MAddCarrier = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addCarrier,{isLoading}] = useAddNewCarrierMutation();
    const {data: carrierTypes} = useGetCarriersTypesQuery();

    const select = (selected) => {
        setSelected(selected);
    }

    const [carrierInfo, setCarrierInfo] = useState({
        name: '',
        width: 0,
        length: 0,
        height: 0,
        weight: 0,
        fitsPost: "false",
        type: ''
      });

    const [fieldErrors, setFieldErrors] = useState({});
    const rules = {
        name: yupRequired('name', t)
    };
    const schema = yup.object().shape(rules);
      
   
    const SaveCarrier = async (carrierInfo, props) => {
console.log(carrierInfo);
            try {

                setFieldErrors({});
                await schema.validate(carrierInfo, { abortEarly: false });

                const data = await addCarrier(carrierInfo).unwrap()
                showToastMessagePostRequest(t,data);
                  props.handleClose();
                } catch (err) {
                    if (err.name === 'ValidationError') {
                    // Create an errors object where keys are field names and values are error messages
                        const errors = err.inner.reduce((acc, currentError) => ({
                            ...acc,
                            [currentError.path]: currentError.message
                        }), {});
                    
                        // Set the validation errors in the state
                        setFieldErrors(errors);
                        } else {
                            showToastMessagePostRequest(t,err);
                    }
                }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setCarrierInfo({
          ...carrierInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };
      

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_carrier")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("name")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                        
                            name="name"
                            value={carrierInfo.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                        {/* <InputBase 
                            sx={{
                                px:2,
                                flex:1, 
                                color:colors.txt["primary"],
                                lineHeight:"32px",
                            }}
                            onChange={(e) => handleChange('name', e.target.value)}
                            /> */}
                    </Box>
                </Box>

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("width")}</Text>&nbsp;
                        <Text italic>(mm)</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        change={(e) => handleChange('width', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("length")}</Text>&nbsp;
                        <Text italic>(mm)</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        change={(e) => handleChange('length', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("height")}</Text>&nbsp;
                        <Text italic>(mm)</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        change={(e) => handleChange('height', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("weight")}</Text>&nbsp;
                        <Text italic>(kg)</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <NumberInput btnSize={"24"}
                        change={(e) => handleChange('weight', e)}/>
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("type")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <InputDropdown
                variant="darker"
                displayName={"name"} 
                onChange={(selected) => handleChange('type', carrierTypes[selected])}
                noSelection={t("choose_type")} //Custom text when nothing is selected
                options={carrierTypes ?? null}
                selected={carrierTypes?.findIndex(carrierType => carrierType === carrierInfo.type) ?? 0}
                closeOnSelection
            />
                    </Box>
                </Box>
                <Box 
                        
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"start",
                            justifyContent:"space-between",
                            gap:7
                        }}
                        >
                            <Box
                                sx={{
                                    width:120,
                                }}>
                        <Text light>
                        {t('fits_in_post')}
                        </Text>
                        </Box>
                        <Box>
                        <ToggleButtonGroup
                            color={carrierInfo.fitsPost === "true" ? "green" : "red"}
                            value={carrierInfo.fitsPost}
                            exclusive
                            onChange={(e) => handleChange('fitsPost', e.target.value)}
                            aria-label="Active"
                            className={cx(`${carrierInfo.fitsPost}`, {
                                'active': carrierInfo.fitsPost === "true"
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('no')}
                            </ToggleButton>
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('yes')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        </Box>
                    </Box>
            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained" 
                    onClick={() => SaveCarrier(carrierInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddCarrier;