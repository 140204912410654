import { Box, ButtonBase, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";

import { ArrowRightIcon } from "@mui/x-date-pickers";
import PopoverModal from "../Modal/PopoverModal";
const ShDataGridSelectedActions = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    

    const [anchorEl, setAnchorEl] = useState(null);
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    return (
        props.selectedItems.length > 0 ?
            <>
                <Box
                    sx={{
                        p:1.5,
                        pl:2,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.txt["primary"],
                        position:"fixed",
                        bottom:32,
                        left:"50%",
                        transform:"translateX(-50%)",
                        borderRadius:9,
                        color:"white",
                        display:"flex",
                        alignItems:"center",
                        minWidth:400,
                        boxShadow: `0px 10px 19px -5px ${colors.grey[300]}`
                    }}
                >
                    <Box
                        sx={{
                            px:1.5,
                            py:1,
                            background:"rgba(255,255,255,.15)",
                            borderRadius:5,
                            fontWeight:"bold",
                            mr:1.5,
                        }}
                    >
                        {props.selectedItems.length}
                        {/* 12321324 */}
                    </Box>
                    
                    geselecteerd
                    <ButtonBase
                        aria-describedby={popoverId}
                        onClick={handlePopoverClick}
                        sx={{
                            height:48,
                            // background:"orange",
                            marginLeft:"auto",
                            borderRadius:6,
                            paddingLeft:3,
                            paddingRight:0.5,
                            background: colors.pastel.light.blue[100],
                            color:"#31415f",
                            fontWeight:"bold",
                            fontSize:"17",
                            fontFamily:"Roboto",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",

                            "& .arrow" : {
                                transition:"250ms",
                            },
                            "&:hover" : {
                                "& .arrow" : {
                                    transition:"250ms",
                                    transform:"rotate(-90deg)"
                                },
                            }
                        }}
                    >
                        Acties
                        <Box
                            className={"arrow"}
                            sx={{
                                marginLeft:2,
                                background:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                                color:`${theme.palette.mode === "dark" ? "#31415f" : "#fff"}`,
                                height:40,
                                width:40,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:5,
                            }}
                        >
                            <ArrowRightIcon/>
                        </Box>
                    </ButtonBase>
                </Box>
                
                <PopoverModal
                    id={popoverId}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    transformOrigin={{
                        vertical:"bottom"
                    }}
                    anchorOrigin={{
                        vertical:"top"
                    }}
                    // {...props}
                    triangle={{position:"center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
                >
                    <Box 
                        sx={{
                            background: theme.palette.mode === "dark" ? colors.grey[300] : "#fff",
                            padding:1.5,
                            paddingBottom: isStandAlone ? 4 : 1.5,
                            display:"flex",
                            gap:1,
                            flexDirection:"column",
                            borderRadius:"16px",
                            borderBottomLeftRadius:0,
                            borderBottomRightRadius:0,
                            minWidth:300,
                            "@media screen and (max-width: 48em)" : {
                                borderRadius:"32px",
                                borderTopLeftRadius:"12px",
                                borderTopRightRadius:"12px",
                                borderBottomLeftRadius:0,
                                borderBottomRightRadius:0,

                            }
                        }}
                    >
                        {props.children}
                    </Box>
                </PopoverModal>
            </>
        :null
    )
}

export default ShDataGridSelectedActions;