import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";

const StockOnLocationCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box display={"flex"} width={props.skeleton ? "70%" : null} flexDirection={"column"}>
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/>
                            :    
                            <>
                                <Text bold>{props.row.description}</Text>
                                <Text>{props.row.relation}</Text>
                            </>
                        }
                    </Box>

                    <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"} onClick={props.onClick}>
                        {props.row.sku}
                    </CardLabel>

                    {/* Holds tht date and batch */}
                    <Box marginTop={3} display={"flex"}>
                        <Box
                            sx={{
                                marginRight:3,
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            <Text light>Batch</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text semibold>{props.row.batch}</Text>
                            }
                        </Box>
                        <Box
                            sx={{
                                marginRight:3,
                                display:"flex",
                                flexDirection:"column"
                            }}
                        >
                            <Text light>THT-datum</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text semibold>{props.row.thtDate}</Text>
                            }
                        </Box>
                    </Box>
                    {/* Holds all physical stock */}
                    <Box
                        sx={{
                            marginTop:3,
                            marginBottom:-1,
                            marginX:-1,
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[50],
                            borderRadius:3,
                            display:"flex",
                            flexDirection:"column",
                            gap:1.5,
                            padding:1,
                        }}
                    >
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>Physical in stock</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text bold>{props.row.physical}</Text>
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>Not in pick process</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text bold>{props.row.notInPickProcess}</Text>
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>Needs scanner</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text bold>{props.row.needsScanner}</Text>
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>Pickbon printed</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text bold>{props.row.pickbonPrinted}</Text>
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Text>Picked up</Text>

                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text bold>{props.row.pickedUp}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default StockOnLocationCard;