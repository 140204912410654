import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Products from "./Views/Products";
import Logs from "./Views/Logs";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { InputDropdown } from "../../../theme/dropdowns/InputDropdown";
import { useGetWarehousesSelectQuery } from "../../../../newapi/warehouse/warehouseApiSlice";
import { useUpdateInboundMutation } from "../../../../newapi/inbound/inboundsSlice";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spInboundDetailsConfig } from "./spInboundDetailsConfig";

const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                {t("close")}
            </Shbutton>
            {/* <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primaryGrey" 
                variant="contained" 
                startIcon={<ModeEditOutlinedIcon/>}
            >
                {t("edit")}
            </Shbutton>
            <Shbutton 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="contained" 
                startIcon={<PictureAsPdfOutlinedIcon/>}
            >
                {t("upload_packing_list")}
            </Shbutton> */}

        </SidepanelHeaderActiongroup>
    )
}

const HeaderDateBox = (props) => {
    const [from, setFrom] = useState({});
    const [to, setTo] =  useState({});
    
    useEffect(() => {
        setFrom(getTranslatedDateAndTime(props.data.receivedFromDate, props.t));
        setTo(getTranslatedDateAndTime(props.data.receivedToDate, props.t));
    },[props.data])

    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"}>
                <Box width={66} marginRight={1}><Text light>Scheduled</Text></Box>
                <Box marginRight={1}><Text bold>{from?.date}</Text></Box>
                <Text>{from?.time}</Text>
            </Box>
            <Box  display={"flex"} alignItems={"center"}>
                <Box width={66}marginRight={1}><Text light>Shipped</Text></Box>
                <Box marginRight={1}><Text bold>{to?.date}</Text></Box>
                <Text>{to?.time}</Text>
            </Box>
        </>
    )

}

const getInboundStatus = (data, t)  => {
    let status = data.state;
    let labelcolor = "";
    let labeltext = "";
    switch(status){
        case "announced":
            labelcolor = "blue"
            labeltext = t('announced');
            break;
        case "approved":
            labelcolor = "green"
            labeltext = t('approved');
            break;
        case "declined":
            labelcolor = "red"
            labeltext = t('declined');
            break;
        case "cancelled":
            labelcolor = "grey"
            labeltext = t('cancelled');
            break;
        case "receivedwithoutnotice":
            labelcolor = "purple"
            labeltext = t('receivedwithoutnotice');
            break;
        default:
            labelcolor = "orange"
            labeltext = t('unknown');
            break;
    }
    return {text: labeltext, color: labelcolor};
}

const InboundDropdown = (props) => { 
    const { t } = useTranslation();
    const { data: warehouses, warehousesLoading } = useGetWarehousesSelectQuery();
    const [updateProduct,{isLoading, isSuccess}] = useUpdateInboundMutation();

    const handleChange = async(selectedOption) => {
        updateProduct({ id: props.id, warehouseId: selectedOption.id })
            .unwrap()
            .then((updatedInbound) => {
                // Handle the success state
                toast.success(t('Inbound updated successfully'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                // Handle the error state
                toast.error(t('Inbound update failed'), {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    return (
        <Box
            sx={{
                mr:2,
            }}
        >
            <InputDropdown
                variant="darker"
                displayName={"name"}
                onChange={(selected) => handleChange(warehouses.data[selected])}
                noSelection={t("choose_option")} 
                options={warehouses?.data ?? []}
                selected={warehouses?.data ? warehouses?.data.findIndex(warehouse => warehouse.id === props.warehouseId) ?? -1 : -1}
                closeOnSelection
            />
        </Box>
    )
}

const SpInboundDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const { data } = props;

    const { t, ready, i18n } = useTranslation();
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: "green",
            content: <ImportExportIcon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />,
        topRight: <InboundDropdown warehouseId={props.data?.warehouseId ?? 0} />
    });

    useEffect(() => {
        if (typeof data !== "undefined" && ready) {
            const { text, color } = getInboundStatus(props.data, t);
            setPanel((prevPanel) => ({
            ...prevPanel,
            title: props.data?.reference ?? t("reference_missing"),
            subTitle: text,
            icon :{
                theme: color, 
                content: <ImportExportIcon/>
            },
            options : [
                ...isFulfilment ? [{
                    heading: t("relation"),
                    content: props.data?.relation?.name
                }] : [],
                {
                    component: <HeaderDateBox data={props.data} t={i18n}/>
                },
                {
                    heading: t("packing_list"),
                    content: "list.pdf"
                },
                {
                    heading: t("pallets"),
                    content: props.data?.pallets
                },
                {
                    heading: t("colli"),
                    content: props.data?.colli
                },
                {
                    heading: t("sku_amount"),
                    content: props.data?.skuCount
                },
                {
                    heading: t("items"),
                    content: props.data?.itemCount
                }
            ],
            topRight: <InboundDropdown id={props.data?.id ?? 0} warehouseId={props.data?.warehouseId ?? 0} />
          }));
        }
      }, [data,ready]);

   
    return (
        <Sidepanel2
            tabConfig={spInboundDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} >
                <>
                    <Products config={spInboundDetailsConfig.products} data={props?.data}/>
                    <Logs config={spInboundDetailsConfig.logs}data={props?.data?.id}/> 
                </>
        </Sidepanel2>
    );
};

export default SpInboundDetails;