import { Box } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../../utils";
import { useGetLogsQuery } from "../../../../../newapi/global/logsSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        </Box>
    )
}

const gridOptions = {
    export:false,
    search: false
};

const Logs = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [config, setConfig] = useState({type: "Product", externalId: props.data.id});

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions></GridActions>
        });

        return () => {
        }
    },[]);
    
    

    const columns = [
        {
            field: "createdAt", 
            headerName: t('created_on'),
            flex:1,
            renderCell : (cellValues) => {
                const {date, time} = getTranslatedDateAndTime(cellValues.row.createdAt, i18n);
                return (
                    <>
                        <Text semibold>{date}</Text>
                        &nbsp;&nbsp;
                        <Text light>{time}</Text>
                    </>
                )
            }
        },
        {
            field: "description", 
            headerName: t('description'),
            flex:1
        },
        {
            field: "userName", 
            headerName: t('by'),
            flex:1
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2
                content={useGetLogsQuery}
                config={config}
                gridActions={<GridActions></GridActions>} 
                title={t('overview')} 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
        </Box>
    );
}

export default Logs;