import { Box, ButtonBase, useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { chartTokens, tokens } from "../../../theme";
import ShChart from "./ShChart";
import ChartCard from "../cards/ChartCard";
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import HomeIcon from '@mui/icons-material/Home';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import SearchIcon from '@mui/icons-material/Search';
import { MenuStateContext } from "../../../contexts/MenuState";

import cx from 'classnames';
import { NoChartData } from "./Status/NoChartData";
import { ChartError } from "./Status/ChartError";

const AreaContent = (props) => {

    // const {collapsed} = useContext(MenuStateContext); //collapse for menu
    //    // State variable to trigger rerender
    // const [menuCollapsed, setMenuCollapsed] = useState(collapsed);

    // // Update menuCollapsed state when the collapsed state changes
    // useEffect(() => {
    //     setMenuCollapsed(collapsed);
    // }, [collapsed]);

    return (
        <Chart

            className="sh-area-chart"
            key={props.key}
            ref={props.ref}
            type="area"
            series={props.series}
            options={props.options}
            height={props.height}
        />
    )
}

export const AreaChartContent = (props) => {
    if(typeof props.colors === "undefined" && typeof props.staticColors === "undefined") throw new Error("Colors not defined");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const [chartMode, setChartMode] = useState('zoom'); 
    const colorType = theme.palette.mode === "dark" ? "light" : "st"; 

    const getColor = () => {
        if(typeof props.color === "undefined") return colors.txt["secondary"];
        if(props.color !== "default") return chartTokens.text.primary;
        return colors.txt["secondary"];
    }

    const getButtonTextColor = () => {
        if(props.color === "default" && theme.palette.mode === "dark") {
            return colors.txt.primary;
        }
        return chartTokens.text.primary;
    }

    const randomData = Array.from({ length: 7 }, () => Math.floor(Math.random() * 60) + 40);
   
    // Define the initial values based on the isLoading prop
    const values = props.isLoading || props.isError ? [{
        name: 'Loading',
        data: randomData
    }] : props.values

    const allValuesAreZero = useMemo(() => {
        return values.every(item => item.data.every(value => value === 0 || isNaN(value)));
    }, [values]);


    // const [values, setValues] = useState(initialValues);


    const getChartColors = () => {
        if (props.isLoading || allValuesAreZero) {
            // Use the loading color from DonutChart
            return [theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]];
        }
        if(props.isError) {
            return [colors.red[200]]
        }
        
        //Static colors take priority
        if(typeof props.staticColors !== "undefined") return props.staticColors;


        let myColors = [];

        var keys = Object.keys(chartTokens[props.colors[0]][colorType]);
        for (var i = keys.length - 1; i >= keys.length - values.length; i--) {
            myColors.push(chartTokens[props.colors[0]][colorType][keys[i]]);
        }
        return myColors;

    }
    

    const options = {
        chart: {
            selection: {
              enabled: true
            },
            pan: {
                enabled:true,
            },
            stacked: true,
            // zoom: {
            //   enabled: chartMode === 'zoom',
            // },
            // pan: {
            //   enabled: chartMode === 'pan',
            // },
            // zoom: {

            //     enabled: true,
            //     type: 'x',  
            //     autoScaleYaxis: false,  
            // },
            toolbar: {
                autoSelected: chartMode,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true
                }
            },
            // autoSelected: 'selection',
        },
        colors:getChartColors(),
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: undefined,
            width:0
        },
        fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [100, 100]
            }
        },
        legend:{
            show:false,
        },
        grid:{
            show:false,
        },
        xaxis: {
            categories: props.categories ?? [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
            tickAmount: props.tickAmount ?? 5,
            // type: 'category',
            // categories: [],
            // tickAmount: undefined,
            // tickPlacement: 'between',
            // min: undefined,
            // max: undefined,
            // range: undefined,
            // floating: false,
            // decimalsInFloat: undefined,
            // overwriteCategories: undefined,
            // position: 'bottom',
            labels: {
                // show: true,
                // rotate: -45,
                // rotateAlways: false,
                // hideOverlappingLabels: true,
                // showDuplicates: false,
                // trim: false,
                // minHeight: undefined,
                // maxHeight: 120,
                style: {
                    colors:getColor(),
                    // colors: ["blue","orange","grey"],
                    fontSize: '12px',
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 500,
                    
                    cssClass: 'apexcharts-xaxis-label',
                },
                // formatter: function(value,ts,opts) { 
                //     console.log("hellovalue",value);
                //     console.log("hellots",ts);
                //     console.log("helloopts",opts);
                //     return "<span>{value}</span>";
                // },
                offsetX: 0,
                // offsetY: 20,
                // format: undefined,
                // formatter: undefined,
                // datetimeUTC: true,
                // datetimeFormatter: {
                //     year: 'yyyy',
                //     month: "MMM 'yy",
                //     day: 'dd MMM',
                //     hour: 'HH:mm',
                // },
            },
            // group: {
            //   groups: [],
            //   style: {
            //     colors: [],
            //     fontSize: '12px',
            //     fontWeight: 400,
            //     fontFamily: undefined,
            //     cssClass: ''
            //   }
            // },
            axisBorder: {
                show: false,
                // color: 'orange',
                // height: 0,
                // width: '100%',
                // offsetX: 0,
                // offsetY: 0
            },
            // axisTicks: {
            //     show: true,
            //     borderType: 'solid',
            //     color: '#78909C',
            //     height: 6,
            //     offsetX: 0,
            //     offsetY: 0
            // },
           
            // title: {
            //     text: undefined,
            // },
            // crosshairs: {
            //     show: true,
            //     width: 1,
            //     position: 'back',
            //     opacity: 0.9,        
            //     stroke: {
            //         color: '#b6b6b6',
            //         width: 0,
            //         dashArray: 0,
            //     },
            //     fill: {
            //         type: 'solid',
            //         color: '#B1B9C4',
            //         gradient: {
            //             colorFrom: '#D8E3F0',
            //             colorTo: '#BED1E6',
            //             stops: [0, 100],
            //             opacityFrom: 0.4,
            //             opacityTo: 0.5,
            //         },
            //     },
            //     dropShadow: {
            //         enabled: false,
            //         top: 0,
            //         left: 0,
            //         blur: 1,
            //         opacity: 0.4,
            //     },
            // },
        },
        yaxis: {
            show:false,
            // opposite: true
            labels: {
                style: {
                    colors:getColor(),
                    // colors: ["blue","orange","grey"],
                    fontSize: '12px',
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 500,
                    // cssClass: 'apexcharts-xaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
                format: undefined,
                formatter: undefined,
                datetimeUTC: true,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: "MMM 'yy",
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
            },
        },
        //   legend: {
        //     horizontalAlign: 'left'
        //   }
    };


    useEffect(() => {
        if(!chartRef.current) {
            // alert("notready");
        }else{
            // alert("ready");
        }
        // console.log("ref",chartRef.current);
    },[chartRef.current])
    

    const setTickPositions = () => {
        setTimeout(() => {
            if(containerRef.current) {
                var p = containerRef.current.querySelectorAll(".apexcharts-xaxis-texts-g")[0];
                var ticks = containerRef.current.querySelectorAll(".apexcharts-xaxis-tick");
                var lastTickPos = ticks[ticks.length-1]?.getAttribute('x2');
                if(typeof p !== "undefined"){
                    p.childNodes[0]?.setAttribute('x', 10);
                    p.childNodes[p.childNodes.length-1]?.setAttribute('x', lastTickPos-12);
                }
            }
        },[20])
    }
    
    const resetChart = () => {
        if (chartRef.current && chartRef.current.chart) {
            requestAnimationFrame(() => {
                chartRef.current.chart.resetSeries();
            });
            setTickPositions();
        }
    };
      
    const zoomIn = () => {
        if (chartRef.current && chartRef.current.chart) {
            requestAnimationFrame(() => {
                let { minX, maxX } = chartRef.current.chart.w.globals;
                let newMin = minX + (maxX - minX) / 4;
                let newMax = maxX - (maxX - minX) / 4;
                chartRef.current.chart.zoomX(newMin, newMax);
            });
            setTickPositions();
        }
    };
      
    const zoomOut = () => {
        if (chartRef.current && chartRef.current.chart) {
            requestAnimationFrame(() => {
                let { minX, maxX } = chartRef.current.chart.w.globals;
                let newMin = minX - (maxX - minX) / 2;
                let newMax = maxX + (maxX - minX) / 2;
                chartRef.current.chart.zoomX(newMin, newMax);
            });
            setTickPositions();
        }
    };

    const toggleMode = () => {
        setChartMode(prevMode => prevMode === 'zoom' ? 'pan' : 'zoom');
        setTickPositions();
    };

    useEffect(() => {
        setTickPositions();
    },[chartRef.current,theme.palette.mode]);

    const handleResize = () => {
        setTickPositions();
    }
       
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    },[]);
  

    return (
        
        <Box
            className={cx(props.className, {
                'loading': props.isLoading,
            })} 
            ref={containerRef}
            sx={{
                position:"relative",
                width:1,
                height:1,
                minHeight:250,
                "& .apexcharts-canvas" : {
                    "& svg" : {
                        marginLeft:props.horizontal ? "-8px" : "-12px",
                        // marginLeft:props.isModal ? "-16px" : "-37px",
                        marginTop:"-16px",
                        position:"absolute",
                        marginRight:"-20px"
                    },
                    // position:"unset",
                    "& .apexcharts-toolbar" : {
                        display:"none",
                    },
                    "& .apexcharts-tooltip, .apexcharts-xaxistooltip, .apexcharts-yaxistooltip" : {
                        background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                        color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.white[400],
                        border:"none",
                        "& .apexcharts-tooltip-title" : {
                            display:"none"
                        }
                    },
                },
                "&.loading .sh-area-chart" : {
                    animation: 'pulse 2.5s infinite'
                },
                "@media screen and (max-width: 47.9375em)" : {
                    minHeight:"200px",
                    // paddingRight:3,
                }
            }}
        >
            {props.optionsEnabled && !props.isLoading && !props.isError && !allValuesAreZero ?
                <Box
                    sx={{
                        position:"absolute",
                        right:24,
                        top:"-40px",
                        height:40,
                        display:"inline-flex",
                        alignItems:"center",
                        background: chartTokens[props.color][colorType][100],
                        padding:0.5,
                        borderRadius:5,
                        gap:0.5,
                        zIndex:120,
                        "@media screen and (max-width: 47.9375em)" : {
                            position:"relative",
                            display: props.isModal ? "flex" : "none",
                            top:"unset",
                            width:"calc(100% - 24px)",
                            // marginRight:3,
                            marginTop:2,
                            marginLeft:3
                        }
                    }}
                >
                    <ButtonBase 
                        sx={{
                            height:32,
                            width:32,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            borderRadius:4,
                            background: chartTokens[props.color][colorType][1000],
                            color: "#fff",
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:1,
                                width:"unset"
                            }
                        }}
                    
                        onClick={resetChart}
                    >
                        <HomeIcon fontSize="small"/>
                    </ButtonBase>
                    <ButtonBase 
                        sx={{
                            height:32,
                            width:32,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            borderRadius:4,
                            color: getButtonTextColor(),
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:1,
                                width:"unset"
                            }
                        }}
                    
                        onClick={zoomIn}
                    >
                        <ZoomInMapOutlinedIcon fontSize="medium"/>
                    </ButtonBase>
                    <ButtonBase 
                        sx={{
                            height:32,
                            width:32,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            borderRadius:4,
                            color: getButtonTextColor(),
                            "@media screen and (max-width: 47.9375em)" : {
                                flex:1,
                                width:"unset"
                            }
                        }}
                    
                        onClick={zoomOut}
                    >
                        <ZoomOutMapOutlinedIcon fontSize="medium"/>
                    </ButtonBase>

                    {/* Switch */}
                    <Box
                        sx={{
                            height:32,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            padding:0.5,
                            background: chartTokens[props.color][colorType][300],
                            borderRadius:4,
                            gap:0.5,
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <ButtonBase 
                            sx={{
                                height:24,
                                width:24,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:4,
                                color: chartMode === "zoom" ? "#fff" : chartTokens.text.primary,
                                background: chartMode === "zoom" ? chartTokens[props.color][colorType][1000] : null,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    width:"unset"
                                }
                            }}
                        
                            onClick={toggleMode}
                        >
                            <SearchIcon fontSize="small"/>
                        </ButtonBase>
                        <ButtonBase 
                            sx={{
                                height:24,
                                width:24,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:4,
                                color: chartMode === "pan" ? "#fff" : chartTokens.text.primary,
                                background: chartMode === "pan" ? chartTokens[props.color][colorType][1000] : null,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    width:"unset"
                                }
                            }}
                        
                            onClick={toggleMode}
                        >
                            <PanToolAltIcon fontSize="small"/>
                        </ButtonBase>

                    </Box>
                </Box>
            :null}

            <AreaContent
                key={chartMode}
                ref={chartRef}
                type="area"
                series={!allValuesAreZero ?values : [{name:"Data",data:randomData}]}
                options={options}
                height={props.isModal ? "100%" : typeof props.height !== "undefined" ? props.height : "300px"}
        
            />
            
            {allValuesAreZero && <NoChartData type="area"/>}
            {props.isError && <ChartError type="area" onRetry={props.onRetry}/>}
            
        </Box>
    )
}

const AreaChart = (props) => {
    return (
        <ShChart>
            <ChartCard 
                optionsEnabled={props.optionsEnabled ?? true}
                {...props}
                type={"area"}
            >
                <AreaChartContent
                    {...props}
                    optionsEnabled={props.optionsEnabled ?? true}
                    color={props.color} 
                    values={props.values} 
                    categories={props.categories} 
                    tickAmount={props.tickAmount ?? 30}/>
            </ChartCard>
        </ShChart>
    );
}
export default AreaChart;