// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
// import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
// import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
// import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import { ReactComponent as Crate } from '../../../../../styles/svg/crate.svg';
import { ReactComponent as Shirt } from '../../../../../styles/svg/shirt.svg';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import ImportComponent from '../../../../theme/import/ImportPreprocessor';
import { Box } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import { useTranslation } from 'react-i18next';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    
        // const columns = [
        //     {title: "PostNL", logo: <PostNLLogo width={60} height={60}/>, key: "POSTNL"},
        //     {title: "DHL", logo: <DhlLogo width={60} height={60}/>, key: "DHL"},
        //     {title: "Dpd", logo: <DpdLogo width={60} height={60}/>, key: "DPD"},
        //     {title: "Anders", logo: <AltRouteOutlinedIcon sx={{fontSize:"60px"}} />, key: "OTHER"}
        // ]
        // // New prop is called "Logo"
        // // {title: 'normal', logo: <CheckCircleOutlineIcon />, key: "normal"},
        // //     {title: 'runner', logo: <DirectionsRunIcon />, key: "runner"},
        // //     {title: 'highcare', logo: <FavoriteOutlinedIcon />, key: "highcare"},
        // //     {title: 'special', logo: <AutoAwesomeOutlinedIcon />, key: "special"}

        // const handleOptionSelect = (option) => {
        //     props.handleDataUpdate({ selectedOption: option });
        //     props.handleNext("tabBasicInfo");
        // };
        const breakpoint = "1366";

        const {t} = useTranslation();
        const setData = (data) => {
            props.handleDataUpdate({"preprocessed":data});
            props.handleNext("tabMatchHeaderToColumn")
        }

        
    
        return (
            <Box
                sx={{
                    width:865,
                    display:"flex",
                    gap:3,

                    [`@media screen and (max-width: ${breakpoint}px)`] : {
                        flexDirection:"column",
                        width:"unset"
                    }
                }}
            >
                {!props.combined ? (
                    <>
                        <ImportComponent
                            setData={setData}
                            icon={<LocationOnOutlinedIcon/>}
                            title={t("locations")}
                            type="warehouselocations"
                            breakpoint="smallpc"
                            color="orange"
                            source={props.source.toLowerCase()} 
                        />
                        <ImportComponent
                            setData={setData}
                            icon={<Shirt/>}
                            title={t("product")}
                            type="product"
                            breakpoint="smallpc"
                            color="blue"
                            source={props.source.toLowerCase()} 
                        />
                        <ImportComponent
                            setData={setData}
                            icon={<Crate/>}
                            title={t("stock")}
                            type="stock"
                            breakpoint="smallpc"
                            color="purple"
                            source={props.source.toLowerCase()} 
                        />
                    </>
                ) : (
                    <ImportComponent
                        setData={setData}
                        icon={<HubOutlinedIcon/>}
                        title={t("combined")}
                        type="combined"
                        breakpoint="smallpc"
                        color="pink"
                        source={props.source.toLowerCase()} 
                    />
                )}
            </Box>
            // <div>
            //     <SelectGroup selected={-1} setSelected={handleOptionSelect} options={columns} image={true} />
            // </div>
        );
    };

const ChooseImportType = (props) => {
    return (
        <ProgressionTab tabId="tabChooseImportType" step={1} maxSteps={3}>
            <Content {...props}/>
        </ProgressionTab>
    )
}

export default ChooseImportType;

