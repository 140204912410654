import { Box, List, useTheme } from "@mui/material"
import SHModal from "../../theme/Modal/SHModal"
import Shbutton from "../../theme/buttons/Shbutton"
import { tokens } from "../../../theme";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import SelectGroup from "../../theme/Select/SelectGroup";
import { useState, useEffect } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useGetWarehousesSelectQuery } from "../../../newapi/warehouse/warehouseApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFindAllWorkspacesQuery, useFindWorkspacesSelectQuery } from "../../../newapi/warehouse/workspaceSlice";
import { setWorkSpace } from "../../../newapi/global/settingsSlice";

const MSelectWorkspaces = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const selectedWorkSpace = useSelector((state) => state.settings.workSpace);
    const [selected, setSelected] = useState(-1);
    const [workTSpace,setTWorkSpace] = useState(selectedWorkSpace);

    const select = (selected) => {
        setTWorkSpace(data[selected]);
    }
    const { data: workSpaceData, isLoading } = useFindWorkspacesSelectQuery();

    useEffect(() => {
        if (!isLoading && workSpaceData) {
            const modifiedArray = workSpaceData.data.map(item => ({
                ...item,
                title: item.name
              }));
            
            setData(modifiedArray);
        }
    }, [workSpaceData, isLoading]);


    const selectWorkSpace = () => {
        dispatch(setWorkSpace({
            id:workTSpace.id,
            name:workTSpace.name,
        }));
        props.handleClose();
        
    }

    useEffect(() => {
        if(data.length > 0) {
            console.log("bigger");
            for(var i = 0;i < data.length; i++) { 
                if(data[i].id === selectedWorkSpace.id){
                    setSelected(i);
                    // setSelected
                }
            }
        }
    }, [selectedWorkSpace,data]);


    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    return(
        <SHModal
            open={props.open}
            onClose={() => props.handleClose(false, null)}
        >
            
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{props.subheading || "Select workspace"}</Subheading>
                {/* <Text light>{props.text || "The data presented will automatically be filtered through the selected warehouses"}</Text> */}
            </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16
                }}
            >
                <SelectGroup selected={selected} setSelected={select} options={data} />
            </Box>
            {/* Hello */}
                    
            {/* <Box
                sx={{
                    paddingBottom:isStandAlone ? "32px" : "16px",
                    width:1,
                    paddingX:3,
                    paddingTop:3,
                    display:"flex"
                }}
            >
                <Shbutton onClick={props.handleClose} color="primaryGrey" variant="contained" modalFooterBtn endIcon={<ArrowRightAltIcon/>}>Sorteren</Shbutton>
            </Box> */}

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                
                <Shbutton isCancel={true} className={"always-visible"} onClick={() => props.handleClose(false, null)} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>Cancel</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} onClick={() => selectWorkSpace()} variant="contained">Select</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MSelectWorkspaces;