import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";
import AreaChart from "../../../theme/charts/AreaChart";


export const InboundChart = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return (
        <Box
            sx={ props.sx ?? {

                overflow:"hidden",
                flex:3,
                "@media screen and (max-width: 47.9375em)" : {
                    minWidth: "calc(100% - 48px)"
                }
            }}
        >

            <AreaChart 
                hideFilter
                title={t("forecasted_and_received")}
                subtitle={t("per_country")}
                type="bar"
                colors={['primary']}
                categories={props.categories}
                values={props.values}
                color={"default"}
                tickAmount={30}
                isLoading={props.isLoading || props.isFetching}
                isError={props.isError}
                onRetry={props.refetch}
            />
        </Box>

    );
}