import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { useRef, useState } from "react";

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Box, Collapse, Typography } from "@mui/material";
import Subheading from "../text/Subheading";

// import cx from classNames
import cx from "classnames";
import Text from "../text/Text";

const DropOut = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState(props.open ?? false);
    const alwaysOpen = props.alwaysOpen ?? false;
    // useRef
    const sx = props.sx;

    function toggleDiv() {

        if(!alwaysOpen) {
            setOpen((isListOpen) => !isListOpen);
        }
    }

    return (
        <Box 
            className={cx(`dropdown-menu`, {
                'open': open || alwaysOpen,
                'always' : alwaysOpen
            })}
            sx={{
                width:"auto",
                borderBottom: !props.isLast ? `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important` : null,
                paddingBottom:0,
                paddingTop:3,
                marginX: props.paddingX ?? null,
                transition:"250ms",
                "&.open" : {
                    paddingBottom:3,

                    "&.always" : {
                        "@media screen and (max-width: 47.9375em)" : {
                            borderBottom:"none !important"
                        }
                    }
                }
            }}
        >
            <Box 
                // ref={dropdownToggle}
                className="dropdown-toggle" 
                onClick={() => toggleDiv()}
                sx={{
                    display:"flex",
                    alignItems:"flex-start",
                    userSelect:"none",
                    paddingBottom:3,
                    cursor:"pointer",
                    gap:4,
                }}    
            >    
                <Box display="flex" flexDirection={"column"} gap={0.5}>
                    {typeof props.explainer !== "undefined" && props.explainer !== null ?
                        <Box
                            sx={{
                                display:"flex",
                                alignItems:"center"
                            }}
                        >
                            <Subheading>
                                {props.title}
                            </Subheading>
                            {props.explainer}
                        </Box>
                    :
                        <Subheading>
                            {props.title}
                        </Subheading>
                    }
                    {typeof props.subTitle !== "undefined" && props.subTitle.length > 0 ? <Text light>{props.subTitle}</Text> : null }
                </Box>
                {alwaysOpen ? null :
                    <ArrowForwardIosOutlinedIcon 
                        className={cx(`arrow`, {
                            'open': open || alwaysOpen,
                        })}
                        sx={{
                            marginLeft:"auto",
                            fontSize:"14px",
                            transform:"rotate(0deg)",
                            transition:"250ms",
                            marginTop:0.5,
                            color:colors.txt["secondary"],
                            "&.open" : {
                                transform:"rotate(90deg)",
                                transition:"250ms",
                                color:colors.txt["primary"],
                            }
                        }}
                    />
                }
            </Box>

            <Collapse in={open || alwaysOpen}>
                <Box 
                    className={cx(`dropdown-content`, {
                        'open': open || alwaysOpen,
                    })}
                    sx={{
                        overflow:"hidden",
                        transition:"250ms",
                        "&.open" : {
                            overflow:"visible",
                            transition:"250ms",
                        }
                    }}>
                    
                    <Box 
                        // ref={dropdownBodyRef}
                        className="dropdown-body"
                        sx={{
                            listStyle:"none",
                            paddingX:0,
                            width:1,
                            position:"relative",
                        }}>
                            {props.children}
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}

export default DropOut;