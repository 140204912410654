
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

export const settingsWarehousesConfig = {
    allWarehouses:  {
        title: "all_warehouses",
        subTitle: "All warehouse registrations",
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
        //mobileactions added later
    },
    reachTypes: {
        title: 'reach_types',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
        //mobileactions added later
    },
    warehouseLocationTypes: {
        title: 'warehouse_location_types',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
        // mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
    }
    // Additional configurations as needed
};
