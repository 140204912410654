import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { tokens } from "../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import SpWarehouseLocationTypeDetails from "../../../../../components/global/Sidepanels/SpWarehouseLocationTypeDetails/SpWarehouseLocationTypeDetails";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetWarehouseLocationTypesQuery, useGetWarehouseLocationTypeQuery } from "../../../../../newapi/warehouse/warehouseLocationTypeSlice";
import MAddWarehouseLocationType from "../../../../../components/global/Modals/MAddWarehouseLocationType";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";


const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {},[props.open]);

    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_warehouse_location_type")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

// const warehouseLocationViewTypeData = (props) => {
//     return {
//         title: 'warehouse_location_types',
//         icon: <Inventory2OutlinedIcon/>,
//         isSingleDataGrid: true,
//         gridOptions:gridOptions,
//         mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
//     }
// }

const WarehouseLocationTypes = forwardRef((props, ref) => {
    console.log("oh ye im called");
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    const { data: warehouseLocationTypeData, error, isLoading } = useGetWarehouseLocationTypeQuery(
       selectedRowId,
        {
            skip: !rowSelected
        }
    );
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const getWarehouseLocationTypeDetails = (row) => {  
        if (!row) return;
        setSelectedRowId(row.id);
        setRowSelected(true);
    };

    useEffect(() => {
        if (!isLoading && warehouseLocationTypeData && rowSelected) {
            setIsExtended(true);
        }
    }, [warehouseLocationTypeData, isLoading, rowSelected]);

    useEffect(() => {
        if(!isExtended) {
            setRowSelected(false);
        }
    },[isExtended])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        {field: "description", 
        headerName: t("description"), 
        flex:2, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "type", 
        headerName: t("type"), 
        flex:1, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return cellValues.row.type
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
                {/* <GridActions></GridActions> */}
                <Box
                    sx={{
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        flex:1,
                        borderTopLeftRadius:16,
                        borderTopRightRadius:16,
                        "@media screen and (max-width: 48em)" : {
                            // display:"none"
                            background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        }
                    }}
                >
                    <ShDataGrid2
                        content={useGetWarehouseLocationTypesQuery}
                        gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                        title={t('overview')} 
                        gridOptions={gridOptions} 
                        isSidepanelGrid
                        columns={columns} 
                        onRowClick={getWarehouseLocationTypeDetails}
                        rows={data}>
                    </ShDataGrid2>
                </Box>

                <MAddWarehouseLocationType onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                <SpWarehouseLocationTypeDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    data={warehouseLocationTypeData}                
                ></SpWarehouseLocationTypeDetails>
        </Box>
    )
});

export default WarehouseLocationTypes;