import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import BottomRight from "../../positionals/BottomRight";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../skeleton/ShDoubleTextSkeleton";

const DownloadAttachmentCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        {props.skeleton ?
                            <ShTextSkeleton/>
                            :
                            <Text bold>{props.row.name}</Text>
                        }
                    </Box>

                    <BottomRight>
                        <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"} size={40} onClick={props.skeleton ? null : props.onClick} icon position="bottom-right">
                            {props.skeleton ? null :
                                <DownloadOutlinedIcon fontSize="small"/>
                            }
                        </CardLabel>
                    </BottomRight>

                    {/* Holds tht date and batch */}
                    <Box marginTop={3} display={"flex"} width={props.skeleton ? "50%" : null} flexDirection={"column"}>
                        {props.skeleton ? 
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text>{props.row.creationDate}</Text>
                                <Text light>{props.row.creationTime}</Text>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default DownloadAttachmentCard;