
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spReturnDetailsConfig = {
    inbound:  {
        title: 'inbound',
        icon: <CropFreeIcon/>,
    },
    logs:  {
        title: 'logs',
        icon: <FormatListBulletedIcon/>,
        isSingleDataGrid: true,
        
    },
    return:  {
        title: 'return',
        icon: <CropFreeIcon/>,
    },
};
