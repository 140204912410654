import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Shbutton from "../../buttons/Shbutton";

import CloseIcon from '@mui/icons-material/Close';
import FilterValue from "./FilterValue";
import Text from "../../text/Text";
import { getConditions } from "./Settings";
import { useEffect, useState } from "react";
import { useFilter } from "../../../../contexts/FilterState";
import { useGridFilter } from "../../../../contexts/GridFilterContext";
import { useDispatch } from "react-redux";
import { removeFilterRule } from "../../../../newapi/global/filter/gridFilterSlice";

const extensionType = [
    "",
    "&",
    "||"
];

const FilterRule = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {index,id} = useGridFilter();
    // const {removeFilterRule} = useFilter();

    const [column, setColumn] = useState();

    let hasBeenSet = false;
    useEffect(() => {
        if(typeof props.apiRef !== "undefined" && Object.keys(props.apiRef).length > 0 && props.apiRef.current !== null && !hasBeenSet) {
            let currColumns = props.apiRef.current.getAllColumns();
            setColumn(currColumns[props.condition.column])
            hasBeenSet = true;
        }
    },[props.apiRef]);

    const handleRemove = () => {
        dispatch(removeFilterRule({
            index,
            id,
            groupIndex: props.groupindex,
            ruleIndex: props.index
        }));
        props.filterRef.current.applyFilterAfterRemoval();
    }

    return (
        
        <Box 
            sx={{
                display:"flex",
                flexWrap:"wrap",
                gap:1,
                width:1,
            }}
        >
                {props.index > 0 ?
            <Box
                sx={{
                }}
            >
                <Shbutton className="filter small">
                    
                    <Typography
                        variant="span" 
                        fontWeight={props.fontWeight} 
                        fontFamily="Roboto, sans-serif"
                        sx={{
                            color:`${colors.txt["primary"]}`,
                            fontSize:17,
                        }}
                    >
                        {extensionType[props.condition.extensionType]}
                    </Typography>
                </Shbutton>
            </Box>

                : null }
            <Box
                sx={{
                    paddingX:2.5,
                    paddingRight:0.5,
                    display:"flex",
                    alignItems:"center",
                    height:"40px",
                    background: theme.palette.mode==="dark" ? colors.grey[500] : colors.bg["tertiary"],
                    borderRadius:5,
                    gap:1.5,
                    flex:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        background: theme.palette.mode==="dark" ? colors.grey[400] : colors.bg["tertiary"],

                    }
                    
                }}
            >

                {props.condition.type.name !== "boolean" && typeof column !== "undefined" ?
                    <Box>
                        <Text light>{column.headerName}</Text>
                    </Box>
                :null }

                <Box display={"flex"} alignItems={"center"}>
                    <Text sx={{
                        fontWeight:"bold", 
                        color:colors.txt["primary"],
                        fontSize:"14px",
                        "@media screen and (max-width: 47.9375em)" : {
                            maxWidth:"56px",
                            whiteSpace:"nowrap",
                            overflow:"hidden",
                            textOverflow:"ellipsis"
                        }
                    }}>{getConditions()[props.condition.type.name][props.condition.type.value]}</Text>
                </Box>
                <FilterValue {...props} column={column}/>
                {/* <FilterCondition {...props}/> */}

                <Shbutton icon onClick={handleRemove} color={theme.palette.mode === "dark" ? "primaryGreyOpposite" : "primaryGrey"} size="32">
                    <CloseIcon fontSize="small"/>
                </Shbutton>
            </Box>
            {/* Rule extension (add filter) */}
        </Box>
    )
}

export default FilterRule;