import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import cx from "classnames"
import {checkAllPropertiesTruthy } from "../../../utils"
import { useAddNewSupplierMutation } from "../../../newapi/global/supplier/supplierSlice";
import { showToastMessage, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../utils/validation";
import SelectGroup from "../../theme/Select/SelectGroup";
// import { required } from '../../utils/validation';
import { ReactComponent as DhlLogo } from '../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../styles/svg/postnl.svg';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';


const MAddStreamRuleShipper = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);

    const select = (selected) => {
        setSelected(selected);
    }


    
   
    const SaveSupplier = async () => {
        // try {
        //   // Reset field errors
        //   setFieldErrors({});
      
        //   await schema.validate(supplierInfo, { abortEarly: false });
      
        //   // If validation is successful, proceed with the addSupplier call
        //   const data = await addSupplier(supplierInfo).unwrap();
        //   showToastMessagePostRequest(t, data);
        // } catch (err) {
        //   if (err.name === 'ValidationError') {
        //     // Create an errors object where keys are field names and values are error messages
        //     const errors = err.inner.reduce((acc, currentError) => ({
        //       ...acc,
        //       [currentError.path]: currentError.message
        //     }), {});
      
        //     // Set the validation errors in the state
        //     setFieldErrors(errors);
        //   } else {
        //     // Handle other errors, such as network errors
        //     showToastMessageRequestError(t("create_failed"), t, { success: false, error: err.error });
        //   }
        // }
    };




    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const columns = [
        {
            title: "PostNL", 
            color:"#FFF8F2", 
            selectionColor: {
                background: "#FFDBBD",
                border: "#F56900",
            }, 
            logo: <PostNLLogo width={60} height={60}/>, 
            key: "POSTNL"
        },
        {
            title: "DHL", 
            color: "#FFFCF2",
            selectionColor: {
                background: "#FFF0BD",
                border: "#F56900",
            }, 
            logo: <DhlLogo width={60} height={60}/>, 
            key: "DHL"
        },
        {
            title: "Dpd", 
            color:"#FDF2F5", 
            selectionColor: {
                background: "#F6C6D3",
                border: "#D90032",
            }, 
            logo: <DpdLogo width={60} height={60}/>, 
            key: "DPD"
        },
        {
            title: "Anders", 
            color:"#F2FDFF",
            selectionColor: {
                background: "#D6F9FF",
                border: "#00BBDC",
            }, 
            logo: <AltRouteOutlinedIcon sx={{fontSize:"60px"}} />, 
            key: "OTHER"
        }
    ]

    const handleOptionSelect = (option) => {
        console.log(option);
        // props.handleDataUpdate({ selectedOption: option });
        // props.handleNext("tabBasicInfo");
    };


    return(
        <SHModal
            open={props.open}
            onClose={() => props.handleClose(false)}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_stream_rule_shipper")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    // overflow:"scroll"
                }}
            >

                <SelectGroup displayName={"name"} selected={-1} setSelected={setSelected} options={props.shippers} />
                

            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={() => props.handleClose(false)} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => props.handleClose(true, selected)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddStreamRuleShipper;
