import { Box, ButtonBase, InputBase, Typography, useTheme } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { tokens } from "../../theme";

import cx from "classnames";
import { Formik } from "formik";
import * as yup from "yup";
import Shbutton from "../../components/theme/buttons/Shbutton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as StockVisual } from '../../styles/svg/rmaportal/stockvisual.svg';
import { useEffect, useState } from "react";
import { useFindOrderForReturnQuery, useGetRelationForReturnQuery } from "../../newapi/return/returnsSlice";
import { showToastMessage, showToastMessageRequestError } from "../../utils/toasts";

const RegisterReturn = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [requestData, setRequestData] = useState(null);
    const [dataPrepared, setDataPrepared] = useState(false);

    const { relation } = useParams();
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const order = searchParams.get("order");
        const email = searchParams.get("email");
        if (order && email) {
            setRequestData({ ordernumber: order, email: email });
            setDataPrepared(true);
        }
    }, []);

    const {data: orderData, isSuccess, isError} = useFindOrderForReturnQuery({relation: relation, body: requestData}, {
        skip: dataPrepared === false || !requestData?.ordernumber,
        enabled: dataPrepared === true && requestData?.ordernumber?.length && requestData?.ordernumber?.length > 1
    });  

    const {data: relationData, isError: relationError} = useGetRelationForReturnQuery({relation: relation}, {
        skip: relation === undefined
    });

    useEffect(() => {
        if((relationData && !relationData?.found) || relationError){
            navigate("/notfound");
        } else {
            props.setRelation(relationData?.dataValues.name);
        }
        
    }, [relationData, relationError])

    const initialValues = {
        ordernumber: "",
        email: "",
    }
    
    const userSchema = yup.object().shape({
        ordernumber: yup.string().required("required"),
        email: yup
        .string()
        .email(t("email_invalid"))
        .required("required"),
    });

    const handleFormSubmit = async (values) => {
        setRequestData(values);
        setDataPrepared(true);
    }

    useEffect(() => {
        if(isSuccess && orderData.success){
            navigate(`/${orderData.hash}/order`);
        } else {
            showToastMessage("success",t('order_not_found'));
        }
    },[orderData, isSuccess, isError]);

    return (
        <Box
            component={"main"}
            sx={{
                // minHeight:"calc(100%)"
                zIndex:1,
            }}
        >
            <Box
                component={"section"}
                sx={{
                    background:"rgba(65,128,246,.10)",
                    background: "radial-gradient(circle, rgba(65,128,246,0.25) 0%, rgba(65,128,246,0.10) 100%)",
                    width:1,
                    minHeight:"700px",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    height:"100vh",
                    position:'relative',
                    overflow:"hidden",

                    ".stock-visual" : {
                        position:'absolute',
                        bottom:"0",
                        width:1,
                    }
                }}
            >

                <Box
                    sx={{
                        width:500,
                    }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize:"2.75em",
                            fontWeight:"bold",
                            color:"#31415f",
                            textAlign:"center",
                            mb:3,
                        }}
                    >
                        {t("create_return")}
                    </Typography>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}>
                        {({values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                            <form className="register-return-form" onSubmit={handleSubmit}>
                                <Box 
                                    display="flex"
                                    sx={{
                                        width:1,
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"rgba(65,128,246,.15)",
                                        border:"1px solid rgba(65,128,246,.15)",
                                        height:48,
                                        pl:1,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#fff",
                                            "webkit-text-fill-color":"#fff",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #23232329",
                                            WebkitTextFillColor: "white",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        'error': !!touched.ordernumber && !!errors.ordernumber
                                    })}
                                >
                                    <Box
                                        className={cx({
                                            'error': !!touched.ordernumber && !!errors.ordernumber
                                        })}
                                        sx={{
                                            height:32,
                                            width:142,
                                            px:2,
                                            borderRadius:4,
                                            textAlign:"center",
                                            fontWeight:"bold",
                                            color: "rgb(65,128,246)",
                                            background:colors.primary[100],
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",

                                            "&.error": {
                                                background:colors.red[100],
                                                color: colors.red[400]
                                            },
                                        }}
                                    >
                                        {t("ordernumber")}
                                    </Box>
                                    <InputBase 
                                        autoFocus
                                        type={"text"}
                                        sx={{
                                            pl:1.5,
                                            flex:1,
                                            color:"#31415f",
                                            fontSize:16,
                                            fontWeight:"500"
                                        }} 
                                        placeholder="#" 
                                        value={values.ordernumber}
                                        onBlur={handleBlur}
                                        onChange={(e, event) => {
                                            handleChange({ ...event, target: { name: 'ordernumber', value: e.target.value } })
                                        }}
                                        error={!!touched.ordernumber && !!errors.ordernumber}
                                    />
                                </Box>

                                <Box 
                                    display="flex"
                                    sx={{
                                        mt:2,
                                        width:1,
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"rgba(65,128,246,.15)",
                                        border:"1px solid rgba(65,128,246,.15)",
                                        height:48,
                                        pl:1,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#fff",
                                            "webkit-text-fill-color":"#fff",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #23232329",
                                            WebkitTextFillColor: "white",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        'error': !!touched.email && !!errors.email
                                    })}
                                >
                                    <Box
                                        className={cx({
                                            'error': !!touched.email && !!errors.email
                                        })}
                                        sx={{
                                            height:32,
                                            width:142,
                                            
                                            px:2,
                                            borderRadius:4,
                                            textAlign:"center",
                                            fontWeight:"bold",
                                            color: "rgb(65,128,246)",
                                            background:colors.primary[100],
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            "&.error": {
                                                background:colors.red[100],
                                                color: colors.red[400]
                                            },
                                        }}
                                    >
                                        {t("email")}
                                    </Box>
                                    <InputBase 
                                        type={"email"}
                                        sx={{
                                            pl:1.5,
                                            flex:1,
                                            color:"#31415f",
                                            fontSize:16,
                                            fontWeight:"500"
                                        }} 
                                        placeholder={t("email")}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        onChange={(e, event) => {
                                            handleChange({ ...event, target: { name: 'email', value: e.target.value } })
                                        }}
                                        error={!!touched.email && !!errors.email}
                                    />
                                </Box>
                                
                                <Box
                                    sx={{
                                        display:"flex",
                                        justifyContent:"flex-end",
                                        mt:2,
                                        width:1,
                                        "@media screen and (max-width: 47.9375em)" : {
                                            // marginTop:4
                                        }

                                    }}
                                >
                                    
                                    <ButtonBase
                                    type="submit"
                                // onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                                        sx={{
                                            color:"white",
                                            fontWeight:"500",
                                            fontSize:16,
                                            // justifyContent:"flex-start",
                                            height:"48px",
                                            width:"48px",
                                            alignItems:"center",
                                            // pl:2,
                                            // background:"rgba(255,255,255,.15)",
                                            background:"rgba(65,128,246,1)",
                                            borderRadius:6,
                                            flexShrink:0,
                                            width:1,
                                            transition:"250ms",
                                            "&:hover" : {
                                                background:"#0057FA",
                                                transition:"250ms"
                                            },    
                                            
                                        }}
                                    >
                                        {t("request_return")}
                                        {/* <ArrowBackIcon fontSize="small"/> */}
                                    </ButtonBase>
                                    {/* <Button type="submit" color="primary" variant="contained">
                                        Create new user
                                    </Button> */}
                                </Box>
                            </form>
                        )}
                    </Formik>
                    <Typography
                        sx={{
                            mt:3,
                            textAlign:"center",
                            fontWeight:"500",
                            color:"#31415f",
                        }}
                    >
                        {t("retour_check_status")}
                    </Typography>
                </Box>

                <StockVisual className="stock-visual"/>
            </Box>
        </Box>
    )
}

export default RegisterReturn