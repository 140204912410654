import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const BASE = "/reachtypes";
const ApiName = "ReachTypes";
const buildUrl = (endpoint) => BASE + endpoint;

const reachTypeSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReachTypes: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: ApiName}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getReachTypes', ApiName)
        }),
        getReachTypesSelect: builder.query({
            query: () => ({
                url: buildUrl(`/`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: ApiName}],
        }),
        getReachType: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
        }),
        updateReachType: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }, { type: ApiName }],
        }),
        addReachType: builder.mutation({
            query: reachTypeInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: reachTypeInfo,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName }],
        }),
        deleteReachType: builder.mutation({
            query: args => ({
                url: buildUrl(`/${args.id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiName}],
        }),
    })
});

export const { 
    useGetReachTypeQuery,
    useGetReachTypesQuery,
    useGetReachTypesSelectQuery,
    useUpdateReachTypeMutation,
    useAddReachTypeMutation,
    useDeleteReachTypeMutation
} = reachTypeSlice;
