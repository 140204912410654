import { Box, ButtonBase, useTheme } from "@mui/material";
import { useProcessFileMutation } from "../../../newapi/global/uploadApiSlice";
import { InputDropdown } from "../dropdowns/InputDropdown";
import { ReactComponent as ArrowAlt } from '../../../styles/svg/arrow_alt.svg';
import { useState } from "react";
import { tokens } from "../../../theme";
import { useTranslation } from "react-i18next";
import Text from "../text/Text";

const ImportProcessor = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [processFile] = useProcessFileMutation();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { t } = useTranslation();


    // Update selectedOptions when a dropdown selection changes
    const handleSelectionChange = (columnIndex, selected) => {
        setSelectedOptions(prev => {
            // Create a new array for updated options
            let updatedOptions = [...prev];

            // Check if the option for this column already exists
            const existingIndex = updatedOptions.findIndex(option => option.internal === props.columns[columnIndex].internal);

            if (existingIndex >= 0) {
                // Update existing option
                updatedOptions[existingIndex] = { internal: props.columns[columnIndex].internal, col: selected };
            } else {
                // Add new option
                updatedOptions.push({ internal: props.columns[columnIndex].internal, col: selected });
            }

            return updatedOptions;
        });
    };

    const save = async (force = false) => {
        try {
            const response = await processFile({ 
                source: props.source,
                type: props.uploadType, 
                data: {
                    file: props.internalFile,
                    force: force,
                    selected: selectedOptions
                } 
            }).unwrap();

            if (response) {
                console.log("File uploaded",response)
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    }

    return (
        <Box
            sx={{
                display:"flex",
                flexDirection:"column",
                gap:2,
                width:1,
                maxHeight: "400px",
                overflowY: "scroll"
            }}
        >
            {props.columns?.map((column,index) => (
                <Box
                    sx={{
                        display:"flex",
                        background:colors.grey[100],
                        width:1,
                        height:48,
                        alignItems:"center",
                        paddingLeft:2,
                        borderRadius:6,
                        gap:2,
                    }}
                >
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between"
                        }}
                    >

                        <Text semibold>{column.name}</Text>
                        <ArrowAlt className={`arrow ${theme.palette.mode === "dark" ? "dark" : null}`} />
                    </Box>
                    <Box
                        sx={{
                            flex:1
                        }}
                    >
                        <InputDropdown
                            variant="darker"
                            displayName={"name"} 
                            onChange={(selected) => handleSelectionChange(index, selected)}
                            noSelection={column.required ? "Kies een veld" : "Niet verplicht maar kies"} //Custom text when nothing is selected
                            options={props.headers}
                            closeOnSelection
                        />
                    </Box>
                </Box>
            ))}

            <Box
                sx={{
                    display:"flex",
                    justifyContent:"flex-end"
                }}
            >
                <ButtonBase
                    onClick={() => save()}
                    sx={{
                        paddingX:2,
                        height:40,
                        display:"flex",
                        alignItems:"center",
                        textAlign:"center",
                        background:colors.primary[100],
                        borderRadius:5,
                        color:colors.primary[400]
                    }}
                >
                    {t('save')}
                </ButtonBase>
            </Box>
        </Box>
    );
}

export default ImportProcessor;