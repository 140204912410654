import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";

import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spPickingCrateDetailsConfig } from "./spPickingCrateDetailsConfig";
const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpPickingCrateDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props


    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: theme.palette.mode === "dark" ? "blue" : "primary",
            content: <ShoppingCartCheckoutOutlinedIcon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && data !== null && typeof data.id !== "undefined") {
        //   let locations = data.stocks.map((stock) => stock.warehouseLocation.location);
        //   let combinedLocations = locations.join(", ");
      
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data?.barcode,
            subTitle: `${data.pickupCartLocations?.length ?? 0} ${t("locations")}`,
            icon :{
                theme: theme.palette.mode === "dark" ? "blue" : "primary",
                content: <ShoppingCartCheckoutOutlinedIcon/>
            },
            options: [
                {
                    heading: t('zone'),
                    content: data.warehouseZone?.description ?? t("unknown")
                },
                {
                    heading: t('reachtype'),
                    content: data?.reachType?.description ?? t("unknown")
                },
                {
                    heading: t('capacity'),
                    content: data?.capacity ?? 0
                }
            ],
          }));
          console.log(data);
        }
      }, [data,theme.palette.mode]);

    return (
        <Sidepanel2
            tabConfig={spPickingCrateDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spPickingCrateDetailsConfig.details} data={props.data}/>
                </>
        </Sidepanel2>
    );
};

export default SpPickingCrateDetails;