import { Box, useTheme } from "@mui/material"
import BaseCard from "../BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../text/Text";
import CardLabel from "../../label/CardLabel";
import TopRight from "../../positionals/TopRight";
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../skeleton/ShTextSkeleton";

const WarehouseLocationCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"} gap={props.skeleton ? 0.5 : null} width={props.skeleton ? "58%" : null}>
                        {props.skeleton ?
                            <ShTextSkeleton variant="orange"/>
                            :
                            <Text  variant={"orange"} bold>{props.location}</Text>
                        }
                        {props.skeleton ?
                            <ShTextSkeleton />
                            :
                            <Text>{props.warehouseLocationType.description}</Text>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel 
                        variant={"grey"}  

                        background={theme.palette.mode === "dark" ? colors.grey[400] : null} 
                        color={theme.palette.mode === "dark" ? colors.txt["primary"] : null} 
                        position="child-tr">
                            TBA
                        </CardLabel>
                    </TopRight>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("level")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.warehouseZone?.description}</Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("zone")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.warehouseZone?.description}</Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("reachtype")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.reachType?.description}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default WarehouseLocationCard;