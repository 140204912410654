import { useNavigate } from "react-router-dom";
import { checkAndRedirect, selectAuthStatus, UserState } from "../newapi/user/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export const RequireAuth = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(checkAndRedirect(props.permissions)) 
          .unwrap() // <-- async Thunk returns a promise, that can be 'unwrapped')
          .then(({loggedOut, authorized}) => {
            if(loggedOut) {
                navigate("/login");
            } else if (authorized !== null && !authorized) {
                // navigate(-1);
            }
          });
        // dispatch(checkAndRedirect()).unwrap()
    }, [dispatch, navigate]);

    return (
        <>
            {props.children}
        </>
    );
}