import { createSlice } from '@reduxjs/toolkit';

import { PURGE, REHYDRATE } from 'redux-persist';
let initialState = {
    activeTab: null,
    tabs: {},
};

export const tabViewSlice = createSlice({
  name: 'views',
  initialState: initialState,
  reducers: {
    registerTab: (state, action) => {
        const { tabId, view } = action.payload;
        state.tabs[tabId] = view;
        if (!state.activeTab) {
          state.activeTab = tabId; // Set the first registered tab as active by default
        }
    },
    unregisterTab: (state, action) => {
        const tabId = action.payload;
        delete state.tabs[tabId];
    },
    setActiveTab: (state, action) => {
        state.activeTab = action.payload;
    },
  },
  
    // extraReducers: (builder) => {
    //     builder
    //     .addCase(PURGE, () => initialState)
    //     // ... your other matchers
    //     .addCase(REHYDRATE, (state, action) => {
    //         // Or if you want to update the state with the rehydrated state:
    //         if (action.payload) {
    //         return { ...state, ...action.payload.views };
    //         }
    //         return state;
    //     })
    // },
});

export const { registerTab, unregisterTab, setActiveTab } = tabViewSlice.actions;
export default tabViewSlice;

// Selectors
export const selectActiveTabContent = (state) => state.views.tabs[state.views.activeTab]?.content;
export const selectActiveTab = (state) => state.views.activeTab;
