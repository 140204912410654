import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import StockOnLocation from "./Views/StockOnLocation";
import WarehouseLocation from "./Views/WarehouseLocation";
import Logs from "./Views/Logs";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spLocationDetailsConfig } from "./spLocationDetailsConfig";

const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const MultiProductBox = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Box display={"flex"} alignItems={"center"}>
                <Box width={128} marginRight={1}>
                    <Text light>{t('in_multi_product_zone')}</Text>
                </Box>
                <Box width={20} marginRight={0.5}>
                    <Text bold variant={props.multiproductzone ? "green" : "red"}>{props.multiproductzone ? t('yes') : t('no')}</Text>
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                <Box width={128} marginRight={1}>
                    <Text light>{t('in_multi_batch_zone')}</Text>
                </Box>
                <Box width={20} marginRight={0.5}>
                    <Text bold variant={props.multibatchzone ? "green" : "red"}>{props.multibatchzone ? t('yes') : t('no')}</Text>
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                <Box width={128} marginRight={1}>
                    <Text light>{t('multiproduct_location')}</Text>
                </Box>
                <Box width={20} marginRight={0.5}>
                    <Text bold variant={props.multiproduct ? "green" : "red"}>{props.multiproduct ? t('yes') : t('no')}</Text>
                </Box>
            </Box>
        </>
    )

}
const SpLocationDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props;
    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: theme.palette.mode === "dark" ? "blue" : "primary",
            content: <StoreMallDirectoryIcon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && typeof data.id !== "undefined") {
        //   let locations = data.stocks.map((stock) => stock.warehouseLocation.location);
        //   let combinedLocations = locations.join(", ");
      
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: data.location,
            icon :{
                theme: theme.palette.mode === "dark" ? "blue" : "primary",
                content: <StoreMallDirectoryIcon/>
            },
            // icon: {
            //     color: theme.palette.mode === "dark" ? "blue" : "blue",
            //     content: <Inventory2Icon/>
            // },
            subTitle: [data.warehouse.name, data.warehouseLevel.description],
            options: [
                {
                    heading: t('zone'),
                    content: data.warehouseZone?.description
                },
                {
                    component: <MultiProductBox 
                    multiproduct={data.multiproduct} 
                    multiproductzone={data.warehouseZone?.multiproduct} 
                    multibatchzone={data.warehouseZone?.multibatch}/>
                },
                {
                    heading: t('location_type'),
                    content: data.warehouseLocationType?.description
                },
                {
                    heading: t('reach_type'),
                    content: data.reachType?.description
                }
            ],
          }));
        }
      }, [data,theme.palette.mode]);

      

    return (
        <Sidepanel2
            tabConfig={spLocationDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <StockOnLocation config={spLocationDetailsConfig.stockOnLocation} warehouseLocationId={props.data ? props.data.id : -1} />
                    <WarehouseLocation config={spLocationDetailsConfig.warehouseLocation} data={props.data} />
                    <Logs config={spLocationDetailsConfig.logs} data={props.data} />
                </>
        </Sidepanel2>
    );
};

export default SpLocationDetails;