import Topright from '../positionals/TopRight';
import BottomRight from '../positionals/BottomRight';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const CardLabelBody = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const position = props.position ?? "top-right";
    const bordered = props.bordered ?? false;
    const icon = props.icon ?? false;
    const palette = props.palette ?? "normal";
    const pastel = props.pastel ?? "light";
    const variant = props.variant ?? "primary";
    const size = props.size ?? 32;
    const bold = props.bold ?? false;
    const colorType = props.colorType ?? props.colorflip ? 100 : palette === "normal" ? 400 : 600;
    const backgroundType = props.backgroundType ?? props.colorflip ? palette === "normal" ? 400 : 600 : 100;

    const hasleft = props.hasleft ?? false;
    const hasright = props.hasright ?? false;

    const getBackground = () => {
        if(palette === "normal") {
            return colors[variant][backgroundType];
        }
        return colors.pastel[pastel][variant][backgroundType];
    }

    const getColor = () => {
        if(palette === "normal") {
            return colors[variant][colorType];
        }
        return colors.pastel[pastel][variant][colorType];
    }

    const getRadius = () => {
        if(position === "topright" || position === "top-right" || position === "topRight"){
            return "6px 12px";
        } else if(position === "bottomright" || position === "bottom-right" || position === "bottomRight"){
            return "12px 6px";
        } else if (position === "bottom") {
            return "6px 6px 12px 12px";
        }

        if(position ==="child-tr") {
            if(hasleft && !hasright) {
                return "0px 12px 6px 0px";
            }else if(hasleft && hasright) {
                return "0px";
            } else if(!hasleft && hasright) {
                return "6px 0px 0px 12px";
            } else {
                return "6px 12px";
            }
        } else if(position === "child-br") {
            if(hasleft && !hasright){
                return "0px 6px 12px 0px";
            } else if(hasleft && hasright) {
                return "0px";
            } else if(!hasleft && hasright) {
                return "12px 0px 0px 6px";
            } else {
                return "6px 12px";
            }
        } else if(position === "child-b") {
            if(hasleft && !hasright){
                return "0px 6px 12px 0px";
            } else if(hasleft && hasright) {
                return "0px";
            } else if(!hasleft && hasright) {
                return "12px 0px 0px 6px";
            } else {
                return "6px 6px 12px 12px";
            }
            
        }
    }
    return (
        <Box
            sx={{
                background: props.background ?? getBackground(),
                color: props.color ?? getColor(),
                border : bordered ? `1px solid ${getColor()}` : null,
                paddingX: icon?0:1.5,
                height:`${size}px`,
                width:icon ? `${size}px` : 1,
                display:"flex",
                justifyContent: "center",
                alignItems:"center",
                fontSize:"14px",
                fontWeight:bold ? 700 : 700,
                borderRadius:getRadius()
            }}
            onClick={props.onClick}
        >
            {props.children}
        </Box>
    )
}

const CardLabel = (props) => {
    const position = props.position ?? "top-right";

    //Return a wrapped element
    const wrapped = () => {
        if(position === "topright" || position === "top-right" || position === "topRight"){
            return <Topright><CardLabelBody {...props}/></Topright>
        } else if(position === "bottomright" || position === "bottom-right" || position === "bottomRight"){
            return <BottomRight><CardLabelBody {...props}/></BottomRight>
        }
        return <CardLabelBody {...props}/>
    }

    return (wrapped());
}

export default CardLabel;