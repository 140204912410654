// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line
import { useTheme } from '@mui/material';
import { ResponsiveLine } from '@nivo/line'
import { tokens } from '../../../theme';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const LineChart = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return (
        <ResponsiveLine
            data={props.data}
            theme={{
                axis:{
                    domain:{
                        line:{
                            strokeWidth:0
                        }
                    },
                    legend:{
                        text:{
                            fill:"orange"
                        }
                    },
                    ticks: {
                        text:{
                            fill: colors.txt["secondary"],
                            fontSize:12
                        }
                    }
                },
                legends:{
                    text:{
                        fill:"orange"
                    }
                }
            }}
            margin={{ top: 0, right: 24, bottom: 24, left: 32 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            yFormat=" >-.2f"
            curve="basis"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                legend: undefined,
                
            }}
            axisLeft={{
                legend: undefined,
                tickValues:5,
            }}
            enableGridX={false}
            enableGridY={false}
            colors={{ scheme: 'spectral' }}
            lineWidth={0}
            enablePoints={false}
            pointSize={10}
            pointColor="#000000"
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor', modifiers: [] }}
            pointLabel="y"
            pointLabelYOffset={-10}
            enableArea={true}
            areaOpacity={1}
            enableCrosshair={true}
            crosshairType="bottom"
            // useMesh={true}
            legends={[
            ]}
        />
    )
}

export default LineChart;