import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useUpdateReplenishOrderMutation, useGetReplenishOrderQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import AddIcon from '@mui/icons-material/Add';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import DeleteIcon from '@mui/icons-material/Delete';
import { showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import MAddWarehouseLevel from "../../../Modals/MAddWarehouseLevel";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('add_level')}
            </Shbutton>
        </ButtonGroup>
    )
}

const ReplenishOrder = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const [config, setConfig] = useState(props.warehouseId);

    const { data: replenishOrderData, isLoading, isError } = useGetReplenishOrderQuery(props.warehouseId);

    console.log(data);

    const [view, setView] = useState({
        title: 'replenish',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
        apiRef: props.apiRef,
        mobileActions: <GridActions />,
        gridOptions:gridOptions,
    });
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [UpdateReplenishOrder,{syncEditIsLoading}] = useUpdateReplenishOrderMutation();
    const handleUpdateIndex = async (id, mutation) => {
        try {
            const response = await UpdateReplenishOrder({id: id, warehouseId: props.warehouseId, mutation}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {
            field: "name", 
            headerName: t('name'),
            flex:2, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.name
            }
        },
        {
            field: "index", 
            headerName: t('index'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.index
            }
        },
        {
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return (
                <Box>
                    { params.row.index > 1 ? 
                    <IconButton type="button" onClick={() => handleUpdateIndex(params.row.id, -1)}
                    sx={{transform: 'rotate(270deg)'}}>
                        <ArrowRightAltIcon/>
                    </IconButton> 
                    : 
                    <></>
                    }
                    { !params.row.isMax ? 
                    <IconButton type="button" onClick={() => handleUpdateIndex(params.row.id, 1)}
                    sx={{transform: 'rotate(90deg)'}}>
                        <ArrowRightAltIcon/>
                    </IconButton> 
                    : 
                    <></>
                    }
                </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2
                content={useGetReplenishOrderQuery}
                itemId={props.warehouseId}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('replenish')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
        </Box>
    );
}

export default ReplenishOrder;