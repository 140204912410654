import { createSlice, current } from '@reduxjs/toolkit';
import { PURGE, REHYDRATE } from 'redux-persist';

/**
 * Redux slice to manage the state of the tabs, progression info, and data.
 */
const initialState = {
    currentTab: null,
    previousTabs: [],
    currentStep: 1.0,
    data: {},
    title: '',
    subtitle: '',
    maxSteps: 1,
    shouldSaveData: false,
    errors: {},
};
const progressionTabSlice = createSlice({
    name: 'progressiontabs',
    initialState: initialState,
    reducers: {
        setResetTab: () => initialState,
        
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
            state.previousTabs.push(state.currentTab);
        },
        setPreviousTabActive: (state) => {
            state.previousTabs.pop();
            state.currentTab = state.previousTabs[state.previousTabs.length-1];
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        updateData: (state, action) => {
            state.data = { ...state.data, ...action.payload };
        },
        setProgressionInfo: (state, action) => {
            const { title, subtitle, maxSteps,shouldSaveData } = action.payload;
            if (title !== undefined) state.title = title;
            if (subtitle !== undefined) state.subtitle = subtitle;
            if (maxSteps !== undefined) state.maxSteps = maxSteps;
            if (shouldSaveData !== undefined) state.shouldSaveData = shouldSaveData;
        },
          // ... other reducers
        setTabErrors: (state, action) => {
            state.errors = action.payload;
            // state.errors = action.payload.errors.reduce((acc, currentError) => ({
            //     ...acc,
            //     [currentError.path]: currentError.message
            // }), {});
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PURGE, () => initialState)
          // ... your other matchers
          .addCase(REHYDRATE, (state, action) => {
            // Or if you want to update the state with the rehydrated state:
            if (action.payload) {
              return { ...state, ...action.payload.progressiontabs};
            }
            return state;
          })
      }
});

export const { setResetTab, setCurrentTab, setCurrentStep, updateData, setProgressionInfo,setPreviousTabActive, setTabErrors } = progressionTabSlice.actions;
export default progressionTabSlice;
