//Tabview dropout
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { useEffect, useRef, useState } from "react";

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Box, Collapse, Typography, useMediaQuery } from "@mui/material";
import Subheading from "../text/Subheading";

// import cx from classNames
import cx from "classnames";
import Text from "../text/Text";
import TabView from "../TabViews/TabView";
import TabViewBody from "../TabViews/TabViewBody";


const TvDropOutContent = (props) => {

    return (
        <Collapse in={props.open || props.always}>
            <Box 
                // className="dropdown-content"
                className={cx(`dropdown-content`, {
                    'open': props.open ,
                })}
                sx={{
                    overflow:"hidden",
                    transition:"250ms",
                    "&.open" : {
                        overflow:"visible",
                        transition:"250ms",
                    }
                }}>
                
                <Box 
                    className="dropdown-body"
                    sx={{
                        listStyle:"none",
                        paddingX:0,
                        width:1,
                        position:"relative",
                    }}>
                        {props.children}
                </Box>
            </Box>
        </Collapse>
    )
}

const TvDropOut = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const matchesDesktop = useMediaQuery('(min-width:1025px)'); // Adjust the breakpoint as needed

    // Initialize open state based on the defaultOpen prop for desktops
    const [open, setOpen] = useState(false);

    
    // useEffect to set the default state when the component mounts,
    // but only if it's on desktop
    useEffect(() => {
        if (matchesDesktop && props.defaultOpen) {
            setOpen(true);
        }
        if(!matchesDesktop) {
            setOpen(false);
        }
    }, [matchesDesktop, props.defaultOpen]);
    // useRef
    const sx = props.sx;

    function toggleDiv() {
        if(window.innerWidth > 1024){
        }
            setOpen((isListOpen) => !isListOpen);
    }

    return (
        <Box 
            className={cx(`dropdown-menu`, {
                'open': open,
            })}
            sx={{
                width:"auto",
                borderBottom: !props.isLast ? `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[200]} !important` : null,
                paddingBottom:0,
                paddingTop:3,
                marginX: props.paddingX ?? null,
                transition:"250ms",
                "&.open" : {
                    paddingBottom:3,
                },
                "@media screen and (max-width: 63.9375em)" : {
                    borderBottom:"none !important",
                    "&.open" : {
                        paddingBottom:0,
                    },
                },
            }}
        >
            <Box 
                className="dropdown-toggle" 
                onClick={() => toggleDiv()}
                sx={{
                    display:"flex",
                    alignItems:"flex-start",
                    userSelect:"none",
                    paddingBottom:3,
                    cursor:"pointer",
                    "@media screen and (max-width: 63.9375em)" : {
                        padding:2,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                        borderRadius:3,
                    }

                }}    
            >    
                {/* Icon */}
                    <Box
                        sx={{
                            display:"none",
                            "@media screen and (max-width: 63.9375em)" : {
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                width:40,
                                height:40,
                                borderRadius:3,
                                flexShrink:0,
                                background:"orange",
                                background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200],
                                color: colors.txt["primary"]
                            }
                        }}
                    >
                        {props.icon}
                    </Box>
                    <Box
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                            pr:3,
                            "@media screen and (max-width: 63.9375em)" : {
                                pl:2,
                                pr:2,
                            }
                        }}
                    >
                        <Subheading>
                            {props.title}
                        </Subheading>
                        {typeof props.subTitle !== "undefined" && props.subTitle.length > 0 ? <Text light>{props.subTitle}</Text> : null }
                    </Box>
                <ArrowForwardIosOutlinedIcon 
                    className={cx(`arrow`, {
                        'open': open,
                    })}
                    sx={{
                        marginLeft:"auto",
                        fontSize:"14px",
                        transform:"rotate(0deg)",
                        transition:"250ms",
                        marginTop:0.5,
                        color:colors.txt["secondary"],
                        "&.open" : {
                            transform:"rotate(90deg)",
                            transition:"250ms",
                            color:colors.txt["primary"],
                        }
                    }}
                />
                
            </Box>
            {/* Dekstop */}
            <Box
                sx={{
                    "@media screen and (max-width: 63.9375em)" : {
                        display:"none"
                    }
                }}
            >

                    <TvDropOutContent
                        open={open}
                        always={false}
                    >
                        {props.children}
                    </TvDropOutContent> 
            </Box>

            {/* Tablet */}
            <Box
                sx={{
                    display:"none",
                }}
            >
                <TabViewBody
                // hideFooter
                    breakPoint="tablet"
                    title={"hello kitty"}
                    activeTab={{
                        title:props.title,
                        subTitle:props.subTitle,
                        topRight: props.topRight,
                    }}
                    // activeView={}
                    // activeTab={activeTab} 
                    subpanelActive={open}
                    setSubpanelActive={setOpen} 
                    // setActiveTab={setActiveTab}
                    // viewType={viewType}
                    {...props}
                >
                    <TvDropOutContent
                        open={open}
                        always={true}
                    >
                        {props.children}
                    </TvDropOutContent>
                   
                </TabViewBody>
            </Box>
        </Box>
    )
}

export default TvDropOut;