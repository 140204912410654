import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import ShLabel from "../../../../../components/theme/label/ShLabel";
// import { getAllStock } from "../../../../api/warehouse/stock";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import SPStockDetails from "../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails";
import SPLocationDetails from "../../../../../components/global/Sidepanels/SpLocationDetails/SpLocationDetails";
import Text from "../../../../../components/theme/text/Text";
import {useGetStockQuery, useGetStockByIdQuery} from "../../../../../newapi/warehouse/stockSlice"
import {useGetWarehouseLocationQuery} from "../../../../../newapi/warehouse/warehouselocationSlice"
import {useGetProductQuery} from "../../../../../newapi/warehouse/productSlice"
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";


const GridActions = (props) => {
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                // onClick={confirm} 
                endIcon={<AddIcon/>}
                variant="contained"
            >
                New
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const CurrentStock =memo((props) => {
    const previousProps = useRef();
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isExtended, setIsExtended] = useState(false);
    const [locationIsExtended, setLocationIsExtended] = useState(false);
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);
    

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    // useEffect(() => {
    //     productIdRef.current = productId;
    // }, [productId]);

    const { data: productData, isLoading: isLoadingProduct } = useGetProductQuery(productId,
    {
        skip: !productId
    });
    
    const getProductDetails = useCallback((params) => {
        if (!params) return;
        if (typeof productId !== "undefined" && productId !== null && parseInt(productId) === parseInt(params.id)) {
            setIsExtended(true);
        } else {
            setProductId(params.id);
        }
    }, [productId]); // Add necessary dependencies
    

    // const getProductDetails = (params) => {
    //     if (!params) return;
    //     if (typeof productIdRef.current !== "undefined" &&  parseInt(productIdRef.current) === parseInt(params.id)) {
    //         setIsExtended(true);
    //     } else {
    //         setProductId(params.id);
    //     }
    // };

    useEffect(() => {
        if (!isLoadingProduct && productData) {
            setIsExtended(true);
        }
    }, [productData, isLoadingProduct]);
    

    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);

    useEffect(() => {
        locationIdRef.current = locationId;
    }, [locationId]);
    
    const { data: locationData, isLoading: isLoadingLocation } = useGetWarehouseLocationQuery(locationId,
        {
            skip: !locationId
        });
        const getLocationDetails = useCallback((params) => {
            if (!params) return;
            if (typeof locationId !== "undefined" && locationId !== null && parseInt(locationId) === parseInt(params.id)) {
                setLocationIsExtended(true);
            } else {
                setLocationId(params.id);
            }
        }, [locationId]); // Add necessary dependencies

        
    useEffect(() => {
        if (!isLoadingLocation && locationData) {
            setLocationIsExtended(true);
        }
    }, [locationData, isLoadingLocation]);

    

    const columns = useMemo(() =>[
        {
            field: "sku",
            dataField: 'product.sku',
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
                }
                const handleClick = () => getProductDetails(params.row.product);
                return (
                    <Box onClick={handleClick}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
                    </Box>
                );
            }
        },
        {
            field: "barcode",
            headerName: t("barcode"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.product.barcode
            },
        },
        {
            field: "omschrijving",
            headerName: t("description"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.product.description
            },
        },
        {
            field: "locatie",
            headerName: t("location"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton variant={"yellow"}/>
                }
                const handleClick = () => getLocationDetails(params.row.warehouseLocation);
                return (
                    <Box onClick={handleClick}>
                        <ShLabel clickable size={32} fitted variant={"yellow"}>
                            {params.row.warehouseLocation.location}
                        </ShLabel>
                    </Box>
                )
            }
        },
        // {
        //     field: "handmatig",
        //     headerName: t("manual"),
        //     flex:1,
        //     renderCell : (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
        //         return params.row.warehouseLocation.reachTypeId
        //     },
        // },
        ...isFulfilment ? [{
            field: "relatie",
            headerName: t("relation"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.product.relation.name
            },
        }] : [],
        {
            field: "voorraad",
            headerName: t("stock"),
            flex:1,
            shFilter: {
                type: "number"
            },
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.available
            },
        },
        {
            field: "batch",
            headerName: t("batch"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return ""
            },
        },
        {
            field: "thtdatum",
            headerName: t("bbdate"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return ""
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >

                    </Box>
                )
            }
        }
    ],[theme.palette.mode,t,isFulfilment]);

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2
                content={useGetStockQuery}
                // gridActions={<GridActions></GridActions>} 
                title={t('overview')}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
            <SPStockDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                data={productData} // Pass the productData as a prop
            ></SPStockDetails>
            <SPLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                data={locationData} // Pass the locationData as a prop
            ></SPLocationDetails>
        </Box>
    );
});
export default CurrentStock;