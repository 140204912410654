// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
// import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
// import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
// import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import { ReactComponent as Crate } from '../../../../../styles/svg/crate.svg';
import { ReactComponent as Shirt } from '../../../../../styles/svg/shirt.svg';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import { Box } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ImportProcessor from '../../../../theme/import/ImportProcessor';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    
        // const columns = [
        //     {title: "PostNL", logo: <PostNLLogo width={60} height={60}/>, key: "POSTNL"},
        //     {title: "DHL", logo: <DhlLogo width={60} height={60}/>, key: "DHL"},
        //     {title: "Dpd", logo: <DpdLogo width={60} height={60}/>, key: "DPD"},
        //     {title: "Anders", logo: <AltRouteOutlinedIcon sx={{fontSize:"60px"}} />, key: "OTHER"}
        // ]
        // // New prop is called "Logo"
        // // {title: 'normal', logo: <CheckCircleOutlineIcon />, key: "normal"},
        // //     {title: 'runner', logo: <DirectionsRunIcon />, key: "runner"},
        // //     {title: 'highcare', logo: <FavoriteOutlinedIcon />, key: "highcare"},
        // //     {title: 'special', logo: <AutoAwesomeOutlinedIcon />, key: "special"}

        // const handleOptionSelect = (option) => {
        //     props.handleDataUpdate({ selectedOption: option });
        //     props.handleNext("tabBasicInfo");
        // };
        const breakpoint = "1366";

        // const setData = (data) => {
        //     props.handleDataUpdate({"preprocessed":data});
        //     props.handleNext("tabMatchHeaderToColumn")
        // }
        console.log(props.data);

        
    
        return (
            <Box
                sx={{
                    width:"unset",
                    display:"flex",
                    gap:3,

                    [`@media screen and (max-width: ${breakpoint}px)`] : {
                        flexDirection:"column",
                        width:"unset"
                    }
                }}
            >
                <ImportProcessor {...props.data.preprocessed}/>
            </Box>
            // <div>
            //     <SelectGroup selected={-1} setSelected={handleOptionSelect} options={columns} image={true} />
            // </div>
        );
    };

const MatchHeaderToColumn = (props) => {
    return (
        <ProgressionTab tabId="tabMatchHeaderToColumn" step={2} maxSteps={3}>
            <Content {...props}/>
        </ProgressionTab>
    )
}

export default MatchHeaderToColumn;

