exports.general_states = {
    ACTIVE: 'active',
    ANNOUNCED: 'announced',
    APPROVED: 'approved',
    AVAILABLE: 'available',
    BACKORDERED: 'backordered',
    BLOCKED: 'blocked',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed',
    CONCEPT: 'concept',
    CONFIRMED: 'confirmed',
    DECLINED: 'declined',
    DELETED: 'deleted',
    ECHECK: 'echeck',
    FULLYRECEIVED: 'fullyreceived',
    IN_PROGRESS: 'in_progress',
    INACTIVE: 'inactive',
    MAINTENANCE: 'maintenance',
    NOTRECEIVED: 'notreceived',
    OCCUPIED: 'occupied',
    OPEN: 'open',
    PARTLYRECEIVED: 'partlyreceived',
    PICKING_IN_PROGRESS: 'picking_in_progress',
    RECEIVEDWITHOUTNOTICE: 'receivedwithoutnotice',
    REPLENISH: 'replenish',
    SHIPPED: 'shipped'
};