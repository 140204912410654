import { toast } from 'react-toastify';

export const showToastMessageUpdateRequest = (t, response) => {
    if (response.success) {
        toast.success(t('update_successfull'), {
            position: toast.POSITION.TOP_RIGHT
        });
    } else {
        toast.error(`${t('update_failed')} - ${t(response.error)}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

export const showToastMessageDeleteRequest = (t, response) => {
    if (response.success) {
        toast.success(t('delete_successfull'), {
            position: toast.POSITION.TOP_RIGHT
        });
    } else {
        toast.error(`${t('delete_failed')} - ${t(response.error)}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

export const showToastMessageRequestError = (action, t, err) => {
    const errorMessage = err?.data?.error ? t(err.data.error) : t('unknown');
    toast.error(`${action} - ${errorMessage}`, {
        position: toast.POSITION.TOP_RIGHT
    });
}

export const showToastMessagePostRequest = (t, response) => {
    if (response.success) {
        toast.success(t('create_successfull'), {
            position: toast.POSITION.TOP_RIGHT
        });
    } else {
        toast.error(`${t('create_failed')} - ${t(response.error || response.message)}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

export const showToastMessage = (type, message) => {
    if (type === "success") {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    } else if (type === "error") {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}