import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  finishedBuilding: false,
  addToFilterGroup: false,
  groupToAddTo: -1,
  newGroup: {
    conditionExtensionType: 0,
    rules: []
  }
};

const gridFilterBuilderSettingsSlice = createSlice({
  name: 'filterBuilderSettings',
  initialState: initialState,
  reducers: {
    resetFilterBuilderSettings: () => {
      return initialState;
    },
    setFilterBuilderSettings: (state, action) => {
      return action.payload;
    },
    setFilterBuilderAddToFilterGroup: (state, action) => {
      state.addToFilterGroup = action.payload;
    },
    setFilterBuilderGroupToAddTo: (state, action) => {
      state.groupToAddTo = action.payload;
    },
    setFilterBuilderNewGroup: (state, action) => {
      state.newGroup = action.payload;
    },
    setFilterBuilderConditionExtensionType: (state, action) => {
      state.newGroup.conditionExtensionType = action.payload;
    },
    setFilterBuilderFinishedBuilding:(state, action) => {
      state.finishedBuilding = action.payload;
    },
    addFilterBuilderRule: (state, action) => {
      state.newGroup.rules.push(action.payload);
    },
    removeFilterBuilderRule: (state, action) => {
      state.newGroup.rules.splice(action.payload, 1);
    }
  }
});

export const {
  resetFilterBuilderSettings,
  setFilterBuilderSettings,
  setFilterBuilderAddToFilterGroup,
  setFilterBuilderGroupToAddTo,
  setFilterBuilderNewGroup,
  setFilterBuilderConditionExtensionType,
  addFilterBuilderRule,
  removeFilterBuilderRule,
  setFilterBuilderFinishedBuilding
} = gridFilterBuilderSettingsSlice.actions;

export default gridFilterBuilderSettingsSlice;
