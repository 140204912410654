import React, { createContext, useContext, useMemo, useState } from "react";

export const useFilter = () => useContext(FilterContext);

const defaultValue = {
    filter:{},

    updateFilter: () => {},
    removeFilterGroup: () => {},
    removeFilterRule: () => {}
};


export const FilterContext = createContext(defaultValue);

const FilterContextProvider = (props) => {
    const [filter, setFilter] = useState({});

    return (
        <FilterContext.Provider value={{
            filter,
            updateFilter: (filter) => {
                setFilter(filter);
            },
            removeFilterGroup: (index) => {
                let groups = filter.groups.slice();
                groups.splice(index,1);
                setFilter({
                    ...filter,
                    groups:groups
                });
            },
            removeFilterRule: (groupIndex, index) => {
                let groups = filter.groups;
                let group = groups[groupIndex];
                let rules = group.rules;
                rules.splice(index,1);

                if(rules.length > 0) {
                    groups[groupIndex] = {
                        ...group,
                        rules: rules
                    };

                    setFilter({
                        ...filter,
                        groups: groups
                    });
                } else{
                    if(groupIndex > 0) {
                        let groups = filter.groups.slice();
                        groups.splice(index,1);
                        setFilter({
                            ...filter,
                            groups:groups
                        });
                    }
                }

            }
        }}>
            {props.children}
        </FilterContext.Provider>
    )
}
export default FilterContextProvider;