
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const spRelationDetailsConfig = {
    details:  {
        title: 'details',
        icon: <CropFreeIcon/>,
    },
    relationContacts:  {
        title: 'contacts',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
        
    },
    webshopCredentials:  {
        title: 'webshopcredentials',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
};
