import { Box } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WidgetsIcon from '@mui/icons-material/Widgets';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DownloadAttachmentCard from "../../../../theme/cards/other/DownloadAttachmentCard";
import { useEffect, useState, useTransition } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import { useTranslation } from "react-i18next";

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        </Box>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Attachments = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [dataHasBeenSet, setDataHasBeenSet] = useState();
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    
    

    const columns = [
        {field: "name", headerName: t("name") ,flex:4, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text semibold>{params.row.name}</Text>
                </Box>
            )
        }},
        
        {field: "creationDate", headerName: t("date_created"),flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShDoubleTextSkeleton/>
            }
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                    }}
                >
                    <Text semibold>{params.row.creationDate}</Text>
                    <Text light>{params.row.creationTime}</Text>
                </Box>
            )
        }},
        {field:"download", headerName: "", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
            }
            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>Download</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <DownloadAttachmentCard skeleton={params.row.skeleton ?? false} {...params} />
                )
            }
        }
    ];

    const mockDataTeam = [
        {
            id: randomIntFromInterval(0, 99999999),
            name: "DHLpallet_Response_2023-05-09_10-36-48-0251",
            creationDate: "21-05-2023",
            creationTime: "21:00",
            phoneView: {}
        },
        {
            id: randomIntFromInterval(0, 99999999),
            name: "DHLpallet_Response_2023-05-09_10-36-48-0251",
            creationDate: "21-05-2023",
            creationTime: "21:00",
            phoneView: {}
        },
        {
            id: randomIntFromInterval(0, 99999999),
            name: "DHLpallet_Response_2023-05-09_10-36-48-0251",
            creationDate: "21-05-2023",
            creationTime: "21:00",
            phoneView: {}
        },
        {
            id: randomIntFromInterval(0, 99999999),
            name: "DHLpallet_Response_2023-05-09_10-36-48-0251",
            creationDate: "21-05-2023",
            creationTime: "21:00",
            phoneView: {}
        },
        {
            id: randomIntFromInterval(0, 99999999),
            name: "DHLpallet_Response_2023-05-09_10-36-48-0251",
            creationDate: "21-05-2023",
            creationTime: "21:00",
            phoneView: {}
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            {/* <ShDataGrid  
                gridActions={<GridActions></GridActions>} 
                title="Attachments" 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                apiRef={props.apiRef} 
                columns={columns} 
                rows={mockDataTeam}>
            </ShDataGrid> */}
        </Box>
    );
}

// const Attachments = (apiRef) => {
//     return {
//         title: "Attachments",
//         icon: <WidgetsIcon/>,
//         view: <AttachmentsBody apiRef={apiRef}/>,
//     }
// }

export default Attachments;