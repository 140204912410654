import { Box, IconButton, useTheme } from "@mui/material";
import { useEffect, useImperativeHandle, useState } from "react";
import Text from "../../../../components/theme/text/Text";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../theme"; 
import Heading from "../../../../components/theme/text/Heading";

// import Echeck from "./views/Echeck";
import { useGridApiRef } from "@mui/x-data-grid";
import All from "./Views/All";
import TabView from "../../../../components/theme/TabViews/TabView";
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import { relationSettingsSupplierConfig } from "./relationSettingsSupplierConfig";

const SettingsSupplier = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const supplierApiRef = useGridApiRef();

    return (
        <Box 
        sx={{
            background:"", 
            width:1,
            paddingTop:9,
            position:"relative",
            "@media screen and (max-width:47.9375em)" : {
                    background:"", 
                width:1,
                marginTop:9,
                height: "calc(100% - 72px)",
                // position:"relative",
                paddingX:4,
                paddingTop:4,
                overflow:"hidden",
                display:"flex",
                flexDirection:"column",
                
            },
            "@media screen and (max-width: 47.9375em)" : {
                paddingX:0,
                paddingTop:3,
                background:colors.bg["tertiary"],
                "&:before,&:after":{
                    display:"none"
                }
            },
        }}
        >      
            <TabView3
                tabConfig={relationSettingsSupplierConfig}
                // viewType="bodytabs"
                sx={{

                    background:"green",
                    minHeight:"calc(100%)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,
                    display:"flex",
                    flexDirection:"column",
                    // "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },
                        "@media screen and (max-width: 47.9375em)" : {

                            "&:before, &:after" : {
                                top:0,
                            }
                        }
                }}
            >
                <>
                    <All config={relationSettingsSupplierConfig.all}/>
                </>
            </TabView3>

        </Box>
    )
};

export default SettingsSupplier;
