import { Box, useTheme } from "@mui/material"
import SHModal from "../../theme/Modal/SHModal"
import { tokens } from "../../../theme";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePrintnodeQuery } from "../../../newapi/warehouse/printerSlice";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import Shbutton from "../../theme/buttons/Shbutton";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { ArrowRightAltOutlined } from "@mui/icons-material";

const MSelectPrinter = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    const { data: printnodeData, error, isLoading } = usePrintnodeQuery();
    const [selectedComputer, setSelectedComputer] = useState(null);
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [filteredPrinters, setFilteredPrinters] = useState([]);
    const [computerOptions, setComputerOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(0);

    const handleComputerChange = (selected) => {
        var computer = computerOptions[selected];
        setSelectedComputer(computer);
        var printers = printnodeData.filter(printer => printer.computer.id === computer.id);
        console.log(printers);
        setFilteredPrinters(printers);
        
    
    };

    const handlePrinterChange = (selected) => {
        var printer = filteredPrinters[selected];
        setSelectedPrinter(printer);
    };

    useEffect(() => {
        if(printnodeData){
            setComputerOptions(printnodeData
                .map(printer => printer.computer)
                .filter((computer, index, self) => self.findIndex(c => c.id === computer.id) === index)
                .map(computer => ({ id: computer.id, name: computer.name })));
        }
    }, [printnodeData, error])

    

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const confirm = () => {
        props.confirm(selectedPrinter);
        props.handleClose();
    }

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                }}>
                <Subheading>{props.data.title}</Subheading>
            </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:3,
                }}
            >
                <InputDropdown
                    displayName={"name"} 
                    onChange={(selected) => handleComputerChange(selected)}
                    noSelection={t("choose_computer")} //Custom text when nothing is selected
                    options={computerOptions}
                    //selected={selectedComputer?.id}
                    closeOnSelection
                />
                <InputDropdown
                    displayName={"name"} 
                    onChange={(selected) => handlePrinterChange(selected)}
                    noSelection={t("choose_printer")} //Custom text when nothing is selected
                    options={filteredPrinters?.map(printer => ({ id: printer?.id, name: printer?.name ?? "Onbekend" }))}
                    //selected={selectedPrinter?.id}
                    closeOnSelection
                />
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                
                <Shbutton 
                    isCancel={true} 
                    className={"always-visible"} 
                    onClick={props.handleClose} 
                    color="primary" 
                    variant="text" 
                    startIcon={<ArrowRightAltOutlined/>}
                >
                    {t("cancel")}
                </Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} onClick={confirm} variant="contained">{t("confirm")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MSelectPrinter;