import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { tokens } from "../../theme";

const Progressbar = forwardRef((props,ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [progress, setPogress] = useState(props.progress ?? 0);

    const isHeader = props.isHeader ?? false;
    const size = props.size ?? 4;
    const status = props.status ?? null;

    const getBackground = () => {
        if(status === null) {
            return theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[200];
        }
        return colors[status.variant][100]
    }
    const getFill = () => {
        if(status === null) {
            return theme.palette.mode === "dark" ? colors.yellow[400] : colors.grey[400];
        }
        return colors[status.variant][400]
    }

    useEffect(() => {
        setPogress(props.progress);
    },[props.progress])

    const getRadius = () => {
        if(size === 48) return 6;
        if(size === 40) return 5;
        if(size === 32) return 4;
        if(size === 16) return 2;
        if(size <= 15) return 1;
    }
    
    useImperativeHandle(ref, () => ({
        setProgress(progress) {
            setPogress(progress);
        }
    }));
    return (
        <>
            {/* Progressbar */}
                <Box
                sx={{
                    height:size,
                    width:1,
                    flex:1,
                    background:getBackground(),
                    borderRadius:getRadius(),
                    borderTopRightRadius:isHeader ? 0 : null,
                    borderBottomRightRadius:isHeader ? 0 : null,
                }}
            >
                <Box
                    sx={{
                        height:size,
                        width: progress,
                        background: getFill(),
                        transition:"250ms",
                        textAlign:"right",
                        borderRadius:getRadius(),
                        paddingRight:2,
                        color:theme.palette.mode === "dark" ? colors.txt["darkened"] : colors.txt["light"],
                        lineHeight:`${size}px`,
                        fontWeight:500,
                        borderTopRightRadius:isHeader ? 0 : null,
                        borderBottomRightRadius:isHeader ? 0 : null,
                    }}
                
                >
                    {status !== null ? status.value : null}
                </Box>
            </Box>
        </>
    )
});

export default Progressbar;