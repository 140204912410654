import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

const ShLabel = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const type = props.type ?? "light";
    const variant = props.variant ?? "grey";
    
    const size = props.size ?? 40;

    const fitted = props.fitted ?? false;

    const isHeader = props.isHeader ?? false;

    const palette = props.palette ?? "pastel"

    const clickable = props.clickable ?? false;

    const getPadding = () => {
        if (size === 48) return 3
        if (size === 40) return 2.5
        if (size < 40) return 2
    }

    const getRadius = () => {
        if (size === 48) return 6
        if (size === 40) return 5
        if (size === 32) return 4
        if (size === 24) return 3
        if (size === 16) return 2
    }

    const getColor = () => {
        if(palette === "pastel") {
            return colors.pastel[type][variant][600];
        }
        return colors[variant][400];
    }

    const getBackground = () => {
        if(palette === "pastel") {
            return colors.pastel[type][variant][100];
        }
        return colors[variant][100];
    }

    const getBorder = () => {
        if(palette === "pastel") {
            return colors.pastel[type][variant][600];
        }
        return colors[variant][400];
    }

    return (
        <Box
            sx={{
                background:getBackground(),
                color:getColor(),
                border: `1px solid ${getBorder()}`,
                borderRadius: getRadius(),
                borderTopRightRadius: isHeader ? 0 : null,
                borderBottomRightRadius: isHeader ? 0: null,
                borderRight:isHeader ? "none" : null,
                paddingX: getPadding(),
                height: size,
                lineHeight : `${size}px`,
                textAlign:"center",
                flex:!fitted ? 1 : null,
                width:fitted?"fit-content":null,
                fontSize: size >= 48 ? 17 : 14,
                fontWeight:props.bold? 700 : 500,
                cursor: clickable ? "pointer" : "default"
            }}
        >
            {props.children}
        </Box>
    )
}

export default ShLabel;