import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import Status from "./Views/Status";
import { useGridApiRef } from "@mui/x-data-grid";
import Replenish from "./Views/Replenish";
import ShipOrder from "./Views/ShipOrder";
import OrderLines from "./Views/OrderLines";
import Attachments from "./Views/Attachments";
import SplitOrder from "./Views/SplitOrder";
import ParcelInvestigation from "./Views/ParcelInvestigation";
import WriteOffs from "./Views/WriteOffs";
import Jokers from "./Views/Jokers";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import Logs from "./Views/Logs";
import {useTranslation} from "react-i18next";
import { getTranslatedDateAndTime } from "../../../../utils";
import PickingLines from "./Views/PickingLines";
import MSplitOrder from "../../Modals/MSplitOrder";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spOrderDetailsConfig } from "./spOrderDetailsConfig";

const SidePanelActions = (props) => {
    const {t} = useTranslation();
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }
    const handleClose =(split, ie) => {
        addModal.setOpen(false);
    }

    useEffect(() => {
        console.log("uwu", props.orderId);
    },[props.orderId])
    return (
        <SidepanelHeaderActiongroup>
            <ButtonGroup version={2}>
                <Box
                    sx={{
                        width:1,
                        flex:1,
                        mx:"auto",
                    }}
                >
                    <Shbutton
                    
                        onClick={() => addModal.setOpen(true)}
                        className={"group-btn"}
                        // onClick={props.action}
                        color={"primaryGrey"}
                        variant="contained"
                        // endIcon={<SaveIcon />}
                    >
                        {t('split_order')}
                    </Shbutton>
                </Box>
            </ButtonGroup>
            
            <MSplitOrder orderId={props.orderId} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
        </SidepanelHeaderActiongroup>
    )
}

const HeaderDateBox = (props) => {
    const [planned, setPlanned] = useState({});
    const [shipped, setShipped] =  useState({});
    
    useEffect(() => {
        setPlanned(getTranslatedDateAndTime(props.data.date_planned, props.t));
        setShipped(getTranslatedDateAndTime(props.data.date_shipped, props.t));
    },[props.data])


    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"}>
                <Box width={66} marginRight={1}><Text light>Scheduled</Text></Box>
                <Box marginRight={1}><Text bold>{planned?.date}</Text></Box>
                <Text>{planned?.time}</Text>
            </Box>
            <Box  display={"flex"} alignItems={"center"}>
                <Box width={66}marginRight={1}><Text light>Shipped</Text></Box>
                <Box marginRight={1}><Text bold>{shipped?.date}</Text></Box>
                <Text>{shipped?.time}</Text>
            </Box>
        </>
    )

}

const getOrderStatusColor = (data)  => {
    let labelcolor = "grey";
    let planned = new Date(data?.date_planned);
    let sent = data?.date_sent ? new Date(data?.date_sent) : null;

    if(sent) {
        // when sent is filled and is equal to planned: green
        if(sent.getTime() === planned.getTime()) {
            labelcolor = "green";
        }
        // when sent is filled but later than planned: orange
        else if(sent.getTime() > planned.getTime()) {
            labelcolor = "orange";
        }
    }
    else {
        // When sent is not filled and planned >= now: blue
        if(planned.getTime() >= Date.now()) {
            labelcolor = "blue";
        }
        // When sent is not filled and planned < now: red
        else if(planned.getTime() < Date.now()) {
            labelcolor = "red";
        }
    }

    return labelcolor;
}

const SpOrderDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const { data } = props;

    const { t, ready, i18n } = useTranslation();


    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: "green",
            content: <Inventory2Icon/>
        },
        //Heading row for default shit
        options : [],
        actions:<SidePanelActions orderId={props.data?.id} setIsExtended={props.setIsExtended} />   
    });
    useEffect(() => {
        if (typeof data !== "undefined" && ready) {
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: "Order " + props.data.id,
            subTitle: props.data.webshopcredentials?.description,
            icon :{
                theme: getOrderStatusColor(props.data), 
                content: <Inventory2Icon/>
            },
            actions:<SidePanelActions orderId={props.data?.id} setIsExtended={props.setIsExtended} />,
            options : [
                {
                    component: <HeaderDateBox data={props.data} t={i18n}/>
                },
                {
                    heading: t("shipment_days"),
                    content:'0'
                },
                {
                    heading: t("pickstream"),
                    content: props.data.stream?.description
                },
                {
                    heading: t("orderlines"),
                    content: props.data.orderlines?.length
                },
                // {
                //     heading: 'Channel order id',
                //     content: props.data.webshopreference
                // },
                {
                    heading: t("reference"),
                    content: props.data.webshopreference
                }
            ],
          }));
        }
      }, [data,ready]);


    //   useEffect(() => {
    //     console.log("changed data",props.data);
    // }, [props.data])

    return (
        props.relation &&
        <>
            {/* {ready ?? */}
                <Sidepanel2
                    tabConfig={spOrderDetailsConfig}
                    content={panel} 
                    isExtended={props.isExtended} 
                    setIsExtended={props.setIsExtended} >
                    <>
                        <Status config={spOrderDetailsConfig.status} data={props.data}/>
                        <ShipOrder config={spOrderDetailsConfig.shipOrder} data={props.data}/>
                        <OrderLines  config={spOrderDetailsConfig.orderLines} canReserveOrderLines={props.canReserveOrderLines} data={props.data?.id}/>
                        <WriteOffs  config={spOrderDetailsConfig.writeOffs} data={props.data}/>
                    </>
                </Sidepanel2>
            {/* } */}
        </>
        ||
        <>
            {/* {ready ?? */}
                <Sidepanel2
                    tabConfig={spOrderDetailsConfig}
                    content={panel} 
                    isExtended={props.isExtended} 
                    setIsExtended={props.setIsExtended} >
                    <>
                        <OrderLines config={spOrderDetailsConfig.orderlines}  data={props.data?.id}/>
                        <PickingLines config={spOrderDetailsConfig.pickingLines} data={props.data} />
                        <Replenish config={spOrderDetailsConfig.replenish}  data={props.data?.id}/> 
                        <ShipOrder config={spOrderDetailsConfig.shipOrder}  data={props.data}/>
                        <WriteOffs config={spOrderDetailsConfig.writeOffs} data={props.data}/>
                        <Attachments config={spOrderDetailsConfig.attachments}  data={props.data}/>
                        <Jokers config={spOrderDetailsConfig.jokers} actions data={props.data}/>
                        <Logs config={spOrderDetailsConfig.logs} data={props.data}/>
                    </>
                </Sidepanel2>
            {/* } */}
        </>
    );
};

export default SpOrderDetails;