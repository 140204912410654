import { useTheme } from "@mui/material"
import { tokens } from "../../../../../../theme";
import SelectGroup from "../../../../Select/SelectGroup";
import { useContext, useEffect, useState } from "react";
import { useFilterbuilder } from "../../../../../../contexts/FbState";
import { useDispatch } from "react-redux";
import { updateColumn, updateType } from "../../../../../../newapi/global/filter/builder/gridFilterBuilderRuleSlice";

const SelectFilterColumnViewBody = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [columns, setColumns] = useState([]);
    const [filterCols, setFilterCols] = useState([]);

    const filterBuilder = useFilterbuilder();

    let hasBeenSet = false;

    const dispatch = useDispatch();

    useEffect(() => {
        if(typeof props.apiRef !== "undefined" && Object.keys(props.apiRef).length > 0 && props.apiRef.current !== null && !hasBeenSet) {
            let toReturn = [];
            let currColumns = props.apiRef.current.getAllColumns();
            setFilterCols(currColumns);
            for(let i = 0; i < currColumns.length; i++) {
                toReturn.push(
                    currColumns[i].headerName,
                )
            }
            setColumns(toReturn);
            hasBeenSet = true;
        }
    },[props.apiRef]);

    //Select element and go to next screen
    const select = (selected) => {
        setSelected(selected);

        //Update context
        dispatch(updateColumn(selected));
        // filterBuilder.updateColumn(selected);

        let name = "";
        if(typeof filterCols[selected].shFilter !== "undefined") {
            name = filterCols[selected].shFilter.type;
        } else {
            if(filterCols[selected].type === "string") {
                name = "text";
            }else{
                name = filterCols[selected].type;
            }
        }

        dispatch(updateType({
            name: name,
        }));
        // filterBuilder.updateType({
        //     name: name,
        //     value: filterBuilder.type.value
        // })

        if(typeof props.builderRef !== "undefined") {
            props.builderRef.current.nextView();
        }
    }
    
    return (
        <SelectGroup selected={selected} setSelected={select} options={columns} />
    )
}

const SelectFilterColumnView = (props, builderRef) => {
    return {
        header : {
			title: "Pick a column",
			// preview: <FiltergroupPreview/>
        },
        body : {
            component: <SelectFilterColumnViewBody {...props} builderRef={builderRef}/>
        }
    }
}

export default SelectFilterColumnView;