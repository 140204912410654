// import { apiSlice } from '../api/apiSlice'
// import 

import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const RETURN_BASE = "/return"; 
const buildUrl = (endpoint) => RETURN_BASE + endpoint;

const returnsApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReturns: builder.query({ 
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Returns' }], 
            onQueryStarted: muiDataGridStarted.bind(null, 'getReturns', 'Returns') 
        }),
        getReturnLines: builder.query({ 
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/returnlines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (args) => [{ type: 'ReturnLines', id: args.id }], 
            onQueryStarted: muiDataGridStarted.bind(null, 'getReturnLines', 'ReturnLines') 
        }),
        getReturn: builder.query({ 
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (id) => [{ type: `Returns-${id}` }]
        }),
        createReturn: builder.mutation({
            query: (retour) => ({
                url: buildUrl('/create'),
                method: 'POST',
                body: retour,
            }),
        }),
        getReturnById: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
        }),
        deleteReturn: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
        }),
    })
});

export const {
    useGetReturnsQuery, 
    useGetReturnLinesQuery, 
    useGetReturnQuery,
    useCreateReturnMutation,
    useGetReturnByIdQuery,
    useDeleteReturnMutation 
  } = returnsApiSlice;

