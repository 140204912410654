import { Box, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { tokens } from "../../../theme";

const getMappedOptions = (primaryOptions) => {
    let options = [];
    for (let i = 0; i < primaryOptions.length; i++) {
        options.push(primaryOptions[i].name);
    }
    return options;
};

const SelectGroupMultiple = forwardRef((props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const multiSelect = props.multiSelect;

    const primaryOptions = props.options ?? alert("no options set");
    const mappedOptions = typeof props.colored !== "undefined" && props.colored ? getMappedOptions(primaryOptions) : props.options;

    const [selectedOptions, setSelectedOptions] = useState(props.selected ?? []);
    const [image, setImage] = useState(props.image ?? null);
    useState(() => {
        setImage(props.image);
    }, [props.image])

    useImperativeHandle(ref, () => ({
        getSelectedOptions() {
            return selectedOptions;
        },
        select(index) {
            if (selectedOptions.includes(index)) {
                setSelectedOptions(selectedOptions.filter(item => item !== index));
            } else {
                setSelectedOptions([...selectedOptions, index]);
            }
            if (typeof props.setSelected !== "undefined") {
                props.setSelected(selectedOptions);
            }
        }
    }));

    const select = (index) => {
        if (selectedOptions.includes(index)) {
            setSelectedOptions(selectedOptions.filter(item => item !== index));
        } else {
            setSelectedOptions([...selectedOptions, index]);
        }
        if (typeof props.setSelected !== "undefined") {
            props.setSelected(selectedOptions);
        }
    };

    const getBackground = (index) => {
        if (selectedOptions.includes(index)) {
            if (props.colored) {
                return colors.pastel.light[primaryOptions[index].variant][100];
            } else {
                return colors.pastel.light.blue[100];
            }
        } else {
            if (theme.palette.mode === "dark") {
                return colors.grey[300];
            } else {
                return colors.grey[100];
            }
        }
    };

    const getBorder = (index) => {
        if (selectedOptions.includes(index)) {
            if (props.colored) {
                return colors.pastel.light[primaryOptions[index].variant][600];
            } else {
                return colors.pastel.light.blue[600];
            }
        } else {
            if (theme.palette.mode === "dark") {
                return colors.grey[300];
            } else {
                return colors.grey[100];
            }
        }
    };

    const getTextColor = (index) => {
        if (selectedOptions.includes(index)) {
            if (props.colored) {
                return colors.pastel.light[primaryOptions[index].variant][600];
            } else {
                return colors.pastel.light.blue[600];
            }
        } else {
            return colors.txt["primary"];
        }
    };

    return (
        image !== null ? 
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", gap: 1.5 }}>
            {mappedOptions.map((option, index) => (
            <Box
                sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                px:3,
                py: 2,
                background: getBackground(index),
                border: `1px solid ${getBorder(index)}`,
                color: getTextColor(index),
                fontWeight: 500,
                fontSize: 14,
                borderRadius: 3,
                userSelect: "none",
                }}
                key={index}
                onClick={() => select(index)}
            >
                <Box sx={{ display: "flex", flexDirection: "column",  gap: 1, alignItems: "center"}}>
                {option.logo}
                    <span>{option.title}</span>
                </Box>
            </Box>
            ))}
        </Box>
        :
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          {mappedOptions.map((option, index) => (
            <Box
            sx={{
                height:40,
                lineHeight:"40px",
                paddingX:2,
                background: getBackground(index),
                border : `1px solid ${getBorder(index)}`,
                color: getTextColor(index),
                fontWeight:500,
                fontSize:14,
                borderRadius:5,
                userSelect:"none"
            }}
            key={index}
            onClick={() => select(index)}
            >
              {option.title}
            </Box>
          ))}
        </Box>
    );

});

export default SelectGroupMultiple;