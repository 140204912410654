// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, Typography } from '@mui/material';
import { tokens } from '../../../../../theme';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);

    const data = {...props.data};
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <div>
            <Box 
            sx={{
                background: colors["orange"][100],
                color: colors["orange"][400],
                padding:2,
                width:1,
                marginTop:2
            }}
        >
            <Typography fontWeight={"bold"} fontSize={"17"}>
                {t("mp_add_user_confirm_title")}
            </Typography>
            <Box sx={{paddingTop:0.5}}>
                <Typography fontSize={"14"}>
                {t("mp_add_user_confirm_text")}
                </Typography>
            </Box>
        </Box>
        </div>
    );
};

const UserConfirm = (props) => {
    return (
        <ProgressionTab
            tabId="tabConfirmInvite"
            subtitle={"Bevestig"}
            step={2}
            //nextTab={"tab"}
            maxSteps={2}
        >
            <Content />
        </ProgressionTab>
    )
}

export default UserConfirm;

