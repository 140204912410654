import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import WidgetsIcon from '@mui/icons-material/Widgets';
import Text from "../../../../theme/text/Text";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import SPStockDetails from "../../SpStockDetails/SPStockDetails";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import SpLocationDetails from "../../SpLocationDetails/SpLocationDetails";
import { useGetWarehouseLocationQuery } from "../../../../../newapi/warehouse/warehouselocationSlice";
import { useGetProductQuery } from "../../../../../newapi/warehouse/productSlice";
import { useGetOrderPickingListPDFMutation, useGetOrderPickingLinesQuery, useUpdateOrderPickingLineMutation } from '../../../../../newapi/order/ordersSlice';

import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";
import PrintIcon from '@mui/icons-material/Print';
import { showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [GetOrderPickingListPDF] = useGetOrderPickingListPDFMutation();
    const [isFetchingPdf, setIsFetchingPdf] = useState(false);

    const handleButtonClick = async () => {
        setIsFetchingPdf(true);
        try {
            const response = await GetOrderPickingListPDF({orders: [props.data.id]}).unwrap();
            const newWindow = window.open(response.data, '_blank', 'noopener,noreferrer');
            if (newWindow) {
                setTimeout(() => {
                    window.URL.revokeObjectURL(response.data);
                }, 500);
            } else {
                window.URL.revokeObjectURL(response.data);
            }
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        } finally {
            setIsFetchingPdf(false);
        }
    };
    
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
                <Shbutton
                        className={"group-btn"}
                        onClick={handleButtonClick}
                        color={theme.palette.mode === "dark" ? "blue" : "primary"}
                        variant="contained"
                        endIcon={<PrintIcon />}
                        disabled={isFetchingPdf}
                    >
                    {isFetchingPdf ? t('loading') : t('print')}
                </Shbutton>
            </ButtonGroup>
        </Box>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const PickingLines = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [locationIsExtended, setLocationIsExtended] = useState(false);

    const [view, setView] = useState({
        title: 'pickinglines',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
        apiRef: props.apiRef,
        mobileActions: <GridActions data={props.data} />,
        gridOptions:gridOptions
    });

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions data={props.data} />,
            gridOptions:gridOptions
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    


    const [locationId, setLocationId] = useState();
    const selectedLocationRowIdRef = useRef(locationId);
    useEffect(() => {
        selectedLocationRowIdRef.current = locationId;
    }, [locationId]);

    const { data: locationData, isLoading: isLoadingLocation } = useGetWarehouseLocationQuery(locationId,
        {
            skip: !locationId
        });
    const getLocationDetails = (id) => {
        if (!id) return;
        if (typeof selectedLocationRowIdRef.current !== "undefined" &&  parseInt(selectedLocationRowIdRef.current) === parseInt(id)) {
            setLocationIsExtended(true);
        } else {
            setLocationId(id);
        }
    };
    useEffect(() => {
        if (!isLoadingLocation && locationData) {
            setLocationIsExtended(true);
        }
    }, [locationData, isLoadingLocation]);




    const [productId, setProductId] = useState();
    const selectedProductRowIdRef = useRef(productId);
    useEffect(() => {
        selectedProductRowIdRef.current = productId;
    }, [productId]);

    const { data: productData, isLoading: isLoadingProduct } = useGetProductQuery(productId,
        {
            skip: !productId
        });
    const getProductDetails = (id) => {
        if (!id) return;
        if (typeof selectedProductRowIdRef.current !== "undefined" &&  parseInt(selectedProductRowIdRef.current) === parseInt(id)) {
            setIsExtended(true);
        } else {
            setProductId(id);
        }
    };
    useEffect(() => {
        if (!isLoadingProduct && productData) {
            setIsExtended(true);
        }
    }, [productData, isLoadingProduct]);

    const [EditPickingLine, {pickingLineIsLoading}] = useUpdateOrderPickingLineMutation();
    const handlePickingLineLocationUpdate = async (params, stockObj) => {
        try {
            const response = await EditPickingLine({id: params.id, orderId: params.orderId, stockId: stockObj.id}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            console.log(err);
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const columns = [
        {
            field: "location", 
            headerName: t('location'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                if (params.row.stock.product.stocks && params.row.stock.product.stocks.length > 1) {
                    return <InputDropdown
                    displayName={"warehouseLocation"} 
                    onChange={(selected) => handlePickingLineLocationUpdate(params.row, params.row.stock.product.stocks[selected])}
                    noSelection={t("select_location")} //Custom text when nothing is selected
                    options={params.row.stock.product.stocks ?? null}
                    selected={params.row.stock.product.stocks?.findIndex(stock => params.row.stock.id === stock.id) ?? -1}
                    closeOnSelection
                />
                } else {
                    return (
                    <Box onClick={() => getLocationDetails(params.row.stock?.warehouseLocation?.id)}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.warehouseLocation?.location}</Text>
                    </Box>
                    )
                }
            }
        },
        {
            field: "sku", 
            headerName: t('sku'),
            flex:1.5, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }
                return (
                    <Box paddingLeft={0} onClick={() => getProductDetails(params.row.stock?.product?.id)}>
                        <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.product?.sku}</Text><br/>
                        <Text clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.product?.description}</Text>
                    </Box>
                )
            }
        },
        {
            field: "amount", 
            headerName: t('amount'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.amount
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}
            sx={{
                "& .sku-cell" : {
                    paddingLeft:0,
                },
                "& [aria-label='SKU']" : {
                    paddingLeft:0,
                },
            }}
        >
            {/* <GridActions></GridActions> */}
            <ShDataGrid2
                // gridActions={<GridActions></GridActions>} 
                content={useGetOrderPickingLinesQuery}
                itemId={props.data.id}
                title={t('pickinglines')}
                gridActions={<GridActions data={props.data}></GridActions>} 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns} 
                rows={data}>
            </ShDataGrid2>
            <SPStockDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                data={productData} // Pass the productData as a prop
            ></SPStockDetails>
            <SpLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                data={locationData} // Pass the productData as a prop
            ></SpLocationDetails>
        </Box>
    );
}

export default PickingLines;