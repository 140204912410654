import { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

const SHTransition = (props) => {
    const theme = useTheme();

    useEffect(() => {
        if(props.in) {
            let names = props.classNames.split(" ");
            for(let i = 0; i < names.length; i++) {
                props.nodeRef.current.classList.add(names[i]+"-enter-done");
            }
        }
    },[theme.palette.mode])

    return (
        <CSSTransition {...props}>
            {props.children}
        </CSSTransition>
    )
} 

export default SHTransition;