import { Box, InputBase, useTheme,IconButton } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { useAddChildProductsMutation } from "../../../newapi/warehouse/productSlice";
import { showToastMessagePostRequest, showToastMessage, showToastMessageRequestError } from "../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../utils/validation";

const MAddChildProduct = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [addChildProducts,{isLoading}] = useAddChildProductsMutation();

    const [newChildProducts, setNewChildProducts] = useState([{
        sku: "",
        quantity: 0,
        index: 1
    }]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
            sku: yupRequired('sku', t),
        };
        
        const schema = yup.object().shape(rules);



    const validateChildProducts = () => {
        for (const child of newChildProducts) {
            if (child.sku.length > 0 && child.quantity < 1) {
                return { valid: false, error: `sku: ${child.sku} - ${t('no_quantity')}` };
            }
        }
        return { valid: true };
    }
      
    const saveChildProducts = async (props) => {
        try {
            setFieldErrors({});

            const validation = validateChildProducts();
            if (!validation.valid) {
                showToastMessage("error", validation.error);
                return;
            }

            const body = newChildProducts
            .filter(x => x.sku.length > 0)
            .map(x => ({
                sku: x.sku,
                quantity: x.quantity
            }));

            const data = await addChildProducts({productId: props.productId, body: {childProducts: body}}).unwrap();
            showToastMessagePostRequest(t, data);

            // Reset on success
            if (data.success) {
                // reset object
                setNewChildProducts([{
                    sku: "",
                    quantity: 0,
                    index: 1
                }]);
                
                props.handleClose();
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessagePostRequest(t('add_failed'), t, err);
              }
            
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (index, key, value) => {
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
        setNewChildProducts(currentProducts => {
          return currentProducts.map(product => {
            if (product.index === index) {
              return {
                ...product,
                [key]: value
              };
            }

            return product;
          });
          
        });

        if (newChildProducts[newChildProducts.length - 1].sku.length > 0) {
            setNewChildProducts([...newChildProducts, {
                sku: "",
                quantity: 0,
                index: newChildProducts[newChildProducts.length - 1].index + 1
            }]);
        }
      };
      
    const handleDeleleteChildRow = (index) => {
        const updatedChildProducts = newChildProducts.filter(product => product.index !== index);
        setNewChildProducts(updatedChildProducts);
    }

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_products")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                {newChildProducts.map((childProduct, index) => (
                <Box flex={1}
                    sx={{
                        display:"flex",
                        gap:4,
                        justifyContent:"flex-start",
                        flexDirection:"column",
                    }}
                >
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:40,
                            }}
                        >
                            <Text light>{t("sku")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <ShValidatedInput
                                    name="sku"
                                    value={childProduct.sku}
                                    onChange={handleChange}
                                    error={fieldErrors.name}
                            />

                        </Box>
                    </Box>
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:64,
                            }}
                        >
                            <Text light>{t("quantity")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:1,
                                width:120,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <NumberInput btnSize={"24"}
                                value={childProduct.quantity}
                            change={(e) => handleChange(childProduct.index, 'quantity', e)}/>
                        </Box>

                        { childProduct.index > 1 ?
                        <>
                        <IconButton type="button" onClick={() => handleDeleleteChildRow(childProduct.index)} sx={{p:1}}>
                            <DeleteIcon/>
                        </IconButton>
                        </> 
                        : null
                        }
                    </Box>
                </Box>
                

                ))}
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => saveChildProducts(props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddChildProduct;
