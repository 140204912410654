import { Box, useTheme } from "@mui/material";
import Text from "../../../theme/text/Text";
import Shbutton from "../../../theme/buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { tokens } from "../../../../theme";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect, useState } from "react";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel/SidepanelHeaderActiongroup";
import { useGridApiRef } from "@mui/x-data-grid";
import Sidepanel from "../../../theme/sidepanel/Sidepanel";
import { useTranslation } from 'react-i18next';
import Details from "./Views/Details";

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import { spUserDetailsConfig } from "./spUserDetailsConfig";
const SidePanelActions = (props) => {
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                Cancel
            </Shbutton>

        </SidepanelHeaderActiongroup>
    )
}

const SpUserDetails = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data } = props

    const [panel, setPanel] = useState({
        title: "",
        subTitle: "",
        icon :{
            theme: theme.palette.mode === "dark" ? "blue": "primary",
            content: <PersonOutlinedIcon/>
        },
        options : [],
        actions:<SidePanelActions setIsExtended={props.setIsExtended} />   
    });

    useEffect(() => {
        if (typeof data !== "undefined" && data !== null && typeof data.id !== "undefined") {      
          setPanel((prevPanel) => ({
            ...prevPanel,
            title: `${data.firstname}${data.insertion ? "" + data.insertion : ""} ${data.lastname}`,
            icon :{
                theme: theme.palette.mode === "dark" ? "blue": "primary",
                content: <PersonOutlinedIcon/>
            },
            subTitle: data.email,
            options: [],
          }));
        }
      }, [data,theme.palette.mode]);

    return (
        <Sidepanel2
            tabConfig={spUserDetailsConfig}
            content={panel} 
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended}>
                <>
                    <Details config={spUserDetailsConfig.details} data={props.data}/>
                </>
        </Sidepanel2>
    );
};

export default SpUserDetails;