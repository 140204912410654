import { Box, colors, useTheme } from "@mui/material";
import FilterGroup from "../FilterGroup";
import { tokens } from "../../../../../theme";
import FilterRuleBuilder from "../Builder/FilterRuleBuilder";
import SelectFilterColumnView from "../Builder/views/SelectFilterColumnView";
import { useEffect, useRef, useState } from "react";
import SelectFilterConditionView from "../Builder/views/SelectFilterConditionView";
import SelectFilterRuleExtensionTypeView from "../Builder/views/FilterRuleExtensionTypeView";
import SelectFilterValueView from "../Builder/views/SelectFilterValueView";
import { useFilterbuilder } from "../../../../../contexts/FbState";
import SelectFilterGroupExtensionTypeView from "../Builder/views/FilterGroupExtensionTypeView";
import { useFilter } from "../../../../../contexts/FilterState";
import { useDispatch, useSelector } from "react-redux";
import { resetFilterBuilderSettings, setFilterBuilderAddToFilterGroup } from "../../../../../newapi/global/filter/builder/gridFilterBuilderSettingsSlice";
import { resetFilterBuilderRule } from "../../../../../newapi/global/filter/builder/gridFilterBuilderRuleSlice";

import Inventory2Icon from '@mui/icons-material/Inventory2';

const AdvancedFilterView = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const builderRef = useRef();
    const filterBuilder = useFilterbuilder();
    // const ref = useRef();
    const {filter,updateFilter} = useFilter();
    const dispatch = useDispatch();

    const settings = useSelector(state => state.gridFilterBuilderSettings);
    const ruleBuilder = useSelector(state => state.gridFilterBuilderRule);
    // console.log("settings advc", settings);
    // console.log("settings rules", ruleBuilder);

    const [view, setView] = useState({
        title: "Advanced",
        icon: <Inventory2Icon/>,
    })

    useEffect(() => {
        props.addView({
            ...view,
            apiRef: props.apiRef
        },false);

        return () => {
            console.log("unmounting warehouse");
        }
    },[]);
    

    //Modal
    const [open, setOpen] = useState(false);
    const views = [
        {...SelectFilterGroupExtensionTypeView(props,builderRef,!settings.addToFilterGroup)},
        {...SelectFilterRuleExtensionTypeView(props,builderRef,settings.addToFilterGroup)},
        {...SelectFilterColumnView(props,builderRef)},
        {...SelectFilterConditionView(props,builderRef)},
        {...SelectFilterValueView(props,builderRef,ruleBuilder.type.name === "boolean")}
    ]

    //Reset everything
    const resetFilter = () => {
        dispatch(resetFilterBuilderSettings());
        dispatch(resetFilterBuilderRule());

        // filterBuilder.updateColumn(-1);
        // filterBuilder.updateConditionExtensionType(-1);
        // filterBuilder.updateType({name:"",value:-1});
        // filterBuilder.updateValue(null);
        // filterBuilder.updateFinishedBuilding(false);
        // filterBuilder.updateSettings({addToFilterGroup:false, groupToAddTo:-1,newGroup:{conditionExtensionType:"and",rules:[]}});
    }

    const handleOpen = (isFilterGroup) => {
        // resetFilter(); // reset

        dispatch(setFilterBuilderAddToFilterGroup(isFilterGroup));
        //Context
        // let settings = filterBuilder.settings;
        // settings.addToFilterGroup = isFilterGroup;
        // filterBuilder.updateSettings(settings);

        //Open the builder
        builderRef.current.setView(0);
        setOpen(true);
    };

    const handleClose = (finishedBuilding) => {
        // setOpen(false)
        if(finishedBuilding) {
            console.log("ruleBuilder",ruleBuilder);
            //Save filter
            // let settings = filterBuilder.settings;
            // let newFilter = {
            //     column: filterBuilder.column,
            //     extensionType: filterBuilder.conditionExtensionType,
            //     type : filterBuilder.type,
            //     value: filterBuilder.value
            // }

            // if(settings.addToFilterGroup){
            //     let newGroup = {
            //         conditionExtensionType: settings.newGroup.conditionExtensionType,
            //         rules: []
            //     }
            //     newGroup.rules.push(newFilter);
            //     filter.groups.push(newGroup);
            // } else {
            //     // settings.groupToAddTo
            //     //Add new filter rule to the over headed filter group
            //     filter.groups[settings.groupToAddTo].rules.push(newFilter);
            // }
            // console.log("filter",filter);
            // updateFilter(filter);
            // props.filterRef.current.applyNewFilterGroup(filter.groups.length-1);
        }
        setOpen(false);
    };

    return (
        props.isActive ?
            <Box sx={{
                padding:1.5,
                width: "520px",
                maxHeight:"350px",
                display:"flex",
                flexDirection:"column",
                overflow:"auto",
                gap:1.5,
                "@media screen and (max-width: 47.9375em)" : {
                    width:1,
                }
            }}>
                {/* Hi */}
                {typeof filter.groups !== "undefined" ?
                <>
                    {filter.groups.map((fg, index) => (
                        <FilterGroup 
                            {...props}
                            handleFbOpen={() => handleOpen(false)}
                            index={index} 
                            key={index} 
                            fgOptions={fg}/>
                    ))}
                
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"center",
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50]}`,
                            paddingY:4,
                            borderRadius:4,
                            color:colors.txt["primary"],
                            fontSize:14,
                            fontWeight:700,
                            userSelect:"none",
                            cursor:"pointer",
                            transition:"250ms",
                            marginBottom:0,
                            "&:hover" : {
                                background:`${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100]}`,
                                transition:"250ms",

                            }
                        }}

                        onClick={() => handleOpen(true)}
                    >
                        Add filter group
                    </Box>
                </>
                :null}
                {/* <Box width={1} height={"1px"}>&nbsp;</Box> */}

                <FilterRuleBuilder ref={builderRef} open={open} handleClose={handleClose} views={views}></FilterRuleBuilder>
                
                {/* <FilterGroup {...props} fgOptions={{conditionExtensionType: "where", rules:[]}} /> */}
            </Box>
        : null
    )
}

export default AdvancedFilterView;