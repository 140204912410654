import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const WORKSPACE_BASE = "/workspaces";
const buildUrl = (endpoint) => WORKSPACE_BASE + endpoint;

const workspaceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createWorkspace: builder.mutation({
            query: workspaceData => ({
                url: buildUrl('/new'),
                method: 'POST',
                body: workspaceData,
            }),
            invalidatesTags: [{ type: 'Workspaces' }],
        }),
        findAllWorkspaces: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: [{ type: 'Workspaces' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'findAllWorkspaces', 'Workspaces')
        }),
        findWorkspaceById: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Workspace', id }],
        }),
        updateWorkspace: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Workspace', id }, { type: 'Workspaces' },{ type: 'WorkspacesSelect' }],
        }),
        deleteWorkspace: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Workspace', id }, { type: 'Workspaces' },{ type: 'WorkspacesSelect' }],
        }),
        findWorkspacesSelect: builder.query({
            query: () => ({
                url: buildUrl(``),
                method: 'POST',
                body: ''
            }),
            providesTags: [{ type: 'WorkspacesSelect' }],
        }),
    })
});

export const {
    useCreateWorkspaceMutation,
    useFindAllWorkspacesQuery,
    useFindWorkspaceByIdQuery,
    useUpdateWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useFindWorkspacesSelectQuery
} = workspaceApiSlice;
