import { useState } from "react";
import ViewPortController from "./ViewPortController"
import { Box, ButtonBase, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import cx from 'classnames';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export const viewPorts = {
    sx: "sx",
    sm: "sm",
    lg: "lg",
}

const ReturnPortalViewPort = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    
    // Ret
    const [viewPort, setViewPort] = useState(viewPorts.lg);

    console.log("props.addModal",props.addModal);
    const handleModal = () => {
        if(props.addModal.open) { 
            props.setAddModalOpen(false);
        } else {
            props.setAddModalOpen(true);
        }
    }
    return (
        <Box
            sx={{
                flex: 1,
                overflow: 'hidden',
                minHeight: "1px",
                display: 'flex', // Ensure the parent uses flexbox
                flexDirection: 'column', // Ensure the parent uses column direction
            }}
        >
            <Box
                className={cx('',{
                    'modal': props.open
                })}
                sx={{
                    position:'relative',
                    p: 3,
                    width: 1,
                    overflow: "auto",
                    flex: 1, // Ensure the inner container takes the remaining height
                    // background: "orange",
                    display:"flex",
                    justifyContent:'center',
                    "&.modal": {
                        p:0,
                    }
                }}
            >
                {/* make it full screen preview */}
                {!props.open &&
                    <ButtonBase
                        onClick={handleModal}
                        sx={{
                            position:"absolute",
                            zIndex:8,
                            right:48,
                            top:48,
                            height:40,
                            width:40,
                            borderRadius:5,
                            color:"#31415f",
                            background:"rgba(255,255,255,1)",
                            border:"1px solid #ced7e1",
                            boxShadow: "0 1px 2px #31415f"
                            // background:colors.blue['400'],
                        }}
                    >
                        <OpenInFullIcon fontSize="small"/>
                    </ButtonBase>
                }

                <ViewPortController setViewPort={setViewPort} activeViewPort={viewPort}></ViewPortController>

            


                <Box 
                
                    className={cx('', {
                        'sx': viewPort === viewPorts.sx,
                        'sm': viewPort === viewPorts.sm,
                        'lg': viewPort === viewPorts.lg,
                        'modal': props.open
                    })}
                    sx={{
                        transition:"300ms",
                        borderRadius:4,
                        // height:800,
                        // background:"black",
                        "&.sx": {
                            width: 420 //nice
                        },
                        "&.sm" : {
                            width:768
                        },
                        "&.lg": {
                            width:1,
                            "&.modal" : {
                                width:1280
                            }
                            // minWidth:1028
                        }

                    }}
                >
                     {/* <iframe
                    title="viewport"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    srcDoc={`<!DOCTYPE html>
                        <html lang="en">
                        <head>
                            <meta charset="UTF-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                            <style>
                                body { margin: 0; }
                            </style>
                        </head>
                        <body>
                            <div id="root"></div>
                            <script>
                                const root = document.getElementById('root');
                                const App = ${props.children};
                                ReactDOM.render(App, root);
                            </script>
                        </body>
                        </html>`}
                /> */}
                    {props.children}

                </Box>

            </Box>
        </Box>
    )
}

export default ReturnPortalViewPort;