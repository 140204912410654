
// import { ReactComponent as TableEmpty } from '../../../..styles/svg/tableempty.svg'
import { ReactComponent as TableEmpty } from '../../../../styles/svg/tableempty.svg'
import { useTranslation } from "react-i18next";
import Subheading from "../../text/Subheading";
import { Box } from '@mui/material';
import Text from '../../text/Text';

export const NoChartData = (props) => {
    const {t} = useTranslation();
    return (
        <Box 
            sx={{
                position:"absolute",
                top:0,
                width:props.type === "donut" ? 1 : "calc(100% + 24px)",
                left:props.type === "donut" ? 0 : -24,
                height:1,
                // background:"rgba(255,255,255,.15)",
                display:"flex",
                justifyContent:"center",
                backdropFilter:"blur(6px)",
                zIndex:4,
            }}
        >
            <Box
                sx={{
                    mt:-3,
                    // maxWidth:300,
                    display:"flex",
                    justifyContent:"center",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >
                <TableEmpty/>
                <Box
                    sx={{
                        mt:3,
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        gap:0.5
                    }}
                >
                    <Subheading align="center">{t("grid_empty_title")}</Subheading>
                    {/* <Text align="center" light>Er is geen informatie gevonden voor xxx</Text> */}
                </Box>
            </Box>
        </Box>
    );
}