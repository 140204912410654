/**
 * This transforms the general slice to only save particular keys
 */

import createTransform from "redux-persist/es/createTransform";

// createTransform
const generalTransform = createTransform(
  // Only save `keyToSave` from the state.
  (inboundState, key) => {
    return {
      enums: inboundState.enums
    };
  },
  // Use the outbound state as-is when rehydrating.
  (outboundState, key) => {
    return outboundState;
  },
  {
    // Specify the reducer this transform gets applied to.
    whitelist: ['general']
  }
);

export {generalTransform};