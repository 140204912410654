import { Box, ButtonBase, Typography, useTheme } from "@mui/material"
import { tokens } from "../../theme";


import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as StockVisual } from '../../styles/svg/rmaportal/stockvisual.svg';



const NotFound = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();


    return (
        <Box
            component={"main"}
            sx={{
                // minHeight:"calc(100%)"
                zIndex:1,
            }}
        >
            <Box
                component={"section"}
                sx={{
                    // background:"rgba(65,128,246,.10)",
                    background: "rgba(65,128,246,.10)",
                    background: "radial-gradient(circle, rgba(65,128,246,0.25) 0%, rgba(65,128,246,0.10) 100%)",
                    width:1,
                    minHeight:"100vh",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"flex-start",
                    paddingTop:"200px",
                    paddingBottom:"200px",
                    // height:"100vh",
                    position:'relative',
                    overflow:"hidden",
                    "@media screen and (max-width: 1280px)" : {
                        py:"120px",
                    },

                    ".stock-visual" : {
                        position:'absolute',
                        bottom:"0",
                        width:1,
                        zIndex:0,
                    }
                }}
            >

                <Box
                    sx={{
                        width:768,
                        zIndex:2,
                        "@media screen and (max-width: 920px)" : {
                            px:4,
                        },
                        "@media screen and (max-width: 768px)" : {
                            px:3,
                        }
                    }}
                >
                    <Box>
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize:"2.75em",
                                fontWeight:"100",
                                color:"#31415f",
                            }}
                        >
                            {t("order_not_found")}
                        </Typography>
                        {/* <Typography 
                            variant="p"
                            sx={{
                                color:"rgba(49,65,95,1)",
                                fontSize:17,
                            }}
                        >
                            Selecteer de te retourneren producten, het aantal en de retourreden.
                        </Typography> */}
                    </Box>
                    <Box
                        className={"order-container"}
                        sx={{
                            alignItems:"stretch",
                            gap:3,
                            mt:3,
                        }}
                    >
                        <Box
                            className={"order-wrapper"}
                            sx={{
                                width:"66%",
                                background:"rgba(255,255,255,.35)",
                                borderRadius:6,
                                backdropFilter:"blur(4px)",
                                width:1
                            }}
                        >
                            <Box className={"order-content"}
                                sx={{
                                    padding:3,
                                    // pb:0,
                                }}
                            >

                                    <Typography
                                        variant="p"
                                        sx={{
                                            fontSize:16,
                                            color:"#31415f",
                                            
                                        }}
                                    >
                                        {t("return_code_not_found_1")} <br/> {t("return_code_not_found_2")}
                                    </Typography>


                            </Box>
                        </Box>

                    </Box>
                </Box>

                <StockVisual className="stock-visual"/>
            </Box>
        </Box>
    )
}

export default NotFound