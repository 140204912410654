import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme";

const Circle = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box 
            sx={{
                width:8,
                height:8,
                marginX:1.5,
                background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[200],
                borderRadius:1
            }}
        ></Box>
    )
}

export default Circle;